import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Container } from '../Container';
import { Label } from '../Label';
import { Icon } from '../Icon';

/**
 * Primary UI component for user interaction
 */
export const Toggle = ({ label, subLabel, enabled, gap, size, onClick }) => {

  const theme = useTheme();

  const renderToggle = () => {
    const toggleStyle = size === "medium" ? style.toggleMedium(theme)({ enabled }) : style.toggleSmall(theme)({ enabled });
    const pinStyle = size === "medium" ? style.pinMedium(theme)({ enabled }) : style.pinSmall(theme)({ enabled });
    return (
      <div css={toggleStyle} onClick={onClick}>
        <div css={pinStyle}>
          { // Se stiamo renderizzando il toggle piccolo, non mostriamo l'icona anche con toggle checked,
            // Perchè sarebbe troppo piccola e brutta da vedere
            (enabled && size !== "small") &&
            <Icon name={"CheckSolid"} color={"primary"} />
          }
        </div>
      </div>
    );
  };

  if (!label) { // Se non ho la label, allora renderizzo solamente il toggle
    return renderToggle();
  }

  // Se ho la label, renderizzo il toggle con la label ed eventualmente anche la sublabel
  // Se non ho la subLabel, allora centro il toggle rispetto alla sola label, altrimenti
  // se ho anche una subLabel, il toggle NON deve essere centrato verticalmente 
  // perchè altrimenti verrebbe sempre centrato rispetto a label + sublabel
  return (
    <div css={style.base(theme)({ gap, center: subLabel ? false : true })}>
      <div css={style.toggleContainer}>
        {renderToggle()}
      </div>
      <div css={style.labelContainer}>
        <Label size={size}>
          {label}
        </Label>
        {
          subLabel &&
          <Label size={size} color={"text_light"}>
            {subLabel}
          </Label>
        }
      </div>
    </div>
  );
};


Toggle.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  enabled: PropTypes.bool,
  gap: PropTypes.oneOf(['micro', 'small', 'standard', 'medium', 'large', 'xl']),
  size: PropTypes.oneOf(['small', 'medium']), // La grandezza del toggle stesso
  onClick: PropTypes.func
};

Toggle.defaultProps = {
  label: null,
  subLabel: null,
  enabled: false,
  gap: "small",
  size: "medium",
  onClick: null,
};