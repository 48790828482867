var CookiesStorage = {};

/**
 * Default expiration time expressed in days
 */
CookiesStorage.expires = 10000;

/**
 * Check if cookies are available
 */
CookiesStorage.exist = function () {
    if (navigator !== undefined) {
        return navigator.cookieEnabled;
    }

    return false;
};

/**
 * Store a new cookie
 *
 * @param name: String
 * @param value: String|Int
 * @param expiration: String|Int
 */
CookiesStorage.setItem = function (name, value, expiration) {
    var date = new Date(),
        expirationDays = expiration || this.expires,
        expires;

    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    expires = "expires=" + date.toUTCString();

    try {
        document.cookie = name + "=" + value + "; " + expires;
    } catch (e) { }
};

/**
 * Retrieve a cookie
 *
 * @param name: String
 */
CookiesStorage.getItem = function (name) {
    var ca = document.cookie.split(';');

    name = name + "=";

    for (var i = 0, length = ca.length; i < length; i++) {
        var c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
};

/**
 * Delete a cookie
 *
 * @param name: String
 */
CookiesStorage.removeItem = function (name) {
    // Setting the num days to -1 I force the
    // expiration date to "Thu Jan 01 1970 00:59:59 GMT+0100 (CET)"
    // and this represent a cookie yet expired
    this.setItem(name, null, -1);
};

export default CookiesStorage;