import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import { Label } from '../../Label';

import { useButtonContext } from '../Context';

import {
  CLICK_ACCEPT,
  CLICK_NEXT,
  CLICK_PREV
} from "../constants";


import * as style from "./style";

/**
 * Primary UI component for user interaction
 */
export const BookButtonMobile = ({ data }) => {

  const theme = useTheme();

  const { handleClick, click, disabled } = useButtonContext();

  const visible = useMemo(() => click === CLICK_ACCEPT, [click]);

  const ROOT = document.getElementsByTagName("body")[0];

  return ReactDOM.createPortal(
    <>
      <div css={style.wrapper}>
        <div css={style.content(theme)({visible})}>
          <Label size={"large"} weight={"bold"}>
            {data.title}
          </Label>
          <div css={visible ? style.visible : style.hidden}>
            <Label size={"medium"} weight={"regular"}>
              {data.description}
            </Label>
          </div>
        </div>
        <button
          data-e2eid={visible ? "summary-book-dialog-proceed" : "summary-book-btn"}
          type="button"
          css={style.button(theme)({ disabled })}
          onClick={() => handleClick(CLICK_NEXT)}
        >
          {visible ?
            data.secondStepButtonTitle :
            data.firstStepButtonTitle
          }
        </button>
      </div>
      {visible ?
        <div css={style.overlay} onClick={() => handleClick(CLICK_PREV)} /> :
        null
      }
    </>,
    ROOT
  );
};

BookButtonMobile.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    firstStepButtonTitle: PropTypes.string,
    secondStepButtonTitle: PropTypes.string
  }).isRequired
};

BookButtonMobile.defaultProps = {
  data: null
};