import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MOP from 'app';

const Header = ({ page, showBack, title }) => {
  
  if (!showBack.show || page === 'home') return null;

  let callback = MOP.HeaderApp.showBackCallback;

  useEffect(() => {
    callback = MOP.HeaderApp.showBackCallback;
    if (showBack && ref.current) {
      const mainWrapperContent = document.getElementById('main-wrapper-content-region');
      const backHeight = ref.current.clientHeight;
      if(mainWrapperContent){
        mainWrapperContent.style.height = `calc(100% - ${backHeight}px)`;
        mainWrapperContent.style.top = `${backHeight}px`;
        mainWrapperContent.style.bottom = 0;
      }
    }
  }, [showBack]);

  const ref = useRef(null);

  return (
    <div className="tt-page-action-container tt-js-page-action-container" ref={ref}>
      <div className="tt-page-action-space">
        <div className="tt-page-action">
          <div className="tt-page-action-action" onClick={callback}>
            <div className="tt-page-action-icon">
              <i className="tt-icon-arrow-left"></i>
            </div>
            <div className="tt-page-action-text">
              {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ activePage, showBack }) => {

  return {
    page: activePage.page,
    showBack,
    title: activePage.title
  };

};


export default connect(mapStateToProps, null)(Header);