import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  cursor: "pointer",
  width: "100%"
});

export const patient = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
  cursor: "pointer"
});