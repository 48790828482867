import { css } from "@emotion/react";

export const base = theme => isMobile => css({
  display: "flex",
  flexDirection: "column",
  gap: isMobile ? theme.layout.gap.medium : theme.layout.gap.small,
  width: "100%",
});

export const number = theme => fontSize => css({
  textAlign: "center",
  fontSize: fontSize || "2.5rem", // 40px
  lineHeight: "100%",
  color: theme.colors.text,
  fontWeight: theme.fonts.weight.semiBold
});



