import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { Icon } from 'ui/Icon/index.jsx';
import { useTheme } from '@emotion/react';

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  e2eid,
  type,
  icon,
  children,
  onClick,
  disabled,
  link,
  danger,
  size,
  width,
  loading
}) => {

  const theme = useTheme();

  const renderLoading = () => {
    if (loading)
      return <Icon name={"SpinnerThirdSolid"} size={"medium"} rotate={true} />;
  };

  const renderIcon = icon => {

    if (!icon) return null;

    return <Icon name={icon} size={"medium"} />;
  };

  const getColor = () => {

    // Se sono false tutte le tre props: danger, link, primary allora il colore di default è il primary
    let color = theme.colors.primary;

    // Altrimenti setto il colore rispetto alle tre props.
    // L'ordine è importante per eventuali errori in cui 2 props sono TRUE, in questo caso abbiamo sempre la priorità corretta
    if (danger) color = theme.colors.danger;
    if (link) color = theme.colors.link;

    return color;
  };

  return (
    <button
      type="button"
      css={[style.base(theme)({ loading, disabled, size, width }), style[type](theme)({ color: getColor() })]}
      onClick={onClick}
      {...e2eid ? {"data-e2eid":e2eid } : null}
    >
      {renderIcon(icon)}
      {children}
      {renderLoading()}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["solid", "light", "text"]),
  link: PropTypes.bool,
  danger: PropTypes.bool,
  size: PropTypes.oneOf(["small", "normal", "big"]),
  icon: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  loading: PropTypes.bool,
  e2eid: PropTypes.string
};

Button.defaultProps = {
  type: "solid",
  link: false,
  danger: false,
  size: "normal",
  icon: null,
  children: "",
  onClick: null,
  disabled: false,
  width: "initial",
  loading: false,
  e2eid: null
};
