import MOP from 'app';
import {getDateFromFormatted} from 'common-lib/dates';

export const getFullName = ({fname, social_name, lname, lname2}) => {
  return `${social_name ? social_name : fname} ${lname ? lname : ''} ${lname2 ? lname2 : ''}`;
}

export const calcAge = ({birthday}) => {
  const birthdayDate = getDateFromFormatted(MOP, birthday)
  
  const today_date = new Date();
  const today_year = today_date.getFullYear();
  const today_month = today_date.getMonth();
  const today_day = today_date.getDate();
  let age = today_year - birthdayDate.get('year');

  const birth_month = birthdayDate.get('month');
  const birth_day = birthdayDate.get('date');

  if (today_month < (birth_month - 1)) {
    age -= 1;
  }
  if (((birth_month - 1) === today_month) && (today_day < birth_day)) {
    age -= 1;
  }
  return age;
};