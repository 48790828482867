import React from 'react';
import { connect } from 'react-redux';
import MOP from 'app';

const SupportTab = ({ support, disabled }) => {

  if(!support) return null;

  const itemClicked = item => {
    const {page, route, callback} = item;
    MOP.changePage(page, route);
    if(callback) return callback;
  };

  return (
    <li onClick={() => itemClicked(support)} className={`pageLink tt-navbar__link ${disabled ? 'tt-pointer-disabled' : ''}`} id={support.id}>
      <a className="tt-navbar__link__text">
        <i className="tt-navbar__link__text-icon tt-icon-question-circle"></i>
      </a>
    </li>
  );
};

const mapStateToProps = state => ({
  disabled: state.disabled
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SupportTab);