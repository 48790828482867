const base = 0;
const above = 1;

// Layout
const zLayoutNavigation = base; // 0
/**
 * * A differenza del BO andiamo a mettere zLayoutModalOverlay e zLayoutModalContent con valori molto altri per evitare
 * * che i dialog del DS vadano sotto altri elementi già presenti nel MOP
 */
const zLayoutModalOverlay = 99998;
const zLayoutModalContent = 99999;
const zLayoutLoading = above + zLayoutModalContent;

// General
const zTooltip = zLayoutNavigation + above; // 1
const zBookButtonMobile = zLayoutNavigation + above;

// Navigation
const zNavDropdown = above + zLayoutNavigation; // 1
const zNavDrawer = above + zNavDropdown; // 2
const zMopLauncher = above + zNavDrawer;

// Data Entry
const zDataSelect = above + zLayoutNavigation; // 1

export const layerIndex = {
  navigation: zLayoutNavigation,
  loading: zLayoutLoading,
  modalOverlay: zLayoutModalOverlay,
  modalContent: zLayoutModalContent,
  tooltip: zTooltip,
  dropdown: zNavDrawer,
  drawer: zNavDrawer,
  dataSelect: zDataSelect,
  mop: zMopLauncher,
  bookButtonMobile: zBookButtonMobile
};
