import React from 'react';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { useTheme } from '@emotion/react';

import * as style from "./style";

import { Label } from "ui/Label";
import { Icon } from "ui/Icon";
import { useMediaQuery } from 'react-responsive';

export const PayBeforeCheckin = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  if (isMobile)
    return (
      <div css={style.mobileContainter}>
        <Label color="text_light" size="medium">{TTPolyglot.t("MOP Fast Checkin Info")}</Label>
      </div>
    );

  return (
    <div css={style.base}>
      <div css={style.textContainer}>
        <Label size="medium" weight="medium">{TTPolyglot.t("MOP Fast Checkin Title")}</Label>
        <Label color="text_light" size="medium">{TTPolyglot.t("MOP Fast Checkin Info")}</Label>
      </div>
      <div css={style.iconContainer}>
        <Icon name="FastCheckin" color="chinese_blue" size="xxxl" />
      </div>
    </div>
  );
};
