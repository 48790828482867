import MOP from "app";
import ShowController from "apps/footer/show/show_controller";

MOP.module("FooterApp", function (FooterApp, MOP, Backbone, Marionette, $, _) {
	FooterApp.page = 'footer';

	FooterApp.onStart = function () {
		MOP.Utilities.log(MOP, "starting FooterApp");
	};

	FooterApp.onStop = function () {
		MOP.Utilities.log(MOP, "stopping FooterApp");
	};

	var API = {
		showFooter: function () {
			ShowController.showFooter();
		}
	};

	MOP.addInitializer(function () {
		API.showFooter();
	});

	MOP.on("footer:show", function () {
		API.showFooter();
	});

});

export default MOP.FooterApp;
