import MOP from "app";
import View from "apps/footer/show/show_view";

MOP.module("FooterApp.Show", function (Show, MOP, Backbone, Marionette, $, _) {

	Show.Controller = {
		showFooter: function () {
			var footer_view = new View.Footer({});
			MOP.footerRegion.show(footer_view);
		}
	};
});

export default MOP.FooterApp.Show.Controller;
