import { css } from "@emotion/react";

export const base = theme => css({
  display: "flex",
  alignItems: "flex",
  boxSizing: "border-box",
  color: theme.colors.primary,
  flexShrink: 0,
  alignSelf: "flex-start",
  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,

  marginRight: "15px",
  borderRadius: "40px",
  height: "40px",
  width: "40px",
  objectFit: "cover",
  objectPosition: "center top",
});