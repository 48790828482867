import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

/**
 * Primary UI component for user interaction
 */
export const Box = ({ e2eid, verticalAlign, children, direction, gap, columnGap, horizontalAlign, width, height, flexWrap }) => {

  const theme = useTheme();

  return (
    <div {...e2eid ? {"e2eid": `${e2eid}-icon` } : null} css={style.base(theme)({ verticalAlign, direction, gap, columnGap, horizontalAlign, width, height, flexWrap })}>
      {children}
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  verticalAlign: PropTypes.oneOf(["initial", "start", "end", "center", "baseline", "stretch", "flex-end"]),
  direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
  horizontalAlign: PropTypes.oneOf(["initial", "start", "end", "center", "space-between", "space-around", "space-evenly"]),
  gap: PropTypes.oneOf(["micro", "small", "medium", "standard", "large", "xl"]),
  columnGap: PropTypes.oneOf(["micro", "small", "medium", "standard", "large", "xl"]),
  flexWrap: PropTypes.oneOf(["wrap", "nowrap", "wrap-reverse"]),
  width: PropTypes.string,
  height: PropTypes.string
};

Box.defaultProps = {
  children: <></>,
  verticalAlign: null,
  direction: "column",
  flexWrap: "nowrap",
  horizontalAlign: undefined,
  gap: undefined,
  columnGap: undefined,
  width: null,
  height: null
};