import {retry} from 'common-lib/promises';
import { TTPolyglot } from "@frontend/tt-polyglot";


function pageLoader(MOP, page) {
  
  switch (page) {
    case 'login': return retry(() => import(/* webpackChunkName: "login" */ 'apps/login/login_app'))
    case 'contactus': return retry(() => import(/* webpackChunkName: "contactus" */ 'apps/contactus/contactus_app'))
    case 'profile': return retry(() => import(/* webpackChunkName: "profile" */ 'apps/profile/profile_app'))
    case 'payment': return retry(() => import(/* webpackChunkName: "payment" */ 'apps/payment/payment_app'))
    case 'notifications': return retry(() => import(/* webpackChunkName: "notifications" */ 'apps/notifications/notifications_app'))
    case 'blog': return retry(() => import(/* webpackChunkName: "blog" */ 'apps/blog/blog_app'))
    case 'community': return retry(() => import(/* webpackChunkName: "community" */ 'apps/community/community_app'))
    case 'prices': return retry(() => import(/* webpackChunkName: "prices" */ 'apps/prices/prices_app'))
    case 'delay': return retry(() => import(/* webpackChunkName: "delay" */ 'apps/delay/delay_app'))
    case 'downloads': return retry(() => import(/* webpackChunkName: "downloads" */ 'apps/downloads/downloads_app'))
    case 'find_doctor': return retry(() => import(/* webpackChunkName: "find_doctor" */ 'apps/find_doctor/find_doctor_app'))
    case 'find_location': return retry(() => import(/* webpackChunkName: "find_location" */ 'apps/find_location/find_location_app'))
    case 'find_instances': return retry(() => import(/* webpackChunkName: "find_instances" */ 'apps/find_instances/find_instances_app'))
    case 'first_availability': return retry(() => import(/* webpackChunkName: "first_availability" */ 'apps/first_availability/first_availability_app'))
    case 'home': return retry(() => import(/* webpackChunkName: "home" */ 'apps/home/home_app'))
    case 'prescriptions': return retry(() => import(/* webpackChunkName: "prescriptions" */ 'apps/prescriptions/prescriptions_app'))
    case 'reservations': return retry(() => import(/* webpackChunkName: "reservations" */ 'apps/reservations/reservations_app'))
    case 'availabilities': return retry(() => import(/* webpackChunkName: "availabilities" */ 'apps/availabilities/availabilities_app'))
    case 'talk_doctor': return retry(() => import(/* webpackChunkName: "talk_doctor" */ 'apps/talk_doctor/talk_doctor_app'))
    case 'maintenance': return retry(() => import(/* webpackChunkName: "maintenance" */ 'apps/maintenance/maintenance_app'))
    case 'search':
      if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
          !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
        return retry(() => import(/* webpackChunkName: "new-search" */ 'apps/new-search/search-app'))
      } else {
        return retry(() => import(/* webpackChunkName: "search" */ 'apps/search/search-app'))
      }
    case 'agenda': return retry(() => import(/* webpackChunkName: "agenda" */ 'apps/agenda/agenda_app'))
    case 'patients': return retry(() => import(/* webpackChunkName: "patients" */ 'apps/patients/patients_app'))
    case 'dossier':
      if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['dossier'])) || 
          !MOP.Utilities.empty(MOP.querystring['enableNewDossier'])) {
            return retry(() => import(/* webpackChunkName: "new-dossier" */ 'apps/new-dossier/dossier_app'))
      } else {
        return retry(() => import(/* webpackChunkName: "dossier" */ 'apps/dossier/dossier_app'))
      }
    default: return undefined
  }
}

function startRouter(MOP) {

  var pagesObj = startIndex(MOP);

  var pageLoaderReturn = pageLoader(MOP, pagesObj.page);

  if (pageLoaderReturn != undefined) {
    pageLoaderReturn.then((module) => {

      if (!Backbone.history.start({ root: window.location.pathname })) {
        MOP.changePage(pagesObj.page, pagesObj.route, pagesObj.anchorPage, pagesObj.anchorRoute, pagesObj.alert);
      }
    })
  } else {
    if (!Backbone.history.start({ root: window.location.pathname })) {
      MOP.changePage(MOP.getFirstActivePage().page)
    }
  }
}

function startIndex(MOP) {
  MOP.getMessageFromParentFrame()

  var _page = MOP.getCurrentPage();
  var _route = MOP.getCurrentRoute();
  var _anchorPage = null;
  var _anchorRoute = null;
  var _alert = null;

  //Se _page è vuoto è perchè è un primo avvio, allora se è presente la rotta vado a recuperare la pagina
  if (MOP.Utilities.empty(_page) && !MOP.Utilities.empty(_route)) {
    _page = MOP.getPageFromRoute();

    if (!MOP.Utilities.empty(_page)) window.MOP_globals.page = _page;
  }

  var config = MOP.config;
  var querystring = MOP.querystring;

  // Se non ho la pagina vuol dire che sono arrivato senza rotta, quindi cerco una pagina da raggiungere
  if (MOP.Utilities.empty(_page)) {
    if (!mopRedirection(MOP)) {

      _page = MOP.getFirstActivePage().page;
      _route = null;

      if (_.has(querystring, 'tab')) {
        switch (querystring['tab']) {
          case '2':
            _page = 'reservations';
            if (_.has(querystring, 'print')) {
              _route = 'print/' + querystring['print'];
            }
            break;
          case '3':
            _page = 'profile';
            _route = 'profile';
            break;
          case '4':
            _page = 'downloads';
            break;
          case '5':
            _page = 'cancel';
            break;
          default:
            break;
        }

      } else if (_.has(querystring, 'forcePage')) {
        _page = querystring['forcePage'];

        // Questo fallback vecchio mi serve perchè altrimenti non viene caricato il giusto modulo
        if (_page == 'search_activity' || 
            _page == 'search_area' || 
            _page == 'search_resource') {
          _page = 'search';
        }

        if (_.has(querystring, 'forceRoute')) {
          _route = querystring['forceRoute'];

          // Di conseguenza sistemiamo anche questo sempre per retrocompatibilità
          if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
               !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
            if (_route.indexOf('search_activity') != -1) {
              _route = _route.replace('search_activity', 'search');
            } else if (_route.indexOf('search_area') != -1) {
              _route = _route.replace('search_area', 'search');
            } else if (_route.indexOf('search_resource') != -1) {
              _route = _route.replace('search_resource', 'search');
            }
          } else {
            if (_route.indexOf('search_activity') != -1) {
              _route = _route.replace('search_activity', 'search/activity');
            } else if (_route.indexOf('search_area') != -1) {
              _route = _route.replace('search_area', 'search/area');
            } else if (_route.indexOf('search_resource') != -1) {
              _route = _route.replace('search_resource', 'search/resource');
            }  
          }
        }
      }

      if (_.has(querystring, 'forceMsg')) {
        MOP.permanentLocalStorage.set("forceMsg", querystring['forceMsg']);
      }

      if (!MOP.isLoggedIn() && (_page == 'downloads')) {
        var viewOptions = config.getInstanceConfig('viewOptions');

        if (!MOP.Utilities.empty(viewOptions.isLoginRequired)) {
          _anchorPage = _page;
          _anchorPage = _route;
          _page = 'login';
          _route = null;
        }

      } else if (!MOP.isLoggedIn() && (_page == 'delay')) {
        //lasciato solo per capire se posso eliminare l'if
        _page = _page;
        _route = _route;

      } else if (!MOP.isLoggedIn() && (_page != 'search') && (_page != 'downloads') && (_page != "dossier" && _route && _route.indexOf("sharedDocuments") != -1)) {
        _anchorPage = _page;
        _anchorPage = _route;
        _page = 'login';
        _route = null;
      } else {
        // FIXME - Analizzare in caso di assenza di sessione, poichè la notifica viene mantenuta al momento in sessionStorage
        // FIXME - Generalizzarlo con diversi tipi di avviso quindi warning - success - danger, es. con un forceMsgType

        if (_.has(querystring, 'forceMsg')) {
          var _translate = TTPolyglot.t(querystring['forceMsg'], [], true);

          // Se è uguale alla chiave vuol dire che non c'è traduzione quindi non incorrere in XSS Reflected non mostriamo il banner
          if (_translate != querystring['forceMsg']) {
            _alert = {
              type: 'warning',
              msg: TTPolyglot.t(querystring['forceMsg'], [], true)
            };
          }
        }
      }
    }
  }

  return { page: _page, route: _route, anchorPage: _anchorPage, anchorRoute: _anchorRoute, alert: _alert };
}

function mopRedirection(MOP) {

  if (MOP.Utilities.isMobileApp()) {
    return false;
  }

  var config = MOP.config;
  var querystring = MOP.querystring;

  var redirecting = false;
  var letMopIframeBecomeParentOnMobile = config.getInstanceConfig('letMopIframeBecomeParentOnMobile');
  var fromApp = querystring['fromApp'];
  var mopUrl = config.getRightProtocol() + '://' + window.MOP_globals.hostname + '/mop/index.php' + '?';

  /*
    Se letMopIframeBecomeParentOnMobile == 1 andiamo ad aggiungere al parametro mopUrl il parent_location
    omesso sotto ma passato in un'altro parametro es mopIframeReturnPage=parent_location

    Poi il pulsante per tornare indietro dovrà essere visualizzato solo se mopIframeReturnPage è pieno e dovrà
    avere un semplice window location che come l'url mopIframeReturnPage
    e in query string a quell'url bisogna aggiungere un parametro per esempio dontLetMopIframeBecomeParentOnMobile
    che va aggiunto nell'if qui sotto in modo che quando si ritorna al sito del cliente non si fa il redirect di nuovo
    ad app tuotempo ma moriamo li nel sito del cliente.

    Questa soluzione è config free, se il secondo punto non piace allora bisogna andare a creare un nuovo config in cui possiamo
    scrivere l'url in cui il cliente vuole tornare.

    Infine bisogna indagare se questa soluzione funziona con il mop in lightbox
  */
  if (config.isInIFrame() && !MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
    if (!MOP.Utilities.empty(letMopIframeBecomeParentOnMobile) && config.isMobile() && MOP.Utilities.empty(fromApp) && MOP.Utilities.empty(querystring['dontLetMopIframeBecomeParentOnMobile'])) {
      mopUrl += $.param(_.omit(querystring, ['parent_location', '__utmz']));

      mopUrl += "&mopIframeReturnPage=" + querystring['parent_location'];

      MOP.sendMessageToParentFrame({
        fn: 'evalFunction',
        value: 'document.location.href=\'' + mopUrl + '\';'
      });

      redirecting = true;
    }
  }

  return redirecting;

}

export default {
  pageLoader,
  startRouter,
  startIndex
}