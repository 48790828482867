import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';

export const OtpInput = forwardRef(({ length, onChange }, ref) => {

  const theme = useTheme();

  const [val, setVal] = useState(new Array(length).fill(""));
  const refInputs = useRef(new Array(length).fill(null));

  const onlyNumbersValidate = val => {
    const re = /^[0-9\b]+$/;
    return re.test(val);
  };

  const handleOnChange = (event, index) => {
    event.preventDefault();

    const { target: { value: _val } } = event;

    // Controllo che il valore impostato sia un numero
    if (!onlyNumbersValidate(_val) && _val.length === 1)
      return;

    // Setto il nuovo valore nello state
    const newVal = [...val];
    newVal[index] = _val;
    setVal(newVal);

    // Invio il valore sottoforma di stringa al componente padre
    // tramite ref/onchange
    const result = newVal.join('');
    if (ref && ref.current)
      ref.current.value = result;
    if (onChange)
      onChange(result);

    // Setto/rimuovo il focus se l'utente non ha cancellato il numero
    if (_val.length === 0)
      return;

    if (index < length - 1) {
      refInputs.current[index + 1].focus();
    } else {
      refInputs.current[index].blur();
    }

  };

  return (
    <div css={style.base} >
      {val.map((el, index) => (
        <input
          css={[style.input(theme), el !== "" && style.inputFilled(theme)]}
          key={index}
          maxLength={1}
          onChange={e => handleOnChange(e, index)}
          ref={element => (refInputs.current[index] = element)}
          size={1}
          value={el}
        />)
      )}
    </div>
  );
});

OtpInput.propTypes = {
  length: PropTypes.number,
  onChange: PropTypes.func,
};

OtpInput.defaultProps = {
  length: 6,
  onChange: null,
};


