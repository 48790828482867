define(['handlebars'], function (Handlebars) {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['banner'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"tt-alert tt-customer-alert\">\n    <div>\n        "
    + ((stack1 = ((helper = (helper = helpers.mopBanner || (depth0 != null ? depth0.mopBanner : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"mopBanner","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>";
},"useData":true});

templates['banner-mobile'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"tt-container\">\n    <div class=\"tt-alert tt-customer-alert\">\n    <div>\n        "
    + ((stack1 = ((helper = (helper = helpers.mopBanner || (depth0 != null ? depth0.mopBanner : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"mopBanner","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    </div>  \n</div>\n";
},"useData":true});
});