import React, { useEffect, useState, useRef } from 'react';
import MOP from 'app';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { CountDownDialog } from './CountDownDialog';
import { ABSOLUTE_DIALOG_SHOW_MILLISECONDS_PATIENT, ABSOLUTE_DIALOG_SHOW_MILLISECONDS_ADMIN, DIALOG_TYPE_ABSOLUTE } from '../../constants';


const Absolute = ({ }) => {

  const [showDialog, setShowDialog] = useState(false);

  const timeoutDialog = useRef(null);
  const timeoutLogout = useRef(null);

  useEffect(() => {
    MOP.handleAbsoluteExpiration = expiration => {

      if (MOP.isAdminLoggedIn() && MOP.config.isInternalMop() && !MOP.config.isLanzaderaMop()) {
        // Se sono su mop interno ma non su lanzadera, allora non voglio settare i timer del MOP 
        // ma voglio gestire tutto a livello backoffice
        // ritorno perchè nel caso di absolute, sarà già stato settato all'avvio della pagina
        return;
      }

      if (MOP.Utilities.empty(expiration)) {

        // Se la expiration è vuota allora vado a cancellare i timer che eventualmente sono attivi
        // In modo da gestire il caso di logout, e quindi pulire i timer se l'utente fa un logout

        if (!MOP.Utilities.empty(timeoutDialog.current)) {
          clearTimeout(timeoutDialog.current);
          timeoutDialog.current = null;
        }

        if (!MOP.Utilities.empty(timeoutLogout.current)) {
          clearTimeout(timeoutLogout.current);
          timeoutLogout.current = null;
        }

        const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
        if (!MOP.Utilities.empty(timeouts)) {
          delete timeouts.absolute_timer;
          delete timeouts.absolute_accepted;
          MOP.permanentLocalStorage.set('timeouts', JSON.stringify(timeouts));
        }

        setShowDialog(false);

        return;

      } else if (MOP.Utilities.empty(timeoutDialog.current) && MOP.Utilities.empty(timeoutLogout.current)) {
        // altrimenti se la expiration è attiva e i timeout sono vuoti, allora li settiamo con la expiration tornata
        // lo facciamo solo la prima volta e non ogni volta che torna una expiration infatti il timer absolute 
        // deve essere settato solo la prima volta che torna una API, non ogni volta (tanto non cambia)

        const _expiration = (1000 * parseInt(expiration)) - Date.now();
        const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};

        // Se absolute_accepted è vuoto allora monto in pagina il timer per mostrare il dialog, se invece il valore è pieno
        // allora non abbiamo ancora accettato il dialog quindi mettiamo in pagina il timer
        if (MOP.Utilities.empty(timeouts?.absolute_accepted)) {
          timeoutDialog.current = setTimeout(() => {
            setShowDialog(true);
          }, _expiration - (MOP.isAdminLoggedIn() ? ABSOLUTE_DIALOG_SHOW_MILLISECONDS_ADMIN : ABSOLUTE_DIALOG_SHOW_MILLISECONDS_PATIENT));
        }

        timeoutLogout.current = setTimeout(() => {

          if (MOP.config.isLanzaderaMop()) {
            // Se sono su lanzadera allora al posto di fare logout dal MOP faccio una postMessage in cui richiamo la window.handleLogout
            // questa è una funzione definita nella window di lanzadera, e si occuperà questa di fare il logout.
            MOP.sendMessageToParentFrame({
              fn: 'evalFunction',
              value: `window.handleLogout(false);`
            });
          } else {
            // Altrimenti in tutti gli altri casi passo per un logout standard del MOP
            // e chiudo il dialog del timer
            setShowDialog(false);

            const alert = { type: 'info', msg: TTPolyglot.t("Mop Absolute Timeout Expired") };

            if (MOP.isOneTimeUser()) {
              MOP.killOneTimeSession(false, alert);
            } else {
              MOP.LM.doLogout(false, alert);
            }

          }
        }, _expiration);

        // Dopo aver settato i nuovi timer vado a scrivere il valore del timer absolute in localstorage
        // Se questo valore in local storage è vuoto, altrimenti non lo scrivo perchè tanto il timer absolute
        // è lo stesso per l'intera sessione
        if (MOP.Utilities.empty(timeouts?.absolute_timer)) {
          timeouts.absolute_timer = 1000 * parseInt(expiration);
        }
        MOP.permanentLocalStorage.set('timeouts', JSON.stringify(timeouts));

      }

    };
  }, []);


  if (!showDialog) return null;

  return (
    <CountDownDialog
      onConfirm={() => {
        setShowDialog(false);
        // Ho visto e confermato il dialog absolute, setto in localStorage absolute_accepted
        // in questo modo anche se la pagina viene ricaricata, non mostro nuovamente il dialog
        const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
        timeouts.absolute_accepted = true;
        MOP.permanentLocalStorage.set('timeouts', JSON.stringify(timeouts));
      }}
      onEnd={() => setShowDialog(false)}
      type={DIALOG_TYPE_ABSOLUTE}
    />
  );

};

export default Absolute;