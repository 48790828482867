import { css } from "@emotion/react";


export const base = theme => css({
  cursor: "pointer"
});

export const containerDesktop = theme => css({
  display: "flex",
  height: "43px",
  padding: theme.layout.padding.xsmall,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.colors.white
});

export const containerMobile = theme => css({
  display: "flex",
  height: "40px",
  padding: theme.layout.padding.xsmall,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.colors.white
});

export const logo = theme => css({
  display: "flex",
  alignSelf: "center",
});