import React from 'react';

import { Box, Label, Container, Image, Divider, Rating } from 'uikit';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { getCountryLogo } from '../../common/utils';

import * as style from "../../style.js";

export const Mobile = ({ stars, opinions }) => {
  return (
    <>
      <div css={style.containerMobile}>
        <Box gap={"micro"} direction={"row"} horizontalAlign={"center"} verticalAlign={"baseline"}>
          <Label size={"medium"}>{stars}</Label>
          <Rating value={stars} size={"small"} />
          <Label size={"small"} color={"link"}>{opinions} {TTPolyglot.t("Mop Reviews Count Title")}</Label>
          <div css={style.logo}>
            <Image size={"xxsmall"} src={getCountryLogo()} />
          </div>
        </Box>
      </div>
      <Divider />
    </>
  );
};