import { css } from "@emotion/react";


export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.standard,
  width: "100%",
});

export const labelBase = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  width: "100%",
});

export const checkLabelsContainer = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro,
  paddingInlineStart: theme.layout.padding.xsmall,
  width: "100%",
});

export const checkLabelsContent = theme => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.micro,
  width: "100%",
});

export const passwordBase = theme => css({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  width: "100%",
  backgroundColor: theme.colors.white,
});

export const passwordContainer = theme => () => css({
  ...theme.input.container.standard.normal,
});

export const input = theme => css({
  ...theme.input.base.standard
});

export const containerContent = () => css({
  display: "flex",
  flexDirection: "row",
  gap: 2,
  flexGrow: 1,
});

export const iconContainer = (clickable = false) => css({
  alignItems: "center",
  cursor: clickable ? "pointer" : "default",
  display: "flex",
  justifyContent: "center",
  padding: "0.5rem 0.5rem 0.5rem 0.5rem",
  width: "2.375rem"
});

