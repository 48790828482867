import { css } from "@emotion/react";


export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const selectContainer = theme => ({error})=> css({
  borderColor: error ? theme.colors.danger : theme.colors.gainsboro,
  backgroundColor: error ? theme.colors.danger_light : "",
  borderRadius: theme.layout.borderRadius.small,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,
  boxSizing: "border-box",
  display: "flex",
  gap: theme.layout.gap.small,
  height: theme.layout.baseHeights.normal,
  width: "100%",
  alignItems: "center",
  padding: theme.layout.padding.small,
  cursor: "pointer",
  justifyContent: "space-between"
});



export const contentBase = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});


export const checkboxContainer = theme => css({
  display: "flex",
  cursor: "pointer",
  height: theme.layout.baseHeights.normal,
  alignItems: "center",
  padding: `0rem ${theme.layout.padding.standard}`,
}); 
