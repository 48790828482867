import React from 'react';

import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Button } from '../Button';

import { TTPolyglot } from "@frontend/tt-polyglot";

import PropTypes from 'prop-types';


export const ConfirmDialog = ({
  open,
  label,
  onConfirm,
  confirmLabel,
  loading,
  onCancel,
  cancelLabel,
  anchorEl
}) => {

  return (
    <Dialog
      open={open}
      closeButton={false}
      outClick={false}
      anchorEl={anchorEl}
    >

      <Dialog.Content>
        <Label>
          {label}
        </Label>
      </Dialog.Content>

      <Dialog.Footer>
        {onCancel && <Button
          type={"light"}
          onClick={onCancel}
          disabled={loading}
        >
          {cancelLabel}
        </Button>}
        <Button
          type={"solid"}
          onClick={onConfirm}
          loading={loading}
        >
          {confirmLabel}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  anchorEl: PropTypes.object
};

ConfirmDialog.defaultProps = {
  open: false,
  label: "",
  loading: false,
  onConfirm: null,
  confirmLabel: TTPolyglot.t("Confirm"),
  onCancel: null,
  cancelLabel: TTPolyglot.t("Cancel"),
  anchorEl: null
};
