import { css } from "@emotion/react";

export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});


export const inputContainer = theme => ({ error, size }) => css({
  ...theme.input.container.standard[size],
  ...(error && theme.input.container.error)
});

export const input = theme => ({ error, disabled }) => css({
  ...theme.input.base.standard,
  ...(error && theme.input.base.error),
  ...(disabled && theme.input.base.disabled),
});
