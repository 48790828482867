import MOP from "app";

export const cordovaRest = (data, res, rej) => {
  const sendData = { ...data };

  if (!MOP.Utilities.empty(window.MOP_globals?.customerConfig?.schemaUrl)) {
    sendData.headers['referer'] = window.MOP_globals.customerConfig.schemaUrl;
  }

  // Il plugin d cordova vuole fare lui stesso l'encoding altrimenti se lo facciamo noi in anticipo
  // non funziona correttamente.
  // In data deve per forza esserci un json
  if (sendData.data && sendData.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    cordova.plugin.http.setDataSerializer('urlencoded');
  } else if (sendData.data && sendData.headers['Content-Type'] === 'application/json') {
    // Questo è il nostro default perchè abbiamo content-type = application/json in tutte le richieste tranne che nelle POST.
    // Andiamo a settare esplicitamente il serializer json perchè altrimenti il plugin cordova 
    // ha problemi se sendData.data è un oggetto vuoto.
    cordova.plugin.http.setDataSerializer('json');
  }

  cordova.plugin.http.sendRequest(
    sendData.url,
    {
      method: sendData.method,
      data: sendData.data,
      headers: sendData.headers
    },
    resp => {

      // Se siamo in app per ora disabilitiamo il flusso dei timers. faremo uno sviluppo apposito lato APP
      // Per gestire cordova ed i timeouts, e per ribuildare tutte le app
      // abbiamo visto che tanto le app con una sessione scaduta lato BE muoiono senza problemi, 
      // fanno redirect alla pagina login dopo aver ricevuto eccezione TUOTEMPO_NOT_LOGGED_IN
      /*
      const idle_timeout_expiration = resp.headers["x-tt-idle-timeout-expiration"];
      const absolute_timeout_expiration = resp.headers["x-tt-absolute-timeout-expiration"];

      if(MOP.handleIdleExpiration) {
        MOP.handleIdleExpiration(idle_timeout_expiration);
      }

      if(MOP.handleAbsoluteExpiration) {
        MOP.handleAbsoluteExpiration(absolute_timeout_expiration);
      }
      */

      const responseParsed = JSON.parse(resp.data);
      res(responseParsed);
      data.successDeferredCallback(responseParsed);
    },
    err => {
      const errorParsed = JSON.parse(err.error);
      rej(errorParsed);
      data.successDeferredCallback(errorParsed);
    });

}