import React from "react";
import { CSSTransition } from 'react-transition-group';
import './transitions.scss';

export const FadeTransition = props => (
  <CSSTransition
    {...props}
    classNames="fade-anim"
    timeout={{ enter: 500, exit: 300 }}
  />
);

export const FadeTransition300 = props => (
  <CSSTransition
    {...props}
    classNames="fade-anim300"
    timeout={{ enter: 300, exit: 300 }}
  />
);

export const SlideFadeTransition = props => (
  <CSSTransition
    {...props}
    classNames="slide-fade-anim"
    timeout={500}
  />
);