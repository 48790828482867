import MOP from "app";
import "templates/head/templates";
import "backbone.syphon";

MOP.module("HeadApp.Show.View", function (View, MOP, Backbone, Marionette, $, _) {

    View.Head = Marionette.ItemView.extend({
        template: Handlebars.templates.head,

        serializeData: function () {

            var CSSs = MOP.config.get('css'),
                attributes = {};

            attributes.isMobileApp = MOP.Utilities.isMobileApp();
            attributes.css = CSSs;

            return attributes;
        },

        renderAndAddToDom: function () {
            this.render();
            $('head').append($(this.el).html());
        }

    });
});

export default MOP.HeadApp.Show.View;