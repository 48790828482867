import React from "react";
import { Global, css } from "@emotion/react";

// Questo Reset mi serve perchè ci sono alcune proprità di default dell'HTML che mi vanno a sovrascrivere le mie proprietà
// e questo da problemi sulle visualizzazioni. con questo stile, vado a resettare tutto quello che mi da fastidio che viene
// settato dall'HTML oppure da classi di bully. 
// Attenzione: Qui non vengono mai resettate cose che vengono usate esplicitamente dal MOP fuori dalla main-region 
// (a parte la main-region abbiamo altre region nel mop come header-region, footer-region, banner-region). 
// quelle proprietà, se devono essere resettate verranno resettate solamente nel componente specifico che ha bisogno del reset.
// Questo perchè se resettassimo in questo punto qualcosa che viene usato in altre regioni, poi quello stile verrebbe resettato in tutta la pagina
// e quindi anche tutte le regioni esterne alla main-region, avrebbero il reset e si romperebbe lo stile
// Questo ResetStyle serve quindi solo ai componenti di storybook e quando si va in un'altro modulo che non utilizza storybook, 
// questo ResetStyle viene smontato 
export const ResetStyle = () => {

  return (
    <Global
      styles={css`    
          p {
            margin: 0
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            background: none;
            padding: 0;
            user-select: none;
            cursor: pointer;
            white-space: nowrap;
            letter-spacing: inherit;
            font: inherit;
            color: inherit;
          }
          input[type] {
            border-radius: inherit !important;
            box-shadow: inherit !important;
            line-height: inherit !important;
          }
        `}
    />
  );
};
export default ResetStyle;
