import MOP from "app";

export const getCountryLogo = () => {

  let name = null;

  switch (MOP.config.getInstanceConfig('defaultPrefix')) {
    case "+90": // Turchia  
      name = "TR";
      break;
    case "+49": // Germania
      name = "DE";
      break;
    case "+39": // Italia
      name = "IT";
      break;
    case "+420": // Repubblica ceca
      name = "CZ";
      break;
    case "+48": // Polonia
      name = "PL";
      break;
    default:
      name = "default";
      break;
  }

  return `${MOP.config.getMopImageBaseUrl()}/certification-widget/${name}.svg`;
};