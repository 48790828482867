import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const ForwardRef = forwardRef(({ children }, ref) => {
  return <div ref={ref}>{children}</div>;
});

ForwardRef.propTypes = {
  children: PropTypes.node
};

ForwardRef.defaultProps = {
  children: null
};
