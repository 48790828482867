import MOP from "app";

export const extendSession = () => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/${MOP.getLoggedUserId()}/sessions`,
      ajax: {
        options: {
          method: 'GET'
        }
      }
    };

    MOP.ajaxRest(ajaxParams, true)
      .done(response => {
        if (response.result === "OK") resolve(response);
        else reject(response);
      })
      .fail(reject);
  });
};