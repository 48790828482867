import Marionette from 'backbone.marionette';
import $ from 'jquery';
import Handlebars from 'handlebars';

  var ViewTemplateBehavior = function (MOP) {
    return Marionette.Behavior.extend({
      _getTemplatesCached: function () {
          return MOP.SessionStorage.getItem('templates');
      },

      initialize: function () {
          var _this = this;

          if (MOP.Utilities.isMobileApp()) {
              this._getTemplatesCached()
                  .done(function (cachedTemplates) {
                      cachedTemplates = JSON.parse(cachedTemplates) || {};

                      if (Boolean(_this.view.templateName)) {
                          if (cachedTemplates[_this.view.templateName]) {
                              _this.view.template = cachedTemplates[_this.view.templateName];
                          } else {
                              _this.view.template = Handlebars.loadRightTemplate(_this.view.templateName);
                          }
                      }

                      //return _this.view.initialize(); //NB => NON SERVE perchè l'initialize viene comunque fatto
                  });
          } else {
              if (this.view.templateName) {
                  this.view.template = Handlebars.loadRightTemplate(this.view.templateName);
              }

              //return this.view.initialize(); //NB => NON SERVE perchè l'initialize viene comunque fatto
          }
      },

      onRender: function () {
          var _this = this;

          if (MOP.Utilities.isMobileApp()) {
              this._getTemplatesCached()
                  .done(function (cachedTemplates) {
                      cachedTemplates = JSON.parse(cachedTemplates) || {};

                      if (Boolean(_this.view.templateName)) {
                          if (!Boolean(cachedTemplates[_this.view.templateName])) {
                              cachedTemplates[_this.view.templateName] = _this.view.$el.prop('outerHTML');
                              MOP.SessionStorage.setItem('templates', JSON.stringify(cachedTemplates));
                          }
                      }

                      try {
                          _this.view.onRender();
                      } catch (e) {

                      }
                  });
          } else {
              try {
                  this.view.onRender();
              } catch (e) {

              }
          }
      },

      onInvalidTemplate: function () {
          var _this = this;

          if (MOP.Utilities.isMobileApp()) {
              this._getTemplatesCached()
                  .done(function (cachedTemplates) {
                      cachedTemplates = JSON.parse(cachedTemplates) || {};

                      if (Boolean(cachedTemplates[_this.view.templateName])) {
                          cachedTemplates[_this.view.templateName] = undefined;
                          MOP.SessionStorage.setItem('templates', JSON.stringify(cachedTemplates));
                      }
                  });
          }
      }
    });
  };

  export default ViewTemplateBehavior;
