import MOP from "app";

export const getZipCodePlaceholderFromPrefix = prefix => {
  // Funzione che torna il codice ISO a due caratteri del prefisso passato
  // Attualmente gestiamo solo italia, spagna, brasile, messico

  switch (prefix) {
    case "+55": {
      return "12345-678";
      break;
    }

    case "+34":
    case "+52":
    case "+39": 
    default: {
      return "12345";
      break;
    }
  }
}

const getZipCodeValidationRegex = prefix => {
  // Ricevo in ingresso il prefisso, ed in base a questo, torno il regex di validazione dello zip code

  switch (prefix) {
    case "+39": {
      return /^\d{5}$/;
      break;
    }
    case "+55": {
      return /^\d{5}[\-]\d{3}$/;
      break;
    }
    case "+34": {
      return /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;
      break;
    }
    case "+52": {
      return /^\d{5}$/;
      break;
    }
    default:
      // Questo regex di default accetta qualsiasi valore
      return /.*/;
      break;
  }
}

export const validateZipCode = (prefix, zipCode) => {
  // Ricevo in ingresso il prefisso e lo ZipCode da validare, ritorno la validazione

  const regex = getZipCodeValidationRegex(prefix)

  // Testa lo zipCode, per tutti quelli non gestiti, torna sempre TRUE
  return regex.test(zipCode);
}