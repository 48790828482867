import MOP from 'app';


export const confirmDossierPrivacy = _userid => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `users/${_userid}/_dossier_privacy`,
      ajax: {
        options: {
          method: 'POST'
        },
      },
      loading: {
        enabled: true
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      )
  });
}

export const confirmDossierTwoFactor = (_userid, userIdNumber, episodeLegacyId, enableLoader) => {

  return new Promise((resolve, reject) => {
    const bodyParams = {
      user_identification_field: userIdNumber,
      episode_legacyid: episodeLegacyId
    }

    const ajaxParams = {
      searchPath: `users/${_userid}/_dossier_strong_auth`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST'
        },
        data: bodyParams
      },
      loading: {
        enabled: enableLoader
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      )
  });
}

export const confirmOTPVerification = (_userid, dossier_otp) => {

  return new Promise((resolve, reject) => {

    var params = {
      searchPath: `users/${_userid}/_dossier_otp_verification`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST'
        },
        data: {
          dossier_otp: dossier_otp
        }
      },
      loading: {
        enabled: true
      }
    };

    MOP.ajaxRest(params)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      )
  })
}


export const sendOTPVerification = (_userid) => {

  return new Promise((resolve, reject) => {
    var params = {
      searchPath: `users/${_userid}/_dossier_otp`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'GET'
        }
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(params)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      )

  })
}

export const verifyOtp = (userid, otp) => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/${userid}/_otp_verification`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST'
        },
        data: { otp: otp }
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        if (response.result !== 'OK') {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .fail(e => {
        reject(e);
      });
  });
};

export const sendOtp = userid => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/${userid}/_otp`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST'
        },
        data: {}
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        if (response.result !== 'OK') {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .fail(e => {
        reject(e);
      });
  });
};


export const registerSpidUser = (tokenid, data) => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `spid/users`,
      ajax: {
        options: {
          method: 'POST'
        },
        data: {
          tokenid,
          ...data
        }
      },
      loading: {
        enabled: true
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        if (response.result !== 'OK') {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .fail(e => {
        reject(e);
      });
  });
};