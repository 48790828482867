import MOP from 'app';
import { createToken } from 'api/tokens';
import { isReservationPast, isApprovedByLegacy } from 'models/_reservation';

export const isSelectUserVisibile = () => {

  const loggedUserData = MOP.getLoggedUserData();
  const { parents } = loggedUserData;
  if (MOP.config.isPortalInstance() && !MOP.Utilities.empty(parents) && !MOP.isFromTRD111()) {
    return true;
  }

  return false;
};

export const isBookAgainButtonHidden = () => {
  return !MOP.Utilities.empty(MOP.config.getInstanceConfig('mopHideBookAgainButton')) || !MOP.TM.isMenuPageVisibileForUserRole('search');
};


export const requestCustomButtonToken = async (config, resid) => {
  try {
    const data = await createToken(
      MOP.config.constants.GET_TOKEN_ENTITY_RESERVATION,
      resid,
      config === "reservationCustomButton" ? MOP.config.constants.GET_TOKEN_GET_INVOICE : MOP.config.constants.GET_RESERVATION_CUSTOM_DOCUMENT
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const buildCustomButtomUrl = (config, token) => {
  const querystring = {
    dbName: MOP.getDbName(),
    mobile: (MOP.config.isMobile() ? 1 : 0),
    token: token.token
  };

  let url = "";

  // TOREMOVE: integrated-custom-button
  if (config === "reservationCustomButton" && MOP.config.isOldCustomButton(MOP)) {
    // Recupero URL CustomButton1 vecchio formato
    url = MOP.config.getInstanceConfig(config).split(MOP.config.constants.DESCRIPTION_SEPARATOR)[1];
  } else {
    // Recupero URL CustomButton1/CustomButton2
    const { url: configUrl } = MOP.config.getInstanceConfig(config);
    url = configUrl;
  }

  url += `&${MOP.Utilities.objectToQueryString(querystring)}`;

  if (MOP.isFromTRD111()) {
    url += `&auth_mode=${MOP.constants.AUTH_MODE_TRD111}`;
  }

  return url;
};

export const payButtonLogic = reservation => {

  const {
    payment_status,
    payment_required,
    payment_with_sincro,
    is_pending,
    payment_method,
    activityPrice,
    insurance_isHiddenPrice,
    insurance_payment_disabled,
  } = reservation;

  if (!(!MOP.Utilities.empty(MOP.config.getInstanceConfig('price')) && MOP.Utilities.empty(insurance_isHiddenPrice)))
    return false;

  const {
    print_reservation: {
      pay_button
    }
  } = MOP.config.getInstanceConfig('trd111Buttons');

  if (MOP.isFromTRD111() && MOP.Utilities.empty(pay_button)) return false;


  if (!MOP.Utilities.empty(insurance_payment_disabled) && parseInt(insurance_payment_disabled) === 1) return false;

  if (!MOP.getLoggedUser().isUserCertified() && !MOP.config.isExternalUserAllowed(MOP, 'payments', 'online')) return false;

  // L'appuntamento non è passato e deve avere un prezzo
  // e l'appuntamento è confermato e il pagamento è abilitato
  // oppure l'appuntamento è in sospeso e il pagamento è obbligatorio in un'istanza non sincronizzata
  // e l'appuntamento non è stato pagato.
  if (!isReservationPast(reservation) &&
    !MOP.Utilities.empty(activityPrice) &&
    ((payment_method === MOP.config.constants.PAYMENT_METHOD_PAYPAL) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_PAYMILL) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_BRAINTREE) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_BRAINTREE_3D) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_CARTASI) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_REDSYS) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_INGENICO) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_ADYEN) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_GETNET) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_PPPLUS) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA_HOSTED) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA_EMBEDDED) ||
      (payment_method === MOP.config.constants.PAYMENT_METHOD_TRANSBANK)
    ) &&
    (
      ((parseInt(is_pending) === 0 || parseInt(is_pending) === 2) && parseInt(payment_required) >= MOP.config.constants.FIELD_VISIBLE) ||
      (parseInt(is_pending) === 1 && parseInt(payment_required) === MOP.config.constants.FIELD_COMPULSORY && parseInt(payment_with_sincro) !== 1) ||
      (isApprovedByLegacy(reservation) && parseInt(payment_required) === MOP.config.constants.FIELD_COMPULSORY && parseInt(payment_with_sincro) === 1)
    ) &&
    (payment_status == null || parseInt(payment_status) === MOP.config.constants.RES_PENDING_PAYMENT)
  ) {
    // Mostra Pulsante Paga
    return true;
  }

  return false;
};

export const getPatientName = reservation => {
  const { fname, social_name, lname, lname2 } = reservation;

  return `${MOP.Utilities.empty(social_name) ? fname : social_name} ${lname} ${lname2 ? lname2 : ''}`;
};