import React from 'react';
import { itemClicked } from '../../common';
import MOP from 'app';
import { OutClick } from 'common-lib/components/hoc/OutClick';

import { TTPolyglot } from "@frontend/tt-polyglot";

const AdditionalMenu = ({ menuItems, disabled, open, onClick, hocRef }) => {
  if (!menuItems || menuItems.length === 0) return null;

  return (
    <>
      <li className={`dropdown ${disabled ? 'tt-pointer-disabled' : ''} tt-navbar__link ${open ? 'open' : ''}`} onClick={onClick} ref={hocRef}>
        <a className="dropdown-toggle tt-navbar__link__text">
          {TTPolyglot.t("Mop Desktop Additional Menu Items")}&nbsp;<i className="tt-navbar__link__text-icon tt-icon-caret-down"></i>
        </a>
        <ul className="dropdown-menu tt-navbar__link-dropdown" role="menu">
          {
            menuItems.map((item, index) => {
              return (
                <li key={index} onClick={() => itemClicked(item)} className={`${disabled ? 'tt-pointer-disabled' : ''}`}>
                  <a className="pageLink">{item.label}</a>
                </li>);
            })
          }
        </ul>
      </li>
    </>
  );
};


export default OutClick(AdditionalMenu);