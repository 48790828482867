import MOP from 'app';

export const GTMInitialize = id => {
  try {
    return new Promise((resolve, reject) => {

      //Inserimento Script Google Tag Manager head e body 
      const head_script = document.createElement('script');
      head_script.text = `window.dataLayer = window.dataLayer || [];
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`;

      const body_noscript = document.createElement('noscript');
      const iframe_noscript = document.createElement('iframe');
      iframe_noscript.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
      iframe_noscript.height = "0";
      iframe_noscript.width = "0";
      iframe_noscript.style.display = "none";
      iframe_noscript.style.visibility = "hidden";
      body_noscript.appendChild(iframe_noscript);

      head_script.onload = resolve(true);
      head_script.onerror = reject(false);

      document.head.prepend(head_script);
      document.body.prepend(body_noscript);
    });
  } catch (error) {
    console.error("MonitoringProviders - Google Tag Manager - GTMInitialize failed: ", error);
  }
};

export const GTMTrackEvent = (event, value) => {
  dataLayer.push({
    event: "click",
    category: event,
    ...value
  })
}
