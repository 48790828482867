import { TTPolyglot } from "@frontend/tt-polyglot";
import dayjs from "dayjs";

// Given a date, it returns the month translated
export const formatMonth = date => {
  const formattedMonth = dayjs(date).format("MMMM");
  const month = TTPolyglot.t(formattedMonth);
  return month;
};

// Given a date, it returns the month translated and the year
export const formatMonthYear = date => {
  const formattedDate = dayjs(date);
  const month = TTPolyglot.t(formattedDate.format("MMMM"));
  const year = formattedDate.format("YYYY");
  return `${month} ${year}`;
};

// Given a date, it returns the day of the week translated
export const formatWeekDay = date => {
  const formattedDate = dayjs(date);
  const day = formattedDate.format("dddd");
  return TTPolyglot.t(`${day} Abbr`);
};