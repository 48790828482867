import { css } from "@emotion/react";

export const base = theme => disablePointerEvents => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
  pointerEvents: disablePointerEvents ? "none" : "initial",
  width: "100%",
});

export const labelsContainer = theme => css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  gap: theme.layout.gap.micro,
});

export const mapContainer = theme => containerWidth => css({
  borderRadius: theme.layout.borderRadius.small,
  height: "250px",
  marginTop: "20px",
  overflow: "hidden",
  position: "relative",
  width: `${containerWidth}px`,
});

export const map = () => url => css({
  backgroundImage: `url(${url})`,
  height: "100%",
  width: "100%",
  printColorAdjust: "exact",
  WebkitPrintColorAdjust: "exact"
});

export const iconContainer = () => css({
  position: "absolute",
  right: 14,
  bottom: 14,

  "@media print": {
    display: "none",
  },
});