import { css } from "@emotion/react";


export const base = theme => () => css({
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  width: "100%",
  padding: theme.layout.padding.medium,
  boxShadow: `0px -4px 12px ${theme.colors.spanish_gray}`,
  backgroundColor: theme.colors.white
});

export const button = theme => ({ disabled }) => css({

  ...theme.button.base,
  ...(disabled && theme.button.disabled),

  height: theme.layout.baseHeights.normal,
  width: "100%",

  backgroundColor: theme.colors.primary,
  color: theme.colors.white
});