import React from 'react';
import PropTypes, { func } from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Divider } from '../Divider';
import { Container } from '../Container';
import { Label } from "../Label";
import { Icon } from 'ui/Icon';
import { AppointmentStatusTag } from '../AppointmentStatusTag';
import { Button } from '../Button';

import { TTPolyglot } from "@frontend/tt-polyglot";

import {
  APPOINTMENT_STATUS_TAG_PAYMENT_PENDING,
  APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED,
  APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED,
  APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED,
  APPOINTMENT_STATUS_TAG_PAID,
  APPOINTMENT_STATUS_TAG_PAID_MANUALLY,
  APPOINTMENT_STATUS_TAG_NOT_PAID,
  APPOINTMENT_STATUS_TAG_PENDING_APPROVAL,
  APPOINTMENT_STATUS_TAG_NO_SHOW,
  APPOINTMENT_STATUS_TAG_CANCELLED,
  APPOINTMENT_STATUS_TAG_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_CONFIRMED,
  APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT,
  APPOINTMENT_TAG_REMOTE,
} from 'models/_reservation';

/**
 * Primary UI component for user interaction
 */
export const AppointmentCard = ({ data: { date, activity, typology, resource, area, tags, insurance, onReschedule, onCancel, onBookAgain, onClick } }) => {

  const theme = useTheme();

  return (

    <div css={style.base(theme)({})}>
      <div css={style.card(theme)({})}>
        <div onClick={onClick} css={style.content(theme)({})}>
          <div css={style.infos(theme)({})}>
            <span css={style.date(theme)({})}>{date}</span>
            <div css={style.labels(theme)({})}>
              <Label color={"text_light"} icon={{ name: "ClipboardLight", color: "primary" }}>{`${typology} - ${activity}`}</Label>
              {
                resource &&
                <Label color={"text_light"} icon={{ name: "UserDoctorLight", color: "primary" }}>{resource}</Label>
              }
              {
                insurance &&
                <Label color={"text_light"} icon={{ name: "ShieldLight", color: "primary" }}>{insurance}</Label>
              }
              {
                area &&
                <Label color={"text_light"} icon={{ name: "BuildingLight", color: "primary" }}>{area}</Label>
              }
            </div>

            <div css={style.tags}>
              {tags.map((tag, index) => <AppointmentStatusTag type={tag} key={index} />)}
            </div>

          </div>
          <div css={style.arrow(theme)({})}><Icon name={"ChevronRightSolid"} color={"auro_metal_saurus"} /></div>

        </div>

        {(onBookAgain || onReschedule || onCancel) ? <Divider /> : null}

        <div css={style.buttons(theme)({})}>
          {onBookAgain && <Button type={"text"} size={"small"} link icon={"CalendarDaysLight"} onClick={onBookAgain}>{TTPolyglot.t("MOP Book Again")}</Button>}
          {onReschedule && <Button type={"text"} size={"small"} link icon={"ClockRotateLeftLight"} onClick={onReschedule}>{TTPolyglot.t("Mop Reschedule Button")}</Button>}
          {onCancel && <Button type={"text"} size={"small"} danger icon={"TrashCanLight"} onClick={onCancel}>{TTPolyglot.t("Cancel Reservation")}</Button>}
        </div>

      </div>

    </div>
  );
};

AppointmentCard.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    activity: PropTypes.string.isRequired,
    typology: PropTypes.string.isRequired,
    resource: PropTypes.string,
    area: PropTypes.string,
    tags: PropTypes.arrayOf([
      APPOINTMENT_STATUS_TAG_PAYMENT_PENDING,
      APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED,
      APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED,
      APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED,
      APPOINTMENT_STATUS_TAG_PAID,
      APPOINTMENT_STATUS_TAG_PAID_MANUALLY,
      APPOINTMENT_STATUS_TAG_NOT_PAID,
      APPOINTMENT_STATUS_TAG_PENDING_APPROVAL,
      APPOINTMENT_STATUS_TAG_NO_SHOW,
      APPOINTMENT_STATUS_TAG_CANCELLED,
      APPOINTMENT_STATUS_TAG_CHECKED_IN,
      APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN,
      APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN,
      APPOINTMENT_STATUS_TAG_CONFIRMED,
      APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT,
      APPOINTMENT_TAG_REMOTE
    ]),
    insurance: PropTypes.string,
    onReschedule: PropTypes.func,
    onCancel: PropTypes.func,
    onBookAgain: PropTypes.func,
    onClick: PropTypes.func
  })
};

AppointmentCard.defaultProps = {
  data: null
};
