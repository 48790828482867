import MOP from 'app';

export const getUser = id => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/${id}`,
      ajax: {
        options: {
          method: 'GET'
        }
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        if (response.result === 'OK') {
          resolve(response.return);
        } else {
          reject({ payload: id, error: null });
        }
      })
      .fail(e => {
        reject({ payload: id, error: e });
      });
  });
};

export const putUser = (id, data) => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/${id}`,
      ajax: {
        options: {
          method: 'PUT'
        },
        data
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        if (response.result === 'OK') {
          resolve(response.return);
        } else {
          reject({ payload: id, error: null })
        }
      })
      .fail(e => {
        reject({ payload: id, error: e });
      });
  });
}

export const sendUntrustedRequest = dataRequest => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: 'users/_untrustablemail',
      ajax: {
        options: {
          method: 'POST'
        },
        data: dataRequest,
        loading: {
          enabled: true
        }
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        resolve(response);
      }).fail(e => {
        reject(e);
      });
  });
};

export const updateDossierLastAccess = _userid => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `users/${_userid}/_dossierlastaccess`,
      ajax: {
        options: {
          method: 'PUT'
        },
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      );
  });
};

export const getUsers = (query, params) => {

  return new Promise((resolve, reject) => {

    const querystring = {
      textToSearch: query,
      ...params
    };

    const ajaxParams = {
      searchPath: `users`,
      querystring,
      ajax: {
        options: {
          method: 'GET'
        }
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams, true)
      .done(resolve)
      .fail(reject);
  });

};


export const getUsersLegacy = querystring => {

  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `users/_legacy_n_upsert`,
      querystring,
      ajax: {
        options: {
          method: 'GET'
        }
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams, true)
      .done(resolve)
      .fail(reject);
  });

};

export const getPatients = ({ query, optFutureRes }) => {

  return new Promise((resolve, reject) => {

    const isLegacySearch = !MOP.Utilities.empty(MOP.config.getInstanceConfig('forceMopPatientSearchOnLegacy'));

    const querystring = {
      textToSearch: query,
      ...(!isLegacySearch &&
      {
        profile: 0, // mi va a richiedere solamente i profili utente, quindi va ad eliminare tutti gli altri (operatore/admin/risorse ecc...)
        with_future_reservations: optFutureRes ? 1 : 0,
        with_reservations: 0
      }
      )
    };

    const ajaxParams = {
      searchPath: !isLegacySearch ? `users` : `users/_legacy_n_upsert`,
      querystring,
      ajax: {
        options: {
          method: 'GET'
        }
      },
      loading: {
        enabled: false
      }
    };


    MOP.ajaxRest(ajaxParams, true)
      .done(resolve)
      .fail(reject);
  });

};


