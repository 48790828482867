import { css } from "@emotion/react";

export const container = theme => () => css({
  display: "flex",
  width: "100%"
});

export const childrenContainer = theme => () => css({
  width: "100%"
});

export const base = theme => ({ isVisible, top, maxWidth }) => css({
  alignItems: "center",
  backgroundColor: "#444444",
  borderRadius: theme.layout.borderRadius.small,
  color: theme.colors.white,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  top: `${top}px`,
  padding: "0.5rem",
  position: "absolute",
  visibility: isVisible ? "visible" : "hidden",
  zIndex: theme.layerIndex.tooltip,
  maxWidth
});

export const text = theme => () => css({
  fontSize: theme.fonts.size.standard,
  fontWeight: theme.fonts.weight.regular,
  margin: 0,
  padding: 0,
  userSelect: "none",
  wordBreak: "break-word"
});