import $ from 'jquery';
import MOP from 'app';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

window.alertTimeout = null;

export default Marionette.Region.extend({

    attachHtml: function (view) {
        this.$el.append(view.el);
    },

    onShow: function (view) {
        this.listenTo(view, "alert:close", this.closeAlert);
        this.$el.fadeIn('slow');

        if (view.model.get('type') === 'success' && !view.model.get('ignoreTimeout')) {
            window.alertTimeout = setTimeout(this.closeAlert, 5000);
        }
        //MOP.TM.scrollTop(); [FIXME] MATTIA - non sta funzionando questo metodo, perchè non funziona il require
        MOP.TM.scrollTo(0, 0);
    },

    closeAlert: function () {
        $('#alert-region').fadeOut('slow');
        //this.$el.remove();
        clearTimeout(window.alertTimeout);
    }
});

