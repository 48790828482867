const axios = require('axios').default;
const qs = require('qs');
import MOP from "app";


export const axiosRest = (data, res, rej) => {

  const sendData = {
    url: data.url,
    method: data.method,
    headers: data.headers,
    data: data.data
  };

  
  // Facciamo lo stringify solamente se dobbiamo inviare un form urlencoded.
  // Lo facciamo qui e non in formatRestOptions perchè comunque su app, il plugin cordova 
  // Funziona solo passando il data come json, quindi facendolo prima non funzionerebbe
  // a questo punto preferiamo farlo separatamente qui dentro e dentro il file cordova.js
  if (sendData.data && sendData.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    sendData.data = qs.stringify(sendData.data);
  }

  return axios({ ...sendData })
    .then(resp => {
      
      res(resp.data);
      data.successDeferredCallback(resp.data);

      if(MOP.handleIdleExpiration) {
        MOP.handleIdleExpiration(resp.headers["x-tt-idle-timeout-expiration"], resp.headers["x-tt-idle-timeout"]);
      }

      if(MOP.handleAbsoluteExpiration) {
        MOP.handleAbsoluteExpiration(resp.headers["x-tt-absolute-timeout-expiration"]);
      }
    }).catch(err => {
      rej(err.response.data);
      data.successDeferredCallback(err.response.data);
    });
};