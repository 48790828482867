import MOP from "app";
import ModelBase from 'models/model_base';
import CollectionBase from 'collections/collection_base';

    MOP.module("Entities", function(Entities, MOP, Backbone, Marionette, $, _){

		Entities.Area = ModelBase.extend({
			
			initialize: function() { 
				// Invoke parent initialize();
				ModelBase.prototype.initialize.apply(this, arguments);
			},
			
			getIdFieldName : function() {
				return "areaid";
			},

            getCoordinatesObj: function(){
                // [TODO] Mattia - Da rifattoriazzare assolutamente la costruzione di questo testo (contenuto tooltip)
                var title = '<a href="#area/'+this.get('areaid')+'"><strong>'+this.get('areaTitle') +'</strong><br>' +this.get('address')+'</a>';
                return {latitude: this.get('latitude'),longitude: this.get('longitude'),title: title};
            },

			defaults: {
				areaid: null,
				areaTitle: ""
			},

			getFormattedAreaTitle: function () {
				return !!this.get('webTitle') ? this.get('webTitle') : (!!this.get('areaTitle') ? this.get('areaTitle') : '');
			},

			getRightAreaImg: function () {
				var imgLink;
				var face_img_link = this.get('face_img_link');

	            if (!face_img_link) {
	                imgLink = `${MOP.config.getMopImageBaseUrl()}/default-img-location.jpg`;
	            } else {
	                imgLink = face_img_link;
	            }

	            return imgLink;
			}
			
		});

		Entities.Areas = CollectionBase.extend({
			model: Entities.Area,
			
			fetch_fn: 'search_areas',
			searchPath: 'areas',
      getCoordinates: function(){
          var coords = [];
          this.each(function(model){
              coords.push(model.getCoordinatesObj());
          });
          return coords;
      }
		});
		
		var API = {
			getAreas: function( params ){
				var areas = new Entities.Areas();
				
				return areas.fetch(MOP, {
					querystring: params
				}); 
			}
		};
		
		MOP.reqres.setHandler("area:entities", function( params ){
			return API.getAreas( params );
		});
		
		MOP.reqres.setHandler("area:entities:new", function(){
			return new Entities.Areas();
		});
    });
	
	export default MOP.Entities;