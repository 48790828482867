import React from 'react';
import PropTypes from 'prop-types';

import { UploadProvider } from './Context.js';
import { UploadInput } from './UploadInput';

import { FileNotSupportedDialog } from './Dialogs/FileNotSupportedDialog.js';
import { PolicyDialog } from './Dialogs/PolicyDialog.js';
import { ConfirmDeleteDialog } from './Dialogs/ConfirmDeleteDialog.js';

import * as style from "./style.js";

import { Icon } from '../Icon';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Input } from '../Input';
import { Checkbox } from '../Checkbox';
import { Button } from '../../index.js';


export const Upload = ({
  title,
  description,
  isRequired,
  error,
  isAdmin,
  uploadDoc,
  deleteDoc,
  policy,
  label
}) => {

  return (
    <UploadProvider isAdmin={isAdmin} uploadDoc={uploadDoc} deleteDoc={deleteDoc}>

      <PolicyDialog policy={policy} />

      <FileNotSupportedDialog />

      <ConfirmDeleteDialog />

      <div css={style.baseUpload}>
        {title &&
          <Label size={"medium"} weight={"medium"}>
            {title} {isRequired ? " * " : ""}
          </Label>
        }
        {description &&
          <Label size={"medium"} color={"text_light"}>
            {description}
          </Label>
        }
        <UploadInput label={label}/>
        {!!error && <Label color={"danger"}>{error}</Label>}
      </div>

    </UploadProvider>
  );
};

Upload.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  uploadDoc: PropTypes.func,
  deleteDoc: PropTypes.func,
  policy: PropTypes.string,
  label: PropTypes.string,
};

Upload.defaultProps = {
  title: null,
  description: null,
  isRequired: false,
  error: null,
  isAdmin: false,
  uploadDoc: null,
  deleteDoc: null,
  policy: null,
  label: null
};