import { css } from "@emotion/react";


export const base = theme => css({
  backgroundColor: theme.colors.bright_gray,
});

export const containerWithChild = theme => css({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  color: theme.colors.gainsboro,
  gap: theme.layout.gap.micro,
});


export const child = theme => css({
  color: theme.colors.text_light,
  paddingLeft: theme.layout.padding.xsmall,
  paddingRight: theme.layout.padding.xsmall
});

export const horizontal = css({
  height: "1px",
  width: "100%",
  
  // Impostata proprietà printColorAdjust: "exact" perchè se no il divider non
  // veniva visualizzato nella stampa della pagina web
  "@media print": {
    printColorAdjust: "exact",
    WebkitPrintColorAdjust: "exact"
  },
});

export const horizontalFlex = css({
  height: "1px",
  flexGrow: 1,
  
  // Impostata proprietà printColorAdjust: "exact" perchè se no il divider non
  // veniva visualizzato nella stampa della pagina web
  "@media print": {
    printColorAdjust: "exact",
    WebkitPrintColorAdjust: "exact"
  },
});


export const vertical = css({
  width: "1px",

  // Impostata proprietà printColorAdjust: "exact" perchè se no il divider non
  // veniva visualizzato nella stampa della pagina web
  "@media print": {
    printColorAdjust: "exact",
    WebkitPrintColorAdjust: "exact"
  },
});