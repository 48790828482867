define(['handlebars'], function (Handlebars) {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['summary-custom-fields'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "		<div class=\"tt-label tt-label--left\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n		<div class=\"tt-input-container\">\n			<input "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_limit : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n						name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n						placeholder=\""
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"\n						class=\"tt-input\" type=\"text\"\n						"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_type : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  />\n		</div>\n	\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description_link : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description_link : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "maxlength=\""
    + container.escapeExpression(((helper = (helper = helpers.chars_limit || (depth0 != null ? depth0.chars_limit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"chars_limit","hash":{},"data":data}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.multiple_id || (depth0 != null ? depth0.multiple_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"multiple_id","hash":{},"data":data}) : helper)))
    + "_";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return ((stack1 = ((helper = (helper = helpers.chars_type || (depth0 != null ? depth0.chars_type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"chars_type","hash":{},"data":data}) : helper))) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "			<a class=\"tt-info-container__link\" href=\"#\" id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_link\">\n					"
    + alias3(((helper = (helper = helpers.description_link || (depth0 != null ? depth0.description_link : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_link","hash":{},"data":data}) : helper)))
    + "\n			</a>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "			<div class=\"tt-info tt-info--clear\">\n				"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n			</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function";

  return "			<div id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_description\" style=\"display: none;\">\n			<div class=\"tt-info tt-info--clear\">\n					"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n			</div>\n		</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "     <div class=\"tt-label tt-label--left\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n			<div class=\"tt-info-container\">\n				<div class=\"tt-input-container\">\n					<textarea "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_limit : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n						name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n						placeholder=\""
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"\n						"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "></textarea>\n				</div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>  \n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "					<div class=\"tt-info tt-info--clear\">\n						"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n					</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "    <div class=\"tt-label tt-label--left\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n		<div class=\"tt-info-container\">\n			<select class=\"tt-input\"\n							name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n							id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n							<option value=\"\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Custom Field Select Placeholder",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</option>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.selectFieldOptions : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</select>\n    </div>\n\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<option value=\""
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "</option>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "selected";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "     <div class=\"tt-label tt-label--left\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n      <div class=\"tt-input-container\">\n        <input class=\"tt-input\" name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" type=\"tel\" id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n          "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " date-validator=\""
    + alias3(((helper = (helper = helpers.date_validator || (depth0 != null ? depth0.date_validator : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"date_validator","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"tt-info tt-info--clear\">\n      "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n		</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"tt-info-container "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.mandatory : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.inputFieldType : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.textareaFieldType : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.selectFieldType : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.dataFieldType : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

templates['summary-custom-fields-mobile'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "        <div data-nested-page=\"first\">\n            <div class=\"tt-container\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n            <div class=\"tt-input-container tt-input-container--link\">\n                <input "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_limit : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n                       class=\"tt-input\"\n                       name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n                       placeholder=\""
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"\n                       value=\""
    + alias3(((helper = (helper = helpers.actualValue || (depth0 != null ? depth0.actualValue : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"actualValue","hash":{},"data":data}) : helper)))
    + "\"\n                       type=\"text\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_type : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description_link : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description_link : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "maxlength=\""
    + container.escapeExpression(((helper = (helper = helpers.chars_limit || (depth0 != null ? depth0.chars_limit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"chars_limit","hash":{},"data":data}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.multiple_id || (depth0 != null ? depth0.multiple_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"multiple_id","hash":{},"data":data}) : helper)))
    + "_";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return ((stack1 = ((helper = (helper = helpers.chars_type || (depth0 != null ? depth0.chars_type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"chars_type","hash":{},"data":data}) : helper))) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <a href=\"javascript:;\" \n									 class=\"tt-input-container--link-text tt-icon-question-circle\" \n									 id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_link\"></a>\n                \n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function";

  return "                <div id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_description\" style=\"display: none;\" class=\"tt-container\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                    <div class=\"tt-container\">\n                        "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "        <a class=\"tt-action nestedPageLink\" data-nested-page=\"first\" data-next-nested-page=\""
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <div class=\"tt-action__content\">\n                "
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "<span id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_preview\">"
    + alias3(((helper = (helper = helpers.actualValue || (depth0 != null ? depth0.actualValue : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"actualValue","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n            <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n        </a>\n        <div data-nested-page=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" style=\"display: none;\">\n            <div class=\"tt-label\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n            <div class=\"tt-container\">\n                <div class=\"tt-input-container\">\n                    <textarea "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.chars_limit : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " style=\"height: 60px;\"\n                              id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n                              name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n                              placeholder=\""
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = helpers.actualValue || (depth0 != null ? depth0.actualValue : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"actualValue","hash":{},"data":data}) : helper)))
    + "</textarea>\n                </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <button type=\"button\" class=\"tt-button tt-button--primary nestedPageLinkBack writeNestedPageSelection\" writenested-toid=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "_preview\" getnesteddata-fromid=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\">\n                    "
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Insert",{"name":"translate","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "style=\"pointer-events: none;\"";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                    <div class=\"tt-container\">\n                      "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "      <a class=\"tt-action nestedPageLink\" data-nested-page=\"first\" data-next-nested-page=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n          <div class=\"tt-action__content\">\n                "
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "<span id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.actualValue : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = ((helper = (helper = helpers.actualValue || (depth0 != null ? depth0.actualValue : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"actualValue","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n          </div>\n          <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n      </a>\n      <span data-nested-page=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" style=\"display: none;\">\n        <div class=\"tt-label\">"
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"tt-container\">\n            <div class=\"tt-input-group\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.selectFieldOptions : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        </div>\n      </span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return ": ";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "          <label class=\"tt-input-radio js-no-required-validation\">\n              <input class=\"tt-input-radio__input nestedPageLinkBack writeNestedPageSelection\" type=\"radio\" name=\""
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].multiple_id : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias2(alias1((depths[1] != null ? depths[1].custom_field : depths[1]), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\" writeNested-ToId=\""
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].multiple_id : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias2(alias1((depths[1] != null ? depths[1].custom_field : depths[1]), depth0))
    + "\" nestedDataToWriteBack=\": "
    + ((stack1 = alias1((depth0 != null ? depth0.label : depth0), depth0)) != null ? stack1 : "")
    + "\">\n              <div class=\"tt-input-radio__content\">\n                  <div class=\"tt-input-radio__content-text\">\n                      "
    + ((stack1 = alias1((depth0 != null ? depth0.label : depth0), depth0)) != null ? stack1 : "")
    + "\n                  </div>\n                  <i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n              </div>\n          </label>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return container.escapeExpression(container.lambda((depths[1] != null ? depths[1].multiple_id : depths[1]), depth0))
    + "_";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "    <div data-nested-page=\"first\">\n      <div class=\"tt-input-container\">\n        <input class=\"tt-input tt-no-border-top tovalidate tt-mt-0\" name=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3(((helper = (helper = helpers.description_title || (depth0 != null ? depth0.description_title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"description_title","hash":{},"data":data}) : helper)))
    + " "
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" type=\"tel\" id=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.multiple_id : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias3(((helper = (helper = helpers.custom_field || (depth0 != null ? depth0.custom_field : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"custom_field","hash":{},"data":data}) : helper)))
    + "\"\n             value=\""
    + alias3(((helper = (helper = helpers.actualValue || (depth0 != null ? depth0.actualValue : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"actualValue","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.cannot_edit : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " date-validator=\""
    + alias3(((helper = (helper = helpers.date_validator || (depth0 != null ? depth0.date_validator : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"date_validator","hash":{},"data":data}) : helper)))
    + "\" data-validation-error=\""
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Inserire il giusto formato [CHIAVE]",{"name":"translate","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias3(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "      <div class=\"tt-container\">\n        "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\""
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.mandatory : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.inputFieldType : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.textareaFieldType : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.selectFieldType : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.dataFieldType : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});
});