import React, { useEffect, useRef } from 'react';
import MOP from 'app';
import { connect } from "react-redux";
import {
  setRightPage,
  familyItemChanged,
  getUnreadNotifications,
  setUnreadNotifications,
  setHideHeader,
  setHideBottomBar,
  updateShowback,
  setDisabled,
  setLoggedUser,
  reload,
} from '../../store/actions';

const HeaderListener = ({
  setLoggedUser,
  setRightPage,
  familyItemChanged,
  updateShowback,
  getUnreadNotifications,
  setUnreadNotifications,
  setHideHeader,
  setHideBottomBar,
  hideBottomBar,
  setDisabled,
  reload,
}) => {


  const bottomBarHidden = useRef(hideBottomBar);

  useEffect(() => {

    const focusout = mainWrapperContent => {

      if (bottomBarHidden.current) return;

      const navContainer = document.querySelector('.tt-js-bottom-nav-container');
  
      if (mainWrapperContent && navContainer) {
        mainWrapperContent.style.height = `calc(100% - ${navContainer.clientHeight}px)`;
        mainWrapperContent.style.bottom = `${navContainer.clientHeight}px`;
        mainWrapperContent.style.top = 0;
      }

      setHideBottomBar(false);
    };
  
    const focusin = mainWrapperContent => {  

      if (bottomBarHidden.current) return;

      if (mainWrapperContent) {
        setTimeout(() => {
          if (bottomBarHidden.current) return;
          
          mainWrapperContent.removeAttribute('style');
        }, 0);
      }
      setHideBottomBar(true);
    };

    
    const initialInnerHeight = window.innerHeight;
    const initialInnerWidth = window.innerWidth;

    const mainWrapperContent = document.getElementById('main-wrapper-content-region');
 
    const blacklist = [
      'button',
      'checkbox',
      'color',
      'file',
      'radio',
      'range',
      'reset',
      'submit',
      'image'
    ];

    // Flusso parametri in ingresso:
    // 'input' => focusin(mainWrapperContent) => e (evento del focusin) => cosa
    // 'stringa del selettore' => funzione che esegue all'evento => evento => corpo della funzione delegate
    const delegate = selector => cb => e => e.target.matches(selector) && blacklist.indexOf(e.target.type) === -1 && cb(e);

    const inputDelegate = delegate('input, textarea');

    if(MOP.config.isMobile()) {
      document.addEventListener('focusin', inputDelegate(() => {
        focusin(mainWrapperContent);
      }));
  
      document.addEventListener('focusout', inputDelegate(() => {
        focusout(mainWrapperContent);
      }));
  
      window.addEventListener('resize', () => {
        if (window.innerWidth === initialInnerWidth && window.innerHeight === initialInnerHeight) {
          focusout(mainWrapperContent);
        }
      });
    }
    

    MOP.on('subapp:start', () => {
      setRightPage();
    });

    let cbs = [];

    MOP.on('showBack', (show, callback = null, _this) => {

      if (show) {
        if (callback) {
          cbs.push(() => callback.call(_this));
        } else {
          cbs.push(() => window.history.back());
        }
      } else {
        cbs = [];
      }

      MOP.HeaderApp.showBackCallback = () => {
        const func = cbs.pop();
        updateShowback(cbs.length !== 0);
        bottomBarHidden.current = cbs.length !== 0;
        setHideBottomBar(cbs.length !== 0);
        func();
      };
      updateShowback(cbs.length !== 0);
      if (MOP.config.isMobile()) {
        bottomBarHidden.current = cbs.length !== 0;
        setHideBottomBar(cbs.length !== 0);
      }


    });

    MOP.on('popCallback', () => {
      cbs.pop();
      updateShowback(cbs.length !== 0);
      bottomBarHidden.current = cbs.length !== 0;
      setHideBottomBar(cbs.length !== 0);

    });

    MOP.on('setDisabled', disabled => {
      setDisabled(disabled);
    });

    MOP.on('hideHeader', hide => {
      setHideHeader(hide);
    });

    MOP.on('hideBottomBar', hide => {
      bottomBarHidden.current = hide;
      if(hide) {
        mainWrapperContent.style = '';
      }
      setHideBottomBar(hide);
    });

    window.addEventListener("hashchange", () => {
      MOP.execute('MOP:banner:show');
      if(!MOP.Utilities.empty(MOP.querystring.permanentAlert)) {
        MOP.execute('MOP:permanentAlert:show', MOP.querystring.permanentAlert);
      }
      setRightPage();
    }, false);

    MOP.state.loggedUser.on('change', loggedUser => {

      const user = MOP.isLoggedIn() ? loggedUser.toJSON() : null;
      reload(user)

      if (!MOP.Utilities.empty(user) && MOP.isOneTimeUser()) {
        MOP.startCountdownKillSession();
      }

      // NB => Invalidiamo anche la BackboneCache fn=>start_new_mop
      //       perchè contiene un logged_user e un sessionid sporco
      MOP.clearBackboneCacheItemsByJsonFn(MOP.config.fetch_fn);

      if (loggedUser.hasChanged('sessionid')) {

        if (user && MOP.config.isMobile()) getUnreadNotifications();

        // Andiamo a ripulire le api di ricerca al cambio utente perchè ci possono essere delle situazioni delle risposte
        // ad hoc per l'utente (per ora operatori/risorse) in base a cosa possono/non possono prenotare
        const fnToInvalidate = ['insurances', 'typologies', 'activities', 'areas', 'resources', 'workingtime', 'provinces'];
        fnToInvalidate.map(fn => {
          MOP.clearBackboneCacheItemsByJsonFn(fn);
        });
      }

      // NB => solo se sono variati i familiari e l'utente è loggato e non si è loggato in questo momento
      if (loggedUser.hasChanged('parents') && loggedUser.get('sessionid') && !loggedUser.hasChanged('sessionid')) {
        familyItemChanged();
      }
    }, this);

    if (MOP.config.isMobile() && MOP.isLoggedIn()) {
      getUnreadNotifications();
    }

    MOP.on('clearUnreadNotifications', () => {
      setUnreadNotifications(0);
    });

  }, []);

  return (
    <></>
  );
};

const mapDispatchToProps = dispatch => ({
  setLoggedUser: (user) => dispatch(setLoggedUser(user)),
  setRightPage: () => dispatch(setRightPage()),
  familyItemChanged: () => dispatch(familyItemChanged()),
  updateShowback: data => dispatch(updateShowback(data)),
  getUnreadNotifications: () => dispatch(getUnreadNotifications()),
  setUnreadNotifications: data => dispatch(setUnreadNotifications(data)),
  setHideHeader: data => dispatch(setHideHeader(data)),
  setHideBottomBar: data => dispatch(setHideBottomBar(data)),
  setDisabled: data => dispatch(setDisabled(data)),
  reload: user => dispatch(reload(user))
});

const mapStateToProps = ({ showBack, hideBottomBar }) => ({
  showBack,
  hideBottomBar
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderListener);
