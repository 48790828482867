import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Icon } from '../Icon';

/**
 * Primary UI component for user interaction
 */
export const Banner = (
  { children,
    customClass,
    onClick,
    padding,
    type,
    onClose
  }) => {

  const theme = useTheme();

  return (
    <div css={[style.base(theme)({ padding, onClick }), type && style[type]]}
      className={customClass || null}>
      {children}
      {
        onClose ?
          <div css={style.close}>
            <div onClick={onClose}>
              <Icon name={"XmarkSolid"} size={"standard"} />
            </div>
          </div>
          : null
      }
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.oneOf(["micro", "xsmall", "small", "standard", "medium", "large"]),
  type: PropTypes.oneOf(["success", "warning", "danger"]),
  onClose: PropTypes.func
};

Banner.defaultProps = {
  customClass: null,
  onClick: null,
  padding: "small",
  type: null,
  onClose: null
};