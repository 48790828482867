
import { css } from "@emotion/react";
import chroma from "chroma-js";

export const base = theme => ({ size, weight }) => css({
  display: "flex",
  flexDirection: "row",
  gap: 0,
  fontSize: theme.fonts.size.standard,
  fontWeight: theme.fonts.weight.regular,
  whiteSpace: "pre" // Serve perchè altrimenti gli span non prendono gli spazi bianchi, e mostrano il testo tutto attaccato
});

export const match = theme => css({
  padding: "0",
  color: theme.colors.primary
});

export const standard = theme => ({ color }) => css({
  padding: "0",
  color: theme.colors[color] || theme.colors.black
});