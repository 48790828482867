import { css } from "@emotion/react";

export const base = theme => () => css({
  backgroundColor: theme.colors.alice_blue,
  border: `${theme.layout.borderWidth.standard} solid ${theme.colors.bright_gray}`,
  borderRadius: theme.layout.borderRadius.medium,
  boxShadow: theme.layout.baseBoxShadow,
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.medium,
  padding: `${theme.layout.padding.xsmall} ${theme.layout.padding.standard}`,
  width: "100%",
});

export const textContainer = theme => () => css({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro,
  justifyContent: "center"
});

export const iconContainer = theme => () => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro,
  justifyContent: "center",
  marginLeft: "auto"
});


export const mobileContainter = theme => () => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  width: "100%",
});
