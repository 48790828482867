import MOP from 'app';
import { GOOGLE_ANALYTICS, INSERT_RESERVATION } from '../constants';

export const GAInitialize = (ids, options) => {
  try {
    return new Promise((resolve, reject) => {
      // Docs che spiegano la migrazione dalla vecchia inclusione di analytics.js verso la nuova gtag
      // deprechiamo la vecchia inclusione
      // https://support.google.com/analytics/answer/10271001?hl=en#zippy=%2Cin-this-article
      // https://developers.google.com/analytics/devguides/migration/ua/analyticsjs-to-gtagjs?hl=it
      // https://stackoverflow.com/questions/54592981/difference-between-google-analytics-script-snippets-analytics-js-and-gtag-js

      //Inserimento Script Google Tag Manager head 

      // Per ogni id inserisco uno script di connessione a quel container
      ids.map(id => {
        const script_tag = document.createElement('script');
        script_tag.type = 'text/javascript';
        script_tag.async = true;
        script_tag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        script_tag.onload = resolve(true);
        script_tag.onerror = reject(false);
        document.head.appendChild(script_tag);
      })


      // Inserimento Script Google Tag Manager Data Layer head 
      // gestendo gli id multipli ed anche le eventuali options inserite nel config
      const script_tag_data_layer = document.createElement('script');
      script_tag_data_layer.type = 'text/javascript';
      script_tag_data_layer.text = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)};
      gtag('js', new Date());
      ${ids.reduce((acc, id) => `${acc} gtag('config', '${id}' ${!MOP.Utilities.empty(options) ? `, ${options}` : ''});\n`, '')}`;

      document.head.appendChild(script_tag_data_layer);

    });
  } catch (error) {
    console.error("MonitoringProviders - Google Analytics - GAInitialize failed: ", error);
  }
};


export const GAInitializeApp = () => {
  return new Promise(async (res, rej) => {
    try {
      await cordova.plugins.firebase.analytics.setEnabled(true);
      res(true);
    } catch (e) {
      console.error("MonitoringProviders - Google Analytics - GAInitializeApp failed: ", error);
      res(false);
    }
  });
}


/** GATrackEvent
 * * Si occupa di inviare un evento a GoogleAnalytics direttamente, oppure alla pagina padre se ci troviamo in un MOP embedded
 * 
 * @param {string} event - categoria dell'evento es: BackToAvailabilities, LoginDuringReservation ecc...
 * @param {object} value - oggetto che contiene tutti i dati utili per quell'evento
 * @returns 
 */
export const GATrackEvent = (event, value) => {
  try {

    // Invio l'evento a GA
    window.gtag('event', event, {
      ...value,
      event_category: event,
      event_action: 'click',
      event_label: JSON.stringify(value)
    });

    if (process.env.NODE_ENV === `development`) {
      console.info(`MonitoringProviders - Google Analytics - Event ${event} tracked.`);
    }

  } catch (error) {
    console.error("MonitoringProviders - Google - GoogleTrackEvent failed: ", error);
  }
};

export const GATrackEventApp = (event, value) => {

  const params = {
    ...value,
    event_category: event,
    event_action: 'click',
    event_label: JSON.stringify(value)
  }

  cordova.plugins.firebase.analytics.logEvent("event", params);

}