import { css } from "@emotion/react";

export const base = theme => ({ backgroundColor, border, height, padding, shadow, width, viewportAware, isMobile }) => {

  let _width = width || "";

  // Se viewportAware è true, allora non consideriamo la larghezza passata, ma forziamo i valori del viewport
  if(viewportAware) {
    _width = isMobile ? "90%" : "66.6666666667%";
  }

  return css({
    backgroundColor: theme.colors[backgroundColor],
    padding: padding ? theme.layout.padding[padding] : "0px",
    width: _width,
    border: border ? `${theme.layout.borderWidth[border]} solid ${theme.colors.gainsboro}` : "",
    borderRadius: theme.layout.borderRadius.small,
    boxShadow: shadow ? theme.layout.baseBoxShadow : "",
    height: height || "",
    overflow: "hidden",
    padding: padding ? theme.layout.padding[padding] : "0px",
  });
};