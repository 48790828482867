import { css } from "@emotion/react";


export const base = theme => ({ padding, onClick }) => css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: theme.colors.alice_blue,
  border: "1px solid #8BB0E3",
  borderRadius: theme.layout.borderRadius.small,
  cursor: onClick ? "pointer" : "initial",
  padding: padding ? theme.layout.padding[padding] : "0px",
  width: "100%",
  gap: theme.layout.gap.micro
});

export const close = theme => css({
  display: "flex",
  alignItems: "flex-start",
  paddingTop: theme.layout.padding.micro,
  paddingBottom: theme.layout.padding.micro
});

export const success = theme => css({
  backgroundColor: theme.colors.success_light,
  border: "1px solid #80E1D2"
});

export const warning = theme => css({
  backgroundColor: theme.colors.warning_light,
  border: "1px solid #FFE380"
});

export const danger = theme => css({
  backgroundColor: theme.colors.danger_light,
  border: "1px solid #F3A59D"
});