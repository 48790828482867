import { css } from "@emotion/react";

export const base = (theme, color) => css({
  alignItems: "center",
  backgroundColor: theme.colors[color] || "",
  borderRadius: theme.layout.baseHeights.big,
  cursor: "pointer",
  display: "flex",
  height: theme.layout.baseHeights.big,
  justifyContent: "center",
  padding: "0",
  width: theme.layout.baseHeights.big,
});


