import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const container = theme => ({ }) => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.small,
  alignItems: "center"
});

export const checkbox = theme => ({ checked, disabled }) => css({

  alignItems: "center",
  borderColor: theme.colors.primary,
  borderStyle: "solid",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "1.125rem",
  minWidth: "1.125rem",
  height: "1.125rem",
  backgroundColor: checked ? theme.colors.primary : "transparent",

  opacity: disabled ? "0.5" : "1",
  pointerEvents: disabled ? "none" : ""
});

export const standard = theme => css({
  borderRadius: theme.layout.borderRadius.small,
  borderWidth: theme.layout.borderWidth.medium,
});

export const round = theme => css({
  borderRadius: "50%",
  borderWidth: theme.layout.borderWidth.standard // attualmente le checkbox tonde hanno il bordo più sottile delle checkbox normali
});