import $ from 'jquery';
import jquery_ui from 'jquery-ui';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

var ANIMATION_DURATION = 500;

export default Marionette.Region.extend({

	attachHtml: function (view) {
		//--- The default implementation of attachHtml ---
		this.$el.empty().append(view.el);
		//------------------------------------------------
		if (window.MOP_regionDirectionReverse) {
			window.MOP_regionDirectionReverse = undefined;
			this.$el.hide().toggle("slide", { direction: "left" }, ANIMATION_DURATION);
		} else {
			this.$el.hide().toggle("slide", { direction: "right" }, ANIMATION_DURATION);
		}
	}
});