import React, { useEffect, useState } from 'react';
import MOP from 'app';

import { ThemeProvider } from '@emotion/react';
import { ResetStyle, Dialog, Box, Label, Button } from "uikit";
import { getTheme } from 'uikit-utils';

import { TTPolyglot } from "@frontend/tt-polyglot";

import { getFormattedCounterFromSeconds } from 'common-lib/dates';
import { useCountDown } from 'ahooks';

import {
  ABSOLUTE_DIALOG_SHOW_MILLISECONDS_PATIENT,
  ABSOLUTE_DIALOG_SHOW_MILLISECONDS_ADMIN,
  DIALOG_TYPE_ABSOLUTE,
  IDLE_DIALOG_SHOW_MILLISECONDS_PATIENT,
  IDLE_DIALOG_SHOW_MILLISECONDS_ADMIN,
} from '../../constants';

export const CountDownDialog = ({ onConfirm, onEnd, type, loading }) => {

  const [targetDate, setTargetDate] = useState(undefined);

  const [timeout] = useCountDown({
    targetDate,
    onEnd: () => {
      !MOP.Utilities.empty(onEnd) && onEnd();
    },
  });

  useEffect(() => {

    const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};

    let target = 0;

    if(type === DIALOG_TYPE_ABSOLUTE) {
      target = Date.now() + (MOP.isAdminLoggedIn() ? ABSOLUTE_DIALOG_SHOW_MILLISECONDS_ADMIN : ABSOLUTE_DIALOG_SHOW_MILLISECONDS_PATIENT);

      // Controllo la scadenza dell'absolute in localStorage, se questa è minore di target allora mostro un timer che parte da absoluteTimer
      // visto che il timer scadrà prima rispetto al valore predefinito
      const absoluteTimer = !MOP.Utilities.empty(timeouts?.absolute_timer) ? parseInt(timeouts.absolute_timer) : null;
      if(!MOP.Utilities.empty(absoluteTimer) && absoluteTimer < target) target = absoluteTimer;
    } else {
      target = Date.now() + (MOP.isAdminLoggedIn() ? IDLE_DIALOG_SHOW_MILLISECONDS_ADMIN : IDLE_DIALOG_SHOW_MILLISECONDS_PATIENT);

      // Controllo la scadenza dell'idle in localStorage, se questa è minore di target allora mostro un timer che parte da idleTimer
      // visto che il timer scadrà prima rispetto al valore predefinito
      const idleTimer = !MOP.Utilities.empty(timeouts?.idle_timer) ? parseInt(timeouts.idle_timer) : null;
      if(!MOP.Utilities.empty(idleTimer) && idleTimer < target) target = idleTimer;
    }

    setTargetDate(target);
    return () => setTargetDate(undefined);
  }, []);


  return (
    <ThemeProvider theme={getTheme()}>
      <ResetStyle />
      <Dialog
        open={true}
        outClick={false}
        closeButton={false}
        fullScreen={false}
      >
        <Dialog.Content>
          <Box direction="column" gap="standard">
            {
              type === DIALOG_TYPE_ABSOLUTE ?
                <>
                  <Label size={"medium"}>
                    {TTPolyglot.t('Mop Absolute Timeout Description')}
                  </Label>
                  <Label size={"medium"}>
                    {TTPolyglot.t('Mop Absolute Timeout Remaining Time')}
                  </Label>
                </>
                :
                <Label size={"medium"}>
                  {TTPolyglot.t('Mop Idle Timeout Remaining Time')}
                </Label>
            }
            <Label size={"large"} weight={"semiBold"}>
              {getFormattedCounterFromSeconds(MOP, Math.round(timeout / 1000))}
            </Label>
          </Box>

        </Dialog.Content>
        <Dialog.Footer>
          <Box direction="row" horizontalAlign="end" width="100%">
            <Button onClick={onConfirm} loading={loading}>
              {
                type === DIALOG_TYPE_ABSOLUTE ?
                  `${TTPolyglot.t('Mop Got It')}` :
                  `${TTPolyglot.t('Mop Keep Me Logged In')}`
              }
            </Button>
          </Box>
        </Dialog.Footer>
      </Dialog>
    </ThemeProvider >
  );

};