import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';

// Rinomina image
export const Image = ({ src, alt, size }) => {

  const theme = useTheme();

  if (!src) return null;

  return (
    <img css={style.base(theme)({ size })} src={src} alt={alt} />
  );
};


Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.oneOf(["xxsmall", "xsmall", "small", "medium"])
};

Image.defaultProps = {
  src: null,
  alt: null,
  size: "small",
};