/**
 * A module representing the user model.
 * @module Entities
 */
import $ from 'jquery';
import MOP from 'app';
import ModelBase from 'models/model_base';
import CollectionBase from 'collections/collection_base';
import {
  updateDossierLastAccess
} from 'api/users';

import { 
  confirmDossierPrivacy,
  confirmDossierTwoFactor,
  confirmOTPVerification,
  sendOTPVerification,
 } from '../lib/api/user';

import { isDossierSynced } from 'common-mop/dossier';

import { DOSSIER_BIOMETRICS_NOT_ACTIVE } from 'new-dossier-common/constants';
import { getDefaultAuthObject } from 'new-dossier-common';

import { TTPolyglot } from "@frontend/tt-polyglot";

import { AUTH_MODE_FROM_SPID } from 'common-lib/constants';

	MOP.module('Entities', function(Entities, MOP, Backbone, Marionette, $, _){

        /**
         * @constructor
         * @alias module:User
         */
		Entities.User = ModelBase.extend({

			fetch_fn: 'search_user',
      searchPath: 'users',
      
			initialize: function() {
				// Invoke parent initialize();
				ModelBase.prototype.initialize.apply(this, arguments);

				this.on(
                    'change:userid',
                    function(model, value, options){
                        this.set('id', value);
                    }, this);
			},
      
      specializeParse : function( entity_data ) {
        
        // Se birthday non è una data formattata e se birthday_formatted è pieno prendo quello per birthday
        if (entity_data["birthday"] && entity_data["birthday"].indexOf("/") == -1 && !MOP.Utilities.empty(entity_data["birthday_formatted"])) {
          entity_data.birthday = entity_data["birthday_formatted"];
        }
      },
      
			getIdFieldName : function() {
				return 'memberid';
			},

			defaults: {
                memberid: null,
                sessionid: undefined,
                fname: "",
                parents: {}
            },

			validate: function(attrs, options) {
        var errors = {};
        if (!attrs.fname) {
            errors.fname = TTPolyglot.t("First name is required.");
        }
        if (this.isNew()) {
          if (!attrs.privacy) {
              errors.privacy = TTPolyglot.t("Privacy NO");
          }
        }

        if (!_.isEmpty(errors)) {
            return errors;
        }
			},

      completeProfile: function (attributes, id) {
        var searchPath = "";
        var ajaxMethod = "";
        
        var requesting = null;

        searchPath = 'users/'+id;
        ajaxMethod = 'PUT';

        //Aggiungiamo sempre in update anche il parametro userid
        attributes.userid = id;

        const ajax_data = {...attributes, forceUserDataUpdate: 1};

        var ajaxRestParams = {
          searchPath: searchPath,
          ajax: {
            options: {
              method: ajaxMethod
            },
            data: ajax_data
          }
        };
        
         var requesting = MOP.ajaxRest(ajaxRestParams);

         var _this = this;
         var updating = requesting.then( function( resp ){
          if( resp['result'] == 'OK' ){
              if (MOP.getLoggedUserId() == id) {
                _this.set(attributes);
                _this.trigger("sync", _this, resp);
              }
          } else {
              // Qui dobbiamo andare a gestire questa eccezione perchè è il caso in cui viene cambiato anche il numero di telefono in fase di update
              // l'api ritorna un'eccezione quindi non viene aggiornato localmente il modello utente.
              if (resp['exception'] == "TUOTEMPO_VCODE_RESENT_AND_USER_INVALIDATED" ||
                   resp['exception'] == "TUOTEMPO_VCODE_RESENT_AND_DOSSIER_STRONG_AUTH_INVALIDATED" ||
                   resp['exception'] == "TUOTEMPO_DOSSIER_STRONG_AUTH_INVALIDATED") {
                    
                    if (MOP.getLoggedUserId() == id) {
                      attributes.validated = undefined;
                      _this.set(attributes);
                      _this.trigger("sync", _this, resp);
                    }
              } else {
                _this.trigger("invalid", _this, resp['msg']);
              }
          }

          return resp;
        });

        return updating;
      },
			save: function(attributes, newParent = false) {

        this.validationError = this.validate(attributes);
        if( this.validationError ){
            return false;
        }
          
          var searchPath = "";
          var ajaxMethod = "";
          
          var requesting = null;

          if( this.isNew()){
              var ajax_fn = 'register_user';
              //Gestione __utmz in fase di registrazione solo per pazienti
              var __utmz = MOP.querystring['__utmz'];
              if( !MOP.isAdminLoggedIn() && !MOP.Utilities.empty(__utmz) ){
                  attributes.__utmz = __utmz;
              }
              var self = this;
              if(!newParent){
                var ajax = {
                  options : {
                      type:     'POST',
                      dataType: 'json'
                  },
                  data: attributes
                 };

                 requesting = MOP.ajax({
                     fetch_fn: ajax_fn,
                     ajax: ajax
                 });
   
              } else {
                searchPath = 'users/' + attributes.parentid + '/children';
                ajaxMethod = 'POST';
                var ajaxRestParams = {
                  searchPath: searchPath,
                  ajax: {
                    options: {
                      method: ajaxMethod
                    },
                    data: attributes
                  }
                };
                requesting = MOP.ajaxRest(ajaxRestParams);
              }
          } else {
              searchPath = 'users/'+this.id;
              ajaxMethod = 'PUT';
              //Aggiungiamo sempre in update anche il parametro userid
              attributes.userid = this.id;

              // Iterare tutti questi blacklisted e controllare se THIS.attributes hanno o no il valore, 
              //se non c'è l'hanno togliere la chiave dall'array blacklist
              var blacklisted_keys = [];
              blacklisted_keys.forEach( function(b) {
                if (!MOP.Utilities.empty(this.attributes[b])) {
                  blacklisted_keys.push(b)
                }
              });

              attributes = _.omit(attributes, blacklisted_keys);

              const contacts_keys = [
                "address",
                "birthday",
                "city",
                "country",
                "cp",
                "email",
                "gender",
                "phone",
                "hometel",
                "province",
                "skypeid"
              ]
              contacts_keys.map(contactKey => {
                if(attributes[contactKey] !== this.attributes[contactKey] && attributes[contactKey] === "") {
                  attributes[contactKey] = "null" // necessario per il BE
                }
              })

              var ajaxRestParams = {
                searchPath: searchPath,
                ajax: {
                  options: {
                    method: ajaxMethod
                  },
                  data: attributes
                }
              };
              var requesting = MOP.ajaxRest(ajaxRestParams);
          }

          var self = this;
          var saving = requesting.then( function( resp ){
            if( resp['result'] == 'OK' ){
                  self.set(attributes);
                  self.trigger("sync", self, resp);
            }else{
              
              // Qui dobbiamo andare a gestire questa eccezione perchè è il caso in cui viene cambiato anche il numero di telefono in fase di update
              // l'api ritorna un'eccezione quindi non viene aggiornato localmente il modello utente.
              if (resp['exception'] == "TUOTEMPO_VCODE_RESENT_AND_USER_INVALIDATED" ||
                   resp['exception'] == "TUOTEMPO_VCODE_RESENT_AND_DOSSIER_STRONG_AUTH_INVALIDATED" ||
                   resp['exception'] == "TUOTEMPO_DOSSIER_STRONG_AUTH_INVALIDATED") {
                     
                     attributes.validated = undefined;
                     self.set(attributes);
                     self.trigger("sync", self, resp);
              } else {
                self.trigger("invalid", self, resp['msg']);
              }
                
            }

              return resp;
          });

          return saving;
      },

			destroy: function(options) {
			  var self = this;
        
        var ajaxRestParams = {
          searchPath: "users/"+this.id,
          ajax: {
            options: {
              method: "DELETE"
            }
          }
        };
        
        var requesting = MOP.ajaxRest(ajaxRestParams);
        
			  var cancelling = requesting.then(function(resp) {
			    if (resp['result'] == 'OK') {
			      self.trigger("sync", self, resp);
			    } else {
			      self.trigger("invalid", self, resp['msg']);
			    }

			    return resp;
			  });

			  return cancelling;
			},
      
      updateDeviceDatas: function (postData) {

          var params = {
              searchPath: 'users/' + this.get('id'),
              bypassCheckResponse: true,
              ajax: {
                  options: {
                      method: 'PUT'
                  },
                  data: postData
              },
              loading: {
                  enabled: false
              }
          };

          MOP.ajaxRest(params);
      },
      sendOTPVerification: function () {
        
          var deferred = new $.Deferred();
          
          var params = {
              searchPath: 'users/' + this.get('id') + '/_dossier_otp',
              bypassCheckResponse: false,
              ajax: {
                  options: {
                      method: 'GET'
                  }
              },
              loading: {
                  enabled: true
              }
          };

          MOP.ajaxRest(params)
          .done(function (resp) {
            if( resp['result'] === 'OK' ){
              deferred.resolve(resp);
            } else {
              deferred.reject(resp);
            }
          })
          .fail(function (e) {
              deferred.reject(e);
          });

          return deferred;
      },
      confirmOTPVerification: function (dossier_otp) {
          var _this = this;
          var deferred = new $.Deferred();
          var params = {
              searchPath: 'users/' + this.get('id') + '/_dossier_otp_verification',
              bypassCheckResponse: true,
              ajax: {
                  options: {
                      method: 'POST'
                  },
                  data: {
                      dossier_otp: dossier_otp
                  }
              },
              loading: {
                  enabled: true
              }
          };

          MOP.ajaxRest(params)
          .done(function (resp) {
            if( resp['result'] === 'OK' ){
              deferred.resolve(resp);
            } else {
              deferred.reject(resp);
            }
              
          })
          .fail(function (e) {
              deferred.reject(e);
          });

          return deferred;
      },
      updatePhone: function (postData) {
          var _this = this;
          var deferred = new $.Deferred();
          var params = {
              searchPath: 'users/' + this.get('id'),
              bypassCheckResponse: true,
              ajax: {
                  options: {
                      method: 'PUT'
                  },
                  data: postData
              }
          };

          MOP.ajaxRest(params)
              .done(function (resp) {
                
                if (resp['result'] === 'OK' || resp['exception'] == "TUOTEMPO_VCODE_RESENT_AND_USER_INVALIDATED") {
                    // Se l'aggiornamento del telefono ha avuto successo
                    // settiamo l'utente come NON valido così entrerà nel flusso
                    // del vcode
                    postData.validated = null;
                    _this.set(postData);
                    
                    deferred.resolve(resp);

                  } else {
                    deferred.reject(resp);
                  }

              })
              .fail(function (e) {
                  deferred.reject();
              });

          return deferred;
      },
      updateUserAfterInvitation: function (postData) {
        var _this = this;
        var deferred = new $.Deferred();

        const _data = { ...postData };

        var params = {
            searchPath: 'users/' + this.get('id') + '/_pwd_and_privacy',
            bypassCheckResponse: false,
            ajax: {
                options: {
                    method: 'PUT'
                },
                data: _data
            },
            loading: {
                enabled: true
            }
        };

        MOP.ajaxRest(params)
            .done(function (resp) {

                if (resp["result"] != "OK") {

                    deferred.reject(resp);

                } else {
                    // Se il validation code è attivo, e se abbiamo appena aggiornato la password con successo
                    // allora siccome il server ci ha già validato, andiamo a validare l'utente lato client
                    if (MOP.isValidationEnabled()) {
                      _data.validated = Math.floor(Date.now() / 1000);
                    }

                    // Siamo appena tornati dalla validazione lato server, andiamo a mettere la password come NON scaduta
                    // Altrimenti si crea la situazione per cui abbiamo l'utente con la password scaduta, anche se siamo appena stati validati lato BE.
                    // Questo perchè il BE non ci torna una risposta con il modello utente, 
                    // quindi dobbiamo settare le nuove chiavi con i dati che abbiamo noi in pancia, e dobbiamo mettere pwd_expired = 0 manualmente
                    _data.pwd_expired = 0;
                    
                    _this.set(_data);
                    MOP.updateLoggedUserData(_data);
                    
                    deferred.resolve(resp);
                }
              
            })
            .fail(function (e) {
                deferred.reject(e);
            });

        return deferred;
    },
            updatePassword: function (postData) {
                var _this = this;
                var deferred = new $.Deferred();
                const _data = { ...postData };
                var params = {
                    searchPath: 'users/' + this.get('id') + '/_password',
                    bypassCheckResponse: true,
                    ajax: {
                        options: {
                            method: 'PUT'
                        },
                        data: _data
                    },
                    loading: {
                        enabled: true
                    }
                };

                MOP.ajaxRest(params)
                    .done(function (resp) {
                      
                      if (resp["result"] != "OK") {
                        deferred.reject(resp);
                      } else {
                        // Se il validation code è attivo, e se abbiamo appena aggiornato la password con successo
                        // allora siccome il server ci ha già validato, andiamo a validare l'utente lato client
                        if (MOP.isValidationEnabled()) {
                          _data.validated = Math.floor(Date.now() / 1000);
                        }

                        // Siamo appena tornati dalla validazione lato server, andiamo a mettere la password come NON scaduta
                        // Altrimenti si crea la situazione per cui abbiamo l'utente con la password scaduta, anche se siamo appena stati validati lato BE.
                        // Questo perchè il BE non ci torna una risposta con il modello utente, 
                        // quindi dobbiamo settare le nuove chiavi con i dati che abbiamo noi in pancia, e dobbiamo mettere pwd_expired = 0 manualmente
                        _data.pwd_expired = 0;
                        
                        _this.set(_data);
                        MOP.updateLoggedUserData(_data);

                        // FD85174 - Solo per app, aggiorno la password nel permanentLocalStorage 
                        if (MOP.getLoginData() && !MOP.Utilities.empty(_data.password)) {
                          const loginDataObjNew = {...MOP.permanentLocalStorage.get('login_data'), ..._data};
                          MOP.setLoginData(loginDataObjNew);
                        }
                        
                        deferred.resolve(resp);
                      }
                    })
                    .fail(function (e) {
                        deferred.reject(e);
                    });

                return deferred;
            },
            
            updateUserData: function (postData, loading) {
              
              var _this = this;
              var deferred = new $.Deferred();
              
              var params = {
                  searchPath: 'users/' + this.get('id'),
                  bypassCheckResponse: true,
                  ajax: {
                      options: {
                          method: 'PUT'
                      },
                      data: postData
                  },
                  loading: {
                      enabled: loading
                  }
              };

              MOP.ajaxRest(params)
                  .done(function (resp) {
                    
                    _this.set(postData);
                    MOP.updateLoggedUserData(postData);
                    
                    deferred.resolve(resp);
                  })
                  .fail(function (e) {
                      deferred.reject(e);
                  });

              return deferred;
              
            },
            
            hideHistory: function (postData) {
                var _this = this;
                var deferred = new $.Deferred();
                var params = {
                    searchPath: 'users/' + this.get('id')+ '/_hide_history',
                    bypassCheckResponse: true,
                    ajax: {
                        options: {
                            method: 'PUT'
                        },
                        data: postData
                    },
                    loading: {
                        enabled: false
                    }
                };

                MOP.ajaxRest(params)
                    .done(function (resp) {
                      if(resp.result === 'OK') {
                        _this.set(resp["return"]["results"]);
                        MOP.updateLoggedUserData(resp["return"]["results"]);

                        deferred.resolve(resp);
                      } else {
                        deferred.reject(resp);
                      }
                        
                    })
                    .fail(function (e) {
                        deferred.reject(e);
                    });

                return deferred;
            },

            updateLoggedUserDataParents: function (userid, data) {
                if (!userid) {
                    throw new ReferenceError("The 'userid' parameters id mandatory");
                }

                if (this.isLoggedIn()) {
                    var parents = _.extend({}, this.get('parents'));
                    var parents_info = _.extend({}, this.get('parents_info'));
                    
                    if (data) { 
                      
                      var name = (MOP.Utilities.empty(data.social_name) ? data.fname : data.social_name) + " " + data.lname;
                      
                      if (!MOP.Utilities.empty(data.lname_2)) {
                        name = name + " " + data.lname_2;
                      }
                      
                        parents_info[userid] = data;
                        parents[userid] = name;
                    } else {
                        parents = _.omit(parents, userid);
                        parents_info = _.omit(parents_info,userid);
                    }

                    this.set({parents_info: parents_info});
                    this.set({parents: parents});

                    return true;
                } else {
                    return false;
                }
            },

            isLoggedIn: function () {
                return !MOP.Utilities.empty(this.get('sessionid'));
            },

            getPrivacyHistoryPrivacyModified: function () {
              const _history = this.get('privacy_history');
              if(MOP.Utilities.empty(_history)) return null;

              const parsedHistory = JSON.parse(_history);

              return MOP.Utilities.empty(parsedHistory?.privacy?.modified) ? null : parsedHistory.privacy.modified;
            },

            setPrivacyHistoryPrivacyModified: function (modified) {

              let privacy_history = {
                privacy: {
                  modified: null
                }
              };

              // Potrebbero esserci dei casi in cui la privacy_history dell'utente è vuota, in tali casi andiamo a crearci un oggeto fittizio in modo da settare tale oggetto
              // In attesa che il BE ci restituisca nella risposta della API tutto il valore di privacy_history e non solo il modified
              // A quel punto basterà salvare solamente la risposta tornata, senza fare queste logiche qui sotto
              const _history = this.get('privacy_history');
              if(!MOP.Utilities.empty(_history)) {
                privacy_history = JSON.parse(_history);
              }

              privacy_history.privacy.modified = modified;

              this.set("privacy_history", JSON.stringify(privacy_history));
            },

            getLoggedUserData: function () {
                return this.toJSON();
            },

            isDossierPrivacyValid: function (_userid) {

                if (_userid && this.get('id') != _userid) {
                    if (this.get('parents_info')[_userid]) {
                        return !MOP.Utilities.empty(this.get('parents_info')[_userid].dossier_privacy)
                    }
                }

                return !MOP.Utilities.empty(this.get('dossier_privacy'));
            },

            isDossierTwoFactorValid: function (_userid) {

                if (_userid && this.get('id') != _userid) {
                    if (this.get('parents_info')[_userid]) {
                        return !MOP.Utilities.empty(this.get('parents_info')[_userid].dossier_strong_auth)
                    }
                }

                return !MOP.Utilities.empty(this.get('dossier_strong_auth'));
            },

            isResultsPrivacyDenied: function (_userid) {

              if (_userid && this.get('id') != _userid) {
                  if (this.get('parents_info')[_userid]) {
                      return !MOP.Utilities.empty(this.get('parents_info')[_userid].results_privacy_denied)
                  }
              }

              return !MOP.Utilities.empty(this.get('results_privacy_denied'));
          },


            isDossierSubSessionValid: function () {
                if (MOP.Utilities.isMobileApp()) {
                  if (MOP.permanentLocalStorage.has('dossierSubSession')) {
                    if (MOP.permanentLocalStorage.get('dossierSubSession')+3600 > MOP.Utilities.getTimestamp()) {
                      return true;
                    }
                  }
                  return false;
                  //return window.docCookies.getItem('dossierSubSession');
                } else {
                    return true;
                }
            },

            isDossierOTPVerifcationValid: function () {
              if (MOP.permanentLocalStorage.has('dossierOTPVerification')) {
                if (MOP.permanentLocalStorage.get('dossierOTPVerification')+1800 > MOP.Utilities.getTimestamp()) {
                  return true;
                }
              }
              return false;
            },

            isResourceLoggedIn: function () {
                return this.isLoggedIn() && this.get('position') == 'resource';
            },
            
            isBaseResourceLoggedIn: function () {
                return this.isLoggedIn() && this.get('position') == 'resource' && this.getLoggedUserProfile() == MOP.constants.VIEWER;
            },
            
            isAdvancedResourceLoggedIn: function () {
                return this.isLoggedIn() && this.get('position') == 'resource' && this.getLoggedUserProfile() == MOP.constants.OPERATOR;
            },
            
            isOperatorNotResourceLoggedIn: function () {
                return this.isLoggedIn() && !this.isResourceLoggedIn() && this.getLoggedUserProfile() == MOP.constants.OPERATOR;
            },
            
            isViewerLoggedIn: function () {
                return this.isLoggedIn()
            },

            getLoggedUserProfile: function () {
                return this.isLoggedIn() && this.get('is_admin');
            },

            getLoggedUserPosition: function () {
                return this.isLoggedIn() && this.get('position');
            },

            getLoggedUserId: function () {
                return this.isLoggedIn() && this.get('userid');
            },

            isFromTRD111: function () {
                return (this.isLoggedIn() && this.get('auth_mode') === MOP.constants.AUTH_MODE_TRD111);
            },

            isFromAuthModePayment: function () {
              return (this.isLoggedIn() && this.get('auth_mode') === MOP.constants.AUTH_MODE_FROM_PAYMENT);
            },

            isFromAuthModeSpid: function () {
              return (this.isLoggedIn() && this.get('auth_mode') === AUTH_MODE_FROM_SPID);
            },

            isAuthModeEnabled: function () {
                return (this.isLoggedIn() && this.has('auth_mode') && !MOP.Utilities.empty(this.get('auth_mode')));
            },

            getParentsNumber: function () {
              if (this.isLoggedIn() && !MOP.Utilities.empty(this.get('parents'))) {
                return Object.keys(this.get('parents')).length;
              }
                return 0;
            },

            getParentsIds: function () {
              if (this.isLoggedIn() && !MOP.Utilities.empty(this.get('parents'))) {
                return Object.keys(this.get('parents'));
              }
                return [];
            },

            
            isOneTimeUser: function () {
                return this.isLoggedIn() && this.get('onetime_user');
            },

            isSSOUser: function () {
              return this.isLoggedIn() && this.get('is_sso_user');
            },

            isSamlUser: function () {
              return this.isLoggedIn() && this.get('is_saml_user');
            },

            isAdeslasUser: function () {
              return this.isLoggedIn() && this.get('is_adeslas_user');
            },

            isSpidUser: function () {
              return this.isLoggedIn() && this.get('is_spid_user');
            },


            isUserCertified: function () {
              return Boolean(this.get('certified')) === true;
            },

            isDossierAuthValid: function (_userid) {
                //L'OTP vince sulla subsession se il config è attivo, altrimenti ricadiamo nella subsession (che è solo per l'APP)
                var otp_subsession = false;
                
                if (!MOP.Utilities.empty(MOP.config.getInstanceConfig("dossierOTPVerification"))) {
                  otp_subsession = this.isDossierOTPVerifcationValid();
                } else {
                  otp_subsession = this.isDossierSubSessionValid();
                }
                
                return this.isDossierPrivacyValid(_userid) && this.isDossierTwoFactorValid(_userid) && otp_subsession;
            },
            
            isDossierActive: function(id) {
              
                if (this.get('id') == id) {
                    if (MOP.Utilities.empty(this.get('results_privacy_denied'))) return true;

                    return false;
                } else if (this.get('parents_info')[id]) {
                    if (MOP.Utilities.empty(this.get('parents_info')[id].results_privacy_denied)) return true;

                    return false;
                }
            },
            // controlla se almeno un dossier è abilitato per l'utente loggato o per qualcuno dei suoi familiari
            isDossierEnabled: function(){
                const ids = this.getParentsIds()
                ids.push(this.get('id'))
                let flag = false
                ids.map((id,index) => {
                    if (this.isDossierPrivacyValid(id) && this.isDossierTwoFactorValid(id) && this.isDossierActive(id)){
                        flag = true;
                        return
                    } 
                })
                return flag
            },

            isDossierSynced: function (_userid) {

              // Se l'istanza NON è integrata allora non andiamo a utilizzare il flusso del dossier_syncd perchè
              // non abbiamo flussi di sincronizzazione esterni ma è tutto interno, quindi torniamo sempre true
              if (!MOP.config.isDossierIntegrated(MOP)) {
                return true;
              }

              var dossier_syncd = null;
              if (this.get('id') == _userid) {
                  dossier_syncd = this.get('dossier_syncd');
              } else {
                  var parents_info = this.get('parents_info')
                  dossier_syncd = parents_info[_userid].dossier_syncd;
              }
              return !MOP.Utilities.empty(dossier_syncd) && dossier_syncd >= (Math.floor(Date.now() / 1000)-3600);
            },
            
            getDossierSynced: function (_userid) {

              // Se l'istanza NON è integrata allora non andiamo a utilizzare il flusso del dossier_syncd perchè
              // non abbiamo flussi di sincronizzazione esterni ma è tutto interno, quindi torniamo sempre
              // il timestamp corrente
              if (!MOP.config.isDossierIntegrated(MOP)) {
                return MOP.Utilities.getTimestamp();
              }

              var dossier_syncd = null;
              if (this.get('id') == _userid) {
                  dossier_syncd = this.get('dossier_syncd');
              } else {
                  var parents_info = this.get('parents_info')
                  dossier_syncd = parents_info[_userid].dossier_syncd;
              }

              return dossier_syncd;
            },

            notifyOnDossierSynced: function (_userid) {
                var mainUserID = this.get('id');

                var laodingOptions = {
                    loading: { enabled: false }
                };
                var proxiedSearchUserRest = $.proxy(this.searchUserRest, this, laodingOptions);

                var stopPollingFunc = function (resp) {
                    var dossier_syncd = null; 
                    if (mainUserID == _userid) {
                        dossier_syncd = resp['return']['dossier_syncd'];
                    } else {
                        var parents_info = resp['return']['parents_info'];
                        dossier_syncd = parents_info[_userid].dossier_syncd;
                    }

                    return (!MOP.Utilities.empty(dossier_syncd) && dossier_syncd >= (Math.floor(Date.now() / 1000)-3600));
                };

                return MOP.Utilities.Pollinger({
                    pollingFunction: proxiedSearchUserRest,
                    stopFunction: stopPollingFunc,
                    stopOnWindowsChange: true,
                    timeout: 10000, // 10s
                    maxExecutionTime: 600000 // 10m
                }).run(this);
            },
            
            isReservationsSynced: function () {
                return (MOP.Utilities.getTimestamp()-this.get('reservations_syncd') < 3600);
            },
            
            notifyOnReservationsSynced: function () {
                var laodingOptions = {
                    loading: { enabled: false }
                };
                
                var _this = this;
                
                var proxiedSearchUserRest = $.proxy(this.searchUserRest, this, laodingOptions);
                var stopPollingFunc = function (resp) {
                  _this.set('reservations_syncd', resp['return']['reservations_syncd']);
                  return _this.isReservationsSynced();
                };

                return MOP.Utilities.Pollinger({
                    pollingFunction: proxiedSearchUserRest,
                    stopFunction: stopPollingFunc,
                    stopOnWindowsChange: true,
                    timeout: 10000, // 10s
                    maxExecutionTime: 120000 // 2m
                }).run(this);
            },

            searchUserRest: function (forcedParams) {
                forcedParams = forcedParams || {};

                var params = {
                    searchPath: 'users/' + this.get('id'),
                    ajax: {
                        options: {
                            method: 'GET'
                        }
                    },
                    loading: {
                        enabled: true
                    }
                };

                Object.assign(params, forcedParams);

                return MOP.ajaxRest(params);
            },

            confirmDossierPrivacy: function (_userid) {
                var _this = this;
                var deferred = new $.Deferred();
                var params = {
                    searchPath: 'users/' + _userid + '/_dossier_privacy',
                    ajax: {
                        options: {
                            method: 'POST'
                        }
                    },
                    loading: {
                        enabled: true
                    }
                };

                MOP.ajaxRest(params)
                    .done(function (resp) {
                        
                        if (_this.get('id') == _userid) {
                            _this.set(resp['return']);
                            MOP.state.loggedUser.set(resp['return']);
                        } else {
                            var parents_info = _this.get('parents_info')
                            parents_info[_userid].dossier_privacy = resp['return']['dossier_privacy'];

                            if (!MOP.Utilities.empty(MOP.config.getInstanceConfig("enableLightDossierActivation"))) {
                              parents_info[_userid].dossier_strong_auth = resp['return']['dossier_strong_auth'];
                            }

                            _this.set('parents_info', parents_info);
                            MOP.state.loggedUser.set('parents_info', parents_info);
                        }

                        deferred.resolve(resp);
                    })
                    .fail(function (e) {
                        deferred.reject(e);
                    });

                return deferred;
            },

            confirmDossierTwoFactor: function (_params, _userid, loading) {
                var _this = this;
                var deferred = new $.Deferred();
                var params = {
                    searchPath: 'users/' + _userid + '/_dossier_strong_auth',
                    bypassCheckResponse: true,
                    ajax: {
                        options: {
                            method: 'POST'
                        },
                        data: _params
                    },
                    loading: {
                        enabled: (loading == (null || undefined)) ? true : loading
                    }
                };

                MOP.ajaxRest(params)
                    .done(function (resp) {
                        if (resp['result'] != 'OK') {
                            return deferred.reject(resp);
                        }

                        if (_this.get('id') == _userid) {
                            _this.set(resp['return']);
                            MOP.state.loggedUser.set(resp['return']);
                        } else {
                            var parents_info = _this.get('parents_info')
                            parents_info[_userid].dossier_strong_auth = resp['return']['dossier_strong_auth'];

                            _this.set('parents_info', parents_info);
                            MOP.state.loggedUser.set('parents_info', parents_info);
                        }

                        deferred.resolve(resp);
                    })
                    .fail(function (e) {
                        deferred.reject(e);
                    });

                return deferred;
            },

            confirmSubSessionPassword: function (params) {
                var deferred = new $.Deferred();

                MOP.LM.checkPasswordStored(params.password)
                    .done(function (resp) {
                        if (!resp) {
                            return deferred.reject(resp);
                        }

                        return deferred.resolve(resp);
                    })
                    .fail(function (e) {
                        // Per qualunque tipo di fallimento redirigo alla login.
                        MOP.changePage('login', null, MOP.getCurrentPage(), MOP.getCurrentRoute());
                    });

                return deferred;
            },

            // Al momento applicabile solo sull'utente loggato
            updateDossierLastAccess: function () {
              const _this = this;
            
              return new Promise((resolve, reject) => {

                updateDossierLastAccess(_this.id)
                  .then( resp => {
                    if (resp['result'] != 'OK') {
                      reject(resp);
                    }
                    _this.set(resp['return']);
                    resolve();
                  })
                  .catch( error => reject(error) );
              }); 
            },


            setFavorite: function(resid, resName){
                var deferred = new $.Deferred();

                var ajaxRestParams = {
                    searchPath: 'users/'+ MOP.getLoggedUserData().userid +'/_favorite_resource',
                    ajax: {
                        options: {
                            method: 'POST'
                        },
                        data: {
                            resourceid: resid,
                            resourceName: resName
                        }
                    }
                };

                return MOP.ajaxRest(ajaxRestParams)
                    .done(function (resp) {
                        if (resp['result'] != 'OK') {
                            deferred.reject(resp);
                            return deferred;
                        }

                        MOP.state.loggedUser.set(resp['return']);

                        deferred.resolve(resp);
                        return deferred;
                    })
                    .fail(function (e) {
                        return deferred.reject(e);
                    });
                },

                unsetFavorite: function(resid){
                    var deferred = new $.Deferred();

                    var ajaxRestParams = {
                        searchPath: 'users/'+ MOP.getLoggedUserData().userid +'/_favorite_resource',
                        ajax: {
                            options: {
                                method: 'DELETE'
                            }
                        },
                        querystring: {
                          resourceid: resid
                      }
                    };

                    return MOP.ajaxRest(ajaxRestParams)
                        .done(function (resp) {
                            if (resp['result'] != 'OK') {
                                return deferred.reject(resp);
                            }

                            MOP.state.loggedUser.set(resp['return']);

                            deferred.resolve(resp);
                            return deferred;
                        })
                        .fail(function (e) {
                            return deferred.reject(e);
                        });
                },
                
                deleteUserPayments: function(){
                    var deferred = new $.Deferred();
                    var _this = this;
                    var ajaxRestParams = {
                        searchPath: 'user_payments/'+ MOP.getLoggedUserData().user_payment['user_paymentid'],
                        ajax: {
                            options: {
                                method: 'DELETE'
                            }
                        }
                    };

                    return MOP.ajaxRest(ajaxRestParams)
                        .done(function (resp) {
                          
                            if (resp['result'] != 'OK') {
                                return deferred.reject(resp);
                            }
                            
                            _this.set('user_payment', null);
                            MOP.state.loggedUser.set('user_payment', null);

                            deferred.resolve(resp);
                            return deferred;
                        })
                        .fail(function (e) {
                            return deferred.reject(e);
                        });
                },

                extendSession: function() {
                  const that = this;

                  return new Promise((resolve, reject) => {

                    const ajaxParams = {
                      searchPath: `users/${that.get("userid")}/sessions`,
                      ajax: {
                        options: {
                          method: 'GET'
                        }
                      }
                    };
                
                    MOP.ajaxRest(ajaxParams, true)
                      .done(response => {
                        if (response.result === "OK") {
                          // Aggiorno il modello utente con il nuovo valore di session tornato e risolvo la promise
                          that.set({ session: response.return});
                          
                          resolve(response);
                        }
                        else reject(response);
                      }
                      )
                      .fail(reject);
                  });
                },




                /*
                ///////////////////////////////

                PIERO:
                Qui sotto ci sono tutte le funzioni che sono state rifatorizzate a seguito del refactoring con react dei moduli che la utlizzano. 
                Facciamo questo perchè abbiamo deciso di tenere il modello backbone dell'utente loggato fino a che non avremo rifattorizzato tutti i moduli, 
                e da valutare se potremo tenerlo anche dopo, perchè visto che tutti i moduli sono atomici, questo è l'unica risorsa condivisa da tutti, 
                e ci permette di trattarlo come se fosse uno stato "redux" comune a tutti. Ovviamente in futuro quando vorremo togliere backbone questa 
                cosa potrà essere sostituita con altro, che però deve mantere le stesse logiche, es. la funzione onChange che al momento utilizza l'header 
                per riflettere i cambiamenti sull'utente.

                //////////////////////////////
                */

                //Pierpaolo WIP

                //Construct user json for the dossier state 
                _getDossierUserInfo : function (id) {

                  let _this = this;

                  if(_this.get('id') === id){
                    return {
                      id,
                      dossier_privacy: _this.get('dossier_privacy'),
                      dossier_strong_auth: _this.get('dossier_strong_auth'),
                      fname: _this.get('fname'),
                      social_name: _this.get('social_name'),
                      lname: _this.get('lname'),
                      lname2: _this.get('lname2'),
                      gender: _this.get('gender'),
                      dossier_syncd: _this.get('dossier_syncd')
                    }
                  } else if(_this.get('id') != id && _this.get('parents_info')[id]) {
                    return {
                      id,
                      dossier_privacy: _this.get('parents_info')[id].dossier_privacy,
                      dossier_strong_auth: _this.get('parents_info')[id].dossier_strong_auth,
                      fname: _this.get('parents_info')[id].fname,
                      social_name: _this.get('parents_info')[id].social_name,
                      lname: _this.get('parents_info')[id].lname,
                      lname2: _this.get('parents_info')[id].lname2,
                      gender: _this.get('parents_info')[id].gender,
                      dossier_syncd: _this.get('parents_info')[id].dossier_syncd,
                    }
                  } 
                  return null;                  
                },

                _getDossierUsers : function () {

                  let _this = this;

                  const parents = _this.getParentsIds();
                  const users = [];
                  
                  // Pushing logged user in state
                  users.push(_this._getDossierUserInfo(_this.id));

                  // Pushing all the parents in state
                  parents.forEach(parentId => {
                    users.push(_this._getDossierUserInfo(parentId));
                  })

                  return users;

                },

                _confirmDossierPrivacy: function (_userid) {
                  const _this = this;
                
                  return new Promise((resolve, reject) => {

                    confirmDossierPrivacy(_userid)
                      .then( resp => {
                        if (resp['result'] != 'OK') {
                          reject(resp);
                        }
                        if (_this.get('id') == _userid) {
                          _this.set(resp['return']);
                        } else {
                          var parents_info = _this.get('parents_info')
                          parents_info[_userid].dossier_privacy = resp['return']['dossier_privacy'];

                          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig("enableLightDossierActivation"))) {
                            parents_info[_userid].dossier_strong_auth = resp['return']['dossier_strong_auth'];
                          }

                          _this.set('parents_info', parents_info);
                        }
                        resolve(_userid);
                      })
                      .catch( error => reject(error) );
                  }); 
                },

                _confirmDossierTwoFactor: function(_userid, userIdNumber, episodeLegacyId, enableLoader = true) {
                  const _this = this;

                  return new Promise((resolve, reject) => {
                    confirmDossierTwoFactor(_userid, userIdNumber, episodeLegacyId, enableLoader)
                      .then( resp => {
                        if (resp['result'] != 'OK') {
                          return reject(resp);
                        }
                        if (_this.get('id') == _userid) {
                          _this.set(resp['return']);
                        } else {
                          let parents_info = _this.get('parents_info')
                          parents_info[_userid].dossier_strong_auth = resp['return']['dossier_strong_auth'];
            
                          _this.set('parents_info', parents_info);
                        }
                        resolve(_userid)
                      })
                      .catch( error => reject(error) )
                  })
                },

                _setDossierTwoFactor: function (data) {
                  const _this = this;

                  if (_this.get('id') == data.userid) {
                    // salvo nei dati dell'utente che ha appena inserito il legacy otp
                    data.legacy_otp_validated = true;
                    _this.set(data);
                  } else {
                    let parents_info = _this.get('parents_info')
                    parents_info[data.userid].dossier_strong_auth = data['dossier_strong_auth'];
                    parents_info[data.userid].legacy_otp_validated = true;
      
                    _this.set('parents_info', parents_info);
                  }
                },

                _renewDossierOTPExpiration: function () {

                  const workBiometrics = MOP.permanentLocalStorage.has('dossierAuth') ? JSON.parse(MOP.permanentLocalStorage.get('dossierAuth')) : getDefaultAuthObject()
                  workBiometrics.otp_expiration = MOP.Utilities.getTimestamp();

                  MOP.permanentLocalStorage.set('dossierAuth',  JSON.stringify(workBiometrics));
                },

                _sendOTPVerification: function (_userid) {
        
                  return new Promise((resolve, reject) => {
                    sendOTPVerification(_userid)
                    .then( resp => {
                      if( resp['result'] != 'OK' ){
                        reject(resp);
                      }
                      resolve(resp);
                    })
                    .catch(
                      error => reject(error)
                    )
                  });
                },

                _confirmOTPVerification: function (_userid, dossier_otp) {
                  const that = this;

                  return new Promise((resolve, reject) => {
                    confirmOTPVerification(_userid, dossier_otp)
                      .then( resp => {
                        if( resp['result'] != 'OK' ){
                          reject(resp)
                        }
                        //MOP.DossierApp.otpVerificationSuccess = resp['msg']; // TODO Pierpaolo a cosa serve? 
                        // La validità dell'OTP è di 60min.
                        that._renewDossierOTPExpiration()
                        
                        resolve(_userid)
                      })
                      .catch( error => reject(error))
                  });
                },

                _renewDossierSubSessionExpiration: function () {

                  const workBiometrics = MOP.permanentLocalStorage.has('dossierAuth') ? JSON.parse(MOP.permanentLocalStorage.get('dossierAuth')) : getDefaultAuthObject()
                  workBiometrics.subsession_expiration = MOP.Utilities.getTimestamp();

                  MOP.permanentLocalStorage.set('dossierAuth',  JSON.stringify(workBiometrics));
                },

                _confirmSubSessionPassword: function (params) {

                  const that = this;

                  // Funzione utilizzata nella subsession standard per rinnovare la subsession
                  
                  return new Promise((resolve, reject) => {
                    MOP.LM.checkPasswordStored(params.password)
                      .done(function (resp) {
                        if (!resp) {
                            reject({msg : 'Passwords do not match.'});
                            return;
                        }

                        that._renewDossierSubSessionExpiration();
                        
                        resolve(resp);
                      })
                      .fail(function (e) {
                        // Per qualunque tipo di fallimento redirigo alla login.
                        MOP.changePage('login', null, MOP.getCurrentPage(), MOP.getCurrentRoute());
                      });

                  });
                },

                _confirmSubSessionBiometrics: function () {

                  // Funzione utilizzata nella subsession con le biometriche per rinnovare la subsession
                  return new Promise ((resolve, reject) => {
                    this._renewDossierSubSessionExpiration();
                    resolve(true);
                  })
                },

                _isDossierPrivacyValid: function (_userid) {

                  if (_userid && this.get('id') != _userid) {
                      if (this.get('parents_info')[_userid]) {
                          return !MOP.Utilities.empty(this.get('parents_info')[_userid].dossier_privacy)
                      }
                  }

                  return !MOP.Utilities.empty(this.get('dossier_privacy'));
                },

                _isDossierTwoFactorValid: function (_userid) {

                  if (_userid && this.get('id') != _userid) {
                      if (this.get('parents_info')[_userid]) {
                          return !MOP.Utilities.empty(this.get('parents_info')[_userid].dossier_strong_auth)
                      }
                  }
  
                  return !MOP.Utilities.empty(this.get('dossier_strong_auth'));
                },

                _isDossierActive: function(id) {
                  if (this.get('id') == id) {
                      if (MOP.Utilities.empty(this.get('results_privacy_denied'))) return true;
                  } else if (this.get('parents_info')[id]) {
                      if (MOP.Utilities.empty(this.get('parents_info')[id].results_privacy_denied)) return true;
                  }
                  return false;
                },

                _confirmDossierSyncronization: function (_userid) {

                  const _this = this;
  
                  var loadingOptions = {
                      loading: { enabled: false }
                  };
                  var proxiedSearchUserRest = $.proxy(_this.searchUserRest, _this, loadingOptions);
  
                  var stopPollingFunc = function (resp) {
                    const dossier_syncd = resp['return']['dossier_syncd'];
                    if (_this.get('id') == _userid) {
                      _this.set(resp['return']);
                    } else {
                      let parents_info = _this.get('parents_info')
                      parents_info[_userid].dossier_syncd = resp['return']['dossier_syncd'];
        
                      _this.set('parents_info', parents_info);
                    }
  
                    return isDossierSynced(dossier_syncd);
                  };
  
                  return MOP.Utilities.Pollinger({
                      pollingFunction: proxiedSearchUserRest,
                      stopFunction: stopPollingFunc,
                      stopOnWindowsChange: true,
                      timeout: 10000, // 10s
                      maxExecutionTime: 600000 // 10m
                  }).run(_this);
                },


                
		}, {
            getReservationUsersByPatient: function (MOP, selected_userid) {
                var users = {};
                var logged_user_data = MOP.getLoggedUserData();
                var logged_userid = MOP.getLoggedUserId();
                var selected_userid = selected_userid || logged_userid;
                var parents = logged_user_data['parents'] || {};
                
                let toShowName = MOP.Utilities.empty(logged_user_data.social_name) ? logged_user_data.fname : logged_user_data.social_name;

                var name = toShowName + ' ' + logged_user_data.lname;
                
                if (!MOP.Utilities.empty(logged_user_data.lname_2)) {
                  name = name + ' ' + logged_user_data.lname_2;
                }
                
                users[logged_userid] = {
                    id: logged_userid,
                    name: name,
                    selected: Boolean(selected_userid == logged_userid)
                };

                if (!MOP.Utilities.empty(parents)) {
                    _(parents).reduce(function (acc, parent, id) {
                        acc[id] = {
                            id:   id,
                            name: parent,
                            selected: Boolean(selected_userid == id)
                        };

                        return acc;
                    }, users);
                }

                return users;
            },

            getSelectedReservationUserid: function (reservationUsersByPatient) {
                return _(reservationUsersByPatient)
                        .find(function (user) {
                            return user.selected;
                        }).id;
            },
            
        });

		Entities.Users = CollectionBase.extend({
			model: Entities.User,

			fetch_fn: 'search_users',
      searchPath: 'users'
		},
		{
			/*
			 * NB => Al momento inserito solo come metodo statico
			 */
            searchUsersRest: function (querystring) {
                querystring  = querystring  || {};

                var params = {
                    searchPath:  'users',
                    querystring: querystring,
                    ajax: {
                        options: {
                            method: 'GET'
                        }
                    },
                    loading: {
                        enabled: true
                    }
                };

                return MOP.ajaxRest(params);
            }
		});

		var API = {
			getUsers: function( params ){
                var deferred = new $.Deferred();
				var users = new Entities.Users();

        users.fetch(MOP, {
            querystring: params
        }).always(function () {
            deferred.resolve(users);
        });

        return deferred;
			},
			getUser: function( id ){
                var deferred = new $.Deferred();
				var user = new Entities.User();

                user.fetch(MOP, {
                    searchPath: user.searchPath + "/" + id
                }).always(function () {
                    deferred.resolve(user);
                });

                return deferred;
			}
		};

		MOP.reqres.setHandler("user:entities", function( params ){
			return API.getUsers( params );
		});

		MOP.reqres.setHandler("user:entity", function( id ){
			return API.getUser( id );
		});

		MOP.reqres.setHandler("user:entities:new", function(){
			return new Entities.Users();
		});

		MOP.reqres.setHandler("user:entity:new", function(){
			return new Entities.User();
		});

        MOP.reqres.setHandler("user:entities:static", function () {
            return Entities.Users;
        });

        MOP.reqres.setHandler("user:entity:static", function(){
            return Entities.User;
        });
    });
