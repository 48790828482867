import { css } from "@emotion/react";

export const base = theme => () => css({
  backgroundColor: theme.colors.alice_blue,
  border: `${theme.layout.borderWidth.standard} solid ${theme.colors.bright_gray}`,
  borderRadius: theme.layout.borderRadius.medium,
  boxShadow: theme.layout.baseBoxShadow,
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
  padding: theme.layout.padding.medium,
  width: "100%",
});

export const header = theme => () => css({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro,
  justifyContent: "center"
});

export const buttons = theme => () => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.micro,
  justifyContent: "flex-start",
});

export const button = android => css({
  width: android ? "190px" : "147px",
  padding: 0,

  "& > img": {
    width: "100%", height: "auto"
  }
});