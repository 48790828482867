import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { TTPolyglot } from '@frontend/tt-polyglot';

export const Select = ({ components, defaultValue, onChange, options, styles, loadOptions, isDisabled, placeholder }) => {

  const [_options, setOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);
  const [val, setVal] = useState(false);

  useEffect(() => {
    if(options && options.length > 0) {
      setOptions(options);
      setIsLoading(false);
    }
  }, [options]);

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  const maybeLoadOptions = () => {
    if (!_options || _options.length === 0) {
      setIsLoading(true);
      loadOptions && loadOptions();
    }
  };

  const _onChange = (value) => {
    setVal(value);
    onChange(value);
  }

  return (
    <ReactSelect
      components={components}
      value={val}
      onChange={_onChange}
      options={_options}
      styles={styles}
      isLoading={isLoading}
      loadingMessage={() => TTPolyglot.t("Loading")}
      onFocus={maybeLoadOptions}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

Select.propTypes = {
  ...ReactSelect.PropTypes,
  loadOptions: PropTypes.func
};

Select.defaultProps = {
  components: null,
  defaultValue: null,
  onChange: () => null,
  options: [],
  styles: {},
  loadOptions: null,
  isDisabled: false,
  placeholder: TTPolyglot.t("Select")
};


