import _ from 'underscore';
import Backbone from 'backbone';
import ModelBase from 'models/model_base';
import 'backbone.picky';
	
	export default Backbone.Collection.extend({
	
		model: ModelBase,
		
		initialize: function() {
			//Extend your collection with the SingleSelect instance to make your collection support exclusive selections directly.
		    var singleSelect = new Backbone.Picky.SingleSelect(this);

		    _.extend(this, singleSelect);


            var newMethods = {
                toJSON2: function() {
                    // [TODO] MATTIA finisci metodo generale che itera sulla collection e aggiunge selected
                }
            };
		},
		
		/*
		 * to implement in concrete classes...
		 */
		fetch_fn: null,

		/**
		 * [fetch description]
		 * 
		 * @param {Object} 	MOP
		 * @param {Object} 	params
		 * 
		 * @param {String}  params.searchPath|params.fetch_fn
		 * @param {Boolean} [params.preventThrowException=false]
		 * 
		 * @param {Object} 	[params.loading]
		 * @param {Boolean} [params.loading.enabled] 
		 * @param {Boolean} [params.loading.replace_main_region]
		 * @param {Object} 	[params.loading.options]
		 * 
		 * @param {Object} 	[params.ajax]
		 * @param {Object}  [params.ajax.data]
		 * @param {Object}  [params.ajax.options] - Parametri che arrivano alla fetch di Backbone
		 * 
		 * @return {$.Deferred}
		 */
		fetch: function (MOP, params) {
			var _this = this;

			return this.constructor.staticFetch.apply(this, arguments);
		},

		parse: function(data) {
			
			var entities_data = [];
			
			// data from ajax json response
			if (_.has(data, 'return')) {
				if (data['result'] == 'OK') {
					// [FIXME] Nel caso di risposta ApiV3 il risultato della chiamata 
					// è dentro la sotto chiave 'results' altrimenti accedo a 'return'.
					entities_data = data['return']['results'] ? data['return']['results'] : data['return'];
					entities_data = this.specializeParseFromAjaxResponse(data, entities_data);
				}

			// otherwise will contain the collection 
			} else {
				entities_data = data;
			}
			
			entities_data = this.specializeParse(entities_data);

			return entities_data;
		},
		
		/*
		 * to implement in concrete classes...
		 */
		specializeParseFromAjaxResponse: function(data, entities_data) {
			return entities_data;
		},
		
		/*
		 * to implement in concrete classes...
		 */
		specializeParse: function(entities_data) {
			return entities_data;
		},
			
		/*
		 * ----- ORDINAMENTI -----
		 */
		orderStrategies: { },

		orderBy: function(property, way) {
			way = way || 'ASC';

			if (_.has(this.orderStrategies, property)) {
				this.comparator = this.orderStrategies[property];
			} else {
				this.comparator = function(model) {
					return model.get(property);
				};
			}

			if (way == 'DESC') {
				this.comparator = this.reverseSortBy(this.comparator);
			}

			this.sort();
		},

		// Function that works nicely for String, Date and Numeric fields
		reverseSortBy: function(sortByFunction) {
			return function(left, right) {
				var l = sortByFunction(left);
				var r = sortByFunction(right);

				if (l === void 0) return -1;
				if (r === void 0) return 1;

				return l < r ? 1 : l > r ? -1 : 0;
			};
		}
	}, {
		staticFetch: function (MOP, params) {
			var deferred = new $.Deferred();
			params.ajaxFunction = Backbone.Collection.prototype.fetch;
			params.fetch_fn = this.fetch_fn || params.fetch_fn;
			
			if (this.searchPath || params.searchPath) {
				
				if (!params.searchPath) {
					params.searchPath = this.searchPath;
				}
				
				var request_options = {};
				
				if (params.ajax && params.ajax.options) {
					request_options = params.ajax.options;
				} else if (!params.ajax) {
					params.ajax = {};
				}
				
				var ajax_options = {
					method: 'GET'
				}
				
				// L'extend fa il merge di un solo livello non mergia oggetti innestati, 
				// quindi ho estrapolato solo options invece che tutto ajax
				params.ajax.options = _.extend(ajax_options, request_options);
			}
			
			MOP.Utilities.ajax(MOP, params, this)
				.done(function (collection, resp) {
					try {
						MOP.checkAjaxResponse(resp);

						deferred.resolve(collection, resp);
					} catch (e) {
						MOP.Utilities.log(MOP, e);

						if (params.preventThrowException) {
							deferred.reject(resp, e);

							return;
						}

						throw e;
		            }
	        	}).fail(function (resp) {
              if(resp === MOP.constants.AJAX_OFFLINE_REJECT) return;
              try {
                  if (!params.bypassCheckResponse) {
                      MOP.checkAjaxResponse(resp);
                  }
                  return deferred.resolve(null, resp);
              } catch (e) {
                  MOP.Utilities.log(MOP, e);
                  
                  if (params.preventThrowException) {
                      deferred.reject();
                  }

                  throw e;
              }
            });

	        return deferred;
		}
	});