import React, { useEffect, useState, useRef } from 'react';
import MOP from 'app';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { CountDownDialog } from './CountDownDialog';
import { DIALOG_TYPE_IDLE, IDLE_DIALOG_SHOW_MILLISECONDS_PATIENT, IDLE_DIALOG_SHOW_MILLISECONDS_ADMIN } from '../../constants';
import { extendSession } from './common/api';

const Idle = ({ }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const timeoutDialog = useRef(null);
  const timeoutLogout = useRef(null);

  const clearTimeouts = () => {

    clearTimeout(timeoutDialog.current);
    timeoutDialog.current = null;

    clearTimeout(timeoutLogout.current);
    timeoutLogout.current = null;

    const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
    if (!MOP.Utilities.empty(timeouts)) {
      delete timeouts.idle_timer;
      delete timeouts.idle_timer_duration;
      MOP.permanentLocalStorage.set('timeouts', JSON.stringify(timeouts));
    }

    setShowDialog(false);
  };

  const setTimeouts = milliseconds => {

    clearTimeouts();

    timeoutDialog.current = setTimeout(async () => {
      try {
        // Alla scadenza prima di mostrare il dialog controllo se l'absolute è già stato mostrato
        // Se si allora estendo la sessione dell'idle senza mostrare nessun dialog
        // FD associato: https://tuotempo.freshdesk.com/a/tickets/107186
        const { absolute_accepted } = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
        if (!MOP.Utilities.empty(absolute_accepted)) {
          await extendSession();
          return;
        }

        setApiLoading(false);
        setShowDialog(true);
      } catch (e) {
        console.log(e);
      }

    }, milliseconds - (MOP.isAdminLoggedIn() ? IDLE_DIALOG_SHOW_MILLISECONDS_ADMIN : IDLE_DIALOG_SHOW_MILLISECONDS_PATIENT));

    timeoutLogout.current = setTimeout(() => {

      const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
      if (!MOP.Utilities.empty(timeouts?.idle_timer) && Date.now() < parseInt(timeouts.idle_timer)) {
        // idle_timer ha una scadenza nel futuro, vuol dire che è stato esteso da qualche altra tab
        // quindi non facciamo il logout, ma settiamo di nuovo i timers con la data che abbiamo trovato in local storage
        return setTimeouts(parseInt(timeouts.idle_timer) - Date.now());
      }

      if (MOP.config.isLanzaderaMop()) {
        // Se sono su lanzadera allora al posto di fare logout dal MOP faccio una postMessage in cui richiamo la window.handleLogout
        // questa è una funzione definita nella window di lanzadera, e si occuperà questa di fare il logout.
        MOP.sendMessageToParentFrame({
          fn: 'evalFunction',
          value: `window.handleLogout(true);`
        });


      } else {
        // Altrimenti in tutti gli altri casi passo per un logout standard del MOP
        // e chiudo il dialog del timer
        setShowDialog(false);

        const alert = { type: 'info', msg: TTPolyglot.t("Mop Idle Timeout Expired") };

        if (MOP.isOneTimeUser()) {
          MOP.killOneTimeSession(false, alert);
        } else {
          MOP.LM.doLogout(false, alert);
        }

      }


    }, milliseconds);
  };


  const extendExpiration = async () => {
    try {
      setApiLoading(true);
      await extendSession();

      const alert = { type: 'success', msg: TTPolyglot.t("Mop Idle Timeout Extended") };
      MOP.execute("MOP:alert", alert);
    } catch (e) {
      const alert = { type: 'danger', msg: e.msg };
      MOP.execute('MOP:alert', alert);
    } finally {
      setShowDialog(false);
    }
  };

  useEffect(() => {

    MOP.handleIdleExpiration = (expiration, duration) => {

      if (MOP.isAdminLoggedIn() && MOP.config.isInternalMop() && !MOP.config.isLanzaderaMop()) {
        // Se sono su mop interno ma non su lanzadera, allora non voglio settare i timer del MOP 
        // ma voglio gestire tutto a livello backoffice
        // quindi invio una postMessage al BO chiamando la funzione del backoffice che si occupa di rigenerare i timer
        MOP.sendMessageToParentFrame({
          fn: 'evalFunction',
          value: `window.handleIdleExpiration(${expiration})`
        });
        return;
      }

      // Se expiration torna vuoto vuol dire che dobbiamo pulire i timer eventualmente attivi
      // questo per gestire il caso di logout in cui se non facessimo la pulizia, i timer risulterebbero 
      // ancora attivi dopo il logout e continuerebbero a funzionare
      if (MOP.Utilities.empty(expiration)) {
        clearTimeouts();
        return;
      }

      const expirationMs = 1000 * parseInt(expiration);

      setTimeouts(expirationMs - Date.now());

      const timeouts = MOP.permanentLocalStorage.get('timeouts') ? JSON.parse(MOP.permanentLocalStorage.get('timeouts')) : {};
      // Dopo aver settato i nuovi timer vado a scrivere il valore dei timers in localstorage
      if (MOP.Utilities.empty(timeouts?.idle_timer) || parseInt(timeouts.idle_timer) < expirationMs) {
        timeouts.idle_timer = expirationMs;
      }
      // Se la chiave idle_timer_duration in localStorage è vuota allora salviamo il valore duration in localStorage
      if (MOP.Utilities.empty(timeouts?.idle_timer_duration)) {
        timeouts.idle_timer_duration = 1000 * parseInt(duration);
      }
      MOP.permanentLocalStorage.set('timeouts', JSON.stringify(timeouts));
    };

  }, []);

  if (!showDialog) return null;

  return <CountDownDialog onConfirm={extendExpiration} onEnd={() => setShowDialog(false)} type={DIALOG_TYPE_IDLE} loading={apiLoading} />;
};

export default Idle;