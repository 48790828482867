import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";


export const WalletButton = ({ imgSrc, onClick }) => {

  return (
    <button
      type="button"
      css={[style.base(imgSrc)]}
      onClick={onClick}
    />
  );
};


WalletButton.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

WalletButton.defaultProps = {
  imgSrc: null,
  onClick: null,
};