import { axiosRest } from './axios';
import { cordovaRest } from './cordova';

import MOP from 'app';

// i data ricevuti vengono decorati con informazioni di backbone:
/*
dataType
emulateHTTP
emulateJSON
parse
type
*/
// TODO: si potrebbero pulire tutte queste informazioni non utilizzate

export const _ajaxRest = data => {
  return new Promise(async (res, rej) => {

    const defaultHeaders = {
      'Content-Type': 'application/json'
    };

    // Andiamo a fare spread operator tra default header e quello eventualmente passato nei data
    // il secondo vince sul primo e sovrascriverà eventuali header di default
    const headers = { ...defaultHeaders, ...data.headers };

    // Se insertAuthBearer = true ed esiste il sessioid, 
    // questo deve sempre essere messo, quindi va fatto dopo eventuali sovrascritture dell'header
    const sessionId = MOP.getSessionid();
    if (data.insertAuthBearer && !MOP.Utilities.empty(sessionId)) {
      headers['Authorization'] = `Bearer ${sessionId}`;
    }

    const apiPreventScraping = MOP.config.getInstanceConfig('apiPreventScraping');
    if (!MOP.Utilities.empty(apiPreventScraping?.totp?.enabled) && !MOP.Utilities.empty(window.POTT)) {
      headers['X-TuoTempo-Authorization'] = await window.POTT.generateToken();
    }

    const sendData = {
      url: data.url,
      method: data.method,
      successDeferredCallback: data.success,
      errorDeferredCallback: data.error,
      headers,
      data: data.data || {}
    };


    if (MOP.Utilities.isMobileApp(true)) {
      cordovaRest(sendData, res, rej);
    } else {
      axiosRest(sendData, res, rej);
    }
  });

};