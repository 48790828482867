import { css } from "@emotion/react";

export const base = theme => () => css({
  backgroundColor: theme.colors.white,
  border: `${theme.layout.borderWidth.standard} solid ${theme.colors.bright_gray}`,
  borderRadius: theme.layout.borderRadius.medium,
  boxShadow: theme.layout.baseBoxShadow,
  overflow: "hidden",
  overflow: "hidden",
  width: "100%",
});

export const content = theme => css({
  padding: theme.layout.padding.medium,
});

export const header = theme => css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: `${theme.layout.padding.micro} ${theme.layout.padding.medium}`,
  width: "100%"
});

export const success = theme => css({
  backgroundColor: theme.colors.light_cyan,
  color: theme.colors.caribbean_green
});

export const warning = theme => css({
  backgroundColor: theme.colors.cornsilk,
  color: theme.colors.warning
});

export const gray = theme => css({
  backgroundColor: theme.colors.light_grey,
  color: theme.colors.auro_metal_saurus
});