import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Container } from '../Container';
import { Label } from '../Label';
import { Icon } from '../Icon';

/**
 * Primary UI component for user interaction
 */
export const Checkbox = ({ children, checked, value, onClick, type, error, disabled , e2eid}) => {

  const theme = useTheme();

  return (
    <div css={style.base}>
      <div css={style.container}>
        <span
          {...e2eid ? {"data-e2eid": e2eid } : null}
          css={[style.checkbox(theme)({ checked, disabled }), style[type]]}
          onClick={() => onClick && onClick(value)}
        >
          {checked && <Icon name={'CheckSolid'} color={"white"} />}
        </span>
        {children && children}
      </div>
      {!!error && <Label color={"danger"}>{error}</Label>}
    </div>

  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.object,
  type: PropTypes.oneOf(["standard", "round"]),
  error: PropTypes.string,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  children: null,
  onClick: null,
  value: null,
  type: "standard",
  error: null,
  disabled: false
};