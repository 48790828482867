
export const ABSOLUTE_DIALOG_SHOW_MILLISECONDS_PATIENT = 300 * 1000;
export const IDLE_DIALOG_SHOW_MILLISECONDS_PATIENT = 30 * 1000;

export const ABSOLUTE_DIALOG_SHOW_MILLISECONDS_ADMIN = 1800 * 1000;
export const IDLE_DIALOG_SHOW_MILLISECONDS_ADMIN = 900 * 1000;

export const DIALOG_TYPE_ABSOLUTE = "DIALOG_TYPE_ABSOLUTE";
export const DIALOG_TYPE_IDLE = "DIALOG_TYPE_IDLE";

