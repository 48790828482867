// Monitoring Providers
export const FACEBOOK_PIXEL = 'facebookpixel';
export const GOOGLE_TAG_MANAGER = 'googletagmanager';
export const GOOGLE_ANALYTICS = 'googleanalytics';

// Events tracked
export const BACK_TO_AVAILABILITIES = "BackToAvailabilities";
export const BACK_TO_CRITERIA = "BackToCriteria";
export const CRITERION_SELECTED = "CriterionSelected";
export const ERROR_LOGIN = "ErrorLogin";
export const ERROR_REGISTRATION = "ErrorRegistration";
export const INTERESTED_APPOINTMENT = "InterestedAppointment";
export const INTERESTED_APPOINTMENT_MULTIPLE = "InterestedAppointmentMultiple";
export const INTERESTED_APPOINTMENT_RESCHEDULING = "InterestedAppointmentRescheduling";
export const INSERT_RESERVATION = "InsertReservation";
export const CANCEL_RESERVATION_PATIENT = "CancelReservationPatient";
export const INSERT_RESERVATION_MULTIPLE = "InsertReservationMultiple";
export const LOGIN = "Login";
export const LOGIN_DURING_RESERVATION = "LoginDuringReservation";
export const LOGIN_FROM_RESET_PASSWORD = "LoginFromResetPassword";
export const RESET_PASSWORD = "ResetPassword";
export const RESET_PASSWORD_DURING_RESERVATION = "ResetPasswordDuringReservation";
export const SEARCH_AVAILABILITIES = "SearchAvailabilities";
export const START_IFRAME_MOP = "StartIframeMOP";
export const USER_PRE_REGISTRATION = "UserPreRegistration";
export const USER_REGISTRATION = "UserRegistration";
export const ERROR_SPID_REGISTRATION = "ErrorSpidRegistration";
export const USER_SPID_PRE_REGISTRATION = "UserSpidPreRegistration";
export const USER_SPID_REGISTRATION = "UserSpidRegistration";


// Stati relativi al config mopAppAnalytics.provider.enableUserEvents che possono avere 3 valori diversi
export const MOP_APP_SEND_EVENT_DISABLED = 0; // invio eventi disabilitato, il cliente deve passare per un nuovo ticket FD
export const MOP_APP_SEND_EVENT_ENABLED = 2; // invio eventi abilitato ed acceso