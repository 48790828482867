import MOP from 'app';
import {Start} from '../index';
import ReactDOM from 'react-dom';


MOP.module("HeaderApp.View", (View, MOP, Backbone, Marionette) => {

  View.Index = Marionette.CompositeView.extend({
    template: () => '<div></div>',
    onShow: function () {            
            
      Start(
        this.el,
        {forceDisabled: Marionette.getOption(this, 'forceDisabled')},
      );
      
    },
    remove: function () {
      ReactDOM.unmountComponentAtNode(this.el);
      Backbone.View.prototype.remove.call(this);
    }
  });
});

export default MOP.HeaderApp.View;
