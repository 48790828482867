import React, { useEffect } from 'react';
import MOP from 'app';
import ContainerHeader from './containers/ContainerHeader';

export const Desktop = () => {

  useEffect(() => {
    MOP.execute('MOP:banner:show');
    if(!MOP.Utilities.empty(MOP.querystring.permanentAlert)) {
      MOP.execute('MOP:permanentAlert:show', MOP.querystring.permanentAlert);
    }
  }, []);

  return (
    <ContainerHeader />
  );
};