import MOP from 'app';

// Si è scelto di wrappare il risultato in una promise nativa per poter sganciarsi in futuro dai Deferred di jquery
export const addDocumentToReservation = (data, resid) => {

  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `reservations/${resid}/downloads`,
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST'
        },
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        resolve(response);
      })
      .fail(e => {
        reject(e);
      });
  });
};

export const updateReservation = (data, resid) => {
  // se la prenotazione è reserved o meno cambia l'api da chiamare
  const searchPath = data?.reserved == 1 ? `reserved/${resid}` : `reservations/${resid}`;

  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath,
      ajax: {
        options: {
          method: 'PUT'
        },
        data: data
      }
    };
    MOP.ajaxRest(ajaxParams)
      .done(response => resolve(response))
      .fail(e => reject(e))
  });
}

export const replaceReservation = (resid, replaceableResid) => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `reservations/${resid}/_replace`,
      ajax: {
        options: {
          method: 'PUT'
        },
        data: { replaceable_resid: replaceableResid }
      }
    };
    MOP.ajaxRest(ajaxParams)
      .done(response => resolve(response))
      .fail(e => reject(e))
  });
};

export const insertReservation = (data) => {
  // se la prenotazione è reserved o meno cambia l'api da chiamare
  const searchPath = data?.reserved == 1 ? `reserved` : `reservations`;

  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath,
      ajax: {
        options: {
          method: 'POST'
        },
        data: data
      }
    };
    MOP.ajaxRest(ajaxParams)
      .done(response => resolve(response))
      .fail(e => reject(e))
  });
}

export const getCancellationReasons = () => {
  // In questa api essendo autorizzata per tutti (tipo GUEST) non passiamo nell'Header il parametro di auth, quindi insertAuthBearer = false
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `reservations/_cancellation_reasons`,
      ajax: {
        options: {
          method: 'GET'
        }
      }
    };
    MOP.ajaxRest(ajaxParams, false)
      .done(response => resolve(response))
      .fail(e => reject(e))
  });
}


export const getReservation = (id, userid, params = {}) => {
  return new Promise((resolve, reject) => {

    const querystring = {
      ...params
    };

    userid && (querystring.userid = userid);

    const ajaxParams = {
      searchPath: `reservations/${id}`,
      querystring,
      ajax: {
        options: {
          method: 'GET'
        }
      }
    };

    MOP.ajaxRest(ajaxParams, true)
      .done(resolve)
      .fail(reject)
  });
}


export const getReservations = (userid, params = {}) => {
  return new Promise((resolve, reject) => {

    const querystring = {
      ...params
    }

    userid && (querystring.userid = userid);

    const ajaxParams = {
      searchPath: `reservations`,
      querystring,
      ajax: {
        options: {
          method: 'GET'
        }
      }
    };

    MOP.ajaxRest(ajaxParams, true)
      .done(resolve)
      .fail(reject)
  });
}

export const lockReservationSlots = slots => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: 'availabilities/_lock',
      ajax: {
        options: {
          method: 'POST'
        },
        data: slots
      }
    };

    MOP.ajaxRest(ajaxParams).done(resolve).fail(reject);
  });
};

export const cancelReservation = (id, params) => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `reservations/${id}`,
      querystring: params,
      ajax: {
        options: {
          method: 'DELETE'
        }
      }
    };

    MOP.ajaxRest(ajaxParams).done(resolve).fail(reject);
  });
};



export const getPaymentTimeout = resid => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `reservations/${resid}/_payment_timeout`,
      ajax: {
        options: {
          method: 'PUT'
        },
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => resolve(response)
      )
      .fail(
        error => reject(error)
      );
  });
};