import MOP from 'app';

export const mopLocation = data => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: 'mopevents/locations',
      ajax: {
        options: {
          method: 'POST'
        },
        data: data
      },
      loading: {
        enabled: false
      },
      bypassCheckResponse: true,
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {      
        resolve(response);
      })
      .fail(e => {
        reject(e);
      });
  });
};