define(['handlebars'], function (Handlebars) {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['footer'] = template({"1":function(container,depth0,helpers,partials,data) {
    return " style=\"display: none;\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"5":function(container,depth0,helpers,partials,data) {
    return " tt-container ";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-6 is-offset-3\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "style=\"height: 50px;\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.currentPage : depth0),"!=","find_instances",{"name":"ifCond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showFirstAvailabilities : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.whiteMop : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <img height=\"1\" border=\"0\" class=\"mop_width\" src=\"";
  stack1 = ((helper = (helper = helpers.getImgPath || (depth0 != null ? depth0.getImgPath : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.getImgPath) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/spacer.png\" alt=\"\" />\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.show_footer_logo : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "              <div class=\"awaiting_time\">\n                  <a class=\"tt-link\" href=\"javascript:\" id=\"mop_waiting_time_div\" >"
    + container.escapeExpression(((helper = (helper = helpers.MopAwaitingTime || (depth0 != null ? depth0.MopAwaitingTime : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"MopAwaitingTime","hash":{},"data":data}) : helper)))
    + "</a>\n              </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "              <div class=\"tt-footer-layout-link\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.gipoFooter : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                  <a target=\"_blank\" href=\""
    + alias3(((helper = (helper = helpers.footerURL || (depth0 != null ? depth0.footerURL : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"footerURL","hash":{},"data":data}) : helper)))
    + "\" class=\"tt_logo\">"
    + alias3(((helper = (helper = helpers.footerMessage || (depth0 != null ? depth0.footerMessage : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"footerMessage","hash":{},"data":data}) : helper)))
    + "</a>\n              </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                      <a target=\"_blank\" href=\"http://www.gipo.it\" class=\"link\">GipoWEB</a> &nbsp;|&nbsp;\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\"tt-footer-layout__logo\">\n                  <img src=\""
    + container.escapeExpression(((helper = (helper = helpers.show_footer_logo || (depth0 != null ? depth0.show_footer_logo : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"show_footer_logo","hash":{},"data":data}) : helper)))
    + "\" border=\"0\" alt=\"\" />\n                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"tt-footer-layout__text\">\n                  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.show_footer_html : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.show_footer_html || (depth0 != null ? depth0.show_footer_html : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"show_footer_html","hash":{},"data":data}) : helper)));
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "      <div style=\"position: fixed; left: 0; right: 0; bottom: "
    + container.escapeExpression(((helper = (helper = helpers.btnReturnPageBottomStyle || (depth0 != null ? depth0.btnReturnPageBottomStyle : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"btnReturnPageBottomStyle","hash":{},"data":data}) : helper)))
    + "; height: 0; z-index: 1005;\">\n        <div style=\"position: relative;\">\n          <div style=\"-webkit-box-sizing: border-box; box-sizing: border-box; position: absolute; width: 100%; bottom: 0;\">\n            <div style=\"text-align: center!important; margin-bottom: 10px!important;\">\n              <button class=\"tt-button tt-button--operator tt-button--primary\" id=\"iFrameReturnPageBtn\"\n                style=\"height: 33px; line-height: 31px; font-size: 16px; padding: 0 14px;\">\n                      "
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Mop Back To Website",{"name":"translate","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n              </button>\n            </div>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=helpers.helperMissing, buffer = 
  "<div class=\"tt-footer-layout\" "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.currentPage : depth0),"==","home",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n  <div class=\""
    + ((stack1 = (helpers.isDesktop || (depth0 && depth0.isDesktop) || alias1).call(depth0,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
  stack1 = ((helper = (helper = helpers.isMobile || (depth0 != null ? depth0.isMobile : depth0)) != null ? helper : alias1),(options={"name":"isMobile","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0,options) : helper));
  if (!helpers.isMobile) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n    <div "
    + ((stack1 = (helpers.isDesktop || (depth0 && depth0.isDesktop) || alias1).call(depth0,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <div "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.mopIframeReturnPage : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.currentPage : depth0),"!=","home",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.mopIframeReturnPage : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
});