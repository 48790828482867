import React, { useState, useRef } from 'react';

import {
  CLICK_START,
  CLICK_ACCEPT,
  CLICK_SUBMIT,
  CLICK_NEXT,
  CLICK_PREV
} from "./constants";

const ButtonContext = React.createContext();

export const ButtonProvider = ({ children, validate, onClick, disabled }) => {

  const [click, setClick] = useState(CLICK_START);

  const handleClick = val => {

    let _click = click;

    // Se sono al primo click, sto andando avanti e la validazione esiste ed è false, allora torno senza fare altro (validazione non passata)
    // ATTENZIONE!!! La validate è un dispatch di una action ed è stato fatto così per questioni di tempo
    // Fare il dispatch di una action dentro uikit è una cosa profondamente sbagliata, e dovrebbe essere evitata a tutti i costi
    // Purtroppo qui è stata fatta per questioni di tempo. La soluzione sarebbe quella di aggiungere un componente provider su uikit
    // E poi wrappare questo componente, attorno al provider, direttamente da APP
    if(_click === CLICK_START && val === CLICK_NEXT && validate !== null && !validate()) {
      return;
    }

    switch (val) {
      case CLICK_NEXT: {
        _click = click+1;
        setClick(_click);
        break;
      }

      case CLICK_PREV: {
        _click = click-1;
        setClick(_click);
        break;
      }

      default:
        break;
    }

    if(_click === CLICK_SUBMIT) {
      setClick(CLICK_START);
      !disabled && onClick();
    }

    return;
  };


  const value = {
    handleClick,
    click,
    disabled
  };


  return <ButtonContext.Provider value={value}>{children}</ButtonContext.Provider>;
};

export const useButtonContext = () => {
  // Se lo useContext non è stato inizializzato, restituisco un oggetto vuoto
  const context = React.useContext(ButtonContext);
  return context;
};