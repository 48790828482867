import { css } from "@emotion/react";

const WIDTH_MOBILE = "100%";
const WIDTH = "6.25rem";

export const noBreak = () => css({
  breakInside: "avoid",
  pageBreakInside: "avoid",
});

export const baseMobile = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
  width: "100%",
});

export const base = theme => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.small,
  width: "100%",
  alignItems: "flex-start"
});

export const qrContainer = theme => css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.layout.gap.small
});

export const qrMobile = () => css({
  width: WIDTH_MOBILE,
  maxWidth: "12.5rem"
});

export const qr = () => css({
  width: WIDTH
});


export const infoContent = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  width: "100%",
});

export const label = () => css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

export const loadingMobile = () => css({
  display: "flex",
  justifyContent: "center",
  flexBasis: WIDTH_MOBILE,
  height: "100%",
  alignItems: "center"
});

export const loading = () => css({
  display: "flex",
  justifyContent: "center",
  flexBasis: WIDTH,
  height: "100%",
  alignItems: "center"
});

export const number = theme => fontSize => css({
  textAlign: "center",
  fontSize: fontSize || "2.5rem", // 40px
  lineHeight: "100%",
  color: theme.colors.text,
  fontWeight: theme.fonts.weight.semiBold
});








