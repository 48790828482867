import { css } from "@emotion/react";

export const base = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%"
});

export const text = css({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
});