import { css } from "@emotion/react";


export const headerContainer = (coloredHeader, theme) => css({
  backgroundColor: coloredHeader ? theme.colors.alice_blue : "",
  display: "flex",
  flexDirection: "column",
  gap: "0.2rem"
});

export const headerDividerContainer = theme => css({
  paddingBottom: theme.layout.padding.micro
});

export const baseContainer = theme => css({
  padding: theme.layout.padding.xsmall
});

export const dividerContainer = theme => css({
  paddingLeft: theme.layout.padding.xsmall,
  paddingRight: theme.layout.padding.xsmall
});


export const rowBaseContainer = css({
  display: "flex",
  flexDirection: "row",
});

export const rowBase = css({
  display: "flex",
  flexGrow: 1,
});

export const rowLabel = css({
  justifyContent: "flex-start",
  width: "50%",
});

export const rowValue = css({
  justifyContent: "flex-end",
  width: "50%",
});










