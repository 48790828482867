
import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ color, size}) => css({
  color: theme.colors[color],
  fontSize: theme.fonts.size[size] || theme.fonts.size.standard,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.layout.gap.small,
});

export const textContainer = theme => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.small
});

export const text = theme => (isCancelled, isNoShow) => css({
  color: isCancelled || isNoShow ? theme.colors.manatee : "inherit",
  textDecoration: isCancelled || isNoShow ? "line-through" : "none",
  // serve perchè se il testo risulta troppo lungo, senza questa proprietà va a ridurre la 
  // grandezza dell'icona (anche se l'icona ha una sua larghezza fissata). con questa proprietà
  // lo span che contiene il testo non si estende in modo eccessivo
  width: "fit-content"
});

export const note = theme => isCancelled => css({
  color: isCancelled ? theme.colors.danger : theme.colors.text_light,
});