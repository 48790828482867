define(['handlebars'], function (Handlebars) {
var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['alert'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-with-close";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-customer-alert";
},"7":function(container,depth0,helpers,partials,data) {
    return "alert-info";
},"9":function(container,depth0,helpers,partials,data) {
    return "alert-success";
},"11":function(container,depth0,helpers,partials,data) {
    return "alert-warning";
},"13":function(container,depth0,helpers,partials,data) {
    return "alert-danger";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"!=","success",{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "				<button data-e2eid=\"alert-close-btn\" class=\"tt-close js-alert-close\"><i class=\"tt-icon-times\"></i></button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return "				<a id=\"change_search\" href=\"#\">"
    + container.escapeExpression(((helper = (helper = helpers.changeSearch || (depth0 != null ? depth0.changeSearch : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"changeSearch","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return "				<a id=\"reload_page\" href=\"#\">"
    + container.escapeExpression(((helper = (helper = helpers.reloadPage || (depth0 != null ? depth0.reloadPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"reloadPage","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper;

  return "				<a id=\"vcodeLink\" href=\"#\">"
    + container.escapeExpression(((helper = (helper = helpers.vcodeLink || (depth0 != null ? depth0.vcodeLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"vcodeLink","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=helpers.helperMissing, alias2="function", buffer = 
  "<div ";
  stack1 = ((helper = (helper = helpers.isMobile || (depth0 != null ? depth0.isMobile : depth0)) != null ? helper : alias1),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias2 ? helper.call(depth0,options) : helper));
  if (!helpers.isMobile) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n	<div \n      class=\"tt-alert tt-alert--"
    + container.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"type","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"!=","success",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==","info",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n      data-e2eid=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==","info",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==","success",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==","warning",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==","danger",{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    >\n"
    + ((stack1 = (helpers.ifFalse || (depth0 && depth0.ifFalse) || alias1).call(depth0,(depth0 != null ? depth0.hideCloseAlertButton : depth0),{"name":"ifFalse","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		<div data-e2eid=\"alert-message\" class=\"tt-message\">"
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.changeSearch : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.reloadPage : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.vcodeLink : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});

templates['checkin_dialog'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-no-border tt-no-padding\" id=\"checkin_dialog_checkin_msg\">\n          <div class=\"tt-info__content\">\n            "
    + alias3(((helper = (helper = helpers.checkin_msg || (depth0 != null ? depth0.checkin_msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"checkin_msg","hash":{},"data":data}) : helper)))
    + "\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border\" id=\"checkin_dialog_checked_msg\" style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-check-circle\"></i>\n          <div class=\"tt-info__content\">\n            "
    + alias3(((helper = (helper = helpers.checkedin_msg || (depth0 != null ? depth0.checkedin_msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"checkedin_msg","hash":{},"data":data}) : helper)))
    + "\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border\" id=\"checkin_dialog_checked_msg_error\" style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-exclamation-triangle\"></i>\n          <div class=\"tt-info__content\" id=\"checkin_dialog_checked_msg_error_content\">\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n        <div id=\"checkin_dialog_button_container\">\n          <button type=\"button\" id=\"checkin_button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Menu Checkin",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n        </div>\n        <div id=\"checked_dialog_button_container\" style=\"display: none;\">\n          <button type=\"button\" id=\"checkedin_close_button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Ok",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n        </div>\n        <div style=\"display: none; padding-bottom: 30px\" id=\"checkin_dialog_loader_container\">\n          <div class=\"tt-deprecated-loader\" style=\"position: absolute; top: 50%; left: 50%; margin: 0px 0px 0px -15px;\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});

templates['dialog_privacy'] = template({"1":function(container,depth0,helpers,partials,data) {
    return " 100% ";
},"3":function(container,depth0,helpers,partials,data) {
    return " 500px ";
},"5":function(container,depth0,helpers,partials,data) {
    return " 600px ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=helpers.helperMissing, alias2="function", alias3=helpers.blockHelperMissing, buffer = 
  "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog js-privacy-dialog\">\n    <div class=\"modal-content\" style=\"border: 0px; padding: 24px; height:";
  stack1 = ((helper = (helper = helpers.isMobile || (depth0 != null ? depth0.isMobile : depth0)) != null ? helper : alias1),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias2 ? helper.call(depth0,options) : helper));
  if (!helpers.isMobile) { stack1 = alias3.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "; width: ";
  stack1 = ((helper = (helper = helpers.isMobile || (depth0 != null ? depth0.isMobile : depth0)) != null ? helper : alias1),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data}),(typeof helper === alias2 ? helper.call(depth0,options) : helper));
  if (!helpers.isMobile) { stack1 = alias3.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n      <div style=\"display: flex; justify-content: end;\" class=\"js-close-dialog tt-pointer\">\n        <span><i class=\"tt-icon-times\"></i></span>\n      </div>\n      <div class=\"tt-deprecated-loader js-privacy-loader\" style=\"display: block;\"></div>\n      <iframe \n        scrolling=\"no\" \n        class=\"js-privacy-iframe\" \n        style=\"\n          position: absolute;\n          width: calc(";
  stack1 = ((helper = (helper = helpers.isMobile || (depth0 != null ? depth0.isMobile : depth0)) != null ? helper : alias1),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data}),(typeof helper === alias2 ? helper.call(depth0,options) : helper));
  if (!helpers.isMobile) { stack1 = alias3.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " - 24px - 24px); \n          border: none; \n          margin: 0; \n          padding: 0;\n          display: none\n        \" \n        src=\""
    + container.escapeExpression(((helper = (helper = helpers.src || (depth0 != null ? depth0.src : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"src","hash":{},"data":data}) : helper)))
    + "\"></iframe>\n    </div>\n  </div>\n</div>\n";
},"useData":true});

templates['dialog_survey'] = template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "              <div class=\"tt-modal-content__body-link\">\n                <a style=\"cursor: pointer\" class=\"js-survey-selection\" survey=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</a>\n              </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=helpers.helperMissing, alias4="function";

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog tt-modal\">\n    <div class=\"modal-content tt-modal-content\">\n      <div class=\"modal-body tt-modal-content__body\">\n        <div class=\"survey-availabilities-content\">\n          <div class=\"js-survey-1\">\n            <div class=\"tt-modal-content__body-title\">\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ava_mop_choice_1 : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            </div>\n            <div class=\"js-survey-1-content\">\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.ava_mop_choice_1 : depth0)) != null ? stack1.options : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              <div>\n                <a class=\"js-survey-other\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias3).call(depth0,"Ava Mop Choice Other",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a>\n              </div>\n            </div>\n          </div>\n          <div class=\"js-survey-2\" style=\"display: none;\">\n            <div>\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ava_mop_choice_2 : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            </div>\n            <div class=\"js-survey-2-content\" style=\"padding-bottom: 20px; padding-top: 10px;\">\n              <div>\n                <textarea class=\"js-survey-2-text\"\n                  placeholder=\""
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias3).call(depth0,"Ava Mop Choice Other Placholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n                  style=\"width: 100%; border: none; min-height: 70px; resize: none;\"></textarea>\n              </div>\n              <div style=\"position: absolute; bottom: 10px; right: 10px\">\n                <a class=\"js-survey-2-send-btn\"\n                  style=\"pointer-events: none; cursor: default; color: gray;\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias3).call(depth0,"Ava Mop Choice Other Submit",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\"survey-availabilities-response\" style=\"display: none;\">\n          <div style=\"text-align: center; font-size: 100px; color: "
    + alias2(((helper = (helper = helpers.icon_color || (depth0 != null ? depth0.icon_color : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"icon_color","hash":{},"data":data}) : helper)))
    + "\">\n            <span><i class=\""
    + alias2(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\"></i></span>\n          </div>\n          <div style=\"text-align: center; font-size: 20px; margin-top: 20px;\">\n          "
    + alias2(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + " \n          </div>\n          <div style=\"text-align: center; margin-bottom: 20px; margin-top: 10px;\">\n            "
    + alias2(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(depth0,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "\n          </div>\n        </div>\n      </div>\n      <div class=\"js-close-dialog tt-modal__close\">\n        <span><i class=\"tt-icon-times\"></i></span>\n      </div>\n    </div>\n    <div class=\"modal-footer\">\n\n    </div>\n  </div>\n</div>";
},"useData":true});

templates['dialog_with_input_loader'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n          <div class=\"tt-container\">\n            <div class=\"modal-body\">\n                <div class=\"tt-text-bold tt-mb-10\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n                <div>"
    + alias3(((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper)))
    + "</div>\n                <div class=\"tt-input-container js-dialog-input-container\" style=\"margin-top: 20px\">\n                    <input class=\"tt-input js-input-dossier-manual-update\" placeholder=\""
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Dossier Check Updates Code Placeholder",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button js-buttons-container\">\n                <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n                <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\" disabled>"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Dossier Check Updates Check Button",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n              </div>\n              <div style=\"display: none; padding-bottom: 30px\" id=\"loader_container\">\n                <div class=\"favorite_loader loader_ajax_small\" style=\"position: absolute; top: 50%; left: 50%; margin: 0px 0px 0px -15px;\"></div>\n              </div>\n            </div>\n          </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['dialog_with_loader'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n                "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"modal-footer\">\n              <div id=\"loader_container\" class=\"tt-pb-30\" style=\"display: none;\">\n                <div class=\"tt-deprecated-loader\"></div>\n              </div>\n              <div id=\"button_container\" class=\"tt-flexend-container tt-dialog-button\">\n\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Callbacks Reason No",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n\n\n                    <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Yes",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n\n              </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['dialog_with_share_action'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Dossier Document Share Link",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <button type=\"button\" class=\"tt-button tt-button--primary js-copy-button\" data-clipboard-target=\"#js-tmp-copy-clipboard\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0,"Dossier Document Copy Link",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"tt-text-bold tt-mb-10\">\n                "
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Dossier Document Copy Link Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"modal-body\">\n              <div class=\"tt-input-container\">\n                <input id=\"js-tmp-copy-clipboard\" class=\"tt-input\" onClick=\"this.setSelectionRange(0, this.value.length)\" data-clipboard-text=\""
    + alias3(((helper = (helper = helpers.download_sharing_link || (depth0 != null ? depth0.download_sharing_link : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"download_sharing_link","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.download_sharing_link || (depth0 != null ? depth0.download_sharing_link : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"download_sharing_link","hash":{},"data":data}) : helper)))
    + "\" style=\"width: 100%; height: 40px;\" readonly/>\n              </div>\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button\">\n\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Cancel",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n                \n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.shareActionAvailable : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "                \n              </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['dialog-big-icon-info'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n                <div style=\"text-align: center; font-size: 100px; color: "
    + alias3(((helper = (helper = helpers.icon_color || (depth0 != null ? depth0.icon_color : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"icon_color","hash":{},"data":data}) : helper)))
    + "\">\n                  <span><i class=\""
    + alias3(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\"></i></span>\n                </div>\n                <div style=\"text-align: center; font-size: 20px; margin-top: 20px;\">\n                  "
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n                </div>\n                <div style=\"text-align: center; margin-bottom: 20px; margin-top: 10px;\">\n                  "
    + alias3(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['dialog'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"modal-content\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-alert tt-alert--warning\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function";

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==",0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <div "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <div class=\"modal-header\">"
    + ((stack1 = ((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"header","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n            <div class=\"modal-body\">"
    + ((stack1 = ((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"body","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n            <div class=\"modal-footer\">"
    + ((stack1 = ((helper = (helper = helpers.footer || (depth0 != null ? depth0.footer : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"footer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['genric_yes_no_dialog'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n                "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button\">\n\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Callbacks Reason No",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n                \n\n                    <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Yes",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n                \n              </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['loading_advertising'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0,(data && data.index),">",0,{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " tt-container tt-text-center js-adv-elem\">\n			"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			<br>\n			"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.text : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			<br/><br/>\n			"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.MOPMessageURL : depth0), depth0))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			<div class=\"tt-deprecated-loader tt-mt-10\"></div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"4":function(container,depth0,helpers,partials,data) {
    return "<h2 class=\"tt-text-load tt-deprecated-h2\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</h2>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<p>"
    + ((stack1 = container.lambda((depth0 != null ? depth0.text : depth0), depth0)) != null ? stack1 : "")
    + "</p>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<br/><img src=\""
    + ((stack1 = container.lambda((depth0 != null ? depth0.image : depth0), depth0)) != null ? stack1 : "")
    + "\" width=\"400\" height=\"300\" border=\"0\" alt=\"\"/>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "<a href=\""
    + ((stack1 = alias1((depth0 != null ? depth0.link : depth0), depth0)) != null ? stack1 : "")
    + "\" target=\"_blank\" >"
    + ((stack1 = alias1((depth0 != null ? depth0.link : depth0), depth0)) != null ? stack1 : "")
    + "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});

templates['loading'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"tt-container tt-margin-top-30\">\n  <div class=\"tt-spinner-wrapper\">\n    <svg\n    viewBox=\"0 0 52 52\"\n    xmlns=\"http://www.w3.org/2000/svg\"\n    class=\"tt-spinner\"\n  >\n    <circle\n      stroke=\"url(#spinner_201)\"\n      fill=\"transparent\"\n      stroke-width=\"4\"\n      cx=\"26\"\n      cy=\"26\"\n      r=\"22\"\n      class=\"tt-spinner-path\"\n    ></circle>\n    <linearGradient id=\"spinner_201\">\n      <stop offset=\"50%\" stop-opacity=\"1\" class=\"tt-spinner-stop-color\"></stop>\n      <stop offset=\"70%\" stop-opacity=\".5\" class=\"tt-spinner-stop-color\"></stop>\n      <stop offset=\"100%\" stop-opacity=\"0\" class=\"tt-spinner-stop-color\"></stop>\n    </linearGradient>\n  </svg>\n  </div>\n</div>";
},"useData":true});

templates['native_dialog'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function";

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\" data-backdrop=\"static\" data-keyboard=\"false\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n                <div class=\"downloads_note\">"
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n            </div>\n            <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n                <button type=\"button\" class=\"tt-button tt-button--primary dismiss\" open-link=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btn_msg || (depth0 != null ? depth0.btn_msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"btn_msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

templates['oblivion_dialog_dossier'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-start-msg-container tt-no-padding\">\n          <div class=\"tt-info__content\">\n            <div>\n              "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-success-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon fad tt-icon-check-circle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-success-content\">\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-error-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-exclamation-triangle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-error-content\">\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-action-container\">\n\n            <button type=\"button\" class=\"tt-button tt-button--secondary js-oblivion-dialog-cancel-btn\">\n              "
    + alias3(((helper = (helper = helpers.cancel_btn_label || (depth0 != null ? depth0.cancel_btn_label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"cancel_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n          \n\n            <button type=\"button\" class=\"tt-button tt-button--danger js-oblivion-dialog-confirm-btn\">\n              "
    + alias3(((helper = (helper = helpers.confirm_btn_label || (depth0 != null ? depth0.confirm_btn_label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"confirm_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n        \n        </div>\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-response\" style=\"display: none;\">\n\n            <button type=\"button\" class=\"tt-button tt-button--primary js-oblivion-dialog-cancel-btn\">\n              "
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Ok",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </button>\n        \n        </div>\n        <div style=\"display: none; padding-bottom: 100px;\" class=\"js-oblivion-dialog-loader-container\">\n          <div class=\"tt-deprecated-loader\"></div>\n          <div class=\"tt-mt-10 tt-text-center\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Mop Operation In Progress",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});

templates['oblivion_dialog'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "            <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mt-10\">\n              <li class=\"tt-checkbox tt-no-border tt-no-padding\">\n                <label class=\"tt-checkbox__label tt-pl-0 \" style=\"right: auto !important;\">\n                  <div class=\"tt-deprecated-loader\" style=\"display: none;\"></div>\n                  <input class=\"tt-checkbox__label-input js-oblivion-checkbox-parents\" type=\"checkbox\">\n                </label>\n                <div class=\"tt-checkbox__text\">\n                  "
    + alias3(((helper = (helper = helpers.checkbox_parents || (depth0 != null ? depth0.checkbox_parents : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"checkbox_parents","hash":{},"data":data}) : helper)))
    + "\n                  <span>\n                   ("
    + alias3(((helper = (helper = helpers.parents_list || (depth0 != null ? depth0.parents_list : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"parents_list","hash":{},"data":data}) : helper)))
    + ")\n                  </span>\n                </div>\n              </li>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=container.escapeExpression;

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-start-msg-container tt-no-padding\">\n          <div class=\"tt-info__content\">\n            <div>\n              "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showCheckbox : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-success-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon fad tt-icon-check-circle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-success-content\">\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-error-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-exclamation-triangle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-error-content\">\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-action-container\">\n\n            <button type=\"button\" class=\"tt-button tt-button--secondary js-oblivion-dialog-cancel-btn\">\n              "
    + alias3(((helper = (helper = helpers.cancel_btn_label || (depth0 != null ? depth0.cancel_btn_label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"cancel_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n          \n\n            <button type=\"button\" class=\"tt-button tt-button--danger js-oblivion-dialog-confirm-btn\">\n              "
    + alias3(((helper = (helper = helpers.confirm_btn_label || (depth0 != null ? depth0.confirm_btn_label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"confirm_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n        \n        </div>\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-response\" style=\"display: none;\">\n\n            <button type=\"button\" class=\"tt-button tt-button--primary js-oblivion-dialog-cancel-btn\">\n              "
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Ok",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </button>\n        \n        </div>\n        <div style=\"display: none; padding-bottom: 100px;\" class=\"js-oblivion-dialog-loader-container\">\n          <div class=\"tt-deprecated-loader\"></div>\n          <div class=\"tt-mt-10 tt-text-center\">"
    + ((stack1 = (helpers.translate || (depth0 && depth0.translate) || alias1).call(depth0,"Mop Operation In Progress",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});

templates['retry_dialog'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <button type=\"button\" class=\"tt-button tt-button--secondary js-dialog-cancel-btn\">\n              "
    + container.escapeExpression(((helper = (helper = helpers.cancel_btn_label || (depth0 != null ? depth0.cancel_btn_label : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"cancel_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function";

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-info--column tt-no-border tt-no-padding\">\n          <i class=\"tt-info__icon fad tt-icon-info-circle\"></i>\n          <div class=\"tt-info__content\">\n            <div>\n              "
    + ((stack1 = ((helper = (helper = helpers.msg || (depth0 != null ? depth0.msg : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"msg","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <div class=\"tt-flexend-container tt-dialog-button\">\n            <button type=\"button\" class=\"tt-button tt-button js-dialog-confirm-btn\">\n              "
    + container.escapeExpression(((helper = (helper = helpers.confirm_btn_label || (depth0 != null ? depth0.confirm_btn_label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"confirm_btn_label","hash":{},"data":data}) : helper)))
    + "\n            </button>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.cancel_btn_label : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
});