Promise.allSettled = Promise.allSettled || (promises => Promise.all(promises.map(p => p
  .then(v => ({
    status: 'fulfilled',
    value: v,
  }))
  .catch(e => ({
    status: 'rejected',
    reason: e,
  }))
)));

if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, 'flat',
    {
      value: function (depth = 1, stack = []) {
        for (const item of this) {
          if (item instanceof Array && depth > 0) {
            item.flat(depth - 1, stack);
          } else {
            stack.push(item);
          }
        }

        return stack;
      }
    });
}