import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { Divider } from '../Divider';
import { Icon } from '../Icon';
import { Checkbox } from '../Checkbox';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Input } from '../../index.js';
import { LineButton } from '../LineButton';

import { TTPolyglot } from "@frontend/tt-polyglot";


export const SelectableList = ({ options, onSelect, selected, onClick }) => {

  return (
    <>
      {
        options.map((option, index, { length }) => <React.Fragment key={index}>
          <div css={style.base} onClick={() => onSelect(option)}>
            <Checkbox
              type="round"
              value={option}
              checked={option.value === selected?.value}
            >
              <Label size='medium'>{option.label}</Label>
            </Checkbox>
          </div>
          {index + 1 < length &&
            <Divider type={"horizontal"} />
          }
        </React.Fragment>
        )
      }
      {onClick && <LineButton icon={"PlusSolid"} onClick={onClick}>{TTPolyglot.t('Family Select User')}</LineButton>}
    </>
  )
};

SelectableList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape(
      {
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      }
    )
  ).isRequired,
  selected: PropTypes.shape(
    {
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }
  ),
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  // onClickLabel
};

SelectableList.defaultProps = {
  options: [],
  onSelect: null,
  onClick: null,
  selected: null,
};


