import { createAsyncThunk } from "@reduxjs/toolkit";
import { getScore as getScoreApi } from '../api/apiCertificationWidget';

export const getScore = createAsyncThunk("certificationWidget/getScore", async (args, { getState, dispatch, rejectWithValue }) => {
  try {

    const {
      return: {
        url,
        stars,
        certificate_value,
        total_opinion_count
      }
    } = await getScoreApi();
    

    return ({
      url,
      stars,
      certificate_value,
      total_opinion_count
    });

  } catch (error) {
    return rejectWithValue(error);
  }
});