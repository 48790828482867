import MOP from "app";
import View from "./view";

MOP.module('HeaderApp', (Show, MOP) => {

  Show.Controller = {
    showHeader: function (options) {            
      const view = new View.Index(options);
      MOP.headerRegion.show(view);
    }
  };
});

export default MOP.HeaderApp.Controller;
