export default function (MOP) {
    var BCKGM = {
        delay: 60000,
        intervalID: undefined,

        start: function start() {
            BCKGM.run();
            BCKGM.intervalID = window.setInterval(BCKGM.run, BCKGM.delay);
        },

        stop: function stop() {
            window.clearInterval(BCKGM.intervalID);
        },

        run: function run() {
            // console.log('running');
            function _setLastBackgroundProcessTimestamp(err, fn) {
                if (!err) {
                    var background = MOP.localStorage.get('background') || {};

                    background[fn] = MOP.Utilities.getTimestamp();
                    MOP.localStorage.set('background', background);
                }
            }

            function _checkBackgroundProcess(fn) {
                var background = MOP.localStorage.get('background') || {};
                var fnLastAccess = background[fn] || 0;

                if (fnLastAccess + MOP.config.getAjaxPolling(fn) < MOP.Utilities.getTimestamp()) {
                    return true;
                }

                return false;
            }

            //Al momento per sicurezza non andiamo più a farla in background
            if (MOP.Utilities.isMobileApp() && false) {
                // Gestione polling start_new_mop function
                var startNewMop = MOP.config.fetch_fn;

                if (MOP.config.getAjaxCache(startNewMop) && _checkBackgroundProcess(startNewMop)) {
                    $.when(MOP.startNewMop(null, { refreshCache: true }))
                        .done(function () {
                            _setLastBackgroundProcessTimestamp(null, startNewMop);
                        })
                        .fail(function (e) {
                            _setLastBackgroundProcessTimestamp(e);
                        });
                }
            }

            // Gestione polling check_recache function
            var checkRecache = 'check_recache';

            if (_checkBackgroundProcess(checkRecache) && MOP.config.isServerCacheEnabled()) {
                $.when(MOP.ajax({
                    fetch_fn: checkRecache
                })).done(function (data) {
                    _setLastBackgroundProcessTimestamp(null, checkRecache);

                    if (data.result === 'OK' && !MOP.Utilities.empty(data.return)) {
                        var serverLastRecacheTimestamp = parseInt(data.return, 10);
                        var localLastRecacheTimestamp = MOP.permanentLocalStorage.get('lastRecacheTimestamp');

                        // Il localLastReacacheTimestamp deve essere sempre uguale
                        // serverLastReacacheTimestamp altrimenti invalido la cache locale.
                        if (serverLastRecacheTimestamp != localLastRecacheTimestamp) {

                            // [TODO] Mattia - Prendi la lista di funzioni filtrandola sul parametro recache = true
                            var fnToInvalidate = ['insurances', 'typologies', 'activities', 'areas', 'resources', 'workingtime', 'provinces'];

                            _.map(fnToInvalidate, function (fn) {
                                MOP.clearBackboneCacheItemsByJsonFn(fn);
                            });

                            MOP.permanentLocalStorage.set('lastRecacheTimestamp', serverLastRecacheTimestamp);
                        }
                    }
                })
                    .fail(function (e) {
                        _setLastBackgroundProcessTimestamp(e);
                    });
            }

            // Gestione polling search_reservations function
            var search_reservations = 'reservations';

            if (_checkBackgroundProcess(search_reservations) && !MOP.isAdminLoggedIn() && MOP.isLoggedIn() && MOP.config.isServerSearchReservationsCacheEnable()) {

                var ReservationsSearchObject = require("models/reservations_search_object").default;
                var _entities_reservation = require("entities/reservation");

                var searchObject = new ReservationsSearchObject();

                var params = {};
                searchObject.formatDefaultParams(MOP, params);

                searchObject.load(params);

                var params_to_search = searchObject.getFiltersToSearch(MOP);

                var reservations = MOP.request("reservation:entities:new");

                reservations.fetch(MOP, {
                    querystring: params_to_search,
                    loading: {
                        enabled: false
                    },
                    preventThrowException: false
                }).done(function () {
                    _setLastBackgroundProcessTimestamp(null, search_reservations);
                    reservations = undefined;
                }).fail(function (e) {
                    _setLastBackgroundProcessTimestamp(e);
                    reservations = undefined;
                });
            }
        }
    };

    return BCKGM;
};
