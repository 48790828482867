import { css } from "@emotion/react";

export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
});


export const text = () => css({
  whiteSpace: "pre-wrap"
});