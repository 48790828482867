import MOP from 'app';
import { loadJsCssFile } from 'common-lib/file';

import { getDefaultAuthObject, dossierOTPVerificationConfig } from 'new-dossier-common';
import { DOSSIER_BIOMETRICS_NOT_ACTIVE } from 'new-dossier-common/constants';

export const loadDropboxPlugin = (isDropBoxActive, callback = false) => {

  if (isDropBoxActive) {
    // Load dropbox Plugin
    loadJsCssFile(
      'https://www.dropbox.com/static/api/2/dropins.js',
      [
        {
          attributeName: 'id',
          attributeVal: 'dropboxjs'
        },
        {
          attributeName: 'data-app-key',
          attributeVal: '23hr3cqr0djs8h1'
        }
      ],
      null,
      null
    )
      .then(() => {
        if (callback) callback();
      });
  }

};

export const isDossierSynced = dossier_syncd => {
  // Se l'istanza NON è integrata allora non andiamo a utilizzare il flusso del dossier_syncd perchè
  // non abbiamo flussi di sincronizzazione esterni ma è tutto interno, quindi torniamo sempre true
  if (!MOP.config.isDossierIntegrated(MOP)) {
    return true;
  }

  return !MOP.Utilities.empty(dossier_syncd) && dossier_syncd >= (Math.floor(Date.now() / 1000) - 3600);
};

export const getDossierAuthObject = () => {

  // Si occupa di restituire l'oggetto delle biometriche che si trova in localstorage, 
  // e se questo non esiste, lo crea, lo inserisce in localstorage e lo restituisce

  if (!MOP.permanentLocalStorage.has('dossierAuth')) {
    const dossierAuth = getDefaultAuthObject();
    MOP.permanentLocalStorage.set(JSON.stringify(dossierAuth));
    return dossierAuth;
  }

  return JSON.parse(MOP.permanentLocalStorage.get('dossierAuth'));
};

// Resetto expiration e/o biometriche pilotate dai parametri in ingresso
export const resetStorageDossierAuth = () => {
  if (
    (
      !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) &&
      !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage').dossier)
    )
    ||
    !MOP.Utilities.empty(MOP.querystring.enableNewDossier)
  ) {

    // Se siamo nel nuovo dossier resettiamo la localstorage del nuovo dossier
    const workAuthObject = getDossierAuthObject();

    // Riprendo i valodi di subsession ed otp dai config dell'istanza
    workAuthObject.subsession = MOP.Utilities.empty(dossierOTPVerificationConfig()),
    workAuthObject.otp = !MOP.Utilities.empty(dossierOTPVerificationConfig());
    // Resetto expiration otp e biometriche attive/disattivate
    workAuthObject.otp_expiration = null;
    workAuthObject.biometrics_active = DOSSIER_BIOMETRICS_NOT_ACTIVE;

    MOP.permanentLocalStorage.set('dossierAuth', JSON.stringify(workAuthObject));

    return workAuthObject;
  }
  // Altrimenti resetto la localstorage del vecchio dossier
  MOP.permanentLocalStorage.unset('dossierOTPVerification');
  return;

};

export const toggleDossierBiometrics = () => {
  const workAuthObject = getDossierAuthObject();

  if (parseInt(workAuthObject.biometrics_active) === 1) {
    // Biometriche attive, le disattiviamo e azzeriamo l'otp expiration senza dover rifare l'otp
    return resetStorageDossierAuth();
  }

  // Biometriche non attive o disattivate, riportiamo alla pagina dell'otp per attivarle
  return MOP.changePage("dossier", `dossier/user/${MOP.getLoggedUserId()}/auth/otp`, null, null, null, null, {});

};

export const isPrivacyDossierActive = () => {
  const workAuthObject = getDossierAuthObject();

  if (parseInt(workAuthObject.biometrics_active) === 1) return true;

  return false;
};