import { css } from "@emotion/react";

export const base = (defaultOpacity, transition, transitionOpacity) => css({
  ...defaultStyle(defaultOpacity, transition),
  ...transitionStyle(transitionOpacity)
});

const defaultStyle = (opacity, transition) => css({
  opacity: opacity || "unset",
  transition: transition || "unset",
});

const transitionStyle = opacity => css({
  opacity: opacity || "unset"
});