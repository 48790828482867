import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Label, Box } from '../../../../../index.js';


export const Header = (
  {
    e2eid,
    title,
    children
  }) => {
  const theme = useTheme();

  return ( 
    <Box width="100%" height="100%">
      <div css={[style.base(theme), style.title(theme)]}>
        <Label e2eid={e2eid} size="large" weight="semiBold">{title}</Label>
      </div>
      <div css={[style.base(theme), style.subtitle(theme)]}>
        { children }
      </div>
    </Box>  
  );
};


Header.propTypes = {
  title: PropTypes.string, 
  children: PropTypes.node
};

Header.defaultProps = {
  title: "Insurance", 
  children: null
}; 