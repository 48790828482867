import React from 'react';
import MOP from 'app';
import { RightMenu } from './RightMenu';
import { certificationWidgetEnabled, getHeaderMenu, itemClicked } from '../../common';
import AdditionalMenu from './AdditionalMenu';

import CertificationWidget from '../../common/components/certification-widget';


export const Header = ({ disabled, active, logo, hide }) => {

  if (MOP.isAuthModeEnabled()) {
    return null;
  }

  if ((MOP.fromBackOffice() && MOP.getCurrentPage() === 'payment') ||
    (MOP.getLoggedUserData() && "auth_mode" in MOP.getLoggedUserData() && parseInt(MOP.getLoggedUserData().auth_mode, 10) === MOP.constants.AUTH_MODE_TRD111) ||
    MOP.Utilities.empty(MOP.config.getInstanceConfig('menuVisibility').desktop)) {
    return null;
  }

  const { menu, rightMenu } = getHeaderMenu();

  const checkActive = item => {
    const { page, route } = active;
    if (item.page === page) {
      if (!route && !item.route) return true;
      return item.route === route;
    }
    return false;
  };

  return (
    <>
      {logo && !MOP.config.isNewLauncher(MOP) && <div className="tt-header-logo tt-block">
        <img
          className={`tt-header-logo__img tt-header-logo-print ${MOP.config.isInIFrame() && MOP.Utilities.empty(MOP.querystring.mopLauncher) && 'tt-header-logo__img--small'}`}
          src={logo} />
      </div>}
      <nav className="tt-navbar-wrapper noprint" role="navigation">
        <div className="collapse navbar-collapse tt-navbar">
          <ul className="tt-navbar-menu">
            {hide ? null :
              <>
                <div className="tt-navbar-menu-actions">
                  {menu.map((item, index) => {
                    return (
                      <li key={index} data-e2eid={item.e2eid} onClick={() => itemClicked(item)} className={`tt-navbar__link ${checkActive(item) ? 'active' : ''} ${disabled ? 'tt-pointer-disabled' : ''}`}>
                        <a className="tt-navbar__link__text">{item.label}</a>
                      </li>
                    );
                  })}
                </div>
                {MOP.isAdminLoggedIn() && MOP.config.isInternalMop() ? null : <AdditionalMenu menuItems={rightMenu.additionalMenu} disabled={disabled} checkActive={checkActive} />}
              </>
            }
            {MOP.isAdminLoggedIn() && MOP.config.isInternalMop() ? null : <RightMenu menuComponents={rightMenu} />}
          </ul>
        </div>
      </nav>
      {certificationWidgetEnabled() && <CertificationWidget />}
    </>
  );
};