import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Label } from '../Label';
import { Icon } from 'ui/Icon/index.jsx';

/**
 * Primary UI component for user interaction
 */
export const Tag = ({color, data}) => {

  const theme = useTheme();

  const { label, color: labelColor, icon } = data;

  const renderIcon = icon => {
    if (!icon) return null;

    return <Icon name={icon} color={labelColor} size={"medium"} />;
  };

  return (
    <div css={style.base(theme)({color})}>
      {renderIcon(icon)}
      <span css={style.content(theme)({color: labelColor})}>{label}</span>
    </div>
  );
};

Tag.propTypes = {
  color: PropTypes.string,
  data: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string
  }).isRequired
};

Tag.defaultProps = {
  color: null,
  data: null
};
