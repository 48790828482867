import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ loading, disabled, size, width }) => css({
  ...theme.button.base,
  ...((loading || disabled) && theme.button.disabled),
  ...(size === "small" && theme.button.small),

  height: theme.layout.baseHeights[size],
  width
});

export const solid = theme => ({ color }) => css({
  backgroundColor: color,
  color: theme.colors.white
});


export const light = () => ({ color }) => css({
  backgroundColor: chroma(color).alpha(0.14).hex(),
  color,
});

export const text = () => ({ color }) => css({
  backgroundColor: "transparent",
  color,
  transition: "0.4s",

  ":hover": {
    backgroundColor: chroma(color).alpha(0.07).hex(),
  }
});