import MOP from 'app';

export const showResource = reservation => {

  const { isHiddenResource, is_machine } = reservation;

  let showResource = MOP.config.isSearchWidgetEnabled('resources');

  if (!MOP.Utilities.empty(parseInt(isHiddenResource, 10) || !MOP.Utilities.empty(is_machine))) {
    showResource = false;
  }

  if (MOP.isFromTRD111()) return false;

  return showResource;
};

export const isPaymentCompulsory = ({ payment_required }) => parseInt(payment_required, 10) === MOP.config.constants.PAYMENT_COMPULSORY;

export const isManuallyPaid = ({ payment_mode }) => {
  return (
    parseInt(payment_mode) === MOP.config.constants.MOP_PAYMENT_MODE_OFFLINE_CREDIT_CARD ||
    parseInt(payment_mode) === MOP.config.constants.MOP_PAYMENT_MODE_OFFLINE_WIRE_TRANSFER ||
    parseInt(payment_mode) === MOP.config.constants.MOP_PAYMENT_MODE_OFFLINE_CHECK ||
    parseInt(payment_mode) === MOP.config.constants.MOP_PAYMENT_MODE_OFFLINE_CASH ||
    parseInt(payment_mode) === MOP.config.constants.MOP_PAYMENT_MODE_OFFLINE_OTHER
  );
};