import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Icon } from 'ui/Icon/index.jsx';


export const LineButton = ({ children, e2eid, icon, onClick }) => {

  return (
    <div
      {...e2eid ? {"data-e2eid": e2eid } : null}
      css={style.base}
      className={!onClick ? 'complete' : ''}
      onClick={onClick}
    >
      {icon && <Icon name={icon} size={"large"} />}
      <span css={style.text}>{children}</span>
    </div>
  );
};

LineButton.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func
};

LineButton.defaultProps = {
  children: null,
  icon: null,
  onClick: null
};
