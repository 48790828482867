import MOP from 'app';
import {
  FACEBOOK_PIXEL,
  GOOGLE_ANALYTICS,
  GOOGLE_TAG_MANAGER,
  INSERT_RESERVATION,
  START_IFRAME_MOP
} from 'monitoring-costants';
import { FPAInitialize, FPATrackEvent } from './providers/facebookPixelAds';
import { GAInitialize, GATrackEvent, GAInitializeApp, GATrackEventApp } from './providers/googleAnalytics';
import { GTMInitialize, GTMTrackEvent } from './providers/tagManager';

export const initializeMonitoring = () => {

  // Variabile Globale dove vengono inseriti i servizi abilititati al monitoring recuperati dal config  
  MOP.MonitoringProviders = [];

  MOP.Utilities.isMobileApp() ? app() : web();
}


export const web = async () => {

  const mopAnalyticsObj = MOP.config.getInstanceConfig('mopAnalytics');
  if (MOP.Utilities.empty(mopAnalyticsObj)) return;


  // Variabile Globale dove vengono inseriti i servizi abilititati al monitoring recuperati
  // dal config  
  MOP.MonitoringProviders = [];

  for (const mopAnalyticsObjProperty of Object.entries(mopAnalyticsObj)) {

    const [analycServiceName, analycServiceValue] = mopAnalyticsObjProperty;
    const { id, enableUserEvents, options } = analycServiceValue;

    // Stiamo considerando una entry con un id vuoto, 
    // non dobbiamo inizializzare nulla a prescindere dal tipo di entry, 
    // quindi continuo il ciclo con la prossima entry
    if (MOP.Utilities.empty(id)) continue;

    switch (analycServiceName.toLowerCase()) {

      case FACEBOOK_PIXEL: {
        
        // Inizializzo FB Pixel tramite gli id presenti nel config 
        // Creo un array di id (valori separati da virgola), così gestisco id multipli
        const checkFPAInitialize = await FPAInitialize(id.split(","), options);

        if (checkFPAInitialize) {
          // Solo a questo punto controllo se abbiamo la enableUserEvent attiva, se si allora aggiungo FP ai monitoring providers
          if (!MOP.Utilities.empty(enableUserEvents) && parseInt(enableUserEvents) === 1) MOP.MonitoringProviders.push(FACEBOOK_PIXEL);

          if (process.env.NODE_ENV === `development`) {
            console.info("MonitoringProviders - Facebook Pixel Ads - Monitoring initialized.");
          }
        } else
          console.error("MonitoringProviders - Facebook Pixel Ads - Monitoring inizialize FAILED.");


        break;
      }
      case GOOGLE_TAG_MANAGER: {
        // Inizializzo Google Tag Manager tramite Id presente nel config 
        // Per questo non c'è bisogno di fare altro, basta inizializzarlo senza inserirlo tra i monitoring providers
        // perchè lui non invia eventi 
        // TODO Per GTM capisci se serve gestire id multipli oppure lavoriamo solo su un singolo id
        const checkGTMInitialize = await GTMInitialize(id);

        if (checkGTMInitialize && !MOP.Utilities.empty(enableUserEvents) && parseInt(enableUserEvents) === 1) {
          MOP.MonitoringProviders.push(GOOGLE_TAG_MANAGER);
        } 

        break;
      }
      case GOOGLE_ANALYTICS: {
        /**
         * A differenza di Facebook Pixels l'inclusione di GA deve essere fatta a prescindere dal config enableUserEvents
         * Infatti google analytics potrebbe essere utilizzato anche nell'invio dell'evento custom, quindi anche se avessimo la enableUserEvents spenta
         * 
         * Inizializzo Google Analytics tramite Id presente nel config 
         * Creo un array di id (valori separati da virgola), così gestisco id multipli
         */

        const chechGAInitialize = await GAInitialize(id.split(","), options);
        if (chechGAInitialize) {

          // Solo a questo punto controllo se abbiamo la enableUserEvent attiva, se si allora aggiungo GA ai monitoring providers
          if (!MOP.Utilities.empty(enableUserEvents) && parseInt(enableUserEvents) === 1) MOP.MonitoringProviders.push(GOOGLE_ANALYTICS);

          if (process.env.NODE_ENV === `development`) {
            console.info("MonitoringProviders - Google Analytics - Monitoring initialized.");
          }
        } else
          console.error("MonitoringProviders - Google Analytics - Monitoring inizialize FAILED.");

        break;
      }

      default:
        break;
    }
  }

  if (!MOP.Utilities.empty(MOP.MonitoringProviders) && MOP.config.isInIFrame())
    sendUserEvent(START_IFRAME_MOP);

};


export const app = async () => {

  const mopAnalyticsObj = MOP.config.getInstanceConfig("mopAppAnalytics");
  if (MOP.Utilities.empty(mopAnalyticsObj)) return;

  for (const mopAnalyticsObjProperty of Object.entries(mopAnalyticsObj)) {

    const [analycServiceName, analycServiceValue] = mopAnalyticsObjProperty;

    const { enableUserEvents } = analycServiceValue;

    switch (analycServiceName.toLowerCase()) {
      case GOOGLE_ANALYTICS: {

        if (!MOP.Utilities.empty(enableUserEvents) && parseInt(enableUserEvents) !== MOP_APP_SEND_EVENT_DISABLED) {
          // Procedo con inizializzazione lato APP solo se enableUserEvents !== 0 ovvero eventi abilitati
          const chechGAInitialize = await GAInitializeApp();
          if (chechGAInitialize) {
            // Aggiungo il provider ai monitoring providers solo se gli eventi sono abilitati ed accesi, altrimenti evito di inserirlo
            // ed il cliente riceverà solamente gli screenview lato APP
            if(parseInt(enableUserEvents) === MOP_APP_SEND_EVENT_ENABLED) MOP.MonitoringProviders.push(GOOGLE_ANALYTICS);

            if (process.env.NODE_ENV === `development`) {
              console.info("MonitoringProviders - Google Analytics - Monitoring initialized.");
            }
          } else {
            console.error("MonitoringProviders - Google Analytics - Monitoring inizialize FAILED.");
          }
        }
        break;
      }

    }
  }
};


export const sendUserEvent = async (event, value = null) => {

  if (!MOP.Utilities.empty(MOP.MonitoringProviders)) {

    // FD90333 Se l'utente è loggato e ha un ruolo non paziente, non invio tracking
    if (MOP.isLoggedIn() && MOP.isAdminLoggedIn()) return;


    MOP.MonitoringProviders.map(provider => {

      switch (provider) {
        case FACEBOOK_PIXEL:
          FPATrackEvent(event, value);
          break;

        case GOOGLE_ANALYTICS:
          MOP.Utilities.isMobileApp() ? GATrackEventApp(event, value) : GATrackEvent(event, value);
          break;

        case GOOGLE_TAG_MANAGER:
          GTMTrackEvent(event, value);
          break;

        default:
          break;
      }
    });

  }

};

