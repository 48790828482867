import React from 'react';
import PropTypes from 'prop-types';

import ReactRating from 'react-rating';

import { Icon } from '../Icon';

export const Rating = ({ value, size }) => {

  return (
    <ReactRating
      readonly={true}
      initialRating={value}
      emptySymbol={<Icon size={size} name={"StarSolid"} color={"gainsboro"} />}
      fullSymbol={<Icon size={size} name={"StarSolid"} color={"caribbean_green"} />}
    />
  );
};

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["micro", "small", "standard", "medium", "ml", "large", "xl", "xxl", "xxxl"]),
};

Rating.defaultProps = {
  value: null,
  size: "standard"
};
