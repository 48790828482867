import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';

/**
 * Primary UI component for user interaction
 */
export const BottomFixedButton = React.memo(({ e2eid, children, disabled, onClick }) => {

  const theme = useTheme();

  return (
    <div css={style.base} >
      <button
        {...e2eid ? {"data-e2eid": e2eid } : null}
        type="button"
        css={style.button(theme)({ disabled })}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
});

BottomFixedButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

BottomFixedButton.defaultProps = {
  children: "",
  onClick: null,
  disabled: false
};
