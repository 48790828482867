import { css } from "@emotion/react";

export const base = theme => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.standard
});

export const content = () => css({
  flex: 1,
});

export const iconContainer = () => css({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center"
});

export const arrowLeftContainer = () => css({
  marginRight: "auto"
});

export const arrowRightContainer = () => css({
  marginLeft: "auto"
});