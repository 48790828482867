import { css } from "@emotion/react";


export const base = theme => ({shadow, border, width, height, backgroundColor, hideScrollbar}) => {
  const scrollbar = hideScrollbar
    ? {
      "::-webkit-scrollbar": {
        display: "none",
      },
    }
    : {};

  return css({
    backgroundColor: backgroundColor ? theme.colors[backgroundColor] : theme.colors.white,
    border: border ? `${theme.layout.borderWidth.standard} solid ${theme.colors.gainsboro}` : "",
    borderRadius: theme.layout.borderRadius.small,
    boxShadow: shadow ? theme.layout.baseBoxShadow : "",
    height: height || "100%",
    position: "relative",
    width: width || "",
    ...scrollbar
  });
};

export const wrapper = theme => padding => css({
  height: "100%",
  overflow: "auto",
  padding: padding ? theme.layout.padding[padding] : "0px",
});



