import MOP from 'app';

export const getMessages = (userid, bypassCheckResponse = false) => {
  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `messages`,
      querystring: {userid: userid},
      bypassCheckResponse: bypassCheckResponse,
      ajax: {
        options: {
          method: 'GET',
        },
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => resolve(response.return.results))
      .fail(e => {
        reject(e);
      });
  });
}