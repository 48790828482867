import Marionette from 'backbone.marionette';

export default function (MOP) {
    return Marionette.Behavior.extend({
        events: {
            'keyup input.input-block-level': 'optionTyped'
        },

        optionTyped: function optionTyped(e) {
            e = MOP.TM.getEvent(e);

            var keycode = e.keyCode;

            if (keycode !== 38 && keycode !== 40 && keycode !== 32) {

                MOP.TM.stopPropagation(e);

                return this.view.optionTyped ? this.view.optionTyped(e) : true;
            }
        }
    });
};