import Marionette from 'backbone.marionette';
import $ from 'jquery';
import Handlebars from 'handlebars';

var NestedPageBehavior = function (MOP) {
  return Marionette.Behavior.extend({

    events: {
      'click .nestedPageLink': 'goToNestedPage',
      'click .nestedPageLinkBack': 'goToPreviousNestedPage',
      'click .writeNestedPageSelection': 'writeNestedPageSelection'
    },

    // Serve per poter salvare il punto di scroll e ripristinarlo tornando indietro
    lastScrollPositionBeforeNest: 0,

    initialize: function () {
      this.view.pagesHistory = [];
    },
    /*
    Questa funzione permette di poter scrivere la selezione di una nestedPage (o il testo che si vuole visualizzare) all'interno di un elemento.
    Per poterla utilizzare bisogna eseguire i seguenti step:
    1.  E' necessario aggiungere la classe: writeNestedPageSelection all'elemento che riceverà il click di selezione
    2.  Allo stesso elemento del punto 1, bisogna aggiungere uno dei due attributi:
          a. writeNested-ToId, è il riferimento dell'elemento utilizzando il tag id, che riceverà il valore selezionato
          b. writeNested-ToClass, è il riferimento dell'elemento utilizzando il tag clas, che riceverà il valore selezionato
        Ovviamente l'elemento di destinazione, dovrà avere l'Id o la Classe scelta.
        E' possibile scegliere arbitrariamnete tra Id o Class in base alle esigenze e alla struttura della pagina.
    3. Allo stesso elemento bisogna decidere dove recuperare il valore selezionato, anche qui aggiungere uno di questi attributi:
          a. nestedDataToWriteBack, come valore mettere il contenuto che si vuole scrivere
          b. getNestedData-fromId, recuperare il valore che si vuole scrivere da questo elemento tramite id
          c. getNestedData-fromClass, recuperare il valore che si vuole scrivere da questo elemento tramite class
          d. non aggiungere nessun attributo e il valore verrà recuperato dall'elemento stesso che ha triggerato l'evento
    4. Di default viene scritto tramite la funzione .text(), ma si può specificare in quale tag scrivere una volta scelto:
          a. .text() DEFAULT (Non c'è bisogno di aggiungere niente)
          b. writeToPlaceholder="true", .attr('placeholder', 'hello' );
    5. E' possibile aggiungere i due punti prima del testo (solo se è pieno) che va scritto di ritorno 
       dalla nested page, di defualt è disattiva, se si vuole si può aggiungere addBackColon="true"
    */
    writeNestedPageSelection: function (e) {
      var destination = $(e.currentTarget).attr('writeNested-ToId') ? "#" + $(e.currentTarget).attr('writeNested-ToId')
        : "." + $(e.currentTarget).attr('writeNested-ToClass');
      var valueToWrite = '';
      if (!MOP.Utilities.empty($(e.currentTarget).attr('nestedDataToWriteBack'))) {
        valueToWrite = $(e.currentTarget).attr('nestedDataToWriteBack');
      } else if (!MOP.Utilities.empty($(e.currentTarget).attr('getNestedData-fromId'))) {
        var tmp = $(e.currentTarget).attr('getNestedData-fromId');
        valueToWrite = $('#' + tmp).val();
      } else if (!MOP.Utilities.empty($(e.currentTarget).attr('getNestedData-fromClass'))) {
        var tmp = $(e.currentTarget).attr('getNestedData-fromClass');
        valueToWrite = $('.' + tmp).val();
      } else {
        valueToWrite = $(e.currentTarget).val();
      }

      if (!MOP.Utilities.empty($(e.currentTarget).attr('addBackColon')) && !MOP.Utilities.empty(valueToWrite)) {
        valueToWrite = ": " + valueToWrite;
      }

      if (!MOP.Utilities.empty($(e.currentTarget).attr('writeToPlaceholder'))) {
        $(destination).attr('placeholder', valueToWrite);
      } else {
        $(destination).html(valueToWrite);
      }

      if(!$(e.currentTarget).attr('dontCloseNested')){
        MOP.trigger('popCallback');
      }
      

      /* $('html, body').animate({
        // Se siamo in Mobile aggiungiamo 50 che è l'altezza della navbar più aggiungo un po di margine
        scrollTop: $(destination).offset().top + (MOP.config.isMobile() ? -70 : 0)
      }, 0); */

    },

    goToNestedPage: function (e) {

      MOP.trigger('showBack', true, this.goToPreviousNestedPage, this);
      
      // NB: In questo flusso non si riescono ad utilizzare le funzioni in common/ui blockBodyScroll, unblockBodyScroll perchè la nestedPage
      // ha delle logiche diverse rispetto a come sono state pensate la blockBodyScroll e unblockBodyScroll per i seguenti motivi:
      // - blockBodyScroll e unblockBodyScroll agiscono sul body html calcolandone lo scroll,
      //   Nel caso di nestedPage mobile, il body però rimane fermo, mentre ciò che scrolla è la main-wrapper-content-region
      //   Di conseguenza per la nestedPage in mobile, le funzioni comuni sono inutili
      //
      // - blockBodyScroll e unblockBodyScroll agiscono andando a "nascondere" il body html 
      //   e aggiungono un elemento in posizione assoluta al posto del body.
      //   Questa cosa qui non si può fare in quanto la nestedPage viene a tutti gli effetti montata sopra il body (quindi non in posizione assoluta)
      //   quindi il Body html deve rimanere visibile, altrimenti scompare anche la nestedPage
      if(!MOP.config.isMobile()) {
        // Nel caso di desktop è la window a scrollare, quindi vado a prendere il valore della window.scrollY
        this.lastScrollPositionBeforeNest = window.pageYOffset;
      } else {
        // Nel caso di mobile, la window rimane ferma ma scrolla la main-wrapper-content-region, di conseguenza prendo il suo valore di scroll
        this.lastScrollPositionBeforeNest = document.getElementById("main-wrapper-content-region").scrollTop;
      }
      

      var _this = this;
      var $this = $(e.currentTarget);
      var pageNameFrom = $this.attr('data-nested-page') || $this.parent('[data-nested-page]').attr('data-nested-page');
      var pageNameTo = $this.attr('data-next-nested-page');

      this.view.pagesHistory.push(pageNameFrom);

      $('[data-nested-page]').hide();
      $('[data-nested-page=' + pageNameTo + ']').show();

      // Questo resize serve perchè le nested page che hanno un contenuto molto lungo non facevano scattare il resize dell'iframe
      // perchè erano già in pagina e messe a display none, questo ne impediva la visualizzazione perchè veniva tagliato
      if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
      !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
        MOP.templateModule.resizeThisFrame();
      } else {
        MOP.TM.oldResizeThisFrame();
      }

      MOP.TM.scrollTop();

    },

    goToPreviousNestedPage: function (e) {
      
      $('[data-nested-page]').hide();

      if (this.view.pagesHistory.length > 0) {
        $('[data-nested-page=' + this.view.pagesHistory.pop() + ']').show();
      }

      // Questo resize serve perchè le nested page che hanno un contenuto molto lungo non facevano scattare il resize dell'iframe
      // perchè erano già in pagina e messe a display none, questo ne impediva la visualizzazione perchè veniva tagliato
      if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
      !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
        MOP.templateModule.resizeThisFrame();
      } else {
        MOP.TM.oldResizeThisFrame();
      }
      

      // NB: In questo flusso non si riescono ad utilizzare le funzioni in common/ui blockBodyScroll, unblockBodyScroll perchè la nestedPage
      // ha delle logiche diverse rispetto a come sono state pensate la blockBodyScroll e unblockBodyScroll per i seguenti motivi:
      // - blockBodyScroll e unblockBodyScroll agiscono sul body html calcolandone lo scroll,
      //   Nel caso di nestedPage mobile, il body però rimane fermo, mentre ciò che scrolla è la main-wrapper-content-region
      //   Di conseguenza per la nestedPage in mobile, le funzioni comuni sono inutili
      //
      // - blockBodyScroll e unblockBodyScroll agiscono andando a "nascondere" il body html 
      //   e aggiungono un elemento in posizione assoluta al posto del body.
      //   Questa cosa qui non si può fare in quanto la nestedPage viene a tutti gli effetti montata sopra il body (quindi non in posizione assoluta)
      //   quindi il Body html deve rimanere visibile, altrimenti scompare anche la nestedPage
      if(!MOP.config.isMobile()) {
        // Nel caso di desktop è la window a scrollare, quindi vado a settare il suo valore di scroll
        window.scrollTo(0, this.lastScrollPositionBeforeNest);
      } else {
        // Nel caso di mobile, la window rimane ferma ma scrolla la main-wrapper-content-region, quindi vado a settare il suo valore di scroll
        document.getElementById("main-wrapper-content-region").scrollTo(0, this.lastScrollPositionBeforeNest);
      }
      
    }
  });
};

export default NestedPageBehavior;
