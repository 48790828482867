import { css } from "@emotion/react";

export const base = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small
});


export const containerBase = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const container = theme => css({
  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: `${theme.layout.borderWidth.standard} 0rem`, // Vogliamo il bordo solo sopra e sotto
  borderLeft: "0px",
  borderRight: "0px",
  boxSizing: "border-box",
  minHeight: theme.layout.baseHeights.normal,
  padding: theme.layout.padding.standard,

  cursor: "pointer",
});

export const containerContent = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
});

export const containerContentNote = theme => ({ }) => css({
  maxWidth: "340px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: theme.fonts.size.standard
});

export const buttonFullContainer = theme => css({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});

export const textarea = theme => ({ }) => css({
  display: "block",
  width: "100%",
  minHeight: "100px",
  padding: "12px 12px 12px 16px",
  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,
  borderRadius: theme.layout.borderRadius.small,

  transition: "border-color ease-in-out .15s",

  ":focus": {
    borderColor: theme.colors.blue_jeans,
    outline: "0",
  },
  "::-moz-placeholder": {
    color: "#999",
    opacity: "1"
  },
  ":-ms-input-placeholder, ::-webkit-input-placeholder": {
    color: "#999"
  }
});

export const firstLine = theme => ({ }) => css({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between"
});