import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({}) => css({
  backgroundColor: theme.colors.white,
  padding: theme.layout.padding["standard"],
  overflow: "hidden",
  borderRadius: theme.layout.borderRadius.small,
  boxShadow: theme.layout.baseBoxShadow,
  
});

export const card = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  width: "352px",
  cursor: "pointer",
  gap: theme.layout.gap.standard

});

export const content = theme => ({}) => css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
});

export const infos = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small
});

export const arrow = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

export const date = theme => ({}) => css({
  fontSize: theme.fonts.size.medium,
  fontWeight: theme.fonts.weight.medium
});

export const labels = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro
});

export const buttons = theme => ({}) => css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
});

export const tags = theme => ({}) => css({
  display: "flex",
  flexDirection: "columns",
  gap: theme.layout.gap.micro
});