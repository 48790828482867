// Qui ci vanno tutte le logiche di manipolazione del DOM che però non ricadono nella UI

/** insertNodeFromString
 * Inserisco il child dentro un div usando innerhtml, in questo modo js trasforma una stringa in un nodo
 * Successivamente itero tutti i child del DIV creato e per ognuno di questi lo inserisco nel DOM facendo attenzione 
 * Perchè se questi nodi sono degli script allora dovrò creare un elemento script a mano ed appendere tale elemento altrimenti lo script non viene eseguito
 * @param {string} child 
 * @param {string} parent 
 * @param {boolean} prepend 
 */
export const insertNodeFromString = (child, parent, prepend = false) => {

  const _parent = document.querySelector(parent);

  const dummyContainer = document.createElement('div');
  dummyContainer.innerHTML = child;

  Array.from(dummyContainer.childNodes).forEach(originalNode => {
    let nodeToInsert;
    if (originalNode.tagName === 'SCRIPT') {
      // Crea un nuovo script per forzare l'esecuzione
      nodeToInsert = document.createElement('script');
      Array.from(originalNode.attributes).forEach(attr => {
        nodeToInsert.setAttribute(attr.name, attr.value);
      });
      if (originalNode.innerHTML) {
        nodeToInsert.text = originalNode.innerHTML;
      }
    } else {
      // Clona altri nodi
      nodeToInsert = originalNode.cloneNode(true);
    }

    // Inserisci il nodo nel DOM
    prepend ? _parent.insertBefore(nodeToInsert, _parent.firstChild) : _parent.appendChild(nodeToInsert);
  });
};