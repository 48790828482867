import React, { useEffect, useState, useLayoutEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { useTheme } from '@emotion/react';

import * as style from "./style";

import { Icon } from 'ui/Icon/index.jsx';
import { getDialogPosition } from './utils';


import { useMediaQuery } from "react-responsive";


/**
 * Primary UI component for user interaction
 */
export const Dialog = ({
  e2eid,
  children,
  title,
  open,
  maxHeight,
  fullScreen,
  onClose,
  closeButton,
  outClick,
  anchorEl, // Elemento su cui il dialog si attacca per calcolare la posizione se ci troviamo in iframe
  titleAnchorEl, // Ref da dare al titolo se viene passato, serve perchè alcune volte dobbiamo calcolare delle logiche in base alla posizione del titolo
  customStyle, // Stile custom, è possibile passare attualmente solo height e width,
  overflow // Permette di far andare in overflow gli input select interni al dialog
}) => {

  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(open);
  const [show, setShow] = useState(open);
  const [dialogPosition, setDialogPosition] = useState({});

  const ROOT = document.getElementsByTagName("body")[0];

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  useEffect(() => {
    
    if (open) {
      setDialogOpen(open);
      setTimeout(() => setShow(open));
    } else {
      setShow(open);
      setTimeout(() => setDialogOpen(false), 300); // 300ms === css transition
    }
  }, [open]);


  // Da finire dopo aver parlato con piero
  // Passare il position object allo stile del dialog
  useLayoutEffect(() => {

    // In queste condizioni NON calcolo il posizionamento custom, ma utilizzo quello di default
    if (
      fullScreen || // Se il dialog è fullscreen non mi serve posizionarlo
      !open || // Se dialog chiuso non devo posizionarlo
      !anchorEl || // Se non abbiamo elemento di ancoraggio, non devo posizionarlo
      !theme.iframe || // Se non siamo in iframe, non devo posizionarlo
      theme.MOPLauncher // Se siamo in MOPLauncher, non devo posizionarlo
    ) return;

    // Altrimenti calcolo la posizione del dialog

    const positionObject = getDialogPosition(anchorEl, ROOT, maxHeight);
    positionObject && setDialogPosition(positionObject);
  }, [open]);

  if(!children) return null;

  return dialogOpen
    ? ReactDOM.createPortal(
      <div css={style.base(theme)({ show })}>
        <div 
          css={style.wrapper(theme)({ fullScreen, maxHeight, dialogPosition, customStyle, isMobile, overflow })}
          {...e2eid ? {"data-e2eid":e2eid } : null}
        >
          <div css={style.container}>

            {
              (title || closeButton) &&
              <div ref={titleAnchorEl} css={style.titleStyle(theme)({ titlePresent: !!title })}>
                {title}
                {
                  (closeButton && !!onClose) &&
                  <div css={style.close} onClick={onClose}>
                    <Icon name={"XmarkSolid"} color={"black"} />
                  </div>
                }
              </div>
            }

            {children}
          </div>
        </div>
        <div css={style.overlay} onClick={() => outClick && onClose()} />
      </div>,
      ROOT
    )
    : null;

};

Dialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  maxHeight: PropTypes.string,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
  outClick: PropTypes.bool,
  anchorEl: PropTypes.object,
  titleAnchorEl: PropTypes.object,
  customStyle: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string
  }),
  overflow: PropTypes.bool,
  title: PropTypes.string,
  e2eid: PropTypes.string
};

Dialog.defaultProps = {
  open: false,
  maxHeight: "350px", // Attualmente nel mop la maxHeight di default è questa
  children: null,
  fullScreen: false,
  onClose: null,
  closeButton: true,
  outClick: true,
  inIframe: false,
  anchorEl: null,
  customStyle: null,
  overflow: false,
  title: null,
  titleAnchorEl: null,
  e2eid: null
};



const Content = ({ children }) => {
  return <>{children}</>;
};

const Footer = ({ children }) => {
  return <div css={style.footerStyle}>{children}</div>;
};


Dialog.Content = Content;
Dialog.Footer = Footer;
