import React from 'react';

export const Spinner = ({ size = "small", classColor }) => {
  return (
    <div className={`tt-spinner-wrapper ${size}`}>
      <svg
        viewBox="0 0 52 52"
        xmlns="http://www.w3.org/2000/svg"
        className={`tt-spinner ${classColor ? classColor : ''}`}
      >
        <circle
          stroke="url(#spinner_201)"
          fill="transparent"
          strokeWidth="4"
          cx="26"
          cy="26"
          r="22"
          className="tt-spinner-path"
        ></circle>
        <linearGradient id="spinner_201">
          <stop offset="50%" stopOpacity="1" className="tt-spinner-stop-color"></stop>
          <stop offset="70%" stopOpacity=".5" className="tt-spinner-stop-color"></stop>
          <stop offset="100%" stopOpacity="0" className="tt-spinner-stop-color"></stop>
        </linearGradient>
      </svg>
    </div>
  );
};

export const OldSpinner = ({ size = "small", color = "white" }) => {
  return (
    <div className={`tt-deprecated-loader tt-deprecated-loader--${size} tt-deprecated-loader--${color}`}></div>
  );
};

export const LoaderEllipsis = () => {
  return (
    <span className="tt-loader-ellipsis"></span>
  );
};
