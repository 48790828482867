import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../Button';
import { Dialog } from '../../Dialog';
import { ForwardRef } from '../../ForwardRef';

import * as style from "./style";

import { useButtonContext } from '../Context';

import { TTPolyglot } from "@frontend/tt-polyglot";

import {
  CLICK_ACCEPT,
  CLICK_NEXT,
  CLICK_PREV
} from "../constants";

/**
 * Primary UI component for user interaction
 */
export const BookButtonDesktop = ({ data }) => {

  const buttonRef = useRef(null);

  const { handleClick, click, disabled } = useButtonContext();

  return (
    <div css={style.base}>

      <ForwardRef ref={buttonRef}>
        <Button e2eid="summary-book-btn" onClick={() => handleClick(CLICK_NEXT)} disabled={disabled}>{data.firstStepButtonTitle}</Button>
      </ForwardRef>

      <Dialog
        e2eid={"summary-book-payment-dialog"}
        closeButton
        outClick
        open={click === CLICK_ACCEPT}
        onClose={() => handleClick(CLICK_PREV)}
        anchorEl={buttonRef?.current}
        title={data.title}
      >
        <Dialog.Content>
          {data.description}
        </Dialog.Content>
        <Dialog.Footer>
          <Button e2eid="summary-book-dialog-cancel" type={"light"} onClick={() => handleClick(CLICK_PREV)}>
            {TTPolyglot.t("Cancel")}
          </Button>
          <Button e2eid="summary-book-dialog-proceed" onClick={() => handleClick(CLICK_NEXT)}>
            {data.secondStepButtonTitle}
          </Button>
        </Dialog.Footer>
      </Dialog>

    </div>

  );
};

BookButtonDesktop.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    firstStepButtonTitle: PropTypes.string,
    secondStepButtonTitle: PropTypes.string
  }).isRequired
};

BookButtonDesktop.defaultProps = {
  data: null
};