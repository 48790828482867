import React from 'react';
import MOP from 'app';
import LanguageTab from './LanguageTab';
import ProfileTab from './ProfileTab';
import SupportTab from './SupportTab';

export const RightMenu = ({ menuComponents }) => {

  if (!menuComponents) return null;

  const { profile, support, languages } = menuComponents;

  return (
    <>
      <div className="tt-navbar-menu-user">
        <ProfileTab profile={profile} />
        <SupportTab support={support} />
        {languages && <LanguageTab languages={languages} />}
      </div>
    </>
  );
};