
import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ color, size, weight, onClick, fontFamily }) => css({
  color: onClick ? theme.colors.link : theme.colors[color],
  cursor: onClick ? "pointer" : "inherit",
  fontSize: theme.fonts.size[size] || theme.fonts.size.standard,
  fontWeight: theme.fonts.weight[weight] || theme.fonts.weight.regular,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.layout.gap.small,
  fontFamily: theme.fonts.fontFamily[fontFamily] || theme.fonts.fontFamily.inter
});

export const text = theme => ({ }) => css({
  // serve perchè se il testo risulta troppo lungo, senza questa proprietà va a ridurre la 
  // grandezza dell'icona (anche se l'icona ha una sua larghezza fissata). con questa proprietà
  // lo span che contiene il testo non si estende in modo eccessivo
  width: "fit-content",
  // gestisco il text overflow
  minWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const blankIcon = theme => ({ size }) => css({
  width: theme.fonts.size[size] || theme.fonts.size.standard
});