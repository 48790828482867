import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";


export const Avatar = ({ src }) => {

  const theme = useTheme();

  return (
    <img css={style.base(theme)} src={src} />
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  src: null,
};