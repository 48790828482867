import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ }) => css({
  width: "55px",
  display: "flex",
  gap: theme.layout.gap.small,
  justifyContent: "center",
  cursor: "pointer",
  height: theme.layout.baseHeights.normal,
});