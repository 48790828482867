import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { Scrollable } from './Scrollable';

import { useMediaQuery } from "react-responsive";

/**
 * Primary UI component for user interaction
 * 
 * viewportAware se TRUE, allora l'eventuale width non verrà considerato, ma verranno considerate le grandezze del viewport
 */
export const Container = ({ backgroundColor, border, children, hideScrollbar, padding, scrollable, shadow, width, height, viewportAware, e2eid}) => {

  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  return scrollable? (
    <Scrollable
      e2eid={e2eid}
      backgroundColor={backgroundColor}
      border={border}
      children={children}
      height={height}
      hideScrollbar={hideScrollbar}
      padding={padding}
      shadow={shadow}
      width={width}
    >
      {children}
    </Scrollable>
  ) : (
    <div {...e2eid ? {"data-e2eid":e2eid } : null} css={style.base(theme)({backgroundColor, border, height, padding, shadow, width, viewportAware, isMobile})}>
      {children}
    </div>
  );
};

Container.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  hideScrollbar: PropTypes.bool,
  padding: PropTypes.oneOf(["micro", "xsmall", "small", "standard", "medium", "large"]),
  scrollable: PropTypes.bool,
  shadow: PropTypes.bool,
  border: PropTypes.oneOf(["standard"]),
  width: PropTypes.string,
  viewportAware: PropTypes.bool
};

Container.defaultProps = {
  backgroundColor: "white",
  height: null,
  hideScrollbar: false,
  children: <></>,
  border: null,
  padding: null,
  scrollable: false,
  shadow: false,
  width: null,
  viewportAware: false
};