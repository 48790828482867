export const fonts = {
  fontFamily: {
    roboto: "Roboto, sans-serif",
    workSans: "Work Sans",
    inter: "Inter"
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },

  size: {
    small: "0.813rem", // 13px
    standard: "0.875rem", // 14px
    medium: "0.938rem", // 15px
    large: "1.125rem", // 18px
    xl: "1.5rem", // 24px
    xxl: "1.625rem", // 26px
  },
};
