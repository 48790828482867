import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import dayjs from 'dayjs';
import Utilities from 'common/utilities';
import common_templates from 'templates/common/templates';

import { TTPolyglot } from "@frontend/tt-polyglot";

    function initializeFastClick(params) {
      var doc = params.document;

      doc.addEventListener('DOMContentLoaded', function () {
          FastClick.attach(document.body);
      }, false);
    }

    // Non più utilizzata
    function initializeMobileCss(params) {
      var MOP = params.MOP;
    }

    function initializeGoogleAnalytics (params) {

      var MOP = params.MOP;
      var win = params.window;

      //Plugin Cordova Google Analytics
      if (!MOP.Utilities.empty(win.ga) && typeof win.ga === Object) {
        if (!MOP.Utilities.empty(win.MOP_globals.customerConfig.googleAnalytics)) {
          var dispatchPeriod = 30;
          win.ga.startTrackerWithId(win.MOP_globals.customerConfig.googleAnalytics, dispatchPeriod);
          win.ga.setAppVersion(MOP.config.get('version'));
        }
      }
    }

    function initializeNetworkInformation (params) {
      var MOP = params.MOP;

        document.addEventListener("online", () => {
          if(!MOP.state.networkReachability) {
            MOP.Utilities.disconnectAlertInformation(MOP, () => location.reload());
          }
          MOP.state.networkReachability = true;
        });

        document.addEventListener("offline", () => {
          MOP.state.networkReachability = false
        });
    }



    function initializePush(params) {
      var MOP = params.MOP;
      var MAM = params.MAM;
      var doc = params.window.document;
      var win = params.window;

      // [FIXME] @Mattia Trova un punto migliore in cui metterlo.
      if (MOP.Utilities.isMobileApp(true) && cordova && cordova.InAppBrowser) {
          window.open = cordova.InAppBrowser.open;
      }

      MOP.CC = win.MOP_globals.customerConfig;
      MOP.MAM.attachPushNotification();
      
      //Questo evento non serve più per le push, ma serve solo per paymentAnchor che viene utilizzato se dall'app andiamo in un
      //pagamento esterno, quando riapriamo l'app portiamo l'utente alle reservations
      doc.addEventListener('resume', MAM.resumeAppHandler, false);
    }

    function forbidAndroidBackButton (params) {
      var MOP = params.MOP;
      var doc = params.document;

      doc.addEventListener("backbutton", function(e){
          e.preventDefault();
          
          // Per ora andiamo a disabilitare per tutti il tasto back di android in modo da utilizzare quello che c'è in interfaccia per evitare problemi
          // if(MOP.getCurrentPage() == "reservations" && MOP.getCurrentRoute().indexOf("print") != -1 && !MOP.isLoggedIn()){
          //     return;
          // } else {
          //   window.history.back();
          // }
          return;
      }, false);
    }

    function addAppEvents (params) {
      const MOP = params.MOP;

      document.addEventListener("resume", () => {
        MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_RESUME_APP, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""});
      }, false);

      document.addEventListener("pause", () => {
        MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_CLOSE_APP, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""});
      }, false);
    }

    var CheckinBanner = function (params) {
      var MOP = params.MOP;

      /**
       * Return the first reservation on wich is possible made checkin
       * @access private
       * @param  {[Object]} reservations - array of reservations
       * @return {Object} reservation - the first reservation valid
       */
      function firstCheckinableReservation(params) {
        var reservations = params.collection;
        var position = params.position;

        /**
         * Filter a reservation based on position
         * @access private
         * @param  {Object} res - a single reservation
         * @return {Object}
         */
        function filterByPosition(res) {
          return (Utilities.myPositionIn(position, { latitude: parseFloat(res.latitude), longitude: parseFloat(res.longitude) }, 0.25));
        }

        var filter = _.partial(_.filter, _, filterByPosition);

        return _.compose(_.first, filter)(reservations);
      }

      function buildCheckinDialog(model) {
        var Dialog = Backbone.Marionette.ItemView.extend({
          template: Handlebars.templates.checkin_dialog,
          events: {
            'click #checkin_button': 'sendCheckin',
            'click #checkedin_close_button': 'destroyDialog'
          },

          serializeData: function () {

            var loggedUser = MOP.getLoggedUserData();
            return { 
              checkin_msg: TTPolyglot.t('App Checkin Banner', [model.activityTitle, model.startTime, model.resourceName]),
              checkedin_msg: TTPolyglot.t('Checkin Kiosk Success', {
                    3: loggedUser.fname,
                    4: loggedUser.lname,
                    5: model.resourceName,
                    69: model.checkin_info
                })
            };
          },

          sendCheckin: function () {  
            $("#checkin_dialog_button_container").hide();
            $("#checkin_dialog_checkin_msg").hide();
            $("#checkin_dialog_loader_container").show();
            
            var _this = this;
            
            var data = {};
            data.checkin_code = model.checkin_code;
            data.resid = model.resid;
            data.resourceName = model.resourceName;
            data.checkin_info = model.checkin_info;
            
            // La logica della callCheckin è stata cambiata a seguito del nuovo checkin per viamed
            // tutta questa parte dell'app dovrà essere adattata al nuovo flusso. 
            // Per mancanza di tempo non l'abbiamo aggiornato nello sviluppo ma apriremo uno sviluppo interno
            // Per fare questo adattamento. 
            // In ogni caso il prodotto ha deciso di spegnere tale flusso spegnendo il config doMobileCheckInByLocation
            // Quindi nessuno dovrebbe arrivare qui
            MOP.callCheckin(data, true)
            .done(function () {
              $("#checkin_dialog_loader_container").hide();
              $("#checkin_dialog_checked_msg").show();
              $("#checked_dialog_button_container").show();
            })
            .fail(function (msg) {
              $("#checkin_dialog_loader_container").hide();
              $("#checkin_dialog_checked_msg_error_content").html(msg);
              $("#checkin_dialog_checked_msg_error").show();
              $("#checked_dialog_button_container").show();
            });
            
          },

          destroyDialog: function () {
            MOP.execute('MOP:dialog:destroy');
          }
        });

        return new Dialog();
      }

      /**
       * It execute the fetch of the reservations.
       * @access public
       * @return {Deferred} [description]
       */
      function init() {
        var result = new $.Deferred();

        var ReservationsSearchObject = require('models/reservations_search_object').default;
        var _entities_reservation = require('entities/reservation');
        var reservations = MOP.request('reservation:entities:new');

            /**
             * It verify the correct execution of the AJAX call.
             * @access private
             * @param  {Object} resp - AJAX response
             * @return {Object|Boolean} - the response itself or false
             */
            function _checkReservationsResponse(resp) {
              if (resp && resp.result && resp.result !== 'OK') {
                return false;
              }

              return resp;
            }

            var reservationsSearchObject = new ReservationsSearchObject();

            reservationsSearchObject.set('pager_limit', 0);
            reservationsSearchObject.set('checkinable', 1);

            reservations.fetch(MOP, {
                querystring: reservationsSearchObject.getFiltersToSearch(MOP),
                bypassCheckResponse: true
            })
            .then(function (collection, resp) {
              var deferred = new $.Deferred();

              if (_checkReservationsResponse(resp)) {
                deferred.resolve(collection);
              } else {
                deferred.reject();
              }

              return deferred;
            })
            .done(function (collection) {
              MOP.config
                .geolocatorFactory()
                .loadCoordinates()
                .done(function (res) {
                  var myPos = { latitude: res.coords.latitude, longitude: res.coords.longitude };
                  result.resolve({ reservations: collection, myPosition: myPos });
                });
            })
            .fail(function () {
              result.reject();
            });

        return result;
      }

      /**
       * Build the dialog and display it.
       * @access public
       * @param  {Array} collection - array of reservations
       * @return {Boolean}
       */
      function run(param) {
        var collection = param.reservations;
        var myPosition = param.myPosition;
        var model = firstCheckinableReservation({ collection: collection.toJSON(), position: myPosition });
        var showCheckinCode = parseInt(MOP.config.getInstanceConfig('showCheckinCode'), 10);

        if (model) {
          var dialog = buildCheckinDialog(model)
          setTimeout(function () { 
            MOP.execute('MOP:dialog:show', dialog);
          }, 0);
        }

        return true;
      }

      return {
        init: init,
        run: run
      };
    };

    export default function (MOP) {
        var MAM = {
            pushNotification: undefined,

            init: function init( options ) {
              initializeMobileCss({ MOP: MOP });
              if (MOP.Utilities.isMobileApp(true)){
                initializeFastClick({ document: document });
                initializePush({MOP: MOP, MAM: MAM, window: window});
                initializeGoogleAnalytics({MOP: MOP, window: window});
                forbidAndroidBackButton({ MOP: MOP, document: document});
                initializeNetworkInformation({MOP:MOP});
                  addAppEvents({MOP: MOP});
              }
              
              var device_uuid = "";
              var memberid = "";
              
              if (MOP.Utilities.isMobileApp(true)) {
                MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_ACCESS, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""})
              }
              
            },

            initCheckinBanner: function () {
              if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('doMobileCheckInByLocation'))) {
                if (MOP.isLoggedIn() && !MOP.isAdminLoggedIn()) {
                  var checkinBanner = CheckinBanner({ MOP: MOP });
  
                  checkinBanner
                    .init()
                    .done(checkinBanner.run);
                }
              }
            },

            /**
             * @returns {window.plugins.pushNotification}
             */
            getPushNotification: function() {
                return this.pushNotification;
            },

            /**
             * @returns {Object|undefined}
             */
            getDeviceObject: function() {
                return window.device;
            },

            /**
             * Check if a specified key exist in the device object
             *
             * @param {String} key
             * @returns {Boolean}
             */
            deviceKeyExist: function(key) {
                var device = this.getDeviceObject();

                return device && device[key];
            },

            /**
             * Returns the device key value
             *
             * @param {String} key
             * @returns {String|null}
             */
            getDeviceData: function(key) {
                if (this.deviceKeyExist(key)) {
                    return this.getDeviceObject()[key];
                }

                return null;
            },

            /**
             * Returns all the device keys values required
             *
             * @param {String[]} keys - array of key required
             * @returns {Object} - object containing all data required in the input array
             */
            getDeviceDatas: function(keys) {
                var _this = this;

                return _.reduce(keys, function(acc, key) {
                    acc['device_' + key] = _this.getDeviceData(key);
                    return acc;
                }, {});
            },

            /**
             * Initialize the pushNotification object
             */
            attachPushNotification: function() {
              
              var push = PushNotification.init(
                { 
                  "android": {},
                  "ios": {"alert": "true", "badge": "true", "sound": "true"}
                }
              );
              
              push.on('registration', function(data) {
                  // data.registrationId
                  MAM._setDeviceAttribute('token', data.registrationId);
              });
              
              push.on('notification', function(data) {
            
                if(!data.additionalData.custom_mop_reason){
     
                  //Old api
              
                // data.message,
                // data.title,
                // data.count,
                // data.sound,
                // data.image,
                // data.additionalData
                
                let _page = "notifications";
                let _route = "";

                if (data.additionalData && data.additionalData.custom && data.additionalData.custom.mop && data.additionalData.custom.mop.forcePage) {
                  if (!MOP.Utilities.empty(data.additionalData.custom.mop.forcePage)) {
                    _page = data.additionalData.custom.mop.forcePage;
                  }

                  if (!MOP.Utilities.empty(data.additionalData.custom.mop.forceRoute)) {
                    _route = data.additionalData.custom.mop.forceRoute;
                  }
                }

                // Foreground = Primo piano
                //L'app è aperta quindi notifichiamo l'utente con un alert della notifica, e gli chiediamo se vuole andare ai messaggi?
                if (data.additionalData && data.additionalData.foreground && data.additionalData.foreground == true) {

                  function onConfirm(buttonIndex) {
                    if (buttonIndex == 2) {
                      MOP.changePage(_page,_route);
                    }
                  }
                  
                  let confirmDialogMessage = "Go To Notifications";

                  if (_page === "delay") confirmDialogMessage = "Go To Delay";
                  if (_page === "reservations") confirmDialogMessage = "Go To Appointments";

                  navigator.notification.confirm(
                      data.message,                             // message
                      onConfirm,                                     // callback
                      MOP.config.getInstanceConfig('title'),    // title
                      [TTPolyglot.t("OK", [], true), TTPolyglot.t(confirmDialogMessage, [], true)]   //buttonLabels
                  );
                  
                  //L'app era o chiusa o in background, se sono qui vuol dire che l'utente ha fatto uno swipe sulla push, quindi lo porto alla giusta pagina
                } else {
                  // Mettiamo un piccolo timeout perchè altrimenti su Android con app completamente chiusa non va alla pagina
                  setTimeout(function () {
                      MOP.changePage(_page,_route);
                  }, 300);
                }  


              } else{
                // new api                     
                let _page = "notifications";
                let _route = "";


                if (data.additionalData && data.additionalData.custom_mop_forcePage) {
                  if (!MOP.Utilities.empty(data.additionalData.custom_mop_forcePage)) {
                    _page = data.additionalData.custom_mop_forcePage;
                  }

                  if (!MOP.Utilities.empty(data.additionalData.custom_mop_forceRoute)) {
                    _route = data.additionalData.custom_mop_forceRoute;
                  }
                }

                // Foreground = Primo piano
                //L'app è aperta quindi notifichiamo l'utente con un alert della notifica, e gli chiediamo se vuole andare ai messaggi?
                if (data.additionalData && data.additionalData.foreground && data.additionalData.foreground == true) {

                  function onConfirm(buttonIndex) {
                    if (buttonIndex == 2) {
                      MOP.changePage(_page,_route);
                    }
                  }
                  
                  let confirmDialogMessage = "Go To Notifications";

                  if (_page === "delay") confirmDialogMessage = "Go To Delay";
                  if (_page === "reservations") confirmDialogMessage = "Go To Appointments";

                  navigator.notification.confirm(
                      data.message,                             // message
                      onConfirm,                                     // callback
                      MOP.config.getInstanceConfig('title'),    // title
                      [TTPolyglot.t("OK", [], true), TTPolyglot.t(confirmDialogMessage, [], true)]   //buttonLabels
                  );
                  
                  //L'app era o chiusa o in background, se sono qui vuol dire che l'utente ha fatto uno swipe sulla push, quindi lo porto alla giusta pagina
                } else {
                  // Mettiamo un piccolo timeout perchè altrimenti su Android con app completamente chiusa non va alla pagina
                  setTimeout(function () {
                      MOP.changePage(_page,_route);
                  }, 300);
                }  

              }
                  
              });
              
              push.on('error', function(e) {
                  // e.message
                  //Per adesso non serve gestirlo
              });
              
              /* Per le push è possibile gestire sia l'audio (aggiungendo un file audio dentro al progetto che corrisponde 
                 al nome del file dentro al payload della push) che il badge, usano l'API della libreria per incrementarlo
              */
            },

            resumeRedirect: function resumeRedirect() {
                if (MOP.localStorage.has('paymentAnchor')) {

                    if (MOP.getCurrentPage() === 'reservations') {
                        document.location.reload();
                    }
                    else {
                        MOP.changePage('reservations');
                    }
                }
                MOP.localStorage.unset('paymentAnchor');
            },

            resumeAppHandler: (function resumeAppHandler () {
                var timeout = 0;

                return function (paramTimeout) {
                    timeout  = paramTimeout || timeout;
                    setTimeout(function () {
                        MAM.resumeRedirect();
                    }, 300);
                };
            }()),

            /**
             * Set a new attribute in the window.device object if this exist
             *
             * @param {String} key
             * @param {String|Number} value
             * @private
             * @return {null|Object}
             */
            _setDeviceAttribute: function (key, value) {
                if (window.device && !window.device[key]) {
                    window.device[key] = value;

                    if (MOP.isLoggedIn()) {
                      MOP.getLoggedUser().updateDeviceDatas(MAM.getDeviceDatas(['model', 'platform', 'uuid', 'version', 'token']));
                    }
                    return window.device;
                }

                return null;
            },

            showContactus: (function() {
                var template;
                var center_description;
                var adminPhone;
                var adminEmail;
                var schedule_info;
                var templateFilled;

                // Cacho template e dati.
                return function(areas) {

                    // Forzo la chiusura del menu laterale.
                    $('body').click();
                    MOP.TM.unblockLoading();

                    if (!template) {
                        template = Handlebars.templates.contactus;
                        center_description = MOP.config.getInstanceConfig('mopContactsDescription');
                        adminPhone = MOP.config.getInstanceConfig('adminPhone');
                        adminEmail = MOP.config.getInstanceConfig('adminEmail');
                        schedule_info = MOP.config.getInstanceScheduleInfo();

                        templateFilled = template({
                            areas: areas.toJSON(),
                            center_description: center_description,
                            adminPhone: adminPhone,
                            adminEmail: adminEmail,
                            schedule_info: schedule_info,
                            translations: {
                                email: TTPolyglot.t('email'),
                                MOPCall: TTPolyglot.t('MOP Call')
                            }
                        });
                    }

                    var $contactus_container = $('#contactus-container');

                    if ($contactus_container.hasClass('hide')) {
                        $contactus_container.html(templateFilled);
                        $('body').addClass('find');
                    } else {
                        $('body').removeClass('find');
                    }

                    MOP.TM.hide('.header-page');

                    MOP.TM.toggleMainRegion('#contactus-container');
                };
            }()),

            startContactus: function startContactus() {
              var entities_area = require('entities/area');
              MOP.request('area:entities')
              .done(function(areas) {
                  MAM.showContactus(areas);
                  MOP.loadingRegion.reset();
              });
            }
        };

        return MAM;
    };
