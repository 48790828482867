import React, { createRef } from 'react';

export const OutClick = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        open: false
      };

      /*
        Outside Click Handler
      */
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);

      this.toggleOpen = this.toggleOpen.bind(this);

    }




    /*
      Outside Click Handler
    */
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside(e) {
      const { open } = this.state;
      if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
        if (open === true) {
          this.setState({ open: false }, () => document.removeEventListener('mousedown', this.handleClickOutside));
        }
        return;
      }
    };

    /*
      Outside Click Handler
    */
    setWrapperRef(node) {
      this.wrapperRef = node;
    }

    toggleOpen() {
      const { open } = this.state;
      this.setState({ open: !open }, () => {
        if (this.state.open) {
          document.addEventListener('mousedown', this.handleClickOutside);
        }
      });
    }

    render() {
      return <WrappedComponent hocRef={this.setWrapperRef} {...this.props} open={this.state.open} onClick={this.toggleOpen} />;
    }
  };
};