import React from 'react';
import Header from './components/Header';
import BottomBar from './components/BottomBar';
import SideMenu from './components/SideMenu';

export const Mobile = () => {
  return (
    <>
      <Header />
      <SideMenu />
      <BottomBar />
    </>
  );
};