import { css } from "@emotion/react";

export const base = imgSrc => css({
  background: `url(${imgSrc})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  border: "none",
  display: "block",
  height: "33px",
  width: "109px",
});