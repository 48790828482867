import { css } from "@emotion/react";

export const inputContainer = theme => ({ disabled }) => css({
  ...theme.input.container.standard.normal,
  ...(disabled && theme.input.container.disabled),
  gap: "0px",
});


export const input = theme => ({ disabled }) => css({
  ...theme.input.base.standard,
  ...(disabled && theme.input.base.disabled),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const button = theme => ({ disabled }) => css({
  ...theme.button.base,
  ...(disabled && theme.button.disabled),
  padding: "0px",
  paddingRight: theme.layout.padding.standard,
  paddingLeft: theme.layout.padding.standard,
  color: theme.colors.link
});
