import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { Icon } from '../Icon';
import { useTheme } from '@emotion/react';
import { Label } from "../Label";

export const Input = forwardRef(({ type, size, icon, placeholder, onChange, charsLimit, error, disabled, value, isRequired, keySubmit, e2eid }, ref) => {

  const theme = useTheme();

  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(value || '');
  }, [value]);

  const onlyNumbersValidate = val => {
    const re = /^[0-9\b]+$/;
    return re.test(val);
  };


  const handleOnChange = event => {
    event.preventDefault();

    const { target: { value: _val } } = event;

    if ((_val !== "" && type === "number" && !onlyNumbersValidate(_val) && _val.length > val.length) || (!isNaN(parseInt(charsLimit, 10)) && _val.length > charsLimit && _val.length > val.length)) {
      setVal(val);
    } else {
      setVal(_val);
      onChange && onChange(_val);
    }


  };

  const handleOnKeyPress = event => {
    // Se passo la callback keysubmit allora devo gestire il submit attraverso il pulsante invio
    if (!keySubmit) return;

    const { which, keyCode } = event;

    if (which === 13 || keyCode === 13) {
      keySubmit(val);
    }
    // A questo punto se ho passato il keySubmit vado a vedere se ho premuto enter. se ho premuto enter allora devo chiamare la callback keySubmit
  };

  const renderIcon = icon => {
    if (!icon) return null;
    return (
      <div css={style.iconContainer(theme)}>
        <Icon name={icon} color={"primary"} size={"medium"} />
      </div>
    );
  };

  return (
    <div css={style.base}>

      <div css={style.inputContainer(theme)({ error: !!error, disabled, size })}>
        <div css={style.containerContent}>
          {icon && renderIcon(icon)}
          <input
            ref={ref}
            css={style.input(theme)({ error: !!error, disabled, isRequired })}
            onChange={handleOnChange}
            placeholder={placeholder}
            maxLength={charsLimit}
            value={val}
            disabled={disabled}
            onKeyUp={handleOnKeyPress}
            {...e2eid ? {"data-e2eid":e2eid } : null}
          />
        </div>
        {
          isRequired &&
          <div css={style.containerRequired}>
            *
          </div>
        }

      </div>

      {!!error && <Label color={"danger"}>{error}</Label>}
    </div>
  );
});

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]),
  icon: PropTypes.string,
  onChange: PropTypes.func,
  keySubmit: PropTypes.func,
  size: PropTypes.oneOf(["small", "normal", "big"]),
  // il charsLimit va bene sia come stringa che come numero, l'input accetta entrambi i tipi
  // in APP alcune volte viene passato un numero, ed altre volte viene passata una stringa
  charsLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  e2eid: PropTypes.string
};

Input.defaultProps = {
  placeholder: "Placeholder",
  type: "text",
  icon: null,
  onChange: null,
  keySubmit: null,
  size: "normal",
  charsLimit: null,
  error: null,
  disabled: false,
  value: "",
  isRequired: false,
  e2eid: null
};
