import React from 'react';
import MOP from 'app';
import { calcAge, getFullName } from 'models/_user';

import { TTPolyglot } from "@frontend/tt-polyglot";

export const UserInfo = ({ loggedUser }) => {

  if (!loggedUser) return null;

  const { gender, birthday, city } = loggedUser;

  return (
    <div className="tt-bottom-nav-collapsed-content-user" data-e2eid="side-menu-user-info">
      <div className="tt-bottom-nav-collapsed-content-user-avatar">
        <i className="tt-icon-user-circle"></i>
      </div>
      <div className="tt-bottom-nav-collapsed-content-user-info">
        <div className="tt-bottom-nav-collapsed-content-user-info-name">{getFullName(loggedUser)}</div>
        <div className="tt-bottom-nav-collapsed-content-user-info-details">
          {!MOP.Utilities.empty(gender) ?
            <>
              {gender === 'M' ? TTPolyglot.t("MOP Gender Male") : TTPolyglot.t("MOP Gender Female")}
            </> : null
          }

          {birthday && <><span> · </span> {TTPolyglot.t("MOP User Age", { 0: calcAge(loggedUser) })} </>}

          {city && <><span> · </span> {city} </>}
        </div>
      </div>
    </div>
  );
};