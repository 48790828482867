import MOP from 'app';
import ShowController from 'apps/header/marionette/controller';

MOP.module('HeaderApp', (HeaderApp, MOP, Backbone, Marionette, $, _) => {
  HeaderApp.page = 'header';

  HeaderApp.onStart = function () {
    MOP.Utilities.log(MOP, 'starting HeaderApp');
  };

  HeaderApp.onStop = function () {
    MOP.Utilities.log(MOP, 'stopping HeaderApp');
  };
});

MOP.module('Routers.HeaderApp', (HeaderAppRouter, MOP, Backbone, Marionette, $, _) => {
  HeaderAppRouter.Router = Marionette.AppRouter.extend({
    appRoutes: {}
  });

  const API = {
    showHeader: forceDisabled => {
      ShowController.showHeader({forceDisabled: forceDisabled});
    },

    loadRightPage: () => {
      MOP.changePage();
    }
  };

  MOP.on('header:show', forceDisabled => {
    API.showHeader(forceDisabled);
  });

  MOP.addInitializer(() => {
    API.showHeader();

    new HeaderAppRouter.Router({
      controller: API
    });
  });
});

export default MOP.HeaderApp;
