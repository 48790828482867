import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ color, backgroundColor }) => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontSize: theme.fonts.size.standard,
  fontWeight: theme.fonts.weight.regular,

  backgroundColor: theme.colors[backgroundColor] || theme.colors.black,
  color: theme.colors[color] || theme.colors.black,
  width: "fit-content",
  padding: theme.layout.padding.micro,
  borderRadius: theme.layout.borderRadius.small,

});