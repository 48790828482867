import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ color }) => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontSize: theme.fonts.size.medium,
  fontWeight: theme.fonts.weight.bold,

  // Nei tag il colore deve essere impostato manualmente con un esadecimale, perchè questi colori in App
  // Tornano direttamente dal BE come stringhe esadecimali
  backgroundColor: color || theme.colors.black,
  color: theme.colors.white,
  width: "fit-content",
  padding: theme.layout.padding.micro,
  borderRadius: theme.layout.borderRadius.small,
  maxWidth: "100%"
});

export const content = theme => ({ color }) => css({

  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"

});
