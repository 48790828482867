import { css } from "@emotion/react";
import chroma from "chroma-js";

export const overlay = theme => css({
  backgroundColor: chroma(theme.colors.black).alpha(0.5).hex(),
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: theme.layerIndex.navigation,
});

export const container = theme => css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: theme.layout.gap.standard
});


export const wrapper = theme => css({
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  width: "100%",
  padding: theme.layout.padding.medium,
  boxShadow: `0px -4px 12px ${theme.colors.spanish_gray}`,
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
  zIndex: theme.layerIndex.bookButtonMobile,
  borderRadius: `${theme.layout.borderRadius.large} ${theme.layout.borderRadius.large} 0px 0px`
});


export const button = theme => ({ disabled }) => css({

  ...theme.button.base,
  ...(disabled && theme.button.disabled),

  height: theme.layout.baseHeights.normal,
  width: "100%",

  backgroundColor: theme.colors.primary,
  color: theme.colors.white
});

export const content = theme => ({ visible }) => css({

  display: "flex",
  flexDirection: "column",
  // Se la descrizione è nascosta, allora non vogliamo alcun GAP altrimenti c'è troppo spazio
  // tra il testo ed il pulsante (il gap c'è lo stesso anche se l'elemento ha altezza 0)
  // L'unico modo per toglierlo è condizionare il gap alla visibilità della descrizione
  gap: visible ? theme.layout.gap.small : "0px",
  transition: "gap 150ms linear"
});

export const visible = theme => ({ }) => css({
  maxHeight: "50vh",
  overflow: "hidden",
  transition: "max-height 500ms ease-in-out"
});

export const hidden = theme => ({}) => css({
  maxHeight: "0vh",
  overflow: "hidden",
  transition: "max-height 150ms ease-in-out"
});

