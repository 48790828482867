
import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const iframe = theme => ({ }) => css({
  width: "100%",
  border: "none",
  margin: "0",
  padding: "0",
  overflow: "hidden"
});

export const loading = theme => ({ }) => css({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  alignContent: "center"
});

