import { css } from "@emotion/react";


export const base = theme => ({size}) => css({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
  width: "100%",
});

export const inputContainer = theme => ({ error, disabled, size }) => css({
  ...theme.input.container.standard[size],
  ...(error && theme.input.container.error),
  ...(disabled && theme.input.container.disabled)
});


export const input = theme => ({ error, disabled, isRequired }) => css({
  ...theme.input.base.standard,
  ...(error && theme.input.base.error),
  ...(disabled && theme.input.base.disabled),

  // Se l'input ha il required, andiamo a togliere il padding right visto che ci sarà l'asterisco che occupa spazio
  // altrimenti poi si avrebbero delle spaziature troppo estese
  paddingRight: isRequired ? "0px" : "" 
});

export const containerContent = theme => ({}) => css({
  display: "flex",
  flexDirection: "row",
  gap: 2,
  flexGrow: 1,
});

export const containerRequired = theme => ({}) => css({
  display: "flex",
  color: theme.colors.primary,
  fontSize: theme.fonts.size.large,
  width: "1.625rem",
  height: "17px",
  alignSelf: "center"
});


export const iconContainer = theme => css({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "0.5rem 0.5rem 0.5rem 1.125rem",
  width: "2.375rem"
});


