import MOP from 'app';
import { createToken } from 'api/tokens';

import { TTPolyglot } from "@frontend/tt-polyglot";

import { isPaymentCompulsory } from 'common-mop/reservations';
import { getPaymentTimeout } from 'api/reservations';

import { getZipCodePlaceholderFromPrefix, validateZipCode } from 'mop-utils/countries';
import { putUser } from 'api/users';

export const paymentHandler = (id, userid, type, route, notification = null, goToExternalPage = null, additionalData = {}) => {

  if (MOP.Utilities.isMobileApp()) {
    let entity;
    let token_reason;
    if (type === MOP.constants.PAYMENT_RESERVATION) {

      entity = MOP.constants.GET_TOKEN_ENTITY_RESERVATION;
      token_reason = MOP.constants.TOKEN_REASON_PAYMENT_RESERVATION;

    } else if (type === MOP.constants.PAYMENT_DOCUMENT) {

      entity = MOP.constants.GET_TOKEN_ENTITY_DOWNLOAD;
      token_reason = MOP.constants.TOKEN_REASON_PAYMENT_DOWNLOAD;

    }

    return createToken(entity, id, token_reason, true)
      .then(data => {

        let url = `https://${MOP.config.get('hostname')}/mop/index.php?token=${data.token}&dbName=${MOP.getDbName()}&token_dbName=${MOP.getDbName()}&auth_mode=${MOP.constants.AUTH_MODE_FROM_PAYMENT}&forcePage=payment&forceRoute=${route}&schemaUrl=${window.MOP_globals.customerConfig.schemaUrl}`;

        // Per il pagamento del documento aggiungiamo un parametro
        if (type === MOP.constants.PAYMENT_DOCUMENT) {
          url += '&pay_document=1';
        }

        // if (MOP.Utilities.isAppleDevice()) {

        SafariViewController.isAvailable(available => {
          if (available) {
            SafariViewController.show({
              url: url,
              hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
              animated: false, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
              transition: 'slide', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
              tintColor: `#${MOP.config.getInstanceConfig('mop_buttonBckgnd')}`, // default is ios blue
              barColor: `#${MOP.config.getInstanceConfig('mop_buttonBckgnd')}`, // on iOS 10+ you can change the background color as well
              controlTintColor: `#ffffff` // on iOS 10+ you can override the default tintColor
            },
              // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
              result => {
                if (result.event === 'opened') {
                } else if (result.event === 'loaded') {
                } else if (result.event === 'closed') {
                  // Se è stato spinto il tasto Done allora potrebbe non essere stato completato il pagamento quindi lo riportiamo nella stampa 
                  // appuntamento per le Reservation e in quella dell'episodio per i documenti
                  if (type === MOP.constants.PAYMENT_RESERVATION) {
                    return MOP.goToPrintReservation(id, userid, null, true);
                  } else if (type === MOP.constants.PAYMENT_DOCUMENT) {
                    const { id_episode } = additionalData;
                    return MOP.changePage('dossier', `dossier/user/${userid}/episodes/${id_episode}`, null, null, null, null, null, null, null, null, true);
                  }
                }
              },
              msg => {
                console.log(`msg: ${msg}`);
              });
          } else {
            alert(TTPolyglot.t("MOP App Device not supported for payment"));
          }
        });

        // } else {

        //   cordova.plugins.browsertab.isAvailable(
        //     result => {
        //       if (!result) {
        //         alert(TTPolyglot.t("MOP App Device not supported for payment"));
        //       } else {
        //         cordova.plugins.browsertab.openUrl(
        //           url,
        //           successResp => {
        //             console.log(successResp);
        //           },
        //           failureResp => {
        //             console.log(failureResp);
        //             alert(TTPolyglot.t("MOP App Device not supported for payment"));
        //           });
        //       }
        //     },
        //     isAvailableError => {
        //       console.log(isAvailableError);
        //       alert(TTPolyglot.t("MOP App Device not supported for payment"));
        //     });

        // }
      })
      .catch(e => {
        console.log(e);
      });

  }

  return MOP.changePage('payment', route, null, null, notification, null, null, null, goToExternalPage);
};

export const getCreditCardIcon = (creditCardType) => {
  switch(creditCardType) {
    case 'visa':
      return 'tt-icon-cc-visa';
    case 'master':
    case 'mastercard':
      return 'tt-icon-cc-mastercard'
    case 'amex':
      return 'tt-icon-cc-amex'
    case 'jcb':
      return 'tt-icon-cc-jcb'
    case 'diners-club':
      return 'tt-icon-cc-diners-club'
    case 'discover':
      return 'tt-icon-cc-discover'
    default:
      return 'tt-icon-credit-card-front'
  }  
};

export const getPaymentHandlerReservationResponseApp = (response, alertType, alertMsg, resid, userid) => {
  return `${MOP.querystring['schemaUrl']}://?auth_mode=${MOP.constants.AUTH_MODE_FROM_PAYMENT}&response=${response}&alertType=${alertType}&alertMsg=${alertMsg}&resid=${resid}&userid=${userid}&type=${MOP.constants.PAYMENT_RESERVATION}`;
}

export const paymentHandlerReservationResponseAppRedirect = (response, alertType, alertMsg, resid, userid) => {
  return location.replace(getPaymentHandlerReservationResponseApp(response, alertType, alertMsg, resid, userid));
};

export const getPaymentHandlerDocumentResponseApp = (response, alertType, alertMsg, episodeid, downloadid, userid) => {
  return `${MOP.querystring['schemaUrl']}://?auth_mode=${MOP.constants.AUTH_MODE_FROM_PAYMENT}&response=${response}&alertType=${alertType}&alertMsg=${alertMsg}&episodeid=${episodeid}&downloadid=${downloadid}&userid=${userid}&type=${MOP.constants.PAYMENT_DOCUMENT}`;
};

export const paymentHandlerDocumentResponseAppRedirect = (response, alertType, alertMsg, episodeid, downloadid, userid) => {
  return location.replace(getPaymentHandlerDocumentResponseApp(response, alertType, alertMsg, episodeid, downloadid, userid));
};

export const isExternalPayment = (payment_method, goToPaymentCheck) => {
  if (!MOP) {
    throw new ReferenceError('The MOP parameter is mandatory');
  }

  // goToPaymentCheck indica che stiamo andando alla pagina del pagamento, se siamo in app tutti i provider
  // esterni escono dall'app, per carta si invece abbiamo gestito come inAppBrowser quindi lo consideriamo interno
  if (goToPaymentCheck && payment_method === MOP.constants.PAYMENT_METHOD_CARTASI) {
    return false;
  }

  if (payment_method === MOP.constants.PAYMENT_METHOD_PAYPAL ||
    payment_method === MOP.constants.PAYMENT_METHOD_CONEKTA ||
    payment_method === MOP.constants.PAYMENT_METHOD_CONEKTA_HOSTED ||
    payment_method === MOP.constants.PAYMENT_METHOD_CARTASI ||
    payment_method === MOP.constants.PAYMENT_METHOD_REDSYS ||
    payment_method === MOP.constants.PAYMENT_METHOD_INGENICO ||
    payment_method === MOP.constants.PAYMENT_METHOD_TRANSBANK) {
    return true;
  }
  return false;
};


export const callPaymentTimeout = async (type, reservation, userid) => {

  // Se non stiamo pagando una reservation oppure stiamo pagando una reservation con pagamento opzionale
  // Torniamo senza far nulla, perchè in questi casi le logiche sul timer non valgono (possiamo procedere con il pagamento)
  if (type !== "reservation" || !isPaymentCompulsory(reservation)) return null;

  // Controllo se il pagamento è ancora valido, richiamando la _payment_timeout
  const { result, msg } = await getPaymentTimeout(reservation.resid);

  if (result !== "OK") {
    // Qui sono nei casi in cui l'appuntamento non è più pagabile, perchè è passato troppo tempo 
    // (oppure è stato cancellato), di conseguenza torno il messaggio di errore
    return ({ type: 'warning', msg });
  }

  return null;

};


export const updateUserBillingInformations = async userid => {

  const mopShowAddressFieldsInPaymentSummary = parseInt(MOP.config.getInstanceConfig('mopShowAddressFieldsInPaymentSummary')) || null;

  // Se il config mopShowAddressFieldsInPaymentSummary è diverso da 1, allora non devo procedere con il flusso dell'address, torno true
  if (mopShowAddressFieldsInPaymentSummary !== 1) return true;

  // Se l'id dell'utente loggato è diverso da quello che abbiamo in querystring, allora stiamo considerando un parente
  // Ci potrebbero essere problemi se una risorsa potesse pagare al posto di un paziente, 
  // (visto che userid !== MOP.getLoggedUserId() anche in quel caso)
  // Ma questo attualmente è un caso d'uso non esistente, quindi non consideriamo questa possibilità
  const isParent = userid !== MOP.getLoggedUserId();


  const showStreetNumber = (
    !MOP.Utilities.empty(MOP.config.getInstanceConfig('showStreetNumberInAddress')) &&
    MOP.config.getInstanceConfig('showStreetNumberInAddress') === "1"
  );
  const defaultPrefix = MOP.config.getInstanceConfig('defaultPrefix');
  const address = document.getElementsByName("address")[0].value;
  const cp = document.getElementsByName("cp")[0].value;
  const city = document.getElementsByName("city")[0].value;
  const country = document.getElementsByName("country")[0].value;

  const dataToUpdate = {
    address: address,
    cp: cp,
    city: city,
    country: country,
  };

  if (showStreetNumber) {
    const street_number = document.getElementsByName("street_number")[0].value;
    dataToUpdate.street_number = street_number;
  }

  // Controlli per country Brasile
  if (defaultPrefix === "+55") {
    const regionEl = document.querySelector('input[name="region"]:checked');
    dataToUpdate.region = regionEl ? regionEl.value : "";

    const neighborhood = document.getElementsByName('neighborhood')[0].value;
    dataToUpdate.neighborhood = neighborhood;
  } else {
    const province = document.getElementsByName("province")[0].value;
    dataToUpdate.province = province;
  }

  let dataToUpdateIsEmpty = false;
  Object.keys(dataToUpdate).map(key => {
    if (dataToUpdate[key] === "") {
      dataToUpdateIsEmpty = true;
    }
  });

  if (dataToUpdateIsEmpty) {
    const alert = { type: "danger", msg: TTPolyglot.t("Mop Billing Informations Fields Empty") };
    MOP.execute("MOP:alert", alert);
    return false;
  }

  // Controllo se è attivo il config per la validazione dello zip code
  if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('mopValidateZipCode'))) {

    const prefix = MOP.config.getInstanceConfig('defaultPrefix');

    const countryPlaceholder = getZipCodePlaceholderFromPrefix(prefix);

    // Se non esiste il validatore per il paese selezionato, allora andiamo a tornare true (skippiamo la validazione)
    const isValid = validateZipCode(prefix, cp);

    if (!isValid) {
      // Lo zip code è in un formato non valido, torno false e stampo a l'alert di errore
      const alert = { type: "danger", msg: TTPolyglot.t("MOP Invalid Zip Code Format", { 0: countryPlaceholder }) };
      MOP.execute("MOP:alert", alert);
      return false;
    }

  }

  // Se sono qui, ho passato tutte le validazioni, vado a pulire eventuali alert
  MOP.execute('MOP:clearAlert');

  const memberid = await putUser(userid, dataToUpdate);

  // Se non stiamo prenotando per nessun parente allora aggiorno le info dell'utente loggato
  if (!isParent) {
    MOP.updateLoggedUserData(dataToUpdate);
  }

  return true;
};

export const showPaymentRefundableInfo = ({is_refundable, payment_method}) => {
  return is_refundable === false && 
  (
    payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA ||
    payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA_HOSTED ||
    payment_method === MOP.config.constants.PAYMENT_METHOD_CONEKTA_EMBEDDED
  ) && 
  !MOP.Utilities.empty(MOP.config.getInstanceConfig('enablePayByLink'));
};