import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { configureStore as configureToolkit, getDefaultMiddleware } from "@reduxjs/toolkit";

export const configureStore = (preloadedState, rootReducer, additionalMiddlewares = []) => {
  console.log(rootReducer)
  const middlewares = [];
  middlewares.push(thunkMiddleware);
  middlewares.push(...additionalMiddlewares);

  // Il redux-logger deve sempre essere l'ultimo elemento della catena dei middleware
  if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  console.log(store)
  return store;
};

export const configureStoreToolkit = (preloadedState = {}, reducer, additionalMiddlewares = []) => {

  const middleware = [...getDefaultMiddleware(), ...additionalMiddlewares];

  const store = configureToolkit({
    reducer,
    middleware,
    preloadedState,
  });

  return store;

}