import { css } from "@emotion/react";


export const base = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const privacyContainer = theme => ({error})=> css({
  borderColor: error ? theme.colors.danger : theme.colors.gainsboro,
  borderRadius: theme.layout.borderRadius.small,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,
  boxSizing: "border-box",
  display: "flex",
  gap: theme.layout.gap.small,
  height: theme.layout.baseHeights.normal,
  width: "100%",
  alignItems: "center",
  padding: theme.layout.padding.small
});