import React, { useState, useEffect, useLayoutEffect } from 'react';
import MaterialUIDialog from '@material-ui/core/Dialog';
import MOP from 'app';
import { Spinner } from '../loaders';

import { MAX_DIALOG_HEIGHT } from 'common-lib/constants';

import { getDialogPosition } from 'common-lib/components/dialogs/utils';

export const ConfirmDialog = ({
  open,
  title,
  description,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmAction,
  cancelAction,
  loaderActive,
  openDialogButtonClass = null // Questa classe mi serve per permettermi di calcolare la posizione custom se ci troviamo in iframe
}) => {

  const [dialogPosition, setDialogPosition] = useState({});

  useLayoutEffect(() => {

    // Se mi trovo in iframe e non sono nel MOP launcher calcolo correttamente la posizione in cui deve trovarsi il dialog
    if (MOP.config.isInIFrame() && !MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
      const positionObject = getDialogPosition(openDialogButtonClass);
      positionObject && setDialogPosition(positionObject);
    }

    // NB: A differenza dei dialog normali, in questi dialog di conferma non calcoliamo l'altezza massima,
    // Perchè normalmente questi dialog sono molto piccoli. Se un giorno ci sarà bisogno di impostare un'altezza massima allora si farà

  }, [open]);

  return (
    <MaterialUIDialog
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          ...dialogPosition
        }
      }}
    >
      <div data-e2eid="document-delete-dialog" className="tt-margin-20 tt-p-h-10 tt-p-w-26">
        {
          title ?
            <div className="tt-text-700 tt-text-size-22 tt-mb-40">
              {title}
            </div>
            : null
        }
        <div className="tt-mb-20" dangerouslySetInnerHTML={{ __html: description }}>
        </div>
        <div className="tt-mt-20 tt-flexend-container tt-dialog-button">
          <div className="tt-flex">
            {cancelButtonLabel && <button
              data-e2eid="document-delete-cancel-btn"
              className="tt-button tt-button--secondary tt-button--inline tt-mr-6"
              onClick={() => {
                cancelAction();
              }}
              disabled={loaderActive}
            >
              {cancelButtonLabel}
            </button>}
            <button
              data-e2eid="document-delete-confirm-btn"
              className="tt-button tt-button--primary tt-button--inline tt-mt-0"
              onClick={() => {
                confirmAction();
              }}
              disabled={loaderActive}
            >
              {confirmButtonLabel}
              {
                loaderActive === true ?
                  <div className="tt-ml-6">
                    <Spinner size={'xsmall'} classColor={'white'} />
                  </div>
                  :
                  null
              }

            </button>
          </div>
        </div>
      </div>
    </MaterialUIDialog>
  );
};

export const Dialog = ({
  open,
  fullScreen,
  title,
  showCloseButton,
  onClose,
  children,
  childrenStyle,
  disableBackdropClick,
  onExited,
  dialogOverflowProp = '', // Proprietà che serve per ora sulle select interne al dialog, per permettere una corretta visualizzazione
  showFullClasses = true,
  customMaxHeight = null,
  openDialogButtonClass = null // Questa classe mi serve per permettermi di calcolare la posizione custom se ci troviamo in iframe
}) => {

  const [maxDialogHeight, setMaxDialogHeight] = useState(null);

  const [dialogPosition, setDialogPosition] = useState({});

  useEffect(() => {
    // All'avvio del componente vado a calcolare la sua altezza massima
    if (fullScreen) {
      // In fullscreen non settiamo la maxHeight
      setMaxDialogHeight(null);
    } else if (customMaxHeight) {
      // Se è stata passata una maxHeight custom, settiamo questa
      setMaxDialogHeight(customMaxHeight);
    } else {
      // Prevediamo per i dialog NON fullscreen, una altezza massima di MAX_DIALOG_HEIGHT per evitare problemi con liste lunghe
      setMaxDialogHeight(MAX_DIALOG_HEIGHT);
    }
  }, []);

  useEffect(() => {
    if (fullScreen) {
      if (open) {
        MOP.toggleCloseButtonLauncherEmbedded(false);
      } else {
        MOP.toggleCloseButtonLauncherEmbedded(true);
      }
    }
  }, [open]);
  
  useLayoutEffect(() => {
    // Se mi trovo in iframe e non sono nel MOP launcher calcolo correttamente la posizione in cui deve trovarsi il dialog
    if (open && MOP.config.isInIFrame() && !MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
      const positionObject = getDialogPosition(openDialogButtonClass, maxDialogHeight);
      positionObject && setDialogPosition(positionObject);
    }
  }, [open]);

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (typeof onClose === "function") {
      onClose();
    }
  };
  
  return (
    <MaterialUIDialog
      open={open}
      aria-labelledby="dialog"
      fullScreen={fullScreen}
      onClose={handleClose}
      title={title}
      onExited={onExited}
      classes={{ paper: 'tt-overflow-h-x' }}
      PaperProps={{
        style: {
          ...dialogPosition,
          overflow: `${dialogOverflowProp}`,
          maxHeight: maxDialogHeight ? `${maxDialogHeight}px` : ''
        }
      }}
    >
      {
        showFullClasses ?
          <div className={`tt-relative tt-p-24 tt-dialog-ui ${MOP.config.isMobile() ? 'tt-mt-20' : ''}`}>
            {title ?
              <div className="tt-content-with-close tt-dialog-ui-title">{title}</div>
              : null
            }

            {showCloseButton === true ?
              <div data-e2eid="dialog-close" className="tt-dialog-close">
                <button
                  onClick={onClose}
                  className='tt-square-icon-button'
                >
                  <i className="tt-icon-times"></i>
                </button>
              </div>
              : null
            }
            <div className={`${title ? 'tt-mt-24' : ''}`}>
              {children}
            </div>
          </div> :
          <>
            {children}
          </>
      }

    </MaterialUIDialog>
  );
};