import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Label } from "ui/Label";
import { Loading } from "ui/Loading";
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import useFetchCheckinImage from 'common-lib/fetchCheckinImage';

export const Qr = ({
  imgUrl,
  code,
  fontSize,
  message
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  const { data, loading, error } = useFetchCheckinImage(imgUrl);

  error && console.log(error);

  const LoadingComponent = () => (
    loading && (
      <div css={isMobile ? style.loadingMobile : style.loading}>
        <Loading />
      </div>
    )
  );


  return (
    <div css={style.noBreak}>
      {isMobile ?
        <div css={style.baseMobile}>
          <div css={style.infoContent}>
            <Label color="text" size="medium" color="text_light">{message}</Label>
          </div>
          <LoadingComponent />
          <div css={style.qrContainer}>
            <img css={style.qrMobile} src={data} />
            <div css={style.number(theme)(fontSize)}>
              {code}
            </div>

          </div>

        </div> :
        <div css={style.base}>
          <LoadingComponent />
          <div css={style.qrContainer}>
            <img css={style.qr} src={data} />
          </div>
          <div css={style.infoContent}>
            <Label color="text" size="medium" color="text_light">{message}</Label>
            <div css={style.number(theme)(fontSize)}>
              {code}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

Qr.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  message: PropTypes.string,
};

Qr.defaultProps = {
  fontSize: null,
  message: null,
};