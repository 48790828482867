import { css } from "@emotion/react";


export const base = theme => css({
  flex: 1,
  padding: theme.layout.padding.micro
});

export const title = () => css({
  flexGrow: 2
});

export const subtitle = theme => css({
  fontSize: theme.fonts.size.standard
});












