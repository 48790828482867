export const colors = {
  primary: "#00C3A5",
  secondary: "#2ab7ca",
  text: "#1B2734",
  text_light: "#627282",
  link: "#0E89FC",
  success: "#00B39B",
  success_light: "#E0F7F4",
  warning: "#997800",
  warning_light: "#FEFBDC",
  danger: "#E74C3C",
  danger_light: "#FDF2F1",
  transpartent: null,


  alice_blue: "#EEF4FD",
  anti_flash_white: "#f3f0fa",
  auro_metal_saurus: "#627282",
  black: "#000000",
  blue_jeans: "#66AFE9",
  bright_gray: "#EDEFF2",
  caribbean_green: "#00C3A5",
  carmine_pink: "#E74C3C",
  chinese_blue:"#3E5789",
  cornsilk: "#FEFBDC",
  dark_gunmetal: "#1B2734",
  gainsboro: "#DCDFE3",
  ghost_white: "#f7f9fa",
  gold: "#997800",
  light_cyan: "#E0F7F4",
  light_grey: "#DDD",
  manatee: "#98A1AB",
  medium_champagne: "#FAE9A1",
  medium_purple: "#8870D5",
  persian_green: "#00B39B",
  seashell: "#FDF2F1",
  spanish_gray: "#97979726",
  super_silver: "#EEE",
  tufts_blue: "#3D83DF",
  white: "#FFFFFF",
};
