import MOP from 'app';

export const getScore = () => {

  return new Promise((resolve, reject) => {

    const ajaxParams = {
      searchPath: `dpcertifications/_scores`,
      ajax: {
        options: {
          method: 'GET'
        }
      }
    };

    MOP.ajaxRest(ajaxParams, false)
      .done(response => {
        if (response.result === "OK") resolve(response);
        else reject(response);
      }
      )
      .fail(reject);
  });
};

