import $ from 'jquery';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

export default  Marionette.Region.extend({
    /*
    attachHtml: function(view) {
        this.$el.append(view.el);
    },
    */
    onShow: function(view) {
        //this.listenTo(view, "banner:close", this.closeBanner);
        this.$el.fadeIn('slow');
    }
    /*
    ,
    closeBanner: function() {
        $('#banner-region').fadeOut('slow');
        //this.$el.remove();
    }
    */
});