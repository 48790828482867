import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@emotion/react';
import { Label } from '../../Label';
import { Dialog } from '../../Dialog';
import { Button } from "../../Button";

import { getAllowedTypesName } from 'common-lib/file';

import {
  STATUS_START,
  STATUS_FILE_NOT_SUPPORTED,
} from "../constants";

import { useUploadContext } from '../Context.js';

import { TTPolyglot } from "@frontend/tt-polyglot";

export const FileNotSupportedDialog = () => {

  const {
    status,
    setStatus,
    inputRef,
    isAdmin,
    uploadRef
  } = useUploadContext();

  const handleOnClose = () => {
    inputRef.current.value = null;
    setStatus(STATUS_START);
  }


  return (
    <Dialog
      open={status === STATUS_FILE_NOT_SUPPORTED}
      onClose={handleOnClose}
      closeButton={true}
      outClick={true}
      anchorEl={uploadRef?.current}
      title={TTPolyglot.t("MOP Upload File Not Supported Title")}
    >
      <Dialog.Content>
        <Label>
          {TTPolyglot.t('MOP Upload File Not Supported Description')}
        </Label>
        <div>
          {getAllowedTypesName(isAdmin).map((el, index) => <li key={index}>{el.toUpperCase()}</li>)}
        </div>
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          type={"solid"}
          onClick={() => {
            inputRef.current.value = null; // resetto l'input
            inputRef.current.click(); // Triggero il click su di esso
            setStatus(STATUS_START);// Setto lo status a START
          }}
        >
          {TTPolyglot.t("MOP Upload Another Document")}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

FileNotSupportedDialog.propTypes = {
};

FileNotSupportedDialog.defaultProps = {
};
