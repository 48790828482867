import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MOP from 'app';
import { UserInfo } from './UserInfo';
import { LanguageDialog } from './LanguageDialog';
import { getMobileMenu, itemClicked } from "../../common";
import { setShowSideMenu } from '../../store/actions';
import { FadeTransition300 } from 'common-lib/components/transitions';

import { TTPolyglot } from "@frontend/tt-polyglot";

const SideMenu = ({ menuComponents, close, loggedUser, activePage, unreadNotifications, showAppVersion, appVersion, hideBottomBar, showSideMenu, whiteMop }) => {

  if (hideBottomBar) return null;

  useEffect(() => {
    if (showSideMenu && ref.current) {
      const bottomNav = document.querySelector('.tt-js-bottom-nav-container');
      ref.current.style.bottom = `${bottomNav.clientHeight}px`;
    }
  }, [showSideMenu]);

  const ref = useRef(null);
  const [showLanguageDialog, setShowLanguageDialog] = useState(false);

  const handleClick = item => {
    const { config } = item;
    if (config !== 'languageChoice') {
      itemClicked(item);
      close();
    } else {
      setShowLanguageDialog(true);
    }
  };

  const handleLanguageChoice = lang => {
    setShowLanguageDialog(false);
    return MOP.changePage(MOP.getCurrentPage(), MOP.getCurrentRoute(), null, null, null, lang);
  };

  const checkActive = item => {
    const { page, route } = activePage;
    if (item.page === page) {
      if (!route && !item.route) return true;
      return item.route === route;
    }
    return false;
  };

  return (
    <FadeTransition300
      in={showSideMenu === true}
      unmountOnExit={true}
    >
      <>
        <LanguageDialog open={showLanguageDialog} onClose={() => setShowLanguageDialog(false)} onChange={handleLanguageChoice} />
        <div className="tt-bottom-nav-collapsed-container" ref={ref} data-e2eid="mobile-side-menu">
          <div className="tt-bottom-nav-collapsed">
            <div className="tt-bottom-nav-collapsed-content tt-container-scroll">
              <div className="tt-bottom-nav-collapsed-content-nav-container">
                <div className="tt-bottom-nav-collapsed-content-nav">
                  <UserInfo loggedUser={loggedUser} />
                  <ul>
                    {menuComponents.map((item, index) => {
                      return (
                        <li key={index} onClick={() => {
                          handleClick(item);
                        }}>
                          <a data-e2eid={item.e2eid} className={`tt-bottom-nav-collapsed-content-nav-item tt-flexstart-container tt-last-end ${checkActive(item) ? 'active' : ''} ${item.page === 'profile' && unreadNotifications > 0 ? 'tt-notification' : ''}`}>
                            <div className="tt-flex">
                              <span className={`${item.icon}`}></span>
                              <div className="tt-flex-1 tt-pr-10">{item.label}</div>
                            </div>
                            <div>
                              <i className="tt-bottom-nav-collapsed-content-nav-item__action tt-icon-chevron-right"></i>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="tt-copyright">
                {
                  showAppVersion === false ? null :
                    <div dangerouslySetInnerHTML={{ __html: appVersion }}></div>
                }
                { // Se il config whiteMop è settato, vado a nascondere questa stringa
                  whiteMop === false &&
                  <div>powered by Tuotempo</div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    </FadeTransition300>
  );
};

const mapStateToProps = ({ showSideMenu, hideBottomBar, activePage, loggedUser, unreadNotifications }) => {

  let showAppVersion = false;
  let appVersion = '';
  if (MOP.Utilities.isMobileApp() && !MOP.Utilities.empty(window.MOP_globals.customerConfig.appVersion)) {
    if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('showThisOnMenuFooter'))) {
      showAppVersion = true;
      appVersion = TTPolyglot.t("Mop App Copyright Version", [window.MOP_globals.customerConfig.appVersion, MOP.config.getInstanceConfig('showThisOnMenuFooter')], true); // TRD0 versione app TRD1 COPYRIGHT
    }
  };

  const whiteMop = !MOP.Utilities.empty(MOP.config.getInstanceConfig('whiteMop'));

  const { sideMenu } = getMobileMenu();

  return {
    showAppVersion,
    appVersion,
    sideMenu,
    showSideMenu,
    hideBottomBar,
    menuComponents: sideMenu,
    activePage,
    loggedUser,
    unreadNotifications,
    whiteMop
  };
};

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(setShowSideMenu(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);