import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Icon } from 'ui/Icon/index.jsx';

export const Label = ({ e2eid, children, color, icon, size, weight, onClick, fontFamily, dangerouslySetInnerHTML, blankIcon }) => {

  const theme = useTheme();

  const renderIcon = (icon, size) => {
    if (blankIcon === true)
      // Questa funzione serve per gestire la visualizzazione di una label in caso
      // si necessiti di un'icona e più righe di testo (esempio Area nel Summary).
      // In questo scenario alle righe successive alla prima è possibile impostare
      // il prop blackIcon = TRUE evitare problemi di allineamento delle label
      return <div css={style.blankIcon(theme)(size)} />;

    if (!icon?.name) return null;

    return <Icon {...e2eid ? {"e2eid": `${e2eid}-icon` } : null} name={icon.name} color={icon.color} size={size} />
  };


  return (
    <div
      css={style.base(theme)({
        color,
        size,
        weight,
        fontFamily,
        onClick
      })}
      onClick={onClick}
    >
      {renderIcon(icon, size)}
      {dangerouslySetInnerHTML ? (
        <span {...e2eid ? {"data-e2eid":e2eid } : null} css={style.text} dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />
      ) : (
        <span {...e2eid ? {"data-e2eid":e2eid } : null} css={style.text}>{children}</span>
      )}
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.string,
  icon: PropTypes.shape(
    {
      name: PropTypes.string.isRequired,
      color: PropTypes.string
    }
  ),
  size: PropTypes.oneOf(['small', 'standard', 'medium', 'large', 'xl', 'xxl']),
  weight: PropTypes.oneOf(['regular', 'medium', 'semiBold', 'bold']),
  onClick: PropTypes.func,
  fontFamily: PropTypes.string,
  blankIcon: PropTypes.bool,
  e2eid: PropTypes.string
};

Label.defaultProps = {
  children: "",
  size: "standard",
  weight: "regular",
  color: null,
  icon: null,
  onClick: null,
  fontFamily: null,
  dangerouslySetInnerHTML: null,
  blankIcon: false,
  e2eid: null
};
