import React from 'react';
import PropTypes from 'prop-types';
import { Transition as ReactTransition } from 'react-transition-group';
import * as style from "./style";

export const Transition = ({
  children,
  defaultStyle,
  inProp,
  mountOnEnter,
  onEnter,
  unmountOnExit,
  timeout,
  transitionStyles,
}) => {
  // DRAFT - Bozza di codice da non utilizzare
  return (
    <ReactTransition
      in={inProp}
      onEnter={onEnter}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      timeout={timeout}
    >
      {state => (
        <div
          css={
            style.base(
              defaultStyle.opacity,
              defaultStyle.transition,
              transitionStyles[state].opacity,
              transitionStyles[state].transition,
            )}>
          {children}
        </div>
      )}
    </ReactTransition>
  );
};

const styleShape = PropTypes.shape({
  opacity: PropTypes.number,
  transition: PropTypes.string,
});

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  defaultStyle: styleShape,
  inProp: PropTypes.bool.isRequired,
  mountOnEnter: PropTypes.bool,
  onEnter: PropTypes.func,
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      enter: PropTypes.number,
      exit: PropTypes.number,
      appear: PropTypes.number
    })
  ]),
  transitionStyles: PropTypes.shape({
    entering: styleShape,
    entered: styleShape,
    exiting: styleShape,
    exited: styleShape,
  }),
  unmountOnExit: PropTypes.bool,
};

Transition.defaultProps = {
  defaultStyle: {
    transition: null,
    opacity: 0,
  },
  mountOnEnter: true,
  timeout: 0,
  transitionStyles: {
    entering: {
      transition: null,
      opacity: 0,
    },
    entered: {
      transition: null,
      opacity: 0,
    },
    exiting: {
      transition: null,
      opacity: 0,
    },
    exited: {
      transition: null,
      opacity: 0,
    },
  },
  unmountOnExit: true
};
