import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { Icon } from 'ui/Icon/index.jsx';
import { useTheme } from '@emotion/react';


export const BackButton = ({
  children,
  onClick,
}) => {

  const theme = useTheme();

  return (
    <button
      type="button"
      css={style.base(theme)}
      onClick={onClick}
    >
      <Icon name="ArrowLeftLight" size="medium" color="primary" />
      {children}
    </button>
  );
};

BackButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  children: "",
  onClick: null,
};
