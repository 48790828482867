import { css } from "@emotion/react";

export const base = theme => ({ gap, center }) => css({
  display: "flex",
  flexDirection: "row",
  alignItems: center ? "center" : "initial",
  gap: theme.layout.gap[gap]
});

export const labelContainer = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro,
  justifyContent: "center",
  lineHeight: "initial"
});

export const toggleContainer = ({}) => css({
  width: "min-content"
});


export const toggleMedium = theme => ({ enabled }) => css({
  width: "48px",
  height: "24px",
  borderRadius: "24px",
  backgroundColor: enabled ? theme.colors.primary : theme.colors.gainsboro,
  transition: "0.5s",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.layout.padding.micro,
  paddingRight: theme.layout.padding.micro,
  cursor: "pointer"
});

export const toggleSmall = theme => ({ enabled }) => css({
  width: "30px",
  height: "15px",
  borderRadius: "15px",
  backgroundColor: enabled ? theme.colors.primary : theme.colors.gainsboro,
  transition: "0.5s",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.layout.padding.micro,
  paddingRight: theme.layout.padding.micro,
  cursor: "pointer"
});

export const pinMedium = theme => ({ enabled }) => css({
  height: "16px",
  width: "16px",
  borderRadius: "50%",
  backgroundColor: theme.colors.white,
  transition: "0.5s",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  transform: enabled ? "translateX(24px)" : "translateX(0)"
});

export const pinSmall = theme => ({ enabled }) => css({
  height: "11px",
  width: "11px",
  borderRadius: "50%",
  backgroundColor: theme.colors.white,
  transition: "0.5s",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  transform: enabled ? "translateX(11px)" : "translateX(0)"
});