import { css } from "@emotion/react";

export const textarea = theme => ({ }) => css({
  display: "block",
  width: "100% !important",
  minHeight: "100px",
  padding: "12px 12px 12px 16px",
  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,

  transition: "border-color ease-in-out .15s",

  ":focus": {
    borderColor: theme.colors.blue_jeans,
    outline: "0",
  },
  "::-moz-placeholder": {
    color: "#999",
    opacity: "1"
  },
  ":-ms-input-placeholder, ::-webkit-input-placeholder": {
    color: "#999"
  }
});