export const getDialogPosition = (anchorEl, ROOT, maxHeight) => {

  if (!anchorEl) return {};

  const { bottom: bodyBottom } = ROOT.getBoundingClientRect();

  const { top, bottom } = anchorEl.getBoundingClientRect();

  if (top > (bodyBottom / 2)) {

    // Se il bottone che apre il dialog, si trova oltre la metà dell'iframe,
    // Vado a settare la proprietà bottom del dialog
    // quindi uso il BOTTOM come riferimento per il dialog,
    // Quindi se il dialog deve crescere, lo farà verso l'alto e non verso il basso 
    // (e non si rischia l'overflow dall'iframe)

    // TODO da vedere insieme a piero
    if(maxHeight && maxHeight + (bodyBottom - bottom) >= ROOT.clientHeight){
      // Fissando l'attributo BOTTOM alla Dialog, se l'altezza totale della Dialog 
      // va in OVERFLOW verso l'ALTO non setto position absolute
      return {};
    }

    return ({
      top: "unset",
      bottom: `${bodyBottom - bottom}px`,
      transform: "translate(-50%, 0%)"
    });
  }


  if(parseInt(maxHeight) && parseInt(maxHeight) + top >= ROOT.clientHeight){
    // Fissando l'attributo TOP alla Dialog, se l'altezza totale della Dialog 
    // va in OVERFLOW verso il BASSO non setto posizione absolute
    return;
  }

  return ({
    bottom: "unset",
    top: `${top}px`,
    transform: "translate(-50%, 0%)"
  });

};