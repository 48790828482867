import { css } from "@emotion/react";
import chroma from "chroma-js";

export const base = theme => ({top, left, maxHeight}) => css({
  backgroundColor: theme.colors.white,
  position: "absolute",
  zIndex: "99999",
  top,
  left,
  maxHeight,
  overflowY: maxHeight ? "auto" : "",
  overflowX: "auto",
  boxShadow: theme.layout.baseBoxShadow,
  borderRadius: theme.layout.borderRadius.small,
});

export const popover = theme => ({width}) => css({
  backgroundColor: theme.colors.white,
  padding: theme.layout.padding.standard,
  width: width || "",
  
  maxWidth: width ? "" : "459px" // Se non ho una larghezza settata, allora vado a mettere una larghezza massima di 459px
});