import React, { useState, useEffect, useRef } from 'react';
import { certificationWidgetEnabled, getMobileMenu, itemClicked, logout } from "../../common";
import { connect } from 'react-redux';
import MOP from 'app';
import { setShowSideMenu } from '../../store/actions';

import { TTPolyglot } from "@frontend/tt-polyglot";


import { API_STATUS_FULFILLED } from 'common-mop/constants';

import CertificationWidget from '../../common/components/certification-widget';


const BottomBar = ({ active, disabled, bottomBar, sideMenu, unreadNotifications, showSideMenu, setShowSideMenu, hideBottomBar, hideMobileMenu, certificationWidget }) => {

  if (hideBottomBar || MOP.config.isOtuFirst(MOP) || MOP.config.isOtuPost(MOP)) return null;

  const [showNotificationSideMenu, setShowNotificationSideMenu] = useState(true);

  const [ellipsisActive, setEllipsisActive] = useState(false);

  /** checkActive
   * * Ci dice se abbiamo il sideMenu aperto o chiuso
   * @param {sideMenu Element} item - elemento dell'array sideMenu
   * @returns {Boolean} - true se il side menu è aperto, false altrimenti
   */
  const checkActive = item => {

    // Ricaviamo pagina e rotta attuale
    const { page, route } = active;

    // Se abbiamo una pagina attiva e l'elemento del sideMenu ha una pagina che si chiama come quella attiva
    // ed anche la rotta è la stessa, allora vuol dire che abbiamo il sideMenu aperto
    if (item.page === page) {
      // Abbiamo il sideMenu aperto anche se sono vuote entrambe le rotte (gestisco i casi di pagine senza rotta)
      if (!route && !item.route) return true;
      return item.route === route;
    }

    // altrimenti active è diverso dall'elemento del sideMenu, torno false
    return false;
  };


  useEffect(() => {
    let tmp = false;
    sideMenu.map(el => {
      if (checkActive(el)) tmp = true;
    });
    setEllipsisActive(tmp);

  }, [active]);

  useEffect(() => {
    const calculateNewHeight = (mutationList, observer) => {
      const mainWrapperContent = document.getElementById('main-wrapper-content-region');
      const bottomBar = document.getElementById("tt-js-bottom-bar-nav-container");

      if (mainWrapperContent && bottomBar) {
        const bottomBarHeight = bottomBar.clientHeight;
        mainWrapperContent.style.height = `calc(100% - ${bottomBarHeight}px)`;
        mainWrapperContent.style.bottom = `${bottomBarHeight}px`;
        mainWrapperContent.style.top = 0;
      }
    };

    /**
     * Al mount del componente calcolo sempre l'altezza una prima volta. poi monto l'observer in modo da
     * mettermi in ascolto su eventuali cambiamenti della bottomBar e triggerare ogni volta un nuovo calcolo dell'altezza
     */
    calculateNewHeight();

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(calculateNewHeight);

    // Start observing the target node for configured mutations
    observer.observe(document.getElementById("tt-js-bottom-bar-nav-container"), { attributes: true, childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);


  const ellipsisIcon = MOP.config.getInstanceConfig('mopPatientBottomBar').slice(-1)[0].icon || "tt-icon-ellipsis-v";

  return (
    <div className="tt-bottom-nav-container tt-js-bottom-nav-container" id={"tt-js-bottom-bar-nav-container"}>
      <div className="tt-nav-bottom-nav-space">
        {certificationWidgetEnabled() && <CertificationWidget />}
        <nav className={`tt-bottom-nav`}>
          <ul className={`tt-bottom-nav-list ${bottomBar.length === 1 ? 'tt-flex-right-align' : ''}`}>
            {bottomBar.map((item, index) => {
              if (item.page === 'profile' && showNotificationSideMenu) {
                setShowNotificationSideMenu(false);
              }
              return (
                <li key={index} className={`${disabled ? 'disabled' : ''}`}>
                  <a data-e2eid={item.e2eid} className={`tt-bottom-nav-list-icon ${checkActive(item) ? 'active' : ''} ${item.page === 'profile' && unreadNotifications > 0 ? 'tt-notification' : ''}`} onClick={() => {
                    itemClicked(item);
                    setShowSideMenu(false);
                  }}>
                    <i className={`${item.icon}`}></i>
                    <span className="tt-bottom-nav-list-icon__label">{item.label}</span>
                  </a>
                </li>
              );
            })}
            {
              !hideMobileMenu &&
              <>
                {disabled && MOP.isLoggedIn() ?
                  <li>
                    <a className="tt-bottom-nav-list-icon" onClick={() => logout()}>
                      <i className="tt-main-color tt-icon-sign-out"></i>
                      <span className="tt-bottom-nav-list-icon__label">{TTPolyglot.t('Log Out')}</span>
                    </a>
                  </li>
                  :
                  <li className={`${disabled ? 'disabled' : ''}`}>
                    <a data-e2eid={"menu-bottom-bar"} className={`tt-bottom-nav-list-icon ${ellipsisActive ? 'active' : ''} ${showNotificationSideMenu && unreadNotifications > 0 ? 'tt-notification' : ''}`} onClick={() => setShowSideMenu(!showSideMenu)}>
                      <i className={ellipsisIcon}></i>
                      <span className="tt-bottom-nav-list-icon__label">{TTPolyglot.t('Menu')}</span>
                    </a>
                  </li>
                }
              </>
            }

          </ul>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = state => {

  const { bottomBar, sideMenu } = getMobileMenu();

  const menuMobileHidden = MOP.Utilities.empty(MOP.config.getInstanceConfig('menuVisibility').mobile);
  const whiteMop = !MOP.Utilities.empty(MOP.config.getInstanceConfig('whiteMop'));

  const forceHideBottomBar = menuMobileHidden && whiteMop && bottomBar.length === 0;

  return {
    bottomBar,
    sideMenu,
    disabled: state.disabled,
    active: state.activePage,
    loggedUser: state.loggedUser,
    showBack: state.showBack,
    hideBottomBar: forceHideBottomBar || state.hideBottomBar || MOP.isAuthModeEnabled(),
    hideMobileMenu: menuMobileHidden && whiteMop, // comanda lo show della voce "menu" che apre la sidebar
    unreadNotifications: state.unreadNotifications,
    showSideMenu: state.showSideMenu,
    certificationWidget: state.certificationWidget
  };
};

const mapDispatchToProps = dispatch => ({
  setShowSideMenu: val => dispatch(setShowSideMenu(val))
});


export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);