import _ from 'underscore';
import $ from 'jquery';

/**
 * @param str {String}
 * @return {Boolean}
 */
function _isJsonString(str) {
    str = str + '';

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

var SessionStorage_proxies = {
    iframe: {
        fn: 'sessionStorage',

        callback_map: {},

        datas: {},

        _postMessage: function (msg) {
            var deferred = new $.Deferred();
            var callback = function callback(value) {
                var resp = value;

                if (resp.result === 'OK') {
                    deferred.resolve(resp.value);
                } else {
                    deferred.reject(resp.value);
                }
            };

            try {
                parent.postMessage(JSON.stringify(msg), '*');
                SessionStorage_proxies.iframe.callback_map[msg.id] = callback;

                return deferred;
            } catch (e) {
                return false;
            }
        },

        _genId: function () {
            return Math.round(Math.random() * 1000);
        },

        _messageListener: (function () {
            var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
            var eventer = window[eventMethod];
            var messageEvent = (eventMethod === 'attachEvent') ? 'onmessage' : 'message';

            eventer(messageEvent, function (e) {
                //var data = (typeof e.data == 'string') ? JSON.parse(e.data) : e.data;
                var data = _isJsonString(e.data) ? JSON.parse(e.data) : e.data;
                var response = data.response;
                var id = data.id;

                if (SessionStorage_proxies.iframe.callback_map[id]) {
                    SessionStorage_proxies.iframe.callback_map[id](response);
                    SessionStorage_proxies.iframe.callback_map = _.omit(SessionStorage_proxies.iframe.callback_map, id);
                }
            }, false);
        })(),

        setItem: function (key, value) {
            var data = {};

            data.fn = SessionStorage_proxies.iframe.fn;
            data.method = 'setItem';
            data.key = key;
            data.value = value;
            data.id = SessionStorage_proxies.iframe._genId();

            SessionStorage_proxies.iframe.datas[key] = value;

            return SessionStorage_proxies.iframe._postMessage(data);
        },

        getItem: function (key) {
            var data = {};

            data.fn = SessionStorage_proxies.iframe.fn;
            data.method = 'getItem';
            data.key = key;
            data.id = SessionStorage_proxies.iframe._genId();

            return SessionStorage_proxies.iframe._postMessage(data);
        },

        removeItem: function (key) {
            var data = {};

            data.fn = SessionStorage_proxies.iframe.fn;
            data.method = 'removeItem';
            data.key = key;
            data.id = SessionStorage_proxies.iframe._genId();

            SessionStorage_proxies.iframe.datas = _.omit(SessionStorage_proxies.iframe.datas, key);

            return SessionStorage_proxies.iframe._postMessage(data);
        },

        removeItemByRegExp: function (reg_exp) {
            var data = {};
            var regExp;

            data.fn = SessionStorage_proxies.iframe.fn;
            data.method = 'removeItemByRegExp';
            data.key = reg_exp;
            data.id = SessionStorage_proxies.iframe._genId();
            regExp = new RegExp(reg_exp);

            Object
                .keys(SessionStorage_proxies.iframe.datas)
                .forEach(function (key) {
                    if (regExp.test(key)) {
                        SessionStorage_proxies.iframe.datas = _.omit(SessionStorage_proxies.iframe.datas, key);
                    }
                });

            return SessionStorage_proxies.iframe._postMessage(data);
        }
    },

    local: {
        setItem: function () {
            var deferred = new $.Deferred();

            try {
                deferred.resolve(window.sessionStorage.setItem.apply(window.sessionStorage, Array.prototype.slice.apply(arguments)));
            } catch (err) {
                deferred.reject(err);
            }

            return deferred;
        },

        getItem: function () {
            var deferred = new $.Deferred();
            var gettedItem;

            try {
                // [TODO] Mattia: finisci il test.
                //gettedItem = window.sessionStorage.getItem.apply(window.sessionStorage, Array.prototype.slice.apply(arguments));
                //deferred.resolve(_isJsonString(gettedItem) ? JSON.parse(gettedItem) : gettedItem);

                deferred.resolve(window.sessionStorage.getItem.apply(window.sessionStorage, Array.prototype.slice.apply(arguments)));
            } catch (err) {
                deferred.reject(err);
            }

            return deferred;
        },

        removeItem: function () {
            var deferred = new $.Deferred();

            try {
                deferred.resolve(window.sessionStorage.removeItem.apply(window.sessionStorage, Array.prototype.slice.apply(arguments)));
            } catch (err) {
                deferred.reject(err);
            }

            return deferred;
        },

        removeItemByRegExp: function () {
            var deferred = new $.Deferred();

            try {
                deferred.resolve(window.sessionStorage.removeItemByRegExp.apply(window.sessionStorage, Array.prototype.slice.apply(arguments)));
            } catch (err) {
                deferred.reject(err);
            }

            return deferred;
        }
    }
};

var isIframe = (window.document.location.href.indexOf('parent_location') !== -1) ? true : false;

export default SessionStorage_proxies[(isIframe ? 'iframe' : 'local')];
