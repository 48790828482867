import MOP from 'app';

export const samlLogin = id => {
  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `sso/service_provider/saml2`,
      ajax: {
        options: {
          method: 'GET'
        },
      },
      loading: {
        enabled: false
      },
      querystring: { idp: id }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => {
          resolve(response.return);
        }
      )
      .fail(
        error => {
          reject(error);
        }
      );
  });

};

export const spidLogin = querystring => {

  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `sso/service_provider/spid`,
      ajax: {
        options: {
          method: 'GET'
        },
      },
      loading: {
        enabled: false
      },
      querystring
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => {
          resolve(response.return);
        }
      )
      .fail(
        error => {
          reject(error);
        }
      );
  });

};

export const getSpidToken = tokenid => {

  return new Promise((resolve, reject) => {
    // 27 è la reason scelta per la getToken dello spid
    const ajaxParams = {
      searchPath: `token/27/${tokenid}`,
      ajax: {
        options: {
          method: 'GET'
        },
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => {
          resolve(response.return);
        }
      )
      .fail(
        error => {
          reject(error);
        }
      );
  });


};


export const getSpidAppToken = tokenid => {

  return new Promise((resolve, reject) => {
    // 29 è la reason scelta per la getToken dello spid
    const ajaxParams = {
      searchPath: `token/29/${tokenid}`,
      ajax: {
        options: {
          method: 'GET'
        },
      },
      loading: {
        enabled: false
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(
        response => {
          resolve(response.return);
        }
      )
      .fail(
        error => {
          reject(error);
        }
      );
  });


};