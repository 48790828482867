import _ from "underscore";
import Backbone from "backbone";

  export default Backbone.Model.extend({

    defaultOrderBy: "start_date",

    initialize: function() {},

    defaults: {
      orderWay: "ASC",
      orders: [],

      current_page: 1,
      pager_limit: 5,

      pastRes: null,
      futureRes: null,

      search: "",

      userid: "",

      is_pending: [],

      start_date: "",
      end_date: "",

      startDateTime: ""
    },

    getOrders: function(by) {
      switch (by) {
        case 'typologyTitle':
          var orders = new Array('typologyTitle', 'start_date', 'startTime', 'name', 'activityTitle', 'modified', 'areaTitle');
          break;
        case 'activityTitle':
        case 'activity_web_title':
          var orders = new Array('activity_web_title', 'start_date', 'startTime', 'name', 'modified', 'typologyTitle', 'areaTitle');
          break;
        case 'resourceName':
          var orders = new Array('name', 'start_date', 'startTime', 'activityTitle', 'modified', 'typologyTitle', 'areaTitle');
          break;
        case 'start_date':
        case 'startTime':
          var orders = new Array('start_date', 'startTime', 'name', 'activityTitle', 'modified', 'typologyTitle', 'areaTitle');
          break;
        case 'created':
        default:
          var orders = new Array('created', 'start_date', 'startTime', 'name', 'activityTitle', 'modified', 'typologyTitle', 'areaTitle');
          break;
      }
      return orders;
    },

    load: function(params, prefilters) {
      var params = params || {};

      var mop_prefilters = prefilters || {};
      var more_params = {};

      this.set($.extend({}, this.format(params), more_params));
    },

    format: function(params) {

      if (_.has(params, 'orderBy')) {
        params.orders = this.getOrders(params.orderBy);

      } else if (!(_.has(params, 'orders'))) {
        params.orders = this.getOrders(this.defaultOrderBy);
      }

      return this.clean(params);
    },

    clean: function(params) {
      var params = params || {};
      var clean_params = {};
      var filters_keys = _.keys(this.defaults);

      _.each(params, function(value, key, list) {
        if (_.indexOf(filters_keys, key) != -1) {
          clean_params[key] = value;
        }
      });
      return clean_params;
    },

    formatDefaultParams: function(MOP, params) {

      /*
       *  Gestione Paziente
       * -------------------
       */
      //Solo se sono un utente esterno voglio caricare tutti gli appuntamenti
      if (!(MOP.isAdminLoggedIn())) {
        params.pastRes = true;
        params.pager_limit = 0;
        //Se lo userid non è specificato allora prendo l'userid dell'utente loggato
        if (!(_.has(params, 'userid'))) {
          params.userid = MOP.getLoggedUserId();
        }

        /*
         *  Gestione Admin/Operator
         * -------------------------
         */
        //Altrimenti voglio caricare solo gli ultimi 5 appuntamenti creati con start date maggiore di adesso 
      } else {
        params.futureRes = true;
        if (!(_.has(params, 'userid'))) {
          params.pager_limit = 5;
          params.userid = "";
          //NB => Se si sta cercando le reservations di un particolare utente => allora le voglio tutte	
        } else {
          params.pager_limit = 0;
        }
        params.orderBy = "start_date";
        params.orderWay = "DESC";
      }
    },

    getFiltersToSearch: function(MOP) {
      
      var attributes = _.extend({}, this.attributes);
      
      if (MOP.Utilities.empty(attributes.pastRes)) delete attributes.pastRes;
      if (MOP.Utilities.empty(attributes.futureRes)) delete attributes.futureRes;
      
      return attributes;
    },

    toQuerystring: function() {
      return $.param(this.attributes);
    }

  });