import { css } from "@emotion/react";

export const inputContainer = theme => ({ disabled, error }) => css({
  ...theme.input.container.standard.normal,
  ...(disabled && theme.input.container.disabled),
  ...(error && theme.input.container.error),
  pointerEvents: disabled ? "none" : "",
  gap: "0px",
  padding: "0px",
  paddingRight: theme.layout.padding.standard,
  paddingLeft: theme.layout.padding.standard,
  cursor: "pointer"
});


export const input = theme => ({ disabled, error }) => css({
  ...theme.input.base.standard,
  ...(disabled && theme.input.base.disabled),
  ...(error && theme.input.base.error),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  padding: "0px"
});

export const baseCalendarContainer = theme => ({ top }) => css({
  position: "fixed",
  paddingBottom: theme.layout.padding.small,
  borderRadius: theme.layout.borderRadius.medium,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,
  borderColor: theme.colors.gainsboro,
  backgroundColor: theme.colors.white,
  top
});


export const baseCalendar = theme => ({ width, maxHeight }) => css({
  width: width ? width : "",
  maxHeight,
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.standard,
  background: "white",
  border: "none",
  paddingTop: theme.layout.padding.small
  
});

export const baseButton = css({
  background: "white",
  border: "none",
  margin: 0,
  padding: 0,
});

export const navigation = css({
  display: "flex",
  width: "100%",
  height: "25px",
  marginBottom: "0px",
  justifyContent: "space-between",
});

export const generalFont = theme => {
  return css({
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.fonts.size.large,
    fontStyle: "normal",
    fontWeight: theme.fonts.weight.bold,
    lineHeight: "21px",
    textTransform: "capitalize",
  });
};

export const arrowsFont = theme => {
  return css({
    fontWeight: theme.fonts.weight.bold,
    fontSize: theme.fonts.size.xl,
  });
};

export const container = css({
  width: "100%",
  height: "100%",
});

export const daysFont = theme => {
  return css({
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.fonts.size.standard,
    fontStyle: "normal",
    fontWeight: theme.fonts.weight.regular,
    lineHeight: "16px",
    textTransform: "uppercase",
    textDecoration: "none",
  });
};

export const monthView = theme => {
  return css({
    color: theme.colors.text,
    marginBottom: "13px",
    display: "block",
    textAlign: "center",
  });
};

export const monthViewDays = css({
  height: "34px",
  padding: "10px",
});

export const monthViewBtn = css({
  padding: "10px",
});

export const selected = theme => {
  return css({
    color: theme.colors.white,
    backgroundColor: theme.colors.primary,
    // borderRadius: theme.layout.borderRadius.large,
  });
};


export const selectedRangeStart = theme => {
  return css({
    color: theme.colors.white,
    backgroundColor: `${theme.colors.primary} !important`,
    // borderTopLeftRadius: theme.layout.borderRadius.large,
    // borderBottomLeftRadius: theme.layout.borderRadius.large,
  });
};

export const selectedRangeBetween = theme => {
  return css({
    color: theme.colors.white,
    backgroundColor: theme.colors.azure_light,
  });
};

export const selectedRangeEnd = theme => {
  return css({
    color: theme.colors.white,
    backgroundColor: theme.colors.primary,
    // borderTopRightRadius: theme.layout.borderRadius.large,
    // borderBottomRightRadius: theme.layout.borderRadius.large,
  });
};


export const monthButton = css({
  maxWidth: "fit-content",
});
