import { css } from "@emotion/react";

export const base = theme => isMobile => css({
  display: "flex",
  flexDirection: "column",
  gap: isMobile ? theme.layout.gap.medium : theme.layout.gap.small,
  width: "100%",
});

export const label = () => css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

export const loading = () => css({
  display: "flex",
  justifyContent: "center",
  flexBasis: "100%",
  height: "100%",
  alignItems: "center"
});

export const number = theme => fontSize => css({
  textAlign: "center",
  fontSize: fontSize || "2.5rem", // 40px
  lineHeight: "100%",
  color: theme.colors.text,
  fontWeight: theme.fonts.weight.semiBold
});

export const barcodeContainer = theme => css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.layout.gap.small
});

export const barcode = () => css({
  width: "16.875rem"
});
