import { css } from "@emotion/react";

export const fade = {
  entering: css({
    opacity: 0.01,
  }),
  entered: css({
    opacity: 1,
    transition: "opacity 500ms ease-in",
  }),
  exiting: css({
    opacity: 1,
  }),
  exited: css({
    opacity: 0.01,
    transition: "opacity 300ms ease-in",
  }),
};

export const fade300 = {
  entering: css({
    opacity: 0.01,
  }),
  entered: css({
    opacity: 1,
    transition: "opacity 300ms ease-in",
  }),
  exiting: css({
    opacity: 1,
  }),
  exited: css({
    opacity: 0.01,
    transition: "opacity 300ms ease-in",
  }),
};

export const slideFade = {
  entering: css({
    opacity: 0.1,
    transform: "translateX(-100%)",
    transition: "opacity 5ms",
  }),
  entered: css({
    opacity: 1,
    transform: "translateX(0%)",
    transition: "opacity 500ms, transform 500ms",
  }),
  exiting: css({
    opacity: 0.,
    transform: "translateX(100%)",
    transition: "opacity 500ms, transform 500ms"
  }),
  exited: css({
    opacity: 1,
    transform: "translateX(0%)",
  }),
};
