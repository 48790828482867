import MOP from "../../../js/app";

export const getDialogPosition = (openDialogButtonClass = null, maxDialogHeight = null) => {

  if (!openDialogButtonClass) return {};

  const [domElem] = document.getElementsByClassName(openDialogButtonClass);

  if (!domElem) return {};

  const { bottom: bodyBottom } = document.body.getBoundingClientRect();

  const { top, bottom } = domElem.getBoundingClientRect();


  if (top > (bodyBottom / 2)) {

    // Se il bottone che apre il dialog, si trova oltre la metà dell'iframe,
    // Vado a settare la proprietà bottom del dialog
    // quindi uso il BOTTOM come riferimento per il dialog,
    // Quindi se il dialog deve crescere, lo farà verso l'alto e non verso il basso 
    // (e non si rischia l'overflow dall'iframe)


    if(!MOP.Utilities.empty(maxDialogHeight) && maxDialogHeight + (bodyBottom - bottom) >= document.getElementById("main-region").clientHeight){
      // Fissando l'attributo BOTTOM alla Dialog, se l'altezza totale della Dialog 
      // va in OVERFLOW verso l'ALTO non setto posizione absolute
      return;
    }

    return ({
      bottom: `${bodyBottom - bottom}px`,
      position: 'absolute'
    });


  }

  // Se il bottone che apre il dialog, si trova prima della metà dell'iframe,
  // Vado a settare la proprietà top del dialog
  // quindi uso il top come riferimento per il dialog
  // Quindi se il dialog deve crescere, lo farà verso il basso e non verso l'alto 
  // (e non si rischia l'overflow dall'iframe)


  if(!MOP.Utilities.empty(maxDialogHeight) && maxDialogHeight + top >= document.getElementById("main-region").clientHeight){
    // Fissando l'attributo TOP alla Dialog, se l'altezza totale della Dialog 
    // va in OVERFLOW verso il BASSO non setto posizione absolute
    return;
  }

  return ({
    top: `${top}px`,
    position: 'absolute'
  });


};