import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ color }) => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontSize: theme.fonts.size.medium,

  // Nei tag il colore deve essere impostato manualmente con un esadecimale, perchè questi colori in App
  // Tornano direttamente dal BE come stringhe esadecimali
  backgroundColor: color ? theme.colors[color] : theme.colors.black,
  width: "fit-content",
  // La larghezza massima è l'80% del contenitore che lo contiene.
  // Questa cosa è stata richiesta dal prodotto in seguito a questo ticket: https://tuotempo.freshdesk.com/a/tickets/89341
  // Questo tag attualmente è utilizzato solamente nella pagina pazienti, se verrà usato in futuro in altri punti, allora dovremo rivedere questa logica
  maxWidth: "80%", 
  padding: theme.layout.padding.micro,
  borderRadius: theme.layout.borderRadius.small,
  gap: theme.layout.gap.small
});

export const content = theme => ({ color }) => css({

  color: color ? theme.colors[color] : theme.colors.white,
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"

});
