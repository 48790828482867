import { fonts } from "./fonts";
import { layout } from "./layout";

export const button = {
  base: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    fontSize: fonts.size.medium,
    fontWeight: fonts.weight.regular,
    justifyContent: "center",
    lineHeight: 1,
    borderRadius: layout.borderRadius.small,
    gap: layout.gap.micro,
    opacity: "1",
    pointerEvents: "all",
    padding: `${layout.padding.small} ${layout.padding.xsmall}`
  },
  disabled: {
    opacity: ".5",
    pointerEvents: "none",
  },
  small: {
    padding: layout.padding.small
  }
};