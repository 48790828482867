import { combineReducers } from 'redux';

/* CERTIFICATION-WIDGET */
import certificationWidget from '../../common/components/certification-widget/features/slice';
/* CERTIFICATION-WIDGET */

import {
  SET_ACTIVE_PAGE,
  RIGHT_MENU_DROPDOWN_OPEN,
  SET_LOGGED_USER, SET_DISABLED,
  SET_HIDE_FAMILY_ITEM, SET_SHOWBACK,
  SET_UNREAD_NOTIFICATIONS,
  SET_HIDE_HEADER,
  SET_HIDE_BOTTOM_BAR,
  SET_SHOW_SIDE_MENU,
} from '../common/types';

const loggedUser = (state = null, action) => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return action.state;
    default:
      return state;
  }
};

const disabled = (state = false, action) => {
  switch (action.type) {
    case SET_DISABLED:
      return action.state;
    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return action.state;
    default:
      return state;
  }
};

const rightMenuDropdownOpen = (state = null, action) => {
  switch (action.type) {
    case RIGHT_MENU_DROPDOWN_OPEN:
      return action.state;
    default:
      return state;
  }
};

const hideFamilyItem = (state = false, action) => {
  switch (action.type) {
    case SET_HIDE_FAMILY_ITEM:
      return action.state;
    default:
      return state;
  }
};

const showBack = (state = false, action) => {
  switch (action.type) {
    case SET_SHOWBACK:
      return action.state;
    default:
      return state;
  }
};

const unreadNotifications = (state = 0, action) => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATIONS:
      return action.state;
    default:
      return state;
  }
};

const hideHeader = (state = false, action) => {
  switch (action.type) {
    case SET_HIDE_HEADER:
      return action.state;
    default:
      return state;
  }
};

const hideBottomBar = (state = false, action) => {
  switch (action.type) {
    case SET_HIDE_BOTTOM_BAR:
      return action.state;
    default:
      return state;
  }
};

const showSideMenu = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_SIDE_MENU:
      return action.state;  
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  loggedUser,
  disabled,
  activePage,
  rightMenuDropdownOpen,
  hideFamilyItem,
  showBack,
  unreadNotifications,
  hideHeader,
  hideBottomBar,
  showSideMenu,
  certificationWidget,
});

export default rootReducer;