import { createSlice } from "@reduxjs/toolkit";

import { getScore } from "./thunk/thunkCertificationWidget";

import { API_STATUS_PENDING, API_STATUS_IDLE, API_STATUS_FULFILLED, API_STATUS_REJECTED } from 'common-mop/constants';

const initialState = {
  apiStatus: API_STATUS_IDLE,
  data: {},
  error: false,
};

export const certificationWidgetSlice = createSlice({
  name: "certificationWidget",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getScore.pending, (state, {}) => {
        state.apiStatus = API_STATUS_PENDING;
      })
      .addCase(getScore.fulfilled, (state, { payload }) => {
        state.apiStatus = API_STATUS_FULFILLED;
        state.data = payload;
      })
      .addCase(getScore.rejected, (state, { payload: { exception }}) => {
        state.apiStatus = API_STATUS_REJECTED;
        state.error = true;
      });

  },
});

export default certificationWidgetSlice.reducer;
