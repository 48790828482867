import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MOP from 'app';
import { OutClick } from 'common-lib/components/hoc/OutClick';


const LanguageTab = ({languages, open, onClick, hocRef}) => {

  const langClicked = lang => {
    return MOP.changePage(MOP.getCurrentPage(), MOP.getCurrentRoute(), null, null, null, lang);
  };

  const dropdownRef = useRef();
  useEffect(() => {
    if(open) {
      const bodyHeight = document.querySelector('body').clientHeight;
      if((dropdownRef.current.getBoundingClientRect().top + dropdownRef.current.clientHeight) > bodyHeight) {
        MOP.TM.resizeThisFrame((dropdownRef.current.getBoundingClientRect().top + dropdownRef.current.clientHeight) - bodyHeight + 10);
      }            
    } else {
      MOP.TM.resizeThisFrame();
    }
  }, [open]);
  
  return (
    <li className={`dropdown language_tab tt-navbar__link ${open ? 'open' : ''}`} onClick={onClick} ref={hocRef}>
      <a className="dropdown-toggle tt-navbar__link__text" data-e2eid="language-menu">
        <i className="tt-navbar__link__text-icon fal tt-icon-globe-light"></i>
      </a>
      <ul className="dropdown-menu tt-navbar__link-dropdown" role="menu" ref={dropdownRef}>
        {Object.keys(languages).map((lang, index) => {
          return <li key={index} onClick={() => langClicked(lang)}><a value={lang} data-e2eid={`language-menu-${lang}`} dangerouslySetInnerHTML={{ __html: languages[lang] }}></a></li>;
        })}
      </ul>
    </li>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

const LanguageTabContainer  = connect(mapStateToProps, mapDispatchToProps)(LanguageTab);

export default OutClick(LanguageTabContainer);
