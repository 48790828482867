
import { css, keyframes } from "@emotion/react";

const rotateAnimation = keyframes`
    0%{transform: rotate(0deg)},
    100%{transform: rotate(359deg)},
    `;


export const base = theme => ({ color, size, rotate }) => {
  return (css({

    fontSize: `${size ? theme.icons.sizes[size] : theme.icons.sizes.small}`,
    "& > svg": {
      height: `1em`,
      width: `1em`,
    },
    display: "flex",
    color: theme.colors[color] || "",
    fill: "currentcolor",
    flexDirection: "row",
    alignItems: "center",


    animation: rotate ? `${rotateAnimation} 2s linear infinite` : "",
  })
  )
}