import { useEffect, useState } from "react";

// Questa funzione e' stata messa qui perche' usata sia da uikit che dentro il pdf generato nella cartellina "reservation"
const useFetchCheckinImage = url => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const blobToBase64 = async blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsBinaryString(blob);
    });
  };

  useEffect(() => {
    (
      async function () {
        try {
          setLoading(true);
          const res = await fetch(url);
          const blobData = await res.blob();
          const base64 = await blobToBase64(blobData);
          base64 && setData(base64);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    )();
    
  }, [url]);

  return { data, error, loading };

};

export default useFetchCheckinImage;