import React from 'react';
import PropTypes from 'prop-types';
import { TTPolyglot } from "@frontend/tt-polyglot";

import * as style from "./style";

import { Label } from "ui/Label";

export const AppPromotionBanner = ({ android, ios }) => {

  if (!android && !ios) return null;

  return (
    <div css={style.base}>
      <div css={style.header}>
        <Label size="large" weight="semiBold">
          {TTPolyglot.t("Download Mobile App")}
        </Label>
        <Label color="text_light" size="medium">
          {TTPolyglot.t("Download Mobile App Desc")}
        </Label>
      </div>
      <div css={style.buttons}>
        {ios &&
          <button css={style.button(false)} onClick={() => window.open(ios.link, "_blank")}>
            <img src={ios.imgSrc} alt="apple-store-logo" />
          </button>
        }
        {android &&
          <button css={style.button(true)} onClick={() => window.open(android.link, "_blank")}>
            <img src={android.imgSrc} alt="google-play-store-logo" />
          </button>
        }
      </div>
    </div>
  );
};

const shape = {
  imgSrc: PropTypes.string,
  link: PropTypes.string,
};

AppPromotionBanner.propTypes = {
  android: PropTypes.shape(shape),
  ios: PropTypes.shape(shape),
};

AppPromotionBanner.defaultProps = {
  android: null,
  ios: null
};