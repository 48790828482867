export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const loadJsCssFile = (filename, fileAttributes, callback, errorCallback) => {
  return new Promise(resolve => {

    let workFileName = filename;
    const filetype = (workFileName.indexOf('js') !== -1) ? 'js' : 'css';
    let fileref;

    const errorOnloadCallback = () => {
      resolve(false);

      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback();
      }
    };

    const standardOnloadCallback = () => {
      resolve(true);

      if (callback && typeof callback === 'function') {
        callback();
      }
    };

    if (filetype === 'js') {
      fileref = document.createElement('script');

      fileref.setAttribute('type', 'text/javascript');

      if (callback) {
        if (workFileName.indexOf('google') !== -1) {
          workFileName += `&callback=${callback}`;
        }
      }

      fileref.setAttribute('src', workFileName);

    } else if (filetype === 'css') {
      fileref = document.createElement('link');

      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute('href', workFileName);

    }

    fileAttributes.map(({ attributeName, attributeVal }) => {
      fileref.setAttribute(attributeName, attributeVal);
    });

    if (filetype === 'js') {
      document.body.appendChild(fileref);
    } else if (filetype === 'css') {
      document.getElementsByTagName('head')[0].appendChild(fileref);
    }

    fileref.onerror = errorOnloadCallback;

    fileref.onload = standardOnloadCallback;
  });
};

/* Upload Input */
const getAllowedTypes = isAdmin => {
  return (
    isAdmin ?
      [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/pkcs7-mime",
        "application/x-pkcs7-mime",
        "application/msword",
        "application/dicom",
        "image/dcm",
        "image/x-dicom",
        "application/octet-stream",
        "video/mp4",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ] :
      [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/msword",
        "video/mp4",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ]
  );
};

export const getAllowedTypesName = isAdmin => {
  return (
    isAdmin ?
      [
        "pdf",
        "jpeg",
        "png",
        "zip",
        "pkcs7",
        "x-pkcs7",
        "msword",
        "dicom",
        "dcm",
        "x-dicom",
        "octet-stream",
        "mp4"
      ] :
      [
        "pdf",
        "jpeg",
        "png",
        "msword",
        "mp4"
      ]
  )
}

export const INPUT_ACCEPT = "image/*, .pdf, .doc, .docx, application/msword, application/pdf, application/dicom"

export const fileAllowed = async (file, isAdmin = false) => {
  try {
    const FileType = require('file-type/browser');

    const allowed = getAllowedTypes(isAdmin);

    const mimeType = await (await FileType.fromBlob(file)).mime;

    return allowed.indexOf(mimeType) > -1;

  } catch (e) {
    return false;
  }
}
/* FINE - Upload Input */