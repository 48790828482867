import { css } from "@emotion/react";

export const base = () => css({
  "@media print": {
    display: "none",
  },
});

export const content = theme => () => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.medium,
  justifyContent: "center"
});

export const buttonContainer = () => css({
  marginLeft: "auto"
});