import { connect } from 'react-redux';
import MOP from 'app';
import { Header } from '../components/Header';

const mapStateToProps = state => {
  
  return {
    disabled: state.disabled,
    active: state.activePage,
    loggedUser: state.loggedUser,
    logo: MOP.config.getInstanceConfig('showHeaderLogo'),
    hide: state.hideHeader
  };
};
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);