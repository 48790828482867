import MOP from "app";
import "templates/footer/templates";

import { TTPolyglot } from "@frontend/tt-polyglot";

    MOP.module("FooterApp.Show.View", function(View, MOP, Backbone, Marionette, $, _){

        View.Footer = Marionette.ItemView.extend({
            templateName: 'footer',
            // template: Handlebars.templates.footer,

            className: 'tt-container',

            translations: {
                'MopAwaitingTime': TTPolyglot.t('Mop Awaiting Time')
            },

            events: {
                'click #mop_waiting_time_div':  'showWaitingTime',
                'click .tt_logo':               'openLink',
                'click #iFrameReturnPageBtn':      'returnPageClicked'
            },
            
            behaviors: {
                ViewTemplateBehavior: {}
            },

            initialize: function () {
                MOP.on('subapp:start', this.render);

                MOP.on('subapp:stop', function (appName) {
                    // TODO [Mattia]
                });
            },

            serializeData: function() {
                    var currentPage = MOP.getPageFromRoute(),
                    footerLogo = MOP.config.getInstanceConfig('showFooterLogo'),
                    footerHtml = MOP.config.getInstanceConfig('showFooterHtml'),
                    whiteMop = MOP.config.getInstanceConfig('whiteMop'),
                    legacyName = MOP.config.getInstanceConfig('legacyName'),
                    web = MOP.config.getInstanceConfig('web'),
                    firstAvailLastSearchTimestamp = MOP.config.getInstanceConfig('firstAvailLastSearchTimestamp'),
                    showActivitiesFirstAvailability = MOP.config.getInstanceConfig('showActivitiesFirstAvailability'),
                    model = {};

                if (MOP.Utilities.empty(whiteMop)) {
                    model.whiteMop = whiteMop;
                }

                if (legacyName == 'gipo') {
                    model.gipoFooter = true;
                }

                if (!MOP.Utilities.empty(footerLogo)) {
                    model.show_footer_logo = footerLogo;
                }

                if (MOP.Utilities.empty(footerLogo) && !MOP.Utilities.empty(footerHtml)) {
                    model.show_footer_html = footerHtml;
                }

                if (!MOP.Utilities.empty(firstAvailLastSearchTimestamp) &&
                    !MOP.Utilities.empty(showActivitiesFirstAvailability) &&
                    (MOP.isSearchPage(currentPage) || currentPage.indexOf('availabilities') != -1)
                    ) {
                    model.showFirstAvailabilities = true;
                }

                model.currentPage = (MOP.currentApp && MOP.currentApp.page) || '';

                model.footerMessage = !MOP.Utilities.empty(web) ? 'TuoTempo' : TTPolyglot.t('Trial Message');
                
                switch (MOP.config.getInstanceConfig('defaultPrefix')) {
                    case "+34":
                        model.footerURL = "https://www.tuotempo.es"
                        break;
                    case "+39":
                        model.footerURL = "https://www.tuotempo.it"
                        break;
                    case "+52":
                        model.footerURL = "https://www.tuotempo.com.mx"
                        break;
                    case "+55":
                        model.footerURL = "https://www.tuotempo.com.br"
                        break;
                    default:
                        model.footerURL = "https://tuotempo.com"
                        break;
                }

                if (!MOP.Utilities.empty(MOP.querystring['mopIframeReturnPage']) && MOP.config.isMobile()) {
                  model.mopIframeReturnPage = MOP.querystring['mopIframeReturnPage'];
                  model.btnReturnPageBottomStyle = 0;
                  
                  if (MOP.getCurrentPage() == 'find_location') {
                    model.btnReturnPageBottomStyle = '65px';
                  }
                }
                return _.extend({}, model, this.translations);
            },

            showWaitingTime: function () {
                MOP.changePage('first_availability');
            },

            openLink: function (e) {
                e.preventDefault();

                var $target = $(MOP.TM.getTarget(e));

                MOP.TM.openNewTabLink($target.attr('href'));
            },
            
            returnPageClicked: function (e) {
              e.preventDefault();
              
              var url = MOP.querystring['mopIframeReturnPage'];
              
              if(MOP.querystring['mopIframeReturnPage'].indexOf("?") != -1) {
                  url += "&"
              } else {
                  url += "?"
              }
              
              url += "dontLetMopIframeBecomeParentOnMobile=1";
              
              window.location.href = url;
            }
        });
    });

    export default MOP.FooterApp.Show.View; 