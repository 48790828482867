import "globals";
import '@babel/polyfill';
import MOP from 'app';
import Backbone from 'backbone';
import _ from 'underscore';
import $ from 'jquery';
import Bootstrap from 'bootstrap';
import Handlebars from 'handlebars';
import _storage from 'storage';
import backbone_fetch_cache from 'fetch-cache';
import {getLongDateFromTimestamp} from 'common-lib/dates';
import { querystringToObject, isProduction } from 'common-lib/url';
import { _ajaxRest } from 'api/http';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { getCreditCardIcon } from './lib/common/payment';

// [FIXME] Mattia - Oggetto globale usata da callback per tt_ico
if (typeof window.docCookies === 'undefined') {
    window.docCookies = {
        getItem: function (sKey) {
            if (!sKey) {
                return null;
            }

            return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
        },

        setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            var sExpires = "";
            if (vEnd) {
                switch (vEnd.constructor) {
                    case Number:
                        sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                        break;
                    case String:
                        sExpires = "; expires=" + vEnd;
                        break;
                    case Date:
                        sExpires = "; expires=" + vEnd.toUTCString();
                        break;
                }
            }

            document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");

            return true;
        },

        removeItem: function (sKey, sPath, sDomain) {
            if (!this.hasItem(sKey)) {
                return false;
            }

            document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");

            return true;
        },

        hasItem: function (sKey) {
            if (!sKey) {
                return false;
            }
            return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
        },

        keys: function () {
            var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
            for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
                aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
            }
            return aKeys;
        }
    };
}

// var originalTimeout = window.setTimeout;

// // Passando come terzo parametro true si fa in modo
// // che non vengano eseguite altre setTimeout fintanto
// // che la funzione chiamata con true non è stata eseguita.
// // -------------------------------------------------------
// // utile per debuggare l'ordine in cui vengono eseguite
// // una serie di setTimeout
// window.setTimeout = (function () {
//     var paused = false;

//     return function () {
//         var _this = this;
//         var toPause = arguments[2] || false;
//         var originalFunction = arguments[0];
//         var functionName = originalFunction.toString()
//                                 .replace(/\r?\n|\r/g, '')
//                                 .replace(/function\s*(\S*)\s*\(.*/, '$1');

//         console.log('Timeout function:', functionName, arguments[0] || arguments[0].toString());
//         console.trace();

//         function myFunction() {
//             paused = false;
//             originalFunction.call(_this);
//         }

//         var wrappedFunction = !toPause ? originalFunction : myFunction;

//         !paused && originalTimeout.call(this, wrappedFunction, arguments[1]);

//         paused = toPause;
//     };
// }());

// Se questo caricamento deriva da un secondo caricamento
// fatto non eseguo lo start dell'applicazione
var disableMainBuildStart = window.docCookies.getItem('disableMainBuildStart');
var mainLoaded = window.docCookies.getItem('mainLoaded');
var startApplication = true;

// Se true la build proviene da caricamento parziale.
if (disableMainBuildStart) {
    window.docCookies.removeItem('disableMainBuildStart');

    // La build corrente è una build parziale e non la main build.
    if (disableMainBuildStart == 1) {
        window.docCookies.setItem('disableMainBuildStart', 2);
    }
    // La build corrente è la build totale ed è già stata startata la build parziale,
    // voglio che non venga ri-eseguito lo start dell'applicazione in questo caso.
    else if (disableMainBuildStart == 2) {
        startApplication = false;
    }
}

if (startApplication) {

        //http://redmine.tuotempo.net/issues/10857
        // Questa funzione ci permette utilizzando un plugin SchemaUrl per cordova di aprire l'applicazione tramite schemaUrl
        // Questo plugin ha un bug che non è stato risolto dal creatore, in pratica intercetta tutti i window.open("", "_system")
        // che vengono aperti nell'app, questo comporta che se apriamo un qualsiasi url che contiene un sessionid o un forcePage
        // andiamo a far scattare tutta la logica che c'è qui sotto, per questo motivo andiamo a mettere dei controlli sul customerConfig
        window.handleOpenURL = function (url) {
          setTimeout(function() {
            console.log("received url: " + url);
            MOP.Utilities.handleSchemaUrl(MOP, url);
            
          }, 0);
        }

        window.Handlebars = Handlebars;

        Handlebars.registerHelper('currencySymbol', function() {
            return MOP.config.getCurrencySymbol();
        });

        Handlebars.registerHelper('start_date_year', function() {
            var date = new Date(this.start_date_timestamp * 1000);

            return date.getUTCFullYear();
        });

        Handlebars.registerHelper('start_date_year_abbr', function() {
            var date = new Date(this.start_date_timestamp * 1000);

            return date.getUTCFullYear().toString().slice(-2);
        });

        Handlebars.registerHelper('start_date_day', function() {
            var date = new Date(this.start_date_timestamp * 1000);

            return date.getUTCDate();
        });

        //Format date 30/05/20XX in:
        //if type==0 -> 30 Mag
        //if type==1 -> 30 Maggio
        Handlebars.registerHelper('formatDate', function(date, type) {

           var monthNames = [
              "Gennaio", "Febbraio", "Marzo",
              "Aprile", "Maggio", "Giugno", "Luglio",
              "Agosto", "Settembre", "Ottobre",
              "Novembre", "Dicembre"
            ];

            if(date) {
                var arrayDate = date.split('/');
                var day = arrayDate[0];
                var month = arrayDate[1];
                var year = arrayDate[2];
                switch(type){
                    case 0: {
                        return day + " " + monthNames[parseInt(month)-1].slice(0,3);
                    }
                    case 1: {
                        return day + " " + monthNames[parseInt(month)-1] + " " + year;
                    }
                }
            }
            return null;
        });


        Handlebars.registerHelper('formatFaceImg', function(face_img_link) {
            var imgLink;

            if (!face_img_link) {
                imgLink = `${MOP.config.getMopImageBaseUrl()}/sagoma.jpg`;
            } else {
                imgLink = face_img_link;
            }

            return imgLink;
        });

        Handlebars.registerHelper('formatLocationImg', function(face_img_link) {
            var imgLink;

            if (!face_img_link) {
                imgLink = `${MOP.config.getMopImageBaseUrl()}/default-img-location.jpg`;
            } else {
                imgLink = face_img_link;
            }

            return imgLink;
        });

        Handlebars.registerHelper('greater', function(left, right, equal, options) {
            options = options || equal;
            equal = (equal === 'equal') ? true : false;

            var is_greater = equal ? (left >= right) : (left > right);

            if (is_greater) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('formattedAreaTitle', function() {
            return !!this.webTitle ? this.webTitle : (!!this.areaTitle ? this.areaTitle : '');
        });

        Handlebars.registerHelper('formattedAreaTitleWidget', function() {
            var formattedAreaTitle = !!this.webTitle ? this.webTitle : (!!this.areaTitle ? this.areaTitle : '');

            return formattedAreaTitle + (!!this.city ? (' - ' + MOP.Utilities.upperCaseFirstLetterEveryWord(this.city)) : '');
        });

        Handlebars.registerHelper('getActivityTitle', function() {

          return !!this.activity_web_title ? this.activity_web_title : (!!this.activityTitle ? this.activityTitle : '');
        });

        Handlebars.registerHelper('hostname', function() {
            return MOP.config.get('hostname');
        });

        Handlebars.registerHelper('dbName', function() {
            return MOP.getDbName();
        });

        Handlebars.registerHelper('isLoggedIn', function(options) {
            return MOP.isLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isNotLoggedIn', function(options) {
            return !MOP.isLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isAdminLoggedIn', function(options) {
            return MOP.isAdminLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isNotAdminLoggedIn', function(options) {
            return !MOP.isAdminLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isNotOneTimeUser', function(options) {
            return !MOP.isOneTimeUser() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isOneTimeUser', function(options) {
            return MOP.isOneTimeUser() ? options.fn(this) : options.inverse(this);
        });
        
        Handlebars.registerHelper('isResourceLoggedIn', function(options) {
            return MOP.isResourceLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isAdvancedResourceLoggedIn', function(options) {
            return MOP.isAdvancedResourceLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isBaseResourceLoggedIn', function(options) {
          return MOP.isBaseResourceLoggedIn() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isOperatorOrAdvancedResource', function(options) {
          return MOP.isOperatorOrAdvancedResource() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isAppleDevice', function(options) {
            return MOP.Utilities.isAppleDevice() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('protocol', function() {
            return location.protocol.split(':')[0];
        });

        Handlebars.registerHelper('rightProtocol', function() {
            return MOP.config.getRightProtocol();
        });

        Handlebars.registerHelper('ifFalse', function(property, options) {
            if (!property) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        //FIXME PIERO Questo helper sembra non funzionare, indagare su cosa fa ifCond, e options.fn options.inverse
        Handlebars.registerHelper("mathCondition", function(first, operator, second, operator2, third, operator3, fourth, options) {
          var one = Handlebars.helpers.math(first,operator,second);
          var two = Handlebars.helpers.math(parseInt(one),operator2,third);
          var cond = Handlebars.helpers.ifCond(parseInt(two),operator3,fourth,options);
          return cond;
        });

        Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
          lvalue = parseFloat(lvalue);
          rvalue = parseFloat(rvalue);

            switch (operator) {
              case "+":
                return lvalue + rvalue;
              case "-":
                return lvalue - rvalue;
              case "*":
                return lvalue * rvalue;
              case "/":
                return lvalue / rvalue;
              case "%":
                return lvalue % rvalue;
            }
        });

        Handlebars.registerHelper('ifCond', function(v1, operator, v2, options) {
            switch (operator) {
                case '!=':
                    return (v1 != v2) ? options.fn(this) : options.inverse(this);
                case '==':
                    return (v1 == v2) ? options.fn(this) : options.inverse(this);
                case '===':
                    return (v1 === v2) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case '&&':
                    return (v1 && v2) ? options.fn(this) : options.inverse(this);
                case '||':
                    return (v1 || v2) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        });

        Handlebars.registerHelper('configValue', function(configKey, defaultValue, options) {
            var configValue = MOP.config.getInstanceConfig(configKey);
            if (!MOP.Utilities.empty(configValue)) {
                return configValue;
            } else {
                return defaultValue;
            }
        });

        Handlebars.registerHelper('isDesktop', function(value, options) {
            if (value == 'web') {
                return (!MOP.config.isMobile() && !MOP.Utilities.isMobileApp()) ? options.fn(this) : options.inverse(this);
            } else {
                return MOP.config.isDesktop() ? options.fn(this) : options.inverse(this);
            }
        });
        
        Handlebars.registerHelper('translateIDType', function(value) {
            return MOP.Utilities.translateIDType(MOP, value);
        });
        
        Handlebars.registerHelper('arrayToStringWithJoiner', function(value, joiner) {
            if (joiner) {
              return value.join(joiner)
            }

            return value.join();
        });

        Handlebars.registerHelper('isArrayorObjectNotEmpty', function(value, options) {
          var test = false;

          if (value.constructor === Object) {
            if (value && Object.keys(value).length !== 0) test = true;
          } else if (value.constructor === Array) {
            if (value && value.length !== 0) test = true;
          }

          return test ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('checkConfig', function(configKey, operator, value, options) {
            var configValue = MOP.config.getInstanceConfig(configKey);

            switch (operator) {
                case '!=':
                    return (configValue != value) ? options.fn(this) : options.inverse(this);
                case '==':
                    return (configValue == value) ? options.fn(this) : options.inverse(this);
                case '===':
                    return (configValue === value) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (configValue < value) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (configValue <= value) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (configValue > value) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (configValue >= value) ? options.fn(this) : options.inverse(this);
                case '&&':
                    return (configValue && value) ? options.fn(this) : options.inverse(this);
                case '||':
                    return (configValue || value) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        });

        Handlebars.registerHelper('ucFirst', function(value, options) {
            return MOP.TM.ucFirstAll(value);
        });

        Handlebars.registerHelper('translate', function(value) {
            var args = Array.prototype.slice.call(arguments, 1, arguments.length - 1);
            var newArgs = [];

            _.reduce(args,
                function(memo, elem) {
                    if (elem.indexOf('conf:') !== -1) {
                        elem = MOP.config.getInstanceConfig(elem.substring(5));
                    }

                    memo.push(elem);

                    return memo;
                }, newArgs);

            return TTPolyglot.t(value, newArgs, true);
        });

        Handlebars.registerHelper('indexOf', function(left, right, options) {
            if (left.indexOf(right) !== -1) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('lang', function() {
            return MOP.config.get('lang');
        });

        Handlebars.registerHelper('MOPFunction', function (functionName, params) {
            return MOP[functionName](params);
        });
      
        Handlebars.registerHelper('humanDateLongFormat', function (start_date_day_abbr_week, start_date, start_date_month) {
            return MOP.Utilities && MOP.Utilities.humanDateLongFormat(MOP, start_date_day_abbr_week, MOP.Utilities.humanDateToDateObjDate(MOP, start_date), start_date_month, MOP.Utilities.humanDateToDateObjYear(MOP, start_date));
        });
        
        Handlebars.registerHelper('getLongDateFromTimestamp', function (timestamp) {
            return getLongDateFromTimestamp(MOP, timestamp);
        });
        
        Handlebars.registerHelper('humanDateToDateObjDate', function (params) {
            return MOP.Utilities && MOP.Utilities.humanDateToDateObjDate(MOP, params);
        });

        Handlebars.registerHelper('humanDateToDateObjYear', function (params) {
            return MOP.Utilities && MOP.Utilities.humanDateToDateObjYear(MOP, params);
        });
        
        Handlebars.registerHelper('unixToHumanDate', function (param) {
          var date = new Date(param*1000);
          return MOP.Utilities && MOP.Utilities.dateObjToHumanDate(MOP, date);
        });

        Handlebars.registerHelper('unixToHumanDateTime', function (param) {
            var date = new Date(param*1000);
            return MOP.Utilities && MOP.Utilities.dateObjToHumanDateTime(MOP, date);
          });

        Handlebars.registerHelper('isConfigTrue', function (configFunction, options) {
            return MOP.config[configFunction]() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isKioskMop', function (value, options) {
            value = (typeof value == 'undefined') ? true : value;

            return MOP.isKioskMop() == value ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('getConfigConstant', function(constant) {
            return MOP.config.constants[constant];
        });

        Handlebars.registerHelper('onlyNumberInput', function(constant) {
            return "return ( event.ctrlKey || event.altKey || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (95<event.keyCode && event.keyCode<106) || (event.keyCode==8) || (event.keyCode==9) || (event.keyCode>34 && event.keyCode<40) || (event.keyCode==46) )";
        });

        Handlebars.registerHelper('isMobileApp', function(options) {
            return MOP.Utilities.isMobileApp() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isNotMobileApp', function(options) {
            return !MOP.Utilities.isMobileApp() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isMobile', function(options) {
            return MOP.config.isMobile() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isNotMobile', function(options) {
            return !MOP.config.isMobile() ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('showSelectInstance', function(options) {
            return (!MOP.Utilities.empty(window.MOP_globals.customerConfig) && !MOP.Utilities.empty(window.MOP_globals.customerConfig.showSelectInstance)) ? options.fn(this) : options.inverse(this)
        });

        Handlebars.loadRightTemplate = function (templateName) {
            var formattedTemplateName = Handlebars.getRightTemplate(templateName);

            return Handlebars.templates[formattedTemplateName] ? Handlebars.templates[formattedTemplateName] : Handlebars.templates[templateName];
        };

        Handlebars.getRightTemplate = function (templateName) {
            return templateName + (MOP.config.isMobile() ? '-mobile' : '');
        };

        Handlebars.registerHelper('isMenuPageVisibile', function (page, options) {
            if (MOP.TM.isMenuPageVisibileForUserRole(page)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

      Handlebars.registerHelper('isNotFromTRD111', function (options) {
          if (!MOP.isFromTRD111()) {
              return options.fn(this);
          }

          return options.inverse(this);
      });
        
        Handlebars.registerHelper('getMenuPageTitle', function (page, fallbackTitle) {
            return MOP.TM.getMenuPageTitle(page,fallbackTitle);
        });

        Handlebars.registerHelper('getImgPath', function () {
            return `${MOP.config.getMopImageBaseUrl()}`;
        });

        Handlebars.registerHelper('getMenuPageCustomValue', function (page, custom, options) {

            return MOP.TM.getMenuPageCustomValue(page, custom);
        });

        Handlebars.registerHelper('getMenuPageUrl', function (page, options) {
            return MOP.TM.getMenuPageUrl(page);
        });

        Handlebars.registerHelper('isMenuPageKeySet', function (page, key, options) {
            if (MOP.TM.isMenuPageKeySet(page, key)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('mopLocalStorageKeyIsTrue', function (key, options) {
            if (MOP.localStorage.has(key) && !MOP.Utilities.empty(MOP.localStorage.get(key))) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('mopLocalStorageKeyNotExistOrTrue', function (key, options) {
          if (!MOP.localStorage.has(key) || (MOP.localStorage.has(key) && !MOP.Utilities.empty(MOP.localStorage.get(key)))) {
              return options.fn(this);
          }

          return options.inverse(this);
      });

        Handlebars.registerHelper('isNotEmpty', function (value, options) {
            if (!MOP.Utilities.empty(value)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('isEmpty', function (value, options) {
            if (MOP.Utilities.empty(value)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('hasParents', function (options) {
            var parents = [];

            if (MOP.isLoggedIn()) {
                parents = MOP.getLoggedUserData().parents;
            }

            if (parents.length != 0) {
                return options.fn(this);
            }

            return options.inverse(this);
        });

        Handlebars.registerHelper('showResources', function(options) {
            return MOP.isSearchEntitiesVisible('resources') ? options.fn(this) : options.inverse(this);
        });

        Handlebars.registerHelper('isMenuVisible', function (options) {

            var isVisible = true;
            var device = (MOP.config.isMobile()) ? 'mobile' : 'desktop';

            if (MOP.Utilities.empty(MOP.config.getInstanceConfig('menuVisibility')[device])) {
                isVisible = false;
            }

            if (MOP.Utilities.empty(isVisible)) {
                return options.inverse(this);
            }

            return options.fn(this);
        });

        Handlebars.registerHelper('existTagWithImg', function (tag, options) {
            if (tag.indexOf('__') != -1) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        Handlebars.registerHelper('getTagFromTagImg', function (tag) {
            if (tag.indexOf('__') != -1) {
                var tmp = tag.split('__');
                return tmp[0];
            }
            return tag;
        });

        Handlebars.registerHelper('getImgFromTagImg', function (tag) {
            if (tag.indexOf('__') != -1) {
                var tmp = tag.split('__');
                return tmp[1];
            }
            return tag;
        });

        Handlebars.registerHelper('splitTagFromImgToHTML', function (tag) {
            if (tag.indexOf('##') != -1) {
                var tmp = tag.split('##');
                return tmp[0];
            }
            return tag;
        });

        Handlebars.registerHelper('convertBR', function (msg) {
            var _msg = MOP.Utilities.empty(msg) ? '' : msg;
            return _msg.replace(/\n/ig, '<br>');;
        });

        Handlebars.registerHelper('getCreditCardIcon', function (creditCardType) {
            return getCreditCardIcon(creditCardType)
        });

				Handlebars.registerHelper('creditCardStars', function () {
					return '**** **** **** ';
				});
				
				Handlebars.registerHelper('isStringNotEmpty', function (string, options) {
          if (string === undefined || string.trim().length === 0) {
						return options.inverse(this);
					}

					return options.fn(this); 
        });

        // Funzioni aggiuntive jQuery
        $.fn.selectRange = function(start, end) {
            if (!end) {
                end = start;
            }

            return this.each(function() {
                if (this.setSelectionRange) {
                    this.focus();
                    this.setSelectionRange(start, end);
                } else if (this.createTextRange) {
                    var range = this.createTextRange();

                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
        };
        $.cssHooks.backgroundColor = {
            get: function(elem) {
                function hex(x) {
                    return ("0" + parseInt(x).toString(16)).slice(-2);
                }

                if (elem.currentStyle)
                    var bg = elem.currentStyle["backgroundColor"];
                else if (window.getComputedStyle)
                    var bg = document.defaultView.getComputedStyle(elem,
                        null).getPropertyValue("background-color");
                if (bg.search("rgb") == -1)
                    return bg;
                else {
                    bg = bg.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

                    return "#" + hex(bg[1]) + hex(bg[2]) + hex(bg[3]);
                }
            }
        };
        // Funzioni aggiuntive Underscore

        /**
         * GroupBy innestato
         *
         * @param {Object} obj - literal da ordinare
         * @param {Array} values - elementi su cui eseguire gli ulteriori groupBy
         * @param context
         * @returns {Object}
         */
        _.groupByMulti = function(obj, values, context) {
            if (!values.length) {
                return obj;
            }

            var byFirst = _.groupBy(obj, values[0], context);
            var rest = values.slice(1);

            for (var prop in byFirst) {
                byFirst[prop] = _.groupByMulti(byFirst[prop], rest, context);
            }

            return byFirst;
        };

        /**
         * Ritorna il primo elemento di un oggetto
         *
         * @param {Object}
         * @returns {Object}
         */
        _.firstElem = function(obj) {
            function firstKey(obj) {
                return _.keys(obj)[0];
            }

            function elemByKey(obj, elem) {
                return obj[elem];
            }

            return _.compose(_.partial(elemByKey, obj), firstKey)(obj);
        };

        // Handler globale sul resize orizzontale della window.
        $(window).on('resize', function() {
            if (MOP.config) {
                if (MOP.config.isMobile() && !MOP.config.from_mobile) {
                    MOP.vent.trigger('view:mobile');
                    MOP.config.from_mobile = true;
                    MOP.Utilities.log(MOP, 'mobile');
                } else if (!MOP.config.isMobile() && MOP.config.from_mobile){
                    MOP.vent.trigger('view:desktop');
                    MOP.config.from_mobile = false;
                    MOP.Utilities.log(MOP, 'desktop');
                }
            }
        });

        // Handler globale sulla pressione del pulsante invio,
        // attivato solo nella pagina login e profile.
        $(window).on('keypress', function (e) {
            var enabledPage = ['profile'];

            if (e.keyCode === 13 && enabledPage.indexOf(MOP.getCurrentPage()) !== -1) {
                e.stopPropagation();
                e.preventDefault();
            }
        });


        if (MOP.Utilities.isMobileApp() && MOP.Utilities.isAppleDevice()) {
          $(document).on('focus', 'input', function() {
            $('body').addClass('fixfixed');
          });

          $(document).on('blur', 'input', function() {
            $('body').removeClass('fixfixed');
          });

        }

        var start = function start(querystring_params) {
          Backbone.ajax = _ajaxRest;
        
          // il controllo della connessione all'apertutra dell'app viene fatto qui così possiamo richiamare lo start
          
          if (MOP.Utilities.isMobileApp() && !window.navigator.onLine) {
            MOP.Utilities.loadRecoveryDeviceLang(MOP).then(() => {
              return setTimeout(() => navigator.notification.confirm(
                TTPolyglot.t("MOP App Device Offline On Start Message"),  // message
                () => start(querystring_params),         // callback
                TTPolyglot.t("MOP App Device Offline On Start Title"),            // title
                TTPolyglot.t("Retry")            // buttonName
            ), 0)
            })
          }
          if (disableMainBuildStart == 1 && !Boolean(mainLoaded)) {
            window.bundleUtilities2(window.MOP_globals).downloadAndCacheMainBuild();
          }
            //wrapper per poter scrivere insuranceTitle piuttosto che insurance_title
            if (!MOP.Utilities.empty(querystring_params['insuranceTitle'])) {
                querystring_params['insurance_title'] = querystring_params['insuranceTitle'];
            }

            // Se è forzato il forceLog=true setto l'env a development,
            // in questo modo i MOP.log sono visibili in console.
            var forceLog = querystring_params['forceLog'];
            var env = (forceLog && forceLog === 'true') ? 'developement' : 'production';
            var initOptions = {
                querystring: querystring_params,
                environment: env
            };

            //NB Prime the cache from localStorage on initialization => commentato nella libreria
            $.when(Backbone.fetchCache.getLocalStorage())
                .done(function() {
                    // Inizializzo il MOP, tra cui:
                    //     1) inizializzazione *Storage,
                    //     2) startNewMop(),
                    //     3) new Config()
                    $.when(MOP.init(initOptions))
                        .done(async function() {

                          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('MopParentLocationsTrackingEnabled'))) {
                            MOP.sendMOPLocation();
                          }
                            // Gestione globale di exception, in questo punto possiamo
                            // catturare tutte le eventuali eccezioni che bloccherebbero il
                            // flusso e non gestite e salvarle in un log.
                            /*window.onerror = function (msg, url, lineNo, columnNo, error) {
                                if (msg + ''.indexOf('TUOTEMPO_') == -1) {
                                    var data = {};
                                    var body = '';

                                    body += 'Message: ' + msg + '\n';
                                    body += 'Url: ' + url + '\n';
                                    body += 'Line number: ' + lineNo + '\n';
                                    body += 'Column number: ' + columnNo + '\n';
                                    body += 'Error: ' + error + '\n';
                                    body += 'dbName: ' + MOP_globals.dbName + '\n';
                                    body += 'Current Page: ' + MOP_globals.page + '\n';
                                    body += 'Browser: ' + window.navigator.userAgent + '\n';
                                    body += 'Environment: ' + MOP.Utilities.isMobileApp() ? 'App' : 'Web' + '\n';

                                    data.body = body;

                                    MOP.ajax('send_mop_log_email', data);
                                }

                                return false;
                            }*/

                            // Aggiungo un css dinamico alla pagina con dimensioni relative all'altezza della view.
                            MOP.TM.init();

                            var doneFunction = function () {
                              // Controlliamo in questo punto se ci sono delle notifiche da visualizzare perchè qui abbiamo caricato le lingue
                              if (MOP.localStorage.has('alert')) {
                                  var alert = MOP.localStorage.get('alert');

                                  MOP.execute('MOP:alert', alert);
                                  MOP.localStorage.unset('alert');
                              }
                              return MOP.start(initOptions);
                            }

                            var lang = MOP.config.get('lang');
                            var special_lang = MOP.config.getInstanceConfig('special_lang');
                            var defaultLang = MOP.config.getInstanceConfig('defaultLanguage');
                            var now = Date.now();

                            const configObj = {
                              version: MOP.config.get("langSpecificVersion"),
                              lang,
                              defaultLang,
                              instanceType: special_lang
                            }

                            // Se siamo su ambiente non produzione andiamo a mettere nel basepath il window.location.origin
                            // Perchè altrimenti i CORS ci bloccano l'accesso al CDN
                            if(!isProduction() && !MOP.Utilities.isMobileApp()) {
                              configObj.basePath = window.location.origin;
                            }

                            const phrases = await TTPolyglot.loadPhrases(configObj);
                            
                            TTPolyglot.extendPhrases(phrases);


                            const specifics = MOP.config.get('instance').spec_lang;
                            TTPolyglot.extendPhrases(specifics);

                            doneFunction();
                            

                            
                            // Altre gestioni nel caso di app mobile
                            if (MOP.Utilities.isMobileApp()) {
                            	MOP.MAM.init( initOptions );
                              MOP.on('subapp:start', function (page) {
                                // Fix momentaneo per questo collegato a un FD: https://git.tuotempo.net:8060/tuotempo/trunk/issues/164
                                if ((page == 'home' || page.indexOf("search") != -1) && MOP.isLoggedIn() && !MOP.isAdminLoggedIn()) MOP.MAM.initCheckinBanner();
                              });
                            }

                            MOP.addBeforeLeavingHandler();
                        })
                        .fail(function() {
                            MOP.Utilities.log(MOP, 'querystring_params failed!');
                        });
                })
                .fail(function() {
                    MOP.Utilities.log(MOP, 'localstorage failed!');
                });
        };

        var querystring_params = querystringToObject(window.location.search.substr(1));

        if (!MOP.Utilities.empty(querystring_params['forceMobileApp'])) {
            window.MOP_globals.fromMobileApp   = true;
            window.MOP_globals.isFakeMobileApp = true;
        }

        if (window.MOP_globals.fromMobileApp) {
          // Se siamo su Cordova aspettiamo l'evento deviceready
          // per startare il MOP altrimenti lo facciamo subito.

          var dbName = (querystring_params['dbName'] ? querystring_params['dbName'] : MOP_globals.dbName);

          MOP.DM.loadjscssfile('js/customers/' + dbName + '.js',
            function () {
              // NB => se stiamo simulando una mobile app => allora startiamo subito
              if ( window.MOP_globals.isFakeMobileApp ){
                start(querystring_params);
              } else{
                document.addEventListener('deviceready', function () {
                  //puglin Cordova che disabilita la visualizzazione dell'app in dark mode
                  WebViewManager.setForceDarkAllowed(false);

                  if (process.env.DISABLE_SSL_PINNING) {
                    start(querystring_params);
                  } else {
                    // il plugin ha bisogno del certificato nella cartella www/certificates del progetto cordova
                    // procede sempre con la callback di successo ma nel caso in cui non ci sia il certificato
                    // allora non permette le chiamate API
                    cordova.plugin.http.setServerTrustMode('pinned', 
                    () => {
                      // Possiamo chiamare la start mop
                      start(querystring_params);
                    }, 
                    () => {
                      console.log('error');
                    });
                  }
                }, false);
              }
            },
            function (err) {
              start(querystring_params);
            });
        } else {
            start(querystring_params);
        }
}
