import React from 'react';
import { Dialog } from 'common-lib/components/dialogs';
import MOP from 'app';
import { getLangs, getCurrentLang } from '../../common';

import { TTPolyglot } from "@frontend/tt-polyglot";

export const LanguageDialog = ({ open, onClose, onChange }) => {

  const langs = getLangs();
  const currentLang = getCurrentLang();
  
  return (
    <Dialog
      open={open}
      fullScreen={true}
      title={TTPolyglot.t('Mop Communication Language')}
      showCloseButton={true}
      onClose={onClose}
    >
      <div className='tt-container'>
        <div className='tt-input-group tt-container-list-radius'>
          {Object.keys(langs).map((lang, index) => {            
            return (
              <label data-e2eid={`language-menu-${lang}`} className="tt-input-radio js-no-required-validation" key={index} onClick={() => onChange(lang)}>
                <input type="radio" name="language" value={lang} className="tt-input-radio__input" checked={lang === currentLang} readOnly/>
                <div className="tt-input-radio__content">
                  <div className="tt-input-radio__content-text" dangerouslySetInnerHTML={{ __html: langs[lang] }}>
                  </div>
                  {lang === currentLang && <i className="tt-input-radio__content-icon tt-icon-check"></i>}
                </div>
              </label>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};