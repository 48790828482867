import MOP from 'app';
import { FACEBOOK_PIXEL } from '../constants';

export const FPAInitialize = (ids, options) => {
  try {

    return new Promise((resolve, reject) => {

      // Inserimento Script FB Pixel nell'head della pagina
      // gestendo gli id multipli ed anche le eventuali options inserite nel config
      const script_tag = document.createElement('script');
      script_tag.type = 'text/javascript';
      script_tag.text = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      ${ids.map(id => `fbq('init', '${id}'${!MOP.Utilities.empty(options) ? ", "+options : ""});\nfbq('track', 'PageView');`).join("\n")}
      fbq.disablePushState = true;`;

      // l'elemento noscript mi serve per caricare l'evento di pageview collegato al container con id passato in ingresso
      const noscript_tag = document.createElement('noscript');
      ids.map(id => {
        const img_tag = document.createElement('img');
        img_tag.height = "1";
        img_tag.width = "1";
        img_tag.style.display = "none";
        img_tag.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
        noscript_tag.appendChild(img_tag);
      });

      script_tag.onload = resolve(true);
      script_tag.onerror = reject(false);
      document.head.appendChild(script_tag);
      document.head.appendChild(noscript_tag);
    });
  } catch (error) {
    console.error("MonitoringProviders - Facebook Pixel Ads - FPAInitialize failed: ", error);
  }
};

/** FPATrackEvent
 * * Si occupa di inviare un evento a Facebook Pixel direttamente, oppure alla pagina padre se ci troviamo in un MOP embedded
 * 
 * @param {string} event - categoria dell'evento es: BackToAvailabilities, LoginDuringReservation ecc...
 * @param {object} value - oggetto che contiene tutti i dati utili per quell'evento
 * @returns 
 */
export const FPATrackEvent = (event, value) => {
  try {

    window.fbq('trackCustom', event, value ? value : {});

    if (process.env.NODE_ENV === `development`) {
      console.info(`MonitoringProviders - Facebook Pixel Ads - Event ${event} tracked.`);
    }
  } catch (error) {
    console.error("MonitoringProviders - Facebook Pixel Ads - FPATrackEvent failed: ", error);
  }
};