import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Divider } from '../Divider';
import { Icon } from '../Icon';
import { Checkbox } from '../Checkbox';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Input, SelectableList } from '../../index.js';

import { TTPolyglot } from "@frontend/tt-polyglot";


export const SelectSummary = ({ title, options, onSelect, error, showFilter }) => {

  const [selected, setSelected] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const selectRef = useRef(null);

  const theme = useTheme();

  const handleClick = option => {

    setSelected(option);
    setDialogOpen(!dialogOpen);

    onSelect && onSelect(option);

  };

  const search = value => {
    let newFilteredOption = [...options];
    if (value !== "") {
      newFilteredOption = options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
    }

    setFilteredOptions(newFilteredOption);
  };

  const handleOpenDialog = () => {
    setFilteredOptions(options);
    setDialogOpen(true);
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        closeButton={true}
        outClick={true}
        anchorEl={selectRef?.current}
        title={title}
      >
        <Dialog.Content>
          <div css={style.contentBase}>
            {
              showFilter &&
              <Input placeholder={TTPolyglot.t('Search')}
                icon="MagnifyingGlassLight"
                onChange={value => search(value)} />
            }
            <div>
              {
                filteredOptions?.length > 0 ?
                  filteredOptions.map((option, index, { length }) => <React.Fragment key={index}>
                    <div css={style.checkboxContainer} onClick={() => handleClick(option)}>
                      <Checkbox
                        type="round"
                        value={option}
                        checked={option.value === selected?.value}
                      >
                        <Label size='medium'>{option.label}</Label>
                      </Checkbox>
                    </div>
                    {index + 1 < length &&
                      <Divider type={"horizontal"} />
                    }
                  </React.Fragment>
                  ) :
                  <Label>{TTPolyglot.t("No Results")}</Label>
              }
            </div>


          </div>
        </Dialog.Content>
      </Dialog>

      <div css={style.base} onClick={handleOpenDialog}>
        <div css={style.selectContainer(theme)({ error: !!error })} ref={selectRef}>
          {
            selected ?
              <Label >
                {selected.label}
              </Label> :
              <Label color='text_light'>
                {TTPolyglot.t("MOP Summary Select")}
              </Label>
          }
          <Label color='link' >
            {TTPolyglot.t("Select")}
          </Label>
        </div>
        {!!error && <Label color={"danger"}>{error}</Label>}
      </div>

    </>
  )


};

SelectSummary.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape(
      {
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      }
    )
  ).isRequired,

  onSelect: PropTypes.func,
  error: PropTypes.string,
  showFilter: PropTypes.bool
};

SelectSummary.defaultProps = {
  title: null,
  options: [],
  onSelect: null,
  error: null,
  showFilter: true
};


