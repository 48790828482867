import { css } from "@emotion/react";
import chroma from "chroma-js";

export const base = theme => ({ show }) => css({
  height: "100%",
  left: 0,
  opacity: show ? 1 : 0,
  position: "fixed",
  transition: "all 0.3s ease-in-out",
  top: 0,
  width: "100%",
  zIndex: theme.layerIndex.modalOverlay,
});

export const overlay = theme => css({
  backgroundColor: chroma(theme.colors.black).alpha(0.5).hex(),
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: theme.layerIndex.modalOverlay,
});

export const container = theme => css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: theme.layout.gap.standard
});

export const wrapper = theme => ({ maxHeight, fullScreen, dialogPosition, customStyle, isMobile, overflow }) => css({
  backgroundColor: theme.colors.white,
  boxShadow: "0rem 0.425rem 3rem rgb(85 85 85)",
  borderRadius: fullScreen ? "0px" : theme.layout.borderRadius.medium,
  left: "50%",
  margin: "0 auto",
  padding: theme.layout.dialogBasePadding,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.layerIndex.modalContent,

  ...(
    fullScreen ? 
      // Se siamo in fullscreen, la larghezza deve essere sempre il 100%
      { width: "100%" } :
      // Se non siamo in fullscreen la larghezza deve essere 90% nel caso di mobile, oppure dialogBaseWidth nel caso di desktop
      {
        width: isMobile ? "90%" : theme.layout.dialogBaseWidth,
        // Se abbiamo passato un customStyle, non settiamo la maxWidth
        maxWidth: customStyle ? "" : theme.layout.dialogBaseWidth
      }
  ),

  height: fullScreen ? "100%" : "",
  maxHeight: (fullScreen || customStyle) ? "" : maxHeight, // Se abbiamo passato un customStyle, non settiamo la maxHeight

  overflowY: overflow ? "visible" : "auto",
  ...dialogPosition,
  ...customStyle
});

export const titleStyle = theme => ({ titlePresent }) => css({
  display: "flex",
  flexDirection: "row",
  // Se non ho titolo cambio il justify, in questo modo la X di chiusura è sempre posizionata sulla destra
  justifyContent: titlePresent ? "space-between" : "end",
  fontWeight: theme.fonts.weight.bold
});

export const footerStyle = theme => css({
  display: "flex",
  flexFlow: "row wrap",
  width: "100%",
  gap: theme.layout.gap.micro,
  justifyContent: "end"
});

export const close = theme => css({
  cursor: "pointer"
});