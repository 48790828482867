
import { colors } from './colors';
import { layout } from './layout';
import { fonts } from './fonts';

const defaultContainer = {
  borderColor: colors.gainsboro,
  borderRadius: layout.borderRadius.small,
  borderStyle: "solid",
  borderWidth: layout.borderWidth.standard,
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  gap: layout.gap.micro
};

const defaultInput = {
  backgroundColor: colors.white,
  border: "none",
  boxShadow: "none",
  borderRadius: layout.borderRadius.small,
  fontSize: fonts.size.medium,
  overflow: "hidden",
  padding: "0.75rem 1.625rem 0.75rem 1rem",
  whiteSpace: "normal",
  width: "100%",

  "::placeholder": {
    color: colors.manatee,
    fontSize: fonts.size.medium
  },

  ":focus, :active": {
    borderColor: colors.tufts_blue,
    caretColor: colors.tufts_blue,
    outline: "none",
    outlineOffsest: "-0.313rem",
  }
};

export const input = {
  container: {
    standard: { 
      normal: {
        ...defaultContainer,
        height: layout.baseHeights.normal 
      },
      small: {
        ...defaultContainer,
        height: layout.baseHeights.small 
      },
      big: {
        ...defaultContainer,
        height: layout.baseHeights.big 
      }
    },
    error: { 
      borderColor: colors.danger,
      backgroundColor: colors.danger_light
    },
    disabled: {
      cursor: "not-allowed",
      backgroundColor: colors.ghost_white
    }
  },

  base: {
    standard: { ...defaultInput },
    error: {
      backgroundColor: colors.danger_light
    },
    disabled: {
      cursor: "not-allowed",
      backgroundColor: colors.ghost_white,
      color: colors.manatee
    }
  }

};