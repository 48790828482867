import MOP from 'app';
import { theme } from 'uikit';

export const getTheme = () => {

  const _theme = { ...theme };

  _theme.colors.primary = `#${MOP.config.getInstanceConfig("mop_buttonBckgnd")}`;
  _theme.colors.secondary = `#${MOP.config.getInstanceConfig("mop_menuToolsBckgnd")}`;

  _theme.iframe = MOP.config.isInIFrame();
  _theme.MOPLauncher = MOP.config.isOldLauncher(MOP) ||  MOP.config.isNewLauncher(MOP); // da capire se questo nel nuovo è giusto non lo conosco, per ora manteniamo retrocompatibilità

  return _theme;
};
