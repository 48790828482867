import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Label } from "ui/Label";
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

export const Numeric = ({
  code,
  fontSize,
  message
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });


  return (
    <div css={style.base(theme)(isMobile)}>
      <Label size="medium" color="text_light">{message}</Label>
      {
        code ?
          <div css={style.number(theme)(fontSize)}>
            {code}
          </div> : null
      }
    </div>
  );
};

Numeric.propTypes = {
  code: PropTypes.string,
  fontSize: PropTypes.string,
  message: PropTypes.string,
};

Numeric.defaultProps = {
  code: null,
  fontSize: null,
  message: null,
};