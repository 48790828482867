import React, { useEffect } from 'react';
import MOP from 'app';
import { getTheme } from 'uikit-utils';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@emotion/react';
import { ResetStyle } from "uikit";

import { selectApiStatus, selectData } from './features/selectors';
import { getScore } from './features/thunk/thunkCertificationWidget';

import { API_STATUS_FULFILLED } from 'common-mop/constants';
import { Mobile } from './features/components/Mobile';
import { Desktop } from './features/components/Desktop';

import * as style from "./style.js";


const CertificationWidget = ({ }) => {

  const dispatch = useDispatch();
  const status = useSelector(selectApiStatus);
  const data = useSelector(selectData);

  useEffect(() => {
    dispatch(getScore());
  }, []);

  if (status !== API_STATUS_FULFILLED) return null;

  const open = () => {
    if (MOP.Utilities.isMobileApp()) {
      cordova.InAppBrowser.open(data.url, '_blank', 'location=no');
    } else {
      window.open(data.url, "_blank");
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <ResetStyle />
      <div css={style.base} onClick={open}>
        {MOP.config.isMobile() ?
          <Mobile stars={data.stars} opinions={data.total_opinion_count} /> :
          <Desktop stars={data.stars} percentage={data.certificate_value} opinions={data.total_opinion_count} />
        }
      </div>
    </ThemeProvider>
  );
};

export default CertificationWidget;