import * as React from "react";

const SvgFastCheckin = (props) => (
  <svg
    viewBox="0 0 52 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 6h14M1 14h16m-7 8h10" stroke="#3E5789" strokeLinecap="round" />
    <circle cx={37} cy={15} r={14.5} stroke="#3E5789" />
    <path
      d="M31 15.95 35.035 20 44 11"
      stroke="#3E5789"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFastCheckin;
