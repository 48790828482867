
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ui/Icon/index.jsx';

import * as style from "./style";

/**
 * Primary UI component for user interaction
 */
export const Loading = () => {

  return (
    <div css={style.base}>
      <Icon name={"SpinnerThirdSolid"} color={"primary"} size={"xl"} rotate={true}/>
    </div>
  );

};

Loading.propTypes = {

};

Loading.defaultProps = {

};