import { css } from "@emotion/react";

export const base = theme => css({
  width: "100%"
});

export const autocomplete = theme => css({
  backgroundColor: theme.colors.white,
  boxSizing: "border-box",
  display: "flex",
  height: theme.layout.baseHeights.normal,
  width: "100%",
  padding: theme.layout.padding.micro,
  gap: theme.layout.gap.micro,
});

export const input = theme => css({
  border: "none",
  boxShadow: "none",
  fontSize: theme.fonts.size.medium,
  overflow: "hidden",
  whiteSpace: "normal",
  width: "100%",

  "::placeholder": {
    color: theme.colors.manatee,
    fontSize: theme.fonts.size.medium
  },

  ":focus, :active": {
    borderColor: theme.tufts_blue,
    caretColor: theme.tufts_blue,
    outline: "none",
    outlineOffsest: "-0.313rem",
  },

});

export const iconContainer = theme => css({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  width: "2.375rem"
});

export const popoverContainer = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro
});

export const childContainer = theme => ({}) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.micro
});