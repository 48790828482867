import MOP from "app";
import ShowController from "apps/head/show/show_controller";

MOP.module("HeadApp", function (HeadApp, MOP, Backbone, Marionette, $, _) {
    HeadApp.page = 'head';

    HeadApp.onStart = function () {
        MOP.Utilities.log(MOP, "starting HeadApp");
    };

    HeadApp.onStop = function () {
        MOP.Utilities.log(MOP, "stopping HeadApp");
    };

    var API = {
        showHead: function () {
            ShowController.showHead();
        }
    };

    MOP.addInitializer(function () {
        API.showHead();
    });

});

export default MOP.HeadApp;
