import { css } from "@emotion/react";

export const base = theme => css({
  display: "flex",
  cursor: "pointer",
  minHeight: theme.layout.baseHeights.normal,
  alignItems: "center",
  padding: `${theme.layout.padding.xsmall} ${theme.layout.padding.standard}`,

}); 


