import { layout } from "./layout";

export const icons = {
  sizes: {
    micro: "0.625rem",
    small: "0.75rem", // 12px
    standard: "0.875rem", // 14px
    medium: "0.938rem", // 15px
    ml: "1rem", // 16px
    large: "1.125rem", // 18px
    xl: "1.5rem", // 24px
    xxl: "1.625rem", // 26px
    xxxl: "3.625rem", // 26px

    buttonIcon: layout.baseHeight, // Icona pulsante, prende la stessa dimensione del button
  },
};