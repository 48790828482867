import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Input } from '../Input';
import { Checkbox } from '../Checkbox';
import { Button } from '../../index.js';

import { TTPolyglot } from "@frontend/tt-polyglot";

import {
  STATUS_FILE_NOT_SUPPORTED,
  STATUS_FILE_SUPPORTED,
  STATUS_POLICY_ACCEPTED,
  STATUS_POLICY_DELETE_FILE,
  STATUS_READ_POLICY,
  STATUS_START
} from "./constants";

import { UploadProvider, useUploadContext } from './Context.js';

import { LineButton } from '../LineButton/index.jsx';
import { fileAllowed, INPUT_ACCEPT } from 'common-lib/file';

export const UploadInput = ({ label }) => {
  const theme = useTheme();

  const {
    status,
    setStatus,
    inputRef,
    uploadRef
  } = useUploadContext();

  const handleOnChange = e => {
    if (e.target.files && e.target.files.length !== 0) {
      const toUpload = e.target.files[0];

      fileAllowed(toUpload).then(allowed => {
        if (allowed) {
          setStatus(STATUS_FILE_SUPPORTED);
        } else {
          setStatus(STATUS_FILE_NOT_SUPPORTED);
        }
      });
    }
  }


  const renderContent = () => {
    if (status === STATUS_POLICY_ACCEPTED || status === STATUS_POLICY_DELETE_FILE) {
      // Policy Accettata, file caricato correttamente, mostro al posto dell'input, il file caricato con l'icona del cestino per cancellarlo
      return (
        <div css={style.baseUpload} ref={uploadRef}>
          <div css={style.container}>
            <div css={style.file}>
              <div css={style.fileContentLeft}>
                <Icon name={"FileLinesLight"} color={"primary"} size={"medium"} />
                <div css={style.fileName}>
                  <Label size={"medium"}>{label}</Label>
                  <Label size={"standard"} color={"text_light"}>{inputRef?.current?.files[0].name || null}</Label>
                </div>
              </div>
              <Label icon={{ name: "TrashCanLight", color: "danger" }} onClick={() => setStatus(STATUS_POLICY_DELETE_FILE)}></Label>
            </div>
          </div>

        </div>
      )
    }

    return (
      <div css={style.baseUpload} ref={uploadRef}>
        <div>
          <div css={style.containerContent}>
            <LineButton icon={"PlusSolid"} onClick={() => inputRef?.current.click()}>{TTPolyglot.t("MOP Add Document Button")}</LineButton>
          </div>
        </div>
      </div>
    );
  }



  return (
    <>
      <input
        ref={inputRef}
        css={style.input}
        type="file"
        accept={INPUT_ACCEPT}
        onChange={handleOnChange}
        onClick={e => e.target.value = null}
      />
      {renderContent()}
    </>
  )
};