import { colors } from "./colors";
import { fonts } from "./fonts";
import { icons } from "./icons";
import { layout } from "./layout";
import { layerIndex } from "./layerIndex";
import { input } from "./input";
import { button } from "./button";
import { image } from "./image";

export const theme = {
  colors,
  fonts,
  icons,
  layout,
  layerIndex,
  input,
  button,
  image,
  iframe: false,
  MOPLauncher: false
};
