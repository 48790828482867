import React from 'react';
import MOP from 'app';
import { connect } from 'react-redux';
import { itemClicked } from '../../common';
import { OutClick } from 'common-lib/components/hoc/OutClick';

const ProfileTab = ({ profile, disabled, open, onClick, hocRef, active }) => {

  if (!profile) return null;

  const checkActive = () => {
    const { page } = active;
    return page === 'login';
  };

  if (MOP.isOneTimeUser()) {
    return (
      <li className={`pageLink tt-navbar__link ${checkActive() ? 'active' : ''} ${disabled ? 'tt-pointer-disabled' : ''}`} onClick={() => itemClicked(profile)}>
        <a className="tt-navbar__link__text">
          {profile.label}
        </a>
      </li>
    );
  }

  return (
    <>
      {Array.isArray(profile) ?
        <li className={`dropdown ${disabled ? 'tt-pointer-disabled' : ''} tt-navbar__link ${open ? 'open' : ''}`} id="loggedInUserMenu" onClick={onClick} ref={hocRef}>
          <a className="dropdown-toggle tt-navbar__link__text" data-e2eid="logged-user-menu">
            <i className="tt-navbar__link__text-icon tt-icon-user"></i>
          </a>
          <ul className="dropdown-menu tt-navbar__link-dropdown" role="menu">
            {profile.map((item, index) => {
              return <li key={index} onClick={() => itemClicked(item)} className={`${disabled ? 'tt-pointer-disabled' : ''}`}><a className="pageLink" data-e2eid={item.e2eid}>{item.label}</a></li>;
            })}

          </ul>
        </li>
        :
        <li className={`pageLink tt-navbar__link ${checkActive() ? 'active' : ''} ${disabled && MOP.getCurrentRoute() !== 'login/otp' && MOP.isLoggedIn() ? 'tt-pointer-disabled' : ''}`} onClick={() => itemClicked(profile)}><a className="tt-navbar__link__text" data-e2eid={profile.e2eid}>{profile.label}</a></li>
      }
    </>
  );
};

const mapStateToProps = state => ({
  disabled: state.disabled,
  active: state.activePage
});

const mapDispatchToProps = dispatch => ({
});

const ProfileTabContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileTab);

export default OutClick(ProfileTabContainer);
