import { css } from "@emotion/react";


export const base = theme => css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.small,
  justifyContent: "center",
  width: "100%",
  flexWrap: "wrap"
});


export const input = theme => () => css({
  ...theme.input.base.standard,

  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: theme.layout.borderWidth.standard,
  padding: theme.layout.padding.small,
  textAlign: "center",
  width: "40px",


  ":focus, :active": {
    borderColor: theme.colors.tufts_blue,
  }
});

export const inputFilled = theme => () => css({
  borderColor: theme.colors.tufts_blue,
});


