import { css } from "@emotion/react";


export const base = theme => () => css({
  ...theme.button.base,

  backgroundColor: "transparent",
  color: theme.colors.auro_metal_saurus,
  gap: theme.layout.gap.small,
  height: theme.layout.baseHeights.normal,
  justifyContent: "start",
  padding: "0px",
  width: "initial"
});