import MOP from "app";
import View from "apps/head/show/show_view";

MOP.module("HeadApp.Show", function (Show, MOP, Backbone, Marionette, $, _) {

    Show.Controller = {
        showHead: function () {
            var head_view = new View.Head();

            head_view.renderAndAddToDom();
        }
    };
});

export default MOP.HeadApp.Show.Controller;
