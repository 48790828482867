
import { querystringToObject } from 'common-lib/url';
/**
 * Punto di compatibilità del nuovo flusso HTML in cui non abbiamo i MOP_globals
 * ma vogliamo aggiungerli perchè ci sono dei punti che li utilizzano ancora, quindi li andiamo a definire a mano
 */

if (!window?.MOP_globals) {

  const fromMobileApp = window.hostnameFromApp ? true : false;
  const e2e = window.e2e ? true : false;

  // Recuperto l'hostname da window.hostnameTestE2E se sono nei test oppure da window.hostnameFromApp se sono in app oppure da window.location negli altri casi
  const hostname = e2e ? window.hostnameTestE2E : (fromMobileApp ? window.hostnameFromApp : window.location.hostname);
  
  // Recupero il dbName dal window.dbNameTestE2E se sono nel flusso dei test, oppure da window.location negli altri casi
  const dbName = e2e ? window.dbNameTestE2E : querystringToObject(window.location.search.substr(1)).dbName;

  window.MOP_globals = {
    e2e,
    fromMobileApp,
    hostname,
    dbName,
    page: ""
  };
}