import React, { useState, useRef } from 'react';

import {
  STATUS_START
} from "./constants";

const UploadContext = React.createContext();

export const UploadProvider = ({ children, isAdmin, uploadDoc, deleteDoc }) => {

  const [status, setStatus] = useState(STATUS_START);

  const inputRef = useRef(null);
  const uploadRef = useRef(null); // riferimento che serve al dialog per posizionarsi correttamente se siamo in iframe
  // Gli admin hanno la privacy automaticamente checkata, a loro non viene renderizzata la checkbox
  const [privacyAccepted, setPrivacyAccepted] = useState(isAdmin);


  const value = {
    isAdmin,
    uploadDoc,
    deleteDoc,
    status,
    setStatus,
    inputRef,
    uploadRef,
    privacyAccepted,
    setPrivacyAccepted
  };


  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useUploadContext = () => {
  // Se lo useContext non è stato inizializzato, restituisco un oggetto vuoto
  const context = React.useContext(UploadContext);
  return context;
};