import MOP from 'app';

export const createToken = (entity, entityid, reason, loadingEnabled = false) => {
  const dataRequest = {};
  dataRequest.entity = entity;
  dataRequest.entityid = entityid;
  dataRequest.reason = reason;

  return new Promise((resolve, reject) => {
    const ajaxParams = {
      searchPath: `tokens`,
      ajax: {
        options: {
          method: 'POST'
        },
        data: dataRequest,
      },
      loading: {
        enabled: loadingEnabled
      }
    };

    MOP.ajaxRest(ajaxParams)
      .done(response => {
        const data = {
          token: response.return,
          expiration: response.expiration
        };
        resolve(data);
      })
      .fail(e => {        
        reject(e);
      });
  });
}
