import { css } from "@emotion/react";
import { theme } from "../../theme";

export const base = theme => ({ }) => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.standard
});

export const baseUpload = theme => css({
  display: "flex",
  flexDirection: "column",
  gap: theme.layout.gap.small,
});

export const container = theme => css({
  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: `${theme.layout.borderWidth.standard} 0rem`, // Vogliamo il bordo solo sopra e sotto
  borderLeft: "0px",
  borderRight: "0px",
  boxSizing: "border-box",
  display: "flex",
  gap: theme.layout.gap.small,
  minHeight: theme.layout.baseHeights.normal,
  width: "100%",
  alignItems: "center",
  padding: theme.layout.padding.standard,
});

export const containerContent = theme => ({ }) => css({
  display: "inline-block",
  position: "relative",
  color: theme.colors.primary,
  cursor: "pointer",
  overflow: "hidden",
  outline: "none",
  transition: ".3s",
  width: "100%",
  zIndex: theme.layerIndex.navigation,
});

export const label = theme => ({ }) => css({
  position: "block",
  cursor: "pointer"
});

export const input = theme => ({ }) => css({
  display: "none",
  zIndex: "-1",
});

export const inputContainer = theme => ({ error }) => css({
  ...theme.input.container.standard.big,
  ...(error && theme.input.container.error)
});

export const inputReview = theme => css({
  ...theme.input.base.standard
});

export const iconContainer = theme => css({
  alignItems: "center",
  display: "flex",
  fontSize: theme.icons.small,
  justifyContent: "center",
  padding: "0.5rem 0.5rem 0.5rem 1.125rem",
  width: "2.375rem"
});

export const list = theme => css({
  listStyle: "initial"
})

export const file = theme => css({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between"
});

export const fileName = theme => css({
  display: "flex",
  flexDirection: "column"
});

export const fileContentLeft = theme => css({
  display: "flex",
  flexDirection: "row",
  gap: theme.layout.gap.small
});