import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from "lodash";

import * as style from "./style.js";
import { Icon } from '../Icon';
import { Popover } from '../Popover/index.jsx';
import { Divider } from '../Divider/index.jsx';

import { OutClick } from 'common-lib/components/hoc/OutClick';

export const Autocomp = ({
  onSearch,
  placeholder,
  loading,
  children,
  open,
  hocRef,
  onClick
}) => {

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [clickEnabled, setClickEnabled] = useState(false);

  const debounceInput = useCallback(debounce(onSearch, 400), []);

  const autoselectNodes = React.Children.toArray(children);

  useEffect(() => {

    if (inputValue.length === 0) {
      debounceInput(inputValue);
      // setClickEnabled(true);
    }


    if (inputValue.length >= 3) {
      debounceInput(inputValue);
      setClickEnabled(true);
    }

  }, [inputValue]);

  useEffect(() => {
    if (children?.length === 0) {
      if (open === true) {
        onClick();
      }

      setClickEnabled(true);
      return;
    }

    if (open === true && clickEnabled) {
      onClick();
    } else if (open === false && clickEnabled) {
      onClick();
      setClickEnabled(false);
    }

  }, [children]);



  return (
    <div ref={hocRef} css={style.base}>
      <div css={style.autocomplete} ref={inputRef}>
        <div css={style.iconContainer}>
          <Icon name={"MagnifyingGlassLight"} color={"primary"} size={"small"} />
        </div>
        <input
          css={style.input}
          onChange={e => setInputValue(e.target.value)}
          placeholder={placeholder}
          onClick={() => {
            if (children.length === 0) return;

            if (open === false) {
              onClick();
            }

          }}
        />
        <div css={style.iconContainer}>
          {loading && <Icon name={"SpinnerThirdSolid"} color={"primary"} rotate={true} /> }
        </div>
      </div>
      {
        inputValue.length > 0 && // Se NON abbiamo scritto nulla nell'input, in ogni caso non voglio vedere nessun children
        <Popover open={open} anchorEl={inputRef.current} sameWidthOfAnchor={true} maxHeight={"180px"}>
          <div css={style.popoverContainer}>
            {
              autoselectNodes.map((node, index) => {
                if (!React.isValidElement(node)) return <></>;

                return (
                  <div key={index} css={style.childContainer}>
                    {
                      React.cloneElement(node, {
                        onClick: param => {
                          node.props.onClick(param);
                          if (open === true) {
                            onClick();
                            setClickEnabled(false);
                          }
                        },
                        searchedString: inputValue
                      })
                    }
                    {index < autoselectNodes.length - 1 && <Divider />}
                  </div>
                )
              })
            }
          </div>
        </Popover>
      }
    </div>
  );
};

Autocomp.propTypes = {
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  placeholder: PropTypes.string
};

Autocomp.defaultProps = {
  onSearch: null,
  loading: false,
  children: null,
  placeholder: ""
};


export const Autocomplete = OutClick(Autocomp);