
import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => css({
  display: "flex",
  flexDirection: "row",
  color: theme.colors.primary,
  cursor: "pointer",
  fontSize: theme.fonts.size.standard,
  fontWeight: theme.fonts.weight.regular,

  borderColor: theme.colors.gainsboro,
  borderStyle: "solid",
  borderWidth: `${theme.layout.borderWidth.standard} 0rem`, // Vogliamo il bordo solo sopra e sotto

  gap: theme.layout.gap.small,
  width: "100%",
  height: theme.layout.baseHeights.normal,
  padding: `0rem ${theme.layout.padding.standard}`,

  alignItems: "center",

  ":not(.complete):hover": {
    color: chroma(theme.colors.primary).alpha(0.6).hex(),
  },

  "&.complete": {
    cursor: "default"
  }
});

export const text = theme => ({}) => css({
  // serve perchè se il testo risulta troppo lungo, senza questa proprietà va a ridurre la 
  // grandezza dell'icona (anche se l'icona ha una sua larghezza fissata). con questa proprietà
  // lo span che contiene il testo non si estende in modo eccessivo
  width: "fit-content" 
});