export const getBaseUrl = () => {
  const { protocol, hostname } = window.location;
  return `${protocol}//${hostname}`;
};

export const getMOPBaseUrl = () => {
  return `${getBaseUrl()}/mop`;
};

export const getImageBaseUrl = (isMobileApp = false) => {
  if (isMobileApp) {
    return `assets/img`;
  }
  return `${getBaseUrl()}/mop/assets/img`;
};


/** addParamsToUrl
 * Riceve in ingresso una stringa url ed un oggetto { key: value }
 * aggiunge i parametri all'url utilizzando le API javascript
 * togliendo i parametri vuoti
 * @param {string} url 
 * @param {object} params 
 * @returns {string}
 */
export const addParamsToUrl = (url, keyValueParams = {}) => {
  try {
    const _url = new URL(url);

    Object.keys(keyValueParams).map(key => {
      const value = keyValueParams[key];
      if(value !== null && value !== undefined && value !== "") _url.searchParams.set(key, value);
    });

    return _url.toString();
  } catch (e) {
    return url;
  }
};


/**
 * Convert a querystring to Object.
 * @param {String} querystring - the querystring to convert
 * @return {Object}
 */
export const querystringToObject = (querystring, allowUndefinedValues) => {
  if (typeof querystring !== 'string') {
    throw new TypeError('The parameter has to be string');
  }

  const a = querystring.split('&');
  if (a === "") {
    return {};
  }
  const b = {};
  for (let i = 0; i < a.length; ++i) {
    const p = a[i].split('=');
    if (p[1] !== undefined) {
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    } else if (allowUndefinedValues) {
      b[p[0]] = undefined;
    }
  }
  return b;
};


export const isNet = () => {
  return window.location.host.indexOf('.net') !== -1;
};

export const isBeta = () => {
  return window.location.host.indexOf('beta.tuotempo.com') !== -1;
};

export const isProduction = () => {
  return window.location.host.indexOf('app.tuotempo.com') !== -1;
};

export const isTestProduction = () => {
  return window.location.host.indexOf('backend.tuotempo.com') !== -1;
};