import { css } from "@emotion/react";
import chroma from "chroma-js";


export const base = theme => ({ verticalAlign, direction, gap, columnGap, horizontalAlign, width, height, flexWrap }) => css({
  alignItems: verticalAlign || "initial",
  display: "flex",
  flexDirection: direction || "initial",
  flexWrap, 
  gap: theme.layout.gap[gap] || "0px",
  // Se non passiamo columnGap allora mettiamo questo valore come vuoto, perchè comanderà il gap generico
  columnGap: theme.layout.gap[columnGap] || "",
  justifyContent: horizontalAlign || "initial",
  width: width || "",
  height: height || "initial",
});