export const MOP_LAUNCHER_ORIGIN_CUSTOMER_ORIGINAL = 'customer_original';
export const MOP_LAUNCHER_ORIGIN_CUSTOMER_EMBEDDED_FROM_MOBILE = 'customer_embedded_redirect_mobile';
export const MOP_LAUNCHER_ORIGIN_WORKING_LIST = 'workinglist';
export const MOP_LAUNCHER_ORIGIN_VIDEO_CONSULTATION = 'videoconsultation';
export const MOP_LAUNCHER_ORIGIN_LEAD_MANAGEMENT = 'leadmanagement';
// Da rimuovere perchè axa non utilizza il launcher ma ci serve per evitare il resize dell'iframe in altezza e 
// lo scroll top e tutte le logiche legate all'iframe embedded che il flusso mop launcher spegne
export const MOP_LAUNCHER_AXA_TEMPORARY = 'axa'; 

export const SURVEY_ORIGIN_MOP = 'mop';

export const MAX_DIALOG_HEIGHT = 350;


export const INPUT_CUSTOM_FIELD = 0;
export const TEXTAREA_CUSTOM_FIELD = 1;
export const SELECT_CUSTOM_FIELD = 2;
export const DATE_INPUT_CUSTOM_FIELD = 3;
export const UPLOAD_CUSTOM_FIELD = 4;
export const SURVEY_CUSTOM_FIELD = 5;
export const PRIVACY_CUSTOM_FIELD = 6;
export const PRESCRIPTION_PRIORITY = 'prescription_priority';
export const MOP_PRIORITY = 'mopPriority';
export const PRESCRIPTION_CODE = 'prescription_code';
export const MOP_CODE = 'mopSummary';
export const PRESCRIPTION_SUMMARY = 'prescription_summary';
export const MOP_SUMMARY = 'mopCode';
export const PRESCRIPTION_DOCTOR = 'prescription_doctor';
export const MOP_DOCTOR = 'mopDoctor';
export const COMMUNICATION_EMAIL = 'communication_email';
export const COMMUNICATION_EMAIL_API_KEY = 'communicationEmail';
export const COMMUNICATION_PHONE = 'communication_phone';
export const COMMUNICATION_PHONE_API_KEY = 'communicationPhone';
export const MOP_BOOKABILITY_DEFERRED_EMAIL = 2;
export const MOP_BOOKABILITY_DEFERRED_URL = 3;
export const MOP_BOOKABILITY_DEFERRED_CREATE_LEAD = 4;


export const AUTH_MODE_FROM_SPID = "from_spid";

export const NEW_DOCUMENT_DOSSIER_THRESHOLD = 604800
