import { colors } from './colors';

export const layout = {
  baseHeights: {
    small: "32px",
    normal: "40px",
    big: "48px"
  },
  baseBoxShadow: `0px 4px 16px ${colors.spanish_gray}`,
  dialogBaseWidth: "520px",
  dialogBasePadding: "1.5rem",
  gap: {
    micro: "4px",
    small: "10px",
    standard: "16px",
    medium: "20px",
    large: "40px",
    xl: "50px"
  },
  borderRadius: {
    small: "0.25rem", //4px
    medium: "0.375rem",
    large: "1.875rem",
  },
  borderWidth: {
    standard: "0.063rem",
    medium: "0.125rem"
  },
  padding: {
    micro: "0.25rem",
    xsmall: "0.5rem",
    small: "0.75rem",
    standard: "1rem",
    medium: "1.5rem",
    large: "2rem",
  },

  mobileView: "699px",
  
}