import React from 'react';

import { Box, Label, Image, Rating } from 'uikit';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { getCountryLogo } from '../../common/utils';

import * as style from "../../style.js";

export const Desktop = ({ stars, percentage, opinions }) => {
  return (
    <div css={style.containerDesktop}>
      <Box gap={"small"} direction={"row"} horizontalAlign={"center"} verticalAlign={"baseline"}>
        <Label weight={"bold"} size={"large"}>{percentage}%</Label>
        <Label size={"small"}>{TTPolyglot.t("Mop Patient Satisfied")}</Label>
        <Rating value={stars} size={"ml"} />
        <Label size={"small"} >{opinions} {TTPolyglot.t("Mop Reviews Count Title")}</Label>
        <div css={style.logo}>
          <Image size={"xsmall"} src={getCountryLogo()} />
        </div>
      </Box>
    </div>
  );
};