import MOP from 'app';
import {
  SET_ACTIVE_PAGE,
  RIGHT_MENU_DROPDOWN_OPEN,
  SET_LOGGED_USER,
  SET_DISABLED,
  SET_HIDE_FAMILY_ITEM,
  SET_SHOWBACK,
  SET_UNREAD_NOTIFICATIONS,
  SET_HIDE_HEADER,
  SET_HIDE_BOTTOM_BAR,
  SET_SHOW_SIDE_MENU
} from '../common/types';
import { isHeaderDisabled, hideHeader } from '../../common';
import { getMessages } from 'api/messages';
import { filterByChannel } from 'entities/_message';

import { TTPolyglot } from "@frontend/tt-polyglot";

const pages = {
  profile: {
    pageTitle: 'Go Back',
  },
  login: {
    pageTitle: 'Go Back',
  },
  search: {
    pageTitle: '',
  },
  availabilities: {
    pageTitle: TTPolyglot.t('MOP Change Search'),
  },
  reservations: {
    pageTitle: 'Mop Appointments',
  },
  agenda: {
    pageTitle: 'Mop Appointments',
  },
  patients: {
    pageTitle: 'Menu patients',
  },
  blog: {
    pageTitle: 'App Menu Blog',
  },
  community: {
    pageTitle: 'App Menu Community',
  },
  downloads: {
    pageTitle: 'Medical Results',
  },
  find_doctor: {
    pageTitle: 'Go Back',
    pageTitlePreferred: 'App Talk Doctor',
  },
  find_location: {
    pageTitle: 'Find Locations',
  },
  first_availability: {
    pageTitle: 'First Availability',
  },
  notifications: {
    pageTitle: 'App Title Inbox',
  },
  payment: {
    pageTitle: 'MOP Prefs Payment',
  },
  prices: {
    pageTitle: 'App Menu Prices',
  },
  dossier: {
    pageTitle: TTPolyglot.t('Go Back'),
    pageTitleSharing: TTPolyglot.t('Dossier Page Share'),
    pageTitleShared: TTPolyglot.t('Shared Doc Page Title', null, true),
  },
  find_instances: {
    pageTitle: 'App Title Home',
  },
  delay: {
    pageTitle: 'App Title Delay',
  },
  talk_doctor: {
    pageTitle: 'MOP Back To Talk Doctor',
  },
  prescriptions: {
    pageTitle: 'App Title Searching',
  },
  maintenance: {
    pageTitle: 'App Maintenance',
  },
};

export const setLoggedUser = state => ({
  type: SET_LOGGED_USER,
  state
});

export const setRightMenuDropdown = state => ({
  type: RIGHT_MENU_DROPDOWN_OPEN,
  state
});

export const setActivePage = state => ({
  type: SET_ACTIVE_PAGE,
  state
});

export const setDisabled = state => ({
  type: SET_DISABLED,
  state
});

export const setHideFamilyItem = state => ({
  type: SET_HIDE_FAMILY_ITEM,
  state
});

export const setShowBack = state => ({
  type: SET_SHOWBACK,
  state
});

export const setUnreadNotifications = state => ({
  type: SET_UNREAD_NOTIFICATIONS,
  state
});

export const setHideHeader = state => ({
  type: SET_HIDE_HEADER,
  state
});

export const setHideBottomBar = state => ({
  type: SET_HIDE_BOTTOM_BAR,
  state
});

export const updateShowback = show => {
  return dispatch => {
    dispatch(setShowBack({ show: show, timestamp: Date.now() }));
  };
};

export const setShowSideMenu = state => ({
  type: SET_SHOW_SIDE_MENU,
  state
});

export const setRightPage = () => {
  return dispatch => {
    const page = MOP.currentApp && MOP.currentApp.page;
    const currentRoute = MOP.getCurrentRoute();
    let title = null;
    let route = null;

    if (page.indexOf('reservations') !== -1) {
      if (!MOP.Utilities.empty(currentRoute) && currentRoute.indexOf('cancel') !== -1) {
        route = 'cancel';
      } else if (!MOP.Utilities.empty(currentRoute) && currentRoute.indexOf('print') !== -1) {
        if(MOP.isLoggedIn()) {
          title = TTPolyglot.t("Mop Back To Reservations");
        } else {
          title = TTPolyglot.t("Onetime Kill Session");
        }
        if (!MOP.Utilities.empty(currentRoute) && currentRoute.indexOf('fromPage/home') !== -1) title = TTPolyglot.t("Mop Back To Home");
        if (!MOP.Utilities.empty(currentRoute) && currentRoute.indexOf('fromPage/cancel') !== -1) route = 'cancel';
      }
    }
    if (page.indexOf('availabilities') !== -1 && !MOP.Utilities.empty(currentRoute) && currentRoute.indexOf('summary') !== -1) {
      title = TTPolyglot.t("MOP Summary Back To Availabilities");
    }
    if (page.indexOf('profile') !== -1) {
      if (currentRoute && currentRoute.indexOf('ot_registration') !== -1) {
        title = TTPolyglot.t("Mop Mobile Onetime Header", [], true);
      } else if (currentRoute &&
        (currentRoute.indexOf('payment_method') !== -1 ||
          currentRoute.indexOf('family/list') !== -1 ||
          currentRoute.indexOf('advanced_options') !== -1)) {
        title = TTPolyglot.t("Back To Profile");
      }
    }
    if (page.indexOf('find_location') !== -1) {
      if (currentRoute && currentRoute.indexOf('area/') !== -1) {
        title = TTPolyglot.t('MOP Find Location Back To Locations');
      }
    }

    if (page === 'dossier') {
      if (currentRoute && currentRoute.indexOf('episodes/') !== -1) {
        title = TTPolyglot.t("Mop Dossier Back To Episodes");
      } else if (currentRoute &&
        (currentRoute.indexOf('viewer') !== -1 ||
          currentRoute.indexOf('sendByEmail') !== -1 ||
          currentRoute.indexOf('sharedDocuments') !== -1)) {
        title = TTPolyglot.t("Mop Dossier Back To Document");
      } else if (currentRoute && currentRoute.indexOf('documents') !== -1) {
        title = TTPolyglot.t("Mop Dossier Back To Episode");
      }
    }

    if (page === 'contactus') {
      if (currentRoute.indexOf('support') !== -1) {
        route = 'support';
      } if (currentRoute.indexOf('custom') !== -1) {
        route = 'custom';
      }
    }

    if (!title && pages[page]) {
      title = TTPolyglot.t(pages[page].pageTitle);
    }

    if(currentRoute === 'login/otp') {
      route = 'otp';
      dispatch(setDisabled(true));
    }
    dispatch(setActivePage({ page: page, route: route, title: title }));
  };

};

export const reload = user => {
  return dispatch => {

    dispatch(setDisabled(isHeaderDisabled(user || {})));

    if (MOP.config.isMobile()) {
      dispatch(setHideBottomBar(hideHeader()));
    } else {
      dispatch(setHideHeader(hideHeader()));
    }

    dispatch(setLoggedUser(user));
  };
};

export const familyItemChanged = () => {

  return (dispatch, getState) => {
    const user = getState().loggedUser;
    const actualParentsLength = Object.keys(user.parents).length;

    switch (actualParentsLength) {
      case 0:
        dispatch(setHideFamilyItem(true));
        MOP.changePage('profile', "profile/edit");
        break;
      case 1:
        dispatch(setHideFamilyItem(false));
      default:
        MOP.changePage('profile', "profile/family/list");
        break;
    }
  };
};

export const getUnreadNotifications = () => {
  return dispatch => {
    if(MOP.isAuthModeEnabled()) return;

    // Se la validazione è attiva e l'utente non è validato, allora non vogliamo chiamare la get messages
    if(MOP.isValidationEnabled() && !MOP.isLoggedUserValidated()) return;
    
    getMessages(MOP.getLoggedUserId(), true)
      .then(messages => {
        const notificationsRead = MOP.permanentLocalStorage.get('notificationsRead') || 0;
        const pushMessages = filterByChannel(messages, 'push').length;
        dispatch(setUnreadNotifications(pushMessages - notificationsRead));
        MOP.localStorage.set('unreadNotifications', pushMessages - notificationsRead);
      })
      .catch(() => dispatch(setUnreadNotifications(0)));

  };
};



